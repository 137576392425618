<kendo-popup
  *ngIf="isVisible"
  [anchor]="anchor"
  (anchorViewportLeave)="isVisible = false"
  [offset]="position"
>
  <div class="k-menu-popup pt-0 pb-0 pl-10 pr-10">
    <div class="d-flex justify-content-between align-items-center gap-10 flex-wrap mb-10">
      <p class="font-size-16 mb-0 fw-500 text-primary">Log time</p>
      <a class="view-logs-link" (click)="onViewTimelogClick()">View time logs</a>
    </div>
    <p class="font-size-14">
      Time logged:
      <strong>{{
        taskData.timelogMinutes
          ? commonService.getHoursAndMinutesFromTotalMinutes(taskData.timelogMinutes)
          : '0h 0m'
      }}</strong>
      <span *ngIf="false"> (Billable: <strong>0m</strong>) </span>
    </p>
    <div class="d-flex gap-10 flex-wrap">
      <button kendoButton [svgIcon]="commonService.clockIcon" (click)="onLogMoreTimeClick()">
        Log more time
      </button>
      <button kendoButton [svgIcon]="commonService.playIcon" (click)="onStartTimerClick()">
        Start timer
      </button>
    </div>
  </div>
</kendo-popup>
