<div class="q-content p-20">
  <div class="q-content-bg">
    <div class="q-content-header">
      <div class="d-flex justify-content-between mb-20 flex-wrap gap-20">
        <kendo-textbox
          placeholder="Search here"
          class="search"
          (valueChange)="onSearch($event)"
          [(ngModel)]="searchText"
          ><ng-template kendoTextBoxPrefixTemplate>
            <i class="fas fa-search k-icon font-size-14"></i> </ng-template
        ></kendo-textbox>
        <div class="d-flex gap-20 flex-wrap">
          <button kendoButton id="create" type="button" (click)="onUserAddEdit()">
            <span><i class="far fa-plus mr-10"></i>{{ '::Add New User' | abpLocalization }}</span>
          </button>
          <kendo-dropdownlist
            class="w-150"
            [data]="statusFilter"
            textField="text"
            valueField="value"
            [value]="selectedFilterValue"
            [valuePrimitive]="true"
            (selectionChange)="selectionChange($event)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              {{ dataItem.text }}
            </ng-template>
          </kendo-dropdownlist>
          <a class="flat-icon-container"><i class="fas fa-ellipsis-h"></i></a>
        </div>
      </div>
    </div>
    <div class="grid-wrapper">
      <kendo-grid
        [kendoGridBinding]="usersData"
        [pageSize]="5"
        [height]="gridHeight"
        [skip]="skip"
        [pageable]="
          usersData === undefined
            ? false
            : {
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: pagerposition,
              }
        "
        (pageChange)="pageChange($event)"
        [sortable]="{
          allowUnsort: true,
          mode: multiple ? 'multiple' : 'single',
        }"
        [sort]="sort"
        (sortChange)="sortChange($event)"
      >
        <kendo-grid-column field="People" title="Name" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.People }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="People" title="Job Title" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.People }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="People" title="Company" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.People }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="People" title="Email" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.People }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="People" title="Teams" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.People }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="Status" title="Status" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex align-items-center justify-content-between">
              <div *ngIf="dataItem.Status === statusType.Active">Active</div>
              <ul class="grid-action-list">
                <div class="d-flex gap-10">
                  <li>
                    <a title="Edit" (click)="onUserAddEdit(dataItem.id)">
                      <i class="far fa-pen"></i>
                    </a>
                  </li>
                  <li *ngIf="!dataItem.isDefault">
                    <a title="Delete" (click)="onDelete(dataItem)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
