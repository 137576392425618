import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AttachTaskFilePopupComponent } from '../../attach-task-file-popup/attach-task-file-popup.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { FileService } from 'projects/task-service/src/lib/proxy/service/task';
import { HttpClient } from '@angular/common/http';
import { FileVersionDto } from 'projects/file-service/src/lib/proxy/file-service/dtos';
import { environment } from 'src/environments/environment';
import { SessionStateService } from '@abp/ng.core';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import { ToasterService } from '@abp/ng.theme.shared';
import { Observable, Subscription, take } from 'rxjs';
import { Lightbox } from 'ngx-lightbox';
import { Store } from '@ngxs/store';
import { TaskState } from 'src/app/core/store/task.state';
import { GetFileList } from 'src/app/core/store/task.action';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit, OnDestroy {
  @Input() taskId: any;
  @Input() taskName: any;
  @Input() loadStateData: any;
  @Input() showTimeLogTaskDetail: boolean = false;

  fileList$: Observable<any[]>;

  reloadSubscription: Subscription;
  isImageAvailable: boolean[] = [];

  constructor(
    private dialogService: DialogService,
    private spinnerService: NgxSpinnerService,
    private fileService: FileService,
    private http: HttpClient,
    private sessionState: SessionStateService,
    public commonService: CommonService,
    private store: Store,
    private toasterService: ToasterService,
    private _lightbox: Lightbox,
  ) {}

  ngOnInit(): void {
    this.fileList$ = this.store.select(TaskState.getFileList);
    this.getFiles();
    this.fileList$.subscribe(files => {
      this.isImageAvailable = files.map(file => this.commonService.isFileTypeImage(file.fileName));
    });
  }

  onImageError(index: number): void {
    this.isImageAvailable[index] = false;
  }

  ngOnDestroy() {
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
    this._lightbox.close();
  }

  downloadFile(file: FileVersionDto): void {
    let apiURL = environment.apis.default.url;
    const tenant = this.sessionState.getTenant();

    if (tenant?.id) {
      apiURL = apiURL.replace('{0}', tenant?.name);
    } else {
      apiURL = apiURL.replace('{0}.', '');
    }

    this.http
      .get(`${apiURL}api/file/${file.fileId}/download`, {
        responseType: 'blob',
      })
      .subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);

          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = file.fileName;
          anchor.click();

          window.URL.revokeObjectURL(url);
        },
        error: error => {},
        complete: () => {
          this.toasterService.success(
            NotificationMessage.downloadFileSuccessMsg,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        },
      });
  }

  getFileIconClass(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'fas fa-image';
      case 'pdf':
        return 'fas fa-file-pdf';
      case 'xls':
      case 'xlsx':
        return 'fas fa-file-excel';
      default:
        return 'fas fa-file';
    }
  }

  openAttachedFile(): void {
    const dialogRef = this.dialogService.open({
      content: AttachTaskFilePopupComponent,
      width: 500,
      cssClass: 'attach-files-popup',
    });

    if (this.showTimeLogTaskDetail) {
      document.body.style.overflow = 'hidden';
    }

    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = this.taskName;
    dialogRef.result.subscribe(res => {
      if (res['confirmed']) {
        this.store.dispatch(new GetFileList(this.taskId)).subscribe();
      }
      if (this.showTimeLogTaskDetail) {
        document.body.style.overflow = 'auto';
      }
    });
  }

  getFiles(): void {
    if (this.loadStateData) {
      this.spinnerService.show();
      this.store.select(TaskState.getFileList).subscribe(() => {
        this.spinnerService.hide();
      });
    } else {
      this.store.dispatch(new GetFileList(this.taskId)).subscribe();
    }
  }

  onImageOpen(index: number): void {
    this.fileList$.pipe(take(1)).subscribe(files => {
      const imageFiles = files.filter(file => this.commonService.isFileTypeImage(file?.fileName));

      if (imageFiles.length === 0) {
        console.warn('Selected file is not an image.');
        return;
      }

      const validIndex = imageFiles.findIndex(file => file === files[index]);

      if (validIndex === -1) return;

      this._lightbox.open(imageFiles, validIndex, {
        disableScrolling: true,
        centerVertically: true,
        showDownloadButton: false,
      });
    });
  }

  onFileDelete(file: any) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + file.fileName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this._lightbox.close();
        this.fileService.delete(this.taskId, file.fileId).subscribe(
          () => {
            this.toasterService.success(
              NotificationMessage.deleteFileSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.store.dispatch(new GetFileList(this.taskId)).subscribe();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }
}
