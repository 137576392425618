import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { TimeLogPopupComponent } from '../time-log-popup/time-log-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/core/services';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { GetIdentityUsersInput, IdentityUserService } from '@abp/ng.identity/proxy';
import { ConfigStateService, ListService } from '@abp/ng.core';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionTypeEnum } from 'src/app/enum/status-type-enum';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { FormControl, FormGroup } from '@angular/forms';
import { TaskType } from 'src/app/enum/task-type';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommentService } from 'projects/comment-service/src/lib/proxy/comment-service';
import { CommentObjectType } from 'src/app/enum/comment-object-type-enum';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { DrawerMode, DrawerPosition } from '@progress/kendo-angular-layout';
import { ProjectUserService } from '@proxy/project-service/project';
import { Location } from '@angular/common';
import { TimeLogGridComponent } from '../shared/time-log-grid/time-log-grid.component';
import { SubTasksComponent } from '../shared/sub-tasks/sub-tasks.component';
import { FilesComponent } from '../shared/files/files.component';
import { TaskDateDto } from '../task-list';
import { Store } from '@ngxs/store';
import { AddTimelogList, SetTaskStatus } from 'src/app/core/store/task.action';
import { Observable } from 'rxjs';
import { TaskState } from 'src/app/core/store/task.state';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-add-task-detail',
  templateUrl: './add-task-detail.component.html',
  styleUrls: ['./add-task-detail.component.scss'],
})
export class AddTaskDetailComponent implements OnInit {
  //#region timmer
  isTimerRunning = false;

  startTime: Date;
  endTime: Date;

  timerInterval: any;
  startDate: any;
  dueDate: any;

  safeDescription: SafeHtml | undefined;
  //#endregion

  sorting = null;

  timerActionList = [
    { id: 1, iconClass: 'fa-solid fa-pen', text: 'Edit Task', actionTypeId: ActionTypeEnum.Edit },
    {
      id: 2,
      iconClass: 'fa-solid fa-download',
      text: 'Export Task Timelog',
      actionTypeId: ActionTypeEnum.Export,
    },
    {
      id: 3,
      iconClass: 'far fa-trash-alt',
      text: 'Delete Task',
      actionTypeId: ActionTypeEnum.Delete,
    },
  ];

  commentList: any[] = [];
  userList: any[] = [];
  fileList: any[] = [];
  subtaskList: any[] = [];
  assigneeList: any[] = [];
  assigneListItems: any[] = [];

  taskId: any;
  projectId: any;
  projectName: any;
  panelId: any;
  currentUserId: any;
  taskListId: any;
  taskDetails: any;
  tabId: any;

  actionTypeEnum = ActionTypeEnum;
  taskStatusType = TaskStatusType;
  taskForm: FormGroup;

  totalLoggedNonBillableTime: string;
  totalLoggedBillableTime: string;

  commentText = '';
  currentUser = '';
  currentUserSurname = '';

  editedRowIndex: number;
  commentCount: number;
  activeCommentId: number | null = null;
  editingCommentId: number | null = null;

  @Input() sortingName = '';

  isAllSubTaskCompleted = false;
  isEditorActive = false;
  isDrawerOpened = false;
  isProjectBillable = false;
  isShowAssigneeMultiSelect = false;
  isShowDueDateInput = false;
  openInNewTab = false;

  formGroup: FormGroup;
  updateCommentId: any;

  expandMode: DrawerMode = 'overlay';
  position: DrawerPosition = 'end';

  taskTypeList: { id: TaskType; name: string; iconClass: string }[] = [];

  customStyleContent = `
  .k-content * {
    font-family: 'Poppins', sans-serif;
    color: #404a5f;
  }

  .k-content .k-placeholder {
    font-size: 13px;
    color: #909EB2;
  }`;

  @ViewChild(TimeLogGridComponent) timelogComponent!: TimeLogGridComponent;
  @ViewChild(SubTasksComponent) subTasksComponent!: SubTasksComponent;
  @ViewChild(FilesComponent) filesComponent!: FilesComponent;

  totalLoggedTime$: Observable<any>;
  subTaskList$: Observable<any[]>;
  taskStatus$: Observable<any>;

  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private taskService: TaskService,
    public commonService: CommonService,
    private commentService: CommentService,
    public readonly list: ListService<GetIdentityUsersInput>,
    protected service: IdentityUserService,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private config: ConfigStateService,
    private projectService: ProjectService,
    private projectUser: ProjectUserService,
    private location: Location,
    private store: Store,
  ) {
    this.currentUser = this.config.getOne('currentUser').name;
    this.currentUserSurname = this.config.getOne('currentUser').surName;
    this.currentUserId = this.config.getOne('currentUser').id;
    this.taskTypeList = this.commonService.getTaskTypeList();
  }

  ngOnInit(): void {
    this.setForm();

    this.route.queryParams.subscribe(params => {
      this.taskId = +atob(params['taskId']);
      this.projectId = +atob(params['projectId']);
      this.projectName = atob(params['projectName']);
      this.taskListId = +atob(params['taskListId']);
      this.panelId = params['panelId'] ? +atob(params['panelId']) : undefined;
      this.tabId = params['tabId'] ? +atob(params['tabId']) : undefined;

      this.getTaskListDetails();
      this.getComments();
      this.getProjectDetail();
      this.getAssigneeList();
    });

    this.commonService.allTimeLogListFromTimer$.subscribe(() => {
      this.timelogComponent.getAllTimeLogList();
    });

    this.totalLoggedTime$ = this.store.select(TaskState.getTotalLoggedTime);

    this.taskStatus$ = this.store.select(TaskState.getTaskStatus);
  }

  setForm(): void {
    this.taskForm = new FormGroup({
      assignee: new FormControl(null),
      dueDate: new FormControl(''),
    });
  }

  getTaskListDetails(): void {
    this.taskService.get(this.taskId).subscribe(x => {
      this.taskDetails = x;
      this.store.dispatch(new SetTaskStatus(x.taskStatus));
      this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(this.taskDetails.description);

      this.taskForm.patchValue({
        assignee: x.users,
        dueDate: x.duedate ? new Date(x.duedate) : '',
      });

      this.store.select(TaskState.getSubTaskList).subscribe(subtasks => {
        const hasSubtasks = subtasks.length > 0;
        this.taskDetails.isAllSubTaskCompleted = hasSubtasks
          ? subtasks.every(task => task.taskStatus === TaskStatusType.Completed)
          : true;
      });
    });
  }

  onSave(): void {
    const dueDate = new Date(this.taskForm.controls.dueDate.value);

    const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(
      this.taskDetails.estimateMinutes,
    );

    const estimateTime = `${typeof hours === 'string' && hours === '' ? 0 : hours}:${
      typeof minutes === 'string' && minutes === '' ? 0 : minutes
    }`;

    const estimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes(estimateTime);

    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: this.taskId,
      name: this.taskDetails.name,
      description: this.taskDetails.description,
      progress: 20,
      startdate:
        this.taskForm.controls.dueDate.value === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      duedate:
        this.taskForm.controls.dueDate.value === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: +this.taskDetails.priority,
      estimateMinutes: estimateMinutes,
      taskListId: this.taskListId,
      parentTaskId: this.taskDetails.parentTaskId,
      notify: true,
      assignedToUserIds:
        this.taskForm.controls.assignee.value.length > 0
          ? this.taskForm.controls.assignee.value.map(item => item.userId)
          : [],
      taskType: this.taskDetails.taskType,
      taskStatus: this.taskDetails.taskStatus,
      subTasks: [],
    };

    this.taskService.update(this.taskId, param).subscribe(
      res => {
        this.spinnerService.hide();
        if (this.taskDetails) {
          this.taskDetails.users = this.taskForm.controls.assignee.value;
        }

        this.toasterService.success(
          NotificationMessage.updateTaskSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.isShowAssigneeMultiSelect = false;
        this.isShowDueDateInput = false;

        document.body.style.overflow = 'scroll';
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  back(): void {
    this.location.back();
  }

  getProjectDetail(): void {
    this.projectService.get(this.projectId).subscribe((res: any) => {
      this.isProjectBillable = res.isBillable;
    });
  }

  onTaskCompleteChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;

    if (this.subTasksComponent.allSubTaskCompleted()) {
      if (isChecked) {
        this.taskService.markTaskAsCompleteById(this.taskId).subscribe(() => {
          this.store.dispatch(new SetTaskStatus(TaskStatusType.Completed));
        });
      } else {
        this.taskService.markTaskAsReopenedById(this.taskId).subscribe(() => {
          this.store.dispatch(new SetTaskStatus(TaskStatusType.ReOpened));
        });
      }
    } else {
      this.toasterService.info(
        NotificationTextMessage.taskCompletedErrorMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      checkbox.checked = false;
    }
  }

  onActionItemClicked(event: any): void {
    switch (event.actionTypeId) {
      case ActionTypeEnum.Edit:
        this.editTask();
        break;
      case ActionTypeEnum.Delete:
        this.removeTask();
        break;
      case ActionTypeEnum.Export:
        // Handle export action
        break;
      default:
        break;
    }
  }

  editTask(): void {
    this.isDrawerOpened = !this.isDrawerOpened;
    this.taskId = this.taskId;
    this.projectId = this.projectId;

    this.assigneeList = this.assigneeList;

    document.body.style.overflow = 'hidden';
  }

  onTaskDrawerClose(event) {
    if (event) {
      this.ngOnInit();
    }
    this.isDrawerOpened = !this.isDrawerOpened;
  }

  removeTask(): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + this.taskDetails.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();
        this.taskService.delete(this.taskId).subscribe(
          res => {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationMessage.deleteTaskSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.redirectToTaskList();
          },
          error => {
            this.spinnerService.hide();
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  getQueryParams() {
    return {
      projectId: btoa(this.projectId),
      projectName: btoa(this.projectName),
      taskListId: btoa(this.taskListId),
      panelId: btoa(this.panelId),
      tabId: btoa(this.tabId),
    };
  }

  redirectToTaskList(event?: MouseEvent): void {
    this.openInNewTab = event?.ctrlKey || event?.metaKey;

    if (this.openInNewTab) return;

    event?.preventDefault();
    if (!this.openInNewTab) {
      const params: { [key: string]: string } = {
        projectId: btoa(this.projectId),
        projectName: btoa(this.projectName),
        panelId: btoa(this.panelId),
        taskListId: btoa(this.taskListId),
      };

      if (this.tabId) {
        params.tabId = btoa(this.tabId);
      }

      this.router.navigate(['task-list'], { queryParams: params });
    }
  }

  openTimeLog(taskName) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
    });
    document.body.style.overflow = 'hidden';

    this.startTime = new Date();
    this.endTime = new Date();
    dialogRef.content.instance.startTime = this.startTime;
    dialogRef.content.instance.endTime = this.endTime;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = taskName;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.isProjectBillable = this.isProjectBillable;

    dialogRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.store.dispatch(new AddTimelogList(res.data)).subscribe();
        }
        document.body.style.overflow = 'scroll';
      }
    });
  }

  onStartTimerClick() {
    const timerData = {
      showTimer: true,
      taskId: this.taskId,
      projectId: this.projectId,
    };

    this.commonService.showTimerPopup(timerData);
  }

  activateEditor() {
    this.isEditorActive = true;
  }

  cancelEdit() {
    this.isEditorActive = false;
    this.editingCommentId = null;
    this.updateCommentId = null;
    this.commentText = '';
  }

  saveComment() {
    this.isEditorActive = false;
    this.spinnerService.show();
    const plainTextComment = this.commentText.replace(/<[^>]*>/g, '');
    const createUpdateCommentParam: any = {
      body: plainTextComment,
      isPrivate: true,
      objectId: this.taskId,
      objectType: CommentObjectType.Task,
      htmlBody: this.commentText,
      lockdownId: 0,
      contentType: 0,
    };
    if (this.updateCommentId !== null && this.updateCommentId !== undefined) {
      this.commentService.update(this.updateCommentId, createUpdateCommentParam).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationTextMessage.commentUpdatedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.commentText = '';
            this.editingCommentId = null;
            this.updateCommentId = null;
            this.getComments();
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.commentService.create(createUpdateCommentParam).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationTextMessage.commentAddedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.commentText = '';
            this.editingCommentId = null;
            this.getComments();
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }
  }

  getComments(): void {
    this.spinnerService.show();
    const params = {
      taskId: this.taskId,
      sorting: null,
      skipCount: null,
      maxResultCount: null,
    };
    this.commentService.getList(params).subscribe(res => {
      this.spinnerService.hide();
      this.commentList = [];
      if (res.items.length > 0) {
        this.commentList = res.items;
        this.commentCount = res.totalCount;
      }
    });
  }

  toggleDropdown(commentId: number): void {
    this.activeCommentId = this.activeCommentId === commentId ? null : commentId;
  }

  editComment(commentId: any): void {
    this.activeCommentId = null;
    this.spinnerService.show();
    if (commentId) {
      this.commentService.get(commentId).subscribe(res => {
        this.editingCommentId = res.id;
        this.commentText = res.htmlBody;
        this.updateCommentId = res.id;
        this.spinnerService.hide();
      });
      this.isEditorActive = true;
    }
  }

  deleteComment(commentId: any): void {
    this.activeCommentId = null;
    if (commentId) {
      this.commentService.delete(commentId).subscribe(res => {
        this.toasterService.success('Delete Comment Succesfully', '', {});
        this.getComments();
      });
    }
  }

  formatDate(dateStr: string): string {
    const date: Date = new Date(dateStr);
    const now: Date = new Date();

    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    const diffInMs: number = now.getTime() - date.getTime(); // Difference in milliseconds
    const diffInSec: number = Math.floor(diffInMs / 1000); // Convert to seconds

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInMonth = 2592000; // Approximate (30 days)
    const secondsInYear = 31536000; // Approximate (365 days)

    if (diffInSec < secondsInMinute) {
      return `${diffInSec} seconds ago`;
    } else if (diffInSec < secondsInHour) {
      return `${Math.floor(diffInSec / secondsInMinute)} minutes ago`;
    } else if (diffInSec < secondsInDay) {
      return `${Math.floor(diffInSec / secondsInHour)} hours ago`;
    } else if (diffInSec < secondsInMonth) {
      return `${Math.floor(diffInSec / secondsInDay)} days ago`;
    } else if (diffInSec < secondsInYear) {
      return `${Math.floor(diffInSec / secondsInMonth)} months ago`;
    } else {
      return `${Math.floor(diffInSec / secondsInYear)} years ago`;
    }
  }

  getSanitizedHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getAssigneeList(): void {
    const params = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    };
    this.projectUser.getPeople(this.projectId, params).subscribe(res => {
      if (res.items.length > 0) {
        this.assigneeList = this.commonService.preprocessAssigneeList(res.items);
        this.assigneListItems = this.assigneeList.map(assignee => ({
          userName: assignee.userName,
          userId: assignee.userId,
        }));
      }
    });
  }

  onAssigneeSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { userName: string; userId: number }) =>
      item.userName.toLowerCase().includes(value.toLowerCase());

    this.assigneeList = this.assigneListItems.filter(contains(searchTerm));
  }
}
