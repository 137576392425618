import { Component } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { PageChangeEvent, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { CommonService } from 'src/core/services';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CategoryDto,
  CategoryService,
} from 'projects/project-service/src/lib/proxy/project-service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent {
  categoryData = { items: [], totalCount: 0 } as PagedResultDto<CategoryDto>;
  gridHeight: number;
  public skip = 0;
  public sorting = null;
  public multiple = false;
  public allowUnsort = true;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  searchText = '';
  public pageSize = 10;
  isNoRecords = false;
  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];
  public statusFilter: Array<{ text: string; value: number }> = [{ text: 'All Status', value: 1 }];
  public selectedFilterValue = 1;
  constructor(
    private dialogService: DialogService,
    private categoryService: CategoryService,
    public readonly list: ListService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.getCategoryList();
    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.calculateGridHeight();
    });
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 72;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  onSearchTextChange(searchText: string): void {
    if (!searchText.trim()) {
      this.searchText = '';
      this.getCategoryList();
    }
  }

  getCategoryList(): void {
    this.spinnerService.show();
    const param: any = {
      sorting: this.sorting,
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      searchTerm: this.searchText,
    };
    this.categoryService.getList(param).subscribe((res: any) => {
      this.spinnerService.hide();
      this.categoryData = res;
      this.isNoRecords = true;
    });
  }

  onCategoryAdd(categoryId?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddCategoryComponent,
      width: 450,
    });
    const attachFileInfo = dialogRef.content.instance as AddCategoryComponent;
    attachFileInfo.categoryId = categoryId;
    attachFileInfo.setOnLoadData();
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getCategoryList();
      }
    });
  }

  onEditCategory(id: any): void {
    const dialogRef = this.dialogService.open({
      content: AddCategoryComponent,
      width: 450,
    });
    dialogRef.content.instance.categoryId = id;

    dialogRef.result.subscribe(res => {
      if (res) {
        this.getCategoryList();
      }
    });
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getCategoryList();
  }

  selectionChange(event): void {}

  onDelete(categoryData) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + categoryData.categoryName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.categoryService.delete(categoryData.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteCategorySuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.getCategoryList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }
}
