<h2>{{ 'AbpSettingManagement::Menu:Emailing' | abpLocalization }}</h2>

<hr class="my-3" />

<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="submit()"
  [validateOnSubmit]="true"
  class="k-form k-form-md abp-md-form"
>
  <div class="mb-3 form-group">
    <label class="form-label"
      >{{ 'AbpSettingManagement::DefaultFromDisplayName' | abpLocalization
      }}<span class="ms-1">*</span></label
    >
    <input type="text" class="form-control" formControlName="defaultFromDisplayName" />
  </div>
  <div class="mb-3 form-group">
    <label class="form-label"
      >{{ 'AbpSettingManagement::DefaultFromAddress' | abpLocalization
      }}<span class="ms-1">*</span></label
    >
    <input type="text" class="form-control" formControlName="defaultFromAddress" />
  </div>
  <div class="mb-3 form-group">
    <label class="form-label">{{ 'AbpSettingManagement::SmtpHost' | abpLocalization }}</label>
    <input type="text" class="form-control" formControlName="smtpHost" />
  </div>
  <div class="mb-3 form-group">
    <label class="form-label"
      >{{ 'AbpSettingManagement::SmtpPort' | abpLocalization }}<span class="ms-1">*</span></label
    >
    <input type="number" class="form-control" formControlName="smtpPort" />
  </div>

  <div class="form-check mb-2">
    <input
      type="checkbox"
      id="smtp-enable-ssl"
      class="form-check-input"
      formControlName="smtpEnableSsl"
    />
    <label class="form-check-label" for="smtp-enable-ssl">{{
      'AbpSettingManagement::SmtpEnableSsl' | abpLocalization
    }}</label>
  </div>
  <div class="form-check mb-2">
    <input
      type="checkbox"
      id="smtp-use-default-credentials"
      class="form-check-input"
      formControlName="smtpUseDefaultCredentials"
    />
    <label class="form-check-label" for="smtp-use-default-credentials">{{
      'AbpSettingManagement::SmtpUseDefaultCredentials' | abpLocalization
    }}</label>
  </div>

  <div
    [@collapse]="{
      value: form.get('smtpUseDefaultCredentials')?.value ? 'collapsed' : 'expanded',
      params: { time: '200ms', easing: 'linear' },
    }"
  >
    <div class="mb-3 form-group">
      <label class="form-label">{{ 'AbpSettingManagement::SmtpDomain' | abpLocalization }}</label>
      <input type="text" class="form-control" formControlName="smtpDomain" />
    </div>

    <div class="mb-3 form-group">
      <label class="form-label">{{ 'AbpSettingManagement::SmtpUserName' | abpLocalization }}</label>
      <input type="text" class="form-control" formControlName="smtpUserName" />
    </div>

    <div class="mb-3 form-group">
      <label class="form-label">{{ 'AbpSettingManagement::SmtpPassword' | abpLocalization }}</label>
      <input type="password" class="form-control" formControlName="smtpPassword" />
    </div>
  </div>

  <hr />

  <button type="submit" class="btn btn-primary">
    {{ 'AbpSettingManagement::Save' | abpLocalization }}
  </button>
  <button
    type="button"
    (click)="openSendEmailModal()"
    class="btn btn-primary mx-2"
    *abpPermission="emailingPolicy"
  >
    <i class="fa f-send" aria-hidden="true"></i>
    {{ 'AbpSettingManagement::SendTestEmail' | abpLocalization }}
  </button>
</form>

<div class="authentication-settings">
  <kendo-dialog
    title="  {{ 'SendTestEmail' | abpLocalization }}"
    *ngIf="isAddEditOpened"
    (close)="close('cancel')"
    [minWidth]="250"
    [width]="450"
    [height]="650"
  >
    <form [formGroup]="emailTestForm" id="emailTestForm" class="k-form k-form-md">
      <div class="mb-3 form-group">
        <label class="form-label">{{
          'AbpSettingManagement::SenderEmailAddress' | abpLocalization
        }}</label>
        <kendo-textbox formControlName="senderEmailAddress" class="form-control"></kendo-textbox>
      </div>

      <div class="mb-3 form-group">
        <label class="form-label">{{
          'AbpSettingManagement::TargetEmailAddress' | abpLocalization
        }}</label>
        <kendo-textbox formControlName="targetEmailAddress" class="form-control"></kendo-textbox>
      </div>

      <div class="mb-3 form-group">
        <label class="form-label">{{ 'AbpSettingManagement::Subject' | abpLocalization }}</label>
        <kendo-textbox formControlName="subject" class="form-control"></kendo-textbox>
      </div>

      <div class="mb-3 form-group">
        <label class="form-label">{{ 'AbpSettingManagement::Body' | abpLocalization }}</label>
        <kendo-textarea formControlName="body" class="form-control"></kendo-textarea>
      </div>
    </form>
    <kendo-dialog-actions>
      <button
        kendoButton
        (click)="emailTestFormSubmit()"
        [disabled]="emailTestForm.invalid && emailTestForm.dirty"
        themeColor="primary"
      >
        {{ 'AbpTenantManagement::Send' | abpLocalization }}
      </button>
      <button kendoButton (click)="close('no')">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
