<div class="q-content p-20">
  <div class="q-content-bg">
    <div class="q-content-header">
      <div class="d-flex justify-content-between mb-20 flex-wrap gap-20">
        <kendo-textbox
          placeholder="Search for product name"
          class="search"
          (keyup.enter)="onSearch($event)"
          [(ngModel)]="searchText"
          (ngModelChange)="onSearchTextChange($event)"
          ><ng-template kendoTextBoxPrefixTemplate>
            <i class="fas fa-search k-icon font-size-14"></i> </ng-template
        ></kendo-textbox>
        <div class="d-flex gap-20 flex-wrap">
          <button kendoButton id="create" type="button" (click)="onProductAdd(0)">
            <span
              ><i class="far fa-plus mr-10"></i>{{ '::Add New Product' | abpLocalization }}</span
            >
          </button>
        </div>
      </div>
    </div>
    <div class="grid-wrapper">
      <kendo-grid
        [data]="gridView"
        [resizable]="true"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="
          gridView === undefined
            ? false
            : {
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: pagerposition,
              }
        "
        (pageChange)="pageChange($event)"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [height]="gridHeight"
        class="custom-no-records-container"
      >
        <ng-template kendoGridNoRecordsTemplate>
          <div
            *ngIf="isNoRecords && this.gridView.data.length === 0"
            class="no-records-text-container"
          >
            No Records Available
          </div>
        </ng-template>

        <kendo-grid-column field="name" title="Product Name" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex gap-15">
              <i class="far fa-envelope-open-text"></i>
              <div class="d-flex flex-column project-details">
                <a
                  class="wmx-100 clamp text-underline"
                  [routerLink]="['/project-list']"
                  [queryParams]="getQueryParams(dataItem)"
                  [target]="openInNewTab ? '_blank' : ''"
                  (click)="onProductClick(dataItem, $event)"
                  title="{{ dataItem.name }}"
                >
                  {{ dataItem.name }}
                </a>
                <span *ngIf="dataItem.projectIds">{{ dataItem.projectIds?.length }} Project</span>
              </div>
            </div>
          </ng-template> </kendo-grid-column
        ><kendo-grid-column field="lastVisit" title="Last Visited" [width]="200" [hidden]="true">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.lastVisit | date: 'yyyy-MM-dd' }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="CreatedBy" title="Created By" [width]="200" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex align-items-center gap-10">
              <kendo-avatar
                rounded="full"
                size="large"
                [border]="true"
                [initials]="commonService.getInitials(getCreatedByName(dataItem.creatorId))"
                [style.backgroundColor]="
                  commonService.generateAvtarColor(
                    dataItem.creatorId,
                    getCreatedByName(dataItem.creatorId)
                  )
                "
                class="social-profile-text"
              ></kendo-avatar>
              <span class="clamp" title="{{ getCreatedByName(dataItem.creatorId) }}">{{
                getCreatedByName(dataItem.creatorId)
              }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="status" title="Status" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex align-items-center justify-content-between">
              <div *ngIf="dataItem.status === statusType.Active">Active</div>
              <ul class="grid-action-list">
                <div class="d-flex gap-10">
                  <li>
                    <a title="Edit" (click)="onProductAdd(dataItem.id)">
                      <i class="far fa-pen"></i>
                    </a>
                  </li>
                  <li *ngIf="!dataItem.isDefault">
                    <a title="Delete" (click)="onDelete(dataItem)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
