<kendo-dialog-titlebar (click)="onCloseDialog()">
  <div>{{ logTimeTitle }}</div>
</kendo-dialog-titlebar>
<div>
  <div class="primary-card mb-1 mt-1">
    <p class="m-0">
      Task: <span class="text-primary">{{ taskName }}</span>
    </p>
  </div>
  <form class="k-form k-form-md" [formGroup]="logTimeForm" validateOnSubmit>
    <div>
      <div class="mb-1">
        <kendo-label text="Date" labelCssClass="k-form-label"></kendo-label>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <kendo-datepicker
              calendarType="classic"
              formControlName="date"
              [format]="'EEE, d MMM yyyy'"
              (valueChange)="updateDate($event)"
            ></kendo-datepicker>
          </div>
          <kendo-avatar
            [initials]="commonService.getInitials(currentUser.userName)"
            [style.backgroundColor]="
              commonService.generateAvtarColor(currentUser.id, currentUser.userName)
            "
          ></kendo-avatar>
        </div>
      </div>
      <div class="mb-1">
        <div class="d-flex gap-10 mb-1">
          <div>
            <kendo-label text="Start Time">
              <kendo-timepicker
                [(ngModel)]="startTime"
                formControlName="startTime"
                (ngModelChange)="calculateTimeSpent()"
                [popupSettings]="{ popupClass: popupClass }"
              ></kendo-timepicker>
            </kendo-label>
          </div>
          <div>
            <kendo-label text="End Time">
              <kendo-timepicker
                [(ngModel)]="endTime"
                formControlName="endTime"
                (ngModelChange)="calculateTimeSpent()"
                [popupSettings]="{ popupClass: popupClass }"
              ></kendo-timepicker>
            </kendo-label>
          </div>
          <div>
            <kendo-label
              labelCssClass="k-form-label"
              [for]="timeSpent"
              text="Time Spent"
            ></kendo-label>
            <kendo-textbox
              [(ngModel)]="timeSpent"
              formControlName="timeSpent"
              required
              [readonly]="true"
              class="disabled"
            ></kendo-textbox>
          </div>
        </div>
        <div
          *ngIf="
            logTimeForm.hasError('invalidTimeRange') &&
            (logTimeForm.get('startTime').dirty || logTimeForm.get('endTime').dirty)
          "
        >
          <span class="error">End time cannot be greater then start time</span>
        </div>
        <div *ngIf="isProjectBillable">
          <kendo-label labelCssClass="k-form-label" text="Billable Hours"></kendo-label>
          <div class="d-flex gap-10">
            <div class="d-flex align-items-center flex-column">
              <kendo-numerictextbox
                class="w-80"
                formControlName="hours"
                required
                [min]="0"
                [format]="'0'"
                [spinners]="false"
                placeholder="Hours"
              ></kendo-numerictextbox>
              <span class="text-gray">hours</span>
            </div>
            <div class="d-flex align-items-center flex-column">
              <kendo-numerictextbox
                class="w-80"
                formControlName="minutes"
                required
                [min]="0"
                [format]="'0'"
                [spinners]="false"
                placeholder="Minutes"
                (blur)="onMinutesBlur()"
              ></kendo-numerictextbox>
              <span class="text-gray">minutes</span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('hours', 2)"
              >2h</a
            >
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('hours', 1)"
              >1h</a
            >
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('minutes', 45)"
              >45m</a
            >
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('minutes', 30)"
              >30m</a
            >
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('minutes', 15)"
              >15m</a
            >
          </div>
        </div>
      </div>
      <div class="mb-1">
        <div>
          <kendo-label text="Description" labelCssClass="k-form-label">
            <kendo-textarea
              placeholder="Description"
              [rows]="3"
              resizable="vertical"
              formControlName="description"
            ></kendo-textarea>
            <kendo-formerror *ngIf="logTimeForm.controls.description.errors?.maxlength"
              >Error: Description cannot be more than 5000 character!</kendo-formerror
            >
          </kendo-label>
        </div>
        <div class="mt-10">
          <input type="checkbox" #taskComplete kendoCheckBox formControlName="taskComplete" />
          <kendo-label
            class="k-checkbox-label"
            [for]="taskComplete"
            text="Mark as task Complete"
          ></kendo-label>
        </div>
      </div>
    </div>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton fillMode="outline" (click)="onCloseDialog()">Close</button>
  <!-- <button kendoButton>Log Time</button> -->
  <button
    kendoButton
    (click)="saveLogTime(logTimeForm.value)"
    themeColor="primary"
    class="k-button-solid-base"
  >
    {{ logTimeTitle }}
  </button>
</kendo-dialog-actions>
