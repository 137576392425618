import { DatePipe, formatDate } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, WindowService } from '@progress/kendo-angular-dialog';
import { GridDataResult, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { DrawerMode, DrawerPosition } from '@progress/kendo-angular-layout';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimelogGetListInput } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { CommonService, UrlShortenerService } from 'src/core/services';
import { TimeLogPopupComponent } from '../../time-log-popup/time-log-popup.component';
import { BillableEnum, ExportFormat } from 'projects/task-service/src/lib/proxy/service/enum';
import { ConfigStateService, PagedResultDto } from '@abp/ng.core';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { saveAs } from 'file-saver';
import { ProjectUserService } from '@proxy/project-service/project';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { IdentityUserDto, IdentityUserService } from '@abp/ng.identity/proxy';
import { TaskListTabEnum } from 'src/app/enum/tasklist-tab-enum';
import { GetProjectListInputDto } from 'projects/project-service/src/lib/proxy/project-service/projects/dtos';
import { TimeComponentFilterType } from 'src/app/enum/time-filter-types-enum';
import { Store } from '@ngxs/store';
import {
  AddTimelogList,
  DeleteTimelogList,
  GetTimeLogList,
  SetTaskStatus,
  SetTimelogList,
} from 'src/app/core/store/task.action';
import { combineLatest, map, Observable, Subscription, take } from 'rxjs';
import { TaskState } from 'src/app/core/store/task.state';
import swal from 'sweetalert/dist/sweetalert.min.js';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent implements OnInit, AfterViewInit, OnDestroy {
  gridView: GridDataResult;
  summary: any;

  dateError = false;
  isDrawerOpened = false;
  openInNewTab = false;
  isAdmin: boolean = false;
  info = true;
  pageSizes = true;
  previousNext = true;
  isBillableColumnVisible = true;
  isFilteredDataAvailable = false;
  showFilterDate: boolean = false;
  showBillableFilter: boolean = false;
  isProjectBillable: boolean = false;
  isTimePage: boolean = false;

  gridHeight: number;
  projectSummary: any;

  type: PagerType = 'numeric';
  pagerposition: PagerPosition = 'bottom';
  expandMode: DrawerMode = 'overlay';
  position: DrawerPosition = 'end';

  selectedFilterValue = 1;
  selectedBillableFilterValue = 0;
  selectedBillableFilterText: string = '';
  public pageSize: number = 100;
  skip = 0;
  filterCount: number = 0;
  buttonCount = 5;

  startDate = new Date();
  endDate = new Date();
  formattedDateRange: string = '';

  userList: any[] = [];
  selectedUserIds: string[] = [];
  selectedUsers: any[] = [];
  projectList: any[] = [];
  selectedProjectIds: string[] = [];
  selectedProject: any[] = [];
  projectListItems: any[] = [];
  userListItems: any[] = [];

  peopleList = { items: [], totalCount: 0 } as PagedResultDto<IdentityUserDto>;

  exportActionList = [
    { id: 1, iconClass: 'fas fa-file-pdf', text: 'To PDF', actionTypeId: ExportFormat.PDF },
    {
      id: 2,
      iconClass: 'fas fa-file-excel',
      text: 'To Excel',
      actionTypeId: ExportFormat.Excel,
    },
  ];

  dateFilter: Array<{ text: string; value: number }> = [
    { text: 'Anytime', value: 0 },
    { text: 'Custom date range', value: 1 },
  ];

  billableFilterList: Array<{ text: string; value: number }> = [
    { text: 'Billable and non billable', value: BillableEnum.All },
    { text: 'Billable only', value: BillableEnum.Billable },
    { text: 'Non billable only', value: BillableEnum.NonBillable },
  ];

  filterTypes = TimeComponentFilterType;
  currentUser: any;

  private refreshTimeSubscription: Subscription;

  @Input() allowGrouping: boolean = true;
  @Input() taskName: string = '';
  @Input() showProjectSummary: boolean = false;
  @Input() showTimeLogTaskDetail: boolean = false;
  @Input() isDrawerEditContent: boolean = false;
  @Input() editTaskId: number = null;
  @Input() projectId: number = null;
  @Input() taskId: number = null;
  @Input() isCurrentUserResponsiblePerson: boolean = false;

  timeLogList$: Observable<any[]>;
  totalLoggedTime$: Observable<any>;
  totalLoggedNonBillableTime$: Observable<any>;
  totalLoggedBillableTime$: Observable<any>;
  totalLoggedEstimate$: Observable<any>;
  totalLoggedNotBilled$: Observable<any>;
  timeLogTotal$: Observable<any>;
  timeList$: Observable<any>;

  @ViewChild('filterContainer') filterContainer!: ElementRef;

  constructor(
    private timelogService: TimelogService,
    private spinnerService: NgxSpinnerService,
    private datePipe: DatePipe,
    public commonService: CommonService,
    private dialogService: DialogService,
    private router: Router,
    private toasterService: ToasterService,
    public projectUser: ProjectUserService,
    private projectService: ProjectService,
    private config: ConfigStateService,
    protected service: IdentityUserService,
    private store: Store,
    private urlShortener: UrlShortenerService,
    private windowService: WindowService,
    private cdr: ChangeDetectorRef,
  ) {
    const currentUserRole: any[] = this.config.getOne('currentUser').roles;
    this.isAdmin = currentUserRole.includes('admin');
    this.currentUser = this.config.getOne('currentUser');

    this.store.dispatch(new SetTimelogList());

    this.timeLogList$ = this.store.select(TaskState.getTimelogList);
    this.timeLogTotal$ = this.store.select(TaskState.getTimelogCount);
    this.timeList$ = combineLatest([this.timeLogList$, this.timeLogTotal$]).pipe(
      map(([data, total]) => ({
        data: data || [],
        total: total || 0,
      })),
    );
  }

  ngOnInit(): void {
    const currentUrl = this.router.url.split('?')[0];
    if (currentUrl === '/time') {
      this.isTimePage = true;
    }

    this.clearFilter();
    this.getProjectList();
    if (!this.isTimePage) this.getProjectSummary();
    if (this.isAdmin || this.isCurrentUserResponsiblePerson) this.getPeopleList();

    this.timeLogList$ = this.store.select(TaskState.getTimelogList);
    this.timeLogTotal$ = this.store.select(TaskState.getTimelogCount);
    this.totalLoggedTime$ = this.store.select(TaskState.getTotalLoggedTime);
    this.totalLoggedNonBillableTime$ = this.store.select(TaskState.getTotalLoggedNonBillableTime);
    this.totalLoggedBillableTime$ = this.store.select(TaskState.getTotalLoggedBillableTime);
    this.totalLoggedEstimate$ = this.store.select(TaskState.getTotalLoggedEstimate);
    this.totalLoggedNotBilled$ = this.store.select(TaskState.getTotalLoggedNotBilled);

    this.refreshTimeSubscription = this.commonService.refreshTimeTrigger$.subscribe(res => {
      this.getTimelogList();
    });
  }

  ngOnDestroy(): void {
    if (this.refreshTimeSubscription) {
      this.refreshTimeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.updateDivHeight();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateDivHeight();
  }

  updateDivHeight(): void {
    if (this.filterContainer) {
      this.calculateGridHeight(this.filterContainer.nativeElement.offsetHeight);
    }
  }

  calculateGridHeight(divHeight: number): void {
    const screenHeight = window.innerHeight;
    const gridHeaderHeight = 50;
    const wrapperpadding = 50;

    this.gridHeight = screenHeight - (gridHeaderHeight + wrapperpadding + divHeight);

    if (this.showProjectSummary) {
      this.gridHeight = this.gridHeight - 75;
    }
  }

  onSubmit(): void {
    if (!this.dateError) this.getData();
    document.body.style.overflow = 'auto';
  }

  onActionItemClicked(event: any): void {
    this.exportTimesheet(event.actionTypeId);
  }

  getData(): void {
    this.setDefaultValueOnSubmit();
    this.getTimelogList();
    this.updateFilterCount();
    this.isDrawerOpened = false;
  }

  setDefaultValueOnSubmit(): void {
    if (this.selectedFilterValue == 1) {
      this.validateDates();
      this.updateFormattedDateRange();
      this.showFilterDate = true;
    } else {
      this.showFilterDate = false;
    }

    this.showBillableFilter = true;
    this.skip = 0;
    this.pageSize = 100;

    this.selectedBillableFilterText = this.billableFilterList.filter(
      (x: any) => x.value === this.selectedBillableFilterValue,
    )[0].text;
  }

  getProjectList(): void {
    const data: GetProjectListInputDto = {
      includeProjectUserInfo: true,
      include: [],
      fields: [],
      orderByCustomFieldId: 0,
      onlyStarredProjects: true,
      hideObservedProjects: true,
      includeCounts: true,
      projectStatuses: true,
      iIncludeCustomFields: true,
      searchByLetter: true,
      skipCount: this.skip,
      maxResultCount: 1000,
      projectIds: [],
    };

    this.projectService.getList(data).subscribe(
      response => {
        this.spinnerService.hide();
        if (response.items.length > 0) {
          this.projectList = response.items;
          this.projectListItems = this.projectList.map(project => ({
            name: project.name,
            id: project.id,
          }));
        }
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  getProjectSummary(): void {
    const params: any = {
      projectIds: [this.projectId],
    };
    this.projectService.getTotal(params).subscribe(
      res => {
        this.projectSummary = res.items?.[0];
        this.isProjectBillable = this.projectSummary?.isBillable;
      },
      err => {
        console.error(err);
      },
    );
  }

  billableFilterselectionChange(event): void {
    this.selectedBillableFilterValue = event.value;
    this.selectedBillableFilterText = event.text;
  }

  onProjectSelectionChange(selectedProject: any[]): void {
    this.selectedProjectIds = selectedProject.map(project => project.id);
  }

  onUserSelectionChange(selectedUsers: any[]): void {
    this.selectedUserIds = selectedUsers.map(user => user.id);
  }

  getPeopleList(): void {
    let param: {
      sorting: string;
      skipCount: number;
      maxResultCount: number;
      isShowAdmin?: boolean;
    } = {
      sorting: 'name asc',
      skipCount: 0,
      maxResultCount: 500,
    };

    let apiCall: Observable<any>;

    if (this.showProjectSummary) {
      param.isShowAdmin = false;
      apiCall = this.projectUser.getPeople(this.projectId, param);
    } else {
      apiCall = this.service.getList(param);
    }

    if (apiCall instanceof Observable) {
      apiCall.subscribe(response => {
        if (response.items.length > 0) {
          this.userList = this.processUserList(response.items);
          this.userListItems = this.userList.map(user => ({
            userName: user.userName,
            id: user.id,
          }));

          this.setLoggedUser();
        } else {
          this.userList = [];
        }
      });
    }
  }

  processUserList(users: any[]): any[] {
    return users.map(user => ({
      ...user,
      id: user.userId ?? user.id,
      userName:
        user.name?.trim() && user.surname?.trim() ? `${user.name} ${user.surname}` : user.userName,
    }));
  }

  setLoggedUser(): void {
    if (!this.selectedUserIds.includes(this.currentUser.id)) {
      this.selectedUserIds.push(this.currentUser.id);
    }
    const currentUserObject = this.userList.find(user => user.id === this.currentUser.id);
    if (currentUserObject !== null && currentUserObject !== undefined) {
      this.selectedUsers = [currentUserObject];
    }
  }

  exportTimesheet(format: ExportFormat): void {
    this.spinnerService.show();
    const params: TimelogGetListInput = {
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      sorting: 'TimelogDatetime desc',
      startDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.startDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      endDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.endDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      format: format,
      isPagination: false,
      userIds: this.selectedUserIds,
      billable: this.selectedBillableFilterValue,
      projectIds: this.projectId ? [this.projectId] : this.selectedProjectIds?.map(id => +id),
    };

    this.commonService.exportTimesheet(params).subscribe(
      response => {
        saveAs(response.blob, response.filename.replace(/^"|"$/g, ''));

        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.exportSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      },
      error => {
        console.error('Download failed:', error);
        this.spinnerService.hide();
      },
    );
  }

  onEdit(data): void {
    if (this.commonService.isTimelogWindowOpen()) return;

    this.commonService.manageScrollPosition();

    document.body.style.overflow = 'hidden';

    const overlayDiv = document.createElement('div');
    overlayDiv.className = 'k-overlay';
    document.body.appendChild(overlayDiv);

    const windowRef = this.windowService.open({
      content: TimeLogPopupComponent,
      width: 500,
      title: 'Update Log Time',
      cssClass: 'timelog-popup add-time-log-quickly timelog-window',
      resizable: false,
    });

    setTimeout(() => {
      const windowEl = document.querySelector('.timelog-window') as HTMLElement;
      if (windowEl) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const popupWidth = windowEl.offsetWidth;
        const popupHeight = windowEl.offsetHeight;

        windowEl.style.position = 'fixed';
        windowEl.style.top = `${(viewportHeight - popupHeight) / 2}px`;
        windowEl.style.left = `${(viewportWidth - popupWidth) / 2}px`;
      }
    });

    windowRef.window.instance.leftChange.subscribe(position => {
      this.cdr.detectChanges();
      this.commonService.restrictTimelogMovement();
    });

    windowRef.window.instance.topChange.subscribe(position => {
      this.cdr.detectChanges();
      this.commonService.restrictTimelogMovement();
    });

    windowRef.content.instance.timeLogId = data.id;
    windowRef.content.instance.taskName = data.taskName;
    windowRef.content.instance.taskId = this.taskId ?? data.taskId;
    windowRef.content.instance.projectId = this.projectId ?? data.projectId;
    windowRef.content.instance.isProjectBillable = this.isTimePage
      ? data.projectIsBillable
      : this.isProjectBillable;
    windowRef.content.instance.userId = data.userId;

    windowRef.result.subscribe((res: any) => {
      if (res?.confirmed) {
        this.store.dispatch(new GetTimeLogList(this.getParamter(), this.allowGrouping)).subscribe();
        this.store.dispatch(new SetTaskStatus(res.data.taskStatus));
      }
      if (this.showTimeLogTaskDetail) {
        document.body.style.overflow = 'auto';
      }
      document.body.removeChild(overlayDiv);
    });
  }

  updateStartDate(event): void {
    this.startDate = event;
    this.validateDates();
    this.updateFormattedDateRange();
  }

  updateEndDate(event): void {
    this.endDate = event;
    this.validateDates();
    this.updateFormattedDateRange();
  }

  updateFormattedDateRange(): void {
    if (this.startDate && this.endDate) {
      this.formattedDateRange = `${formatDate(this.startDate, 'd MMM, y', 'en-US')} - ${formatDate(
        this.endDate,
        'd MMM, y',
        'en-US',
      )}`;
    }
  }

  validateDates() {
    if (this.startDate && this.endDate) {
      this.dateError = this.endDate < this.startDate;
    } else {
      this.dateError = false;
    }
  }

  closeDrawer() {
    this.isDrawerOpened = false;
    document.body.style.overflow = 'auto';
  }

  selectionChange(event): void {
    this.dateError = false;
    this.selectedFilterValue = event.value;
    if (this.selectedFilterValue == 0) {
      this.startDate = undefined;
      this.endDate = undefined;
      this.showFilterDate = false;
    } else {
      this.startDate = new Date();
      this.endDate = new Date();
      this.showFilterDate = true;
    }
  }

  openFilter(): void {
    this.isDrawerOpened = !this.isDrawerOpened;
    this.projectId = this.projectId;
    document.body.style.overflow = 'hidden';
  }

  pageChange(event: { skip: number; take: number }): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getTimelogList();
  }

  clearFilter(isDrawerClose: boolean = false): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.selectedFilterValue = 1;
    this.selectedBillableFilterValue = 0;
    this.selectedUsers = [];
    this.selectedUserIds = [];
    this.selectedProject = [];
    this.selectedProjectIds = [];
    this.startDate = new Date();
    this.endDate = new Date();
    this.pageSize = 100;
    this.gridView = {
      data: [],
      total: 0,
    };
    this.updateFilterCount();
    this.setLoggedUser();
    this.getData();
    if (isDrawerClose) this.closeDrawer();
  }

  getParamter(): TimelogGetListInput {
    const params: TimelogGetListInput = {
      userId: null,
      taskId: this.taskId,
      projectId: null,
      timelogDatetime: null,
      timelogMinutes: null,
      timelogDescription: null,
      timelogIsBillable: null,
      timelogHasStartTime: null,
      taskIdPreMove: null,
      deskTicketId: null,
      timelogInvoiceNumber: null,
      projectBillingInvoiceId: null,
      timelogFinalCost: null,
      maxResultCount: this.pageSize,
      skipCount: this.isDrawerOpened ? 0 : this.skip,
      sorting: 'TimelogDatetime desc',
      format: ExportFormat.Excel,
      isPagination: true,
      userIds: this.isAdmin && !this.allowGrouping ? null : this.selectedUserIds,
      billable: this.selectedBillableFilterValue,
      projectIds: this.projectId ? [this.projectId] : this.selectedProjectIds?.map(id => +id),
      startDate:
        this.allowGrouping === false
          ? null
          : this.selectedFilterValue === 1
            ? new Date(this.datePipe.transform(this.startDate, 'yyyy-MM-dd'))
                .toLocalISOString()
                .slice(0, 10)
            : undefined,
      endDate:
        this.allowGrouping === false
          ? null
          : this.selectedFilterValue === 1
            ? new Date(this.datePipe.transform(this.endDate, 'yyyy-MM-dd'))
                .toLocalISOString()
                .slice(0, 10)
            : undefined,
    };

    return params;
  }

  getTimelogList(): void {
    this.store
      .dispatch(new GetTimeLogList(this.getParamter(), this.allowGrouping))
      .subscribe(res => {
        this.gridView = {
          data: res['task'].timeLogList,
          total: res['task'].timelogTotalCount,
        };

        this.isBillableColumnVisible = this.allowGrouping
          ? res['task'].timeLogList.some(group => group.items.some(item => !item.isBillable))
          : res['task'].timeLogList.every(item => !item.isBillable);
      });
  }

  updateFilterCount(): void {
    let count = 0;
    if (this.selectedFilterValue == 1) count++;
    if (this.selectedUserIds.length && (this.isAdmin || this.isCurrentUserResponsiblePerson))
      count++;
    if (this.selectedProjectIds.length) count++;
    if (this.showBillableFilter) count++;
    this.filterCount = count;
  }

  getQueryParams(data: any) {
    return {
      taskId: btoa(data.taskId),
      projectId: btoa(this.projectId ?? data.projectId),
      taskListId: btoa(data.tasklistId),
    };
  }

  onGoToTaskDetailsClick(data, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    event.preventDefault();

    const params: { [key: string]: string } = {
      taskId: btoa(data.taskId),
    };

    if (this.showProjectSummary) {
      params.tabId = btoa(TaskListTabEnum.Time.toString());
    }

    localStorage.removeItem('url_params');
    const shortKey = this.urlShortener.shortUrl(params);
    const fullUrl = `/task-Detail/${shortKey}`;

    if (this.openInNewTab) {
      window.open(fullUrl, '_blank');
    } else {
      this.router.navigate([fullUrl], { replaceUrl: true });
    }
  }

  onRemoveFilterClick(filterType: number, selectedProjectOrUserId?: number): void {
    const isFilterCleared = () =>
      this.formattedDateRange === '' &&
      this.selectedUsers.length === 0 &&
      this.selectedProject.length === 0;

    const updateFilterCount = () => {
      if (isFilterCleared()) {
        this.filterCount = Math.max(this.filterCount - 1, 0);
      }
    };

    const updateShowBillableFilter = () => {
      this.showBillableFilter = !isFilterCleared();
    };

    switch (filterType) {
      case TimeComponentFilterType.DateFilter:
        this.startDate = new Date();
        this.endDate = new Date();
        this.selectedFilterValue = 0;
        this.showFilterDate = false;
        this.filterCount = Math.max(this.filterCount - 1, 0);
        break;

      case TimeComponentFilterType.SelectedUsersFilter:
        this.selectedUserIds = this.selectedUserIds.filter(
          (id: any) => id !== selectedProjectOrUserId,
        );
        this.selectedUsers = this.selectedUsers.filter(user => user.id !== selectedProjectOrUserId);
        if (
          this.selectedUsers.length === 0 &&
          (this.isAdmin || this.isCurrentUserResponsiblePerson)
        )
          this.filterCount = Math.max(this.filterCount - 1, 0);
        updateFilterCount();
        break;

      case TimeComponentFilterType.SelectedProjectsFilter:
        this.selectedProjectIds = this.selectedProjectIds.filter(
          (id: any) => id !== selectedProjectOrUserId,
        );
        this.selectedProject = this.selectedProject.filter(
          project => project.id !== selectedProjectOrUserId,
        );
        if (this.selectedProject.length === 0) this.filterCount = Math.max(this.filterCount - 1, 0);
        updateFilterCount();
        break;
    }

    updateShowBillableFilter();

    const noDataToDisplay =
      !this.showFilterDate &&
      !this.showBillableFilter &&
      this.selectedProjectIds.length === 0 &&
      this.selectedUserIds.length === 0;

    if (noDataToDisplay) {
      this.gridView.data = [];
      this.filterCount = 0;
    } else {
      this.skip = 0;
      this.pageSize = 100;
      this.getTimelogList();
    }
  }

  openTimeLog(taskName) {
    if (this.commonService.isTimelogWindowOpen()) return;

    this.commonService.manageScrollPosition();

    document.body.style.overflow = 'hidden';

    const overlayDiv = document.createElement('div');
    overlayDiv.className = 'k-overlay';
    document.body.appendChild(overlayDiv);

    const windowRef = this.windowService.open({
      content: TimeLogPopupComponent,
      width: 500,
      title: 'Log Time',
      cssClass: 'timelog-popup add-time-log-quickly timelog-window',
      resizable: false,
    });

    setTimeout(() => {
      const windowEl = document.querySelector('.timelog-window') as HTMLElement;
      if (windowEl) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const popupWidth = windowEl.offsetWidth;
        const popupHeight = windowEl.offsetHeight;

        windowEl.style.position = 'fixed';
        windowEl.style.top = `${(viewportHeight - popupHeight) / 2}px`;
        windowEl.style.left = `${(viewportWidth - popupWidth) / 2}px`;
      }
    });

    windowRef.window.instance.leftChange.subscribe(position => {
      this.cdr.detectChanges();
      this.commonService.restrictTimelogMovement();
    });

    windowRef.window.instance.topChange.subscribe(position => {
      this.cdr.detectChanges();
      this.commonService.restrictTimelogMovement();
    });

    if (this.showTimeLogTaskDetail) {
      document.body.style.overflow = 'hidden';
    }

    windowRef.content.instance.startTime = new Date();
    windowRef.content.instance.endTime = new Date();
    windowRef.content.instance.taskId = this.taskId;
    windowRef.content.instance.taskName = taskName;
    windowRef.content.instance.projectId = this.projectId;
    windowRef.content.instance.isProjectBillable = this.isProjectBillable;

    windowRef.result.subscribe((res: any) => {
      if (res?.confirmed) {
        this.store.dispatch(new AddTimelogList(res.data)).subscribe();
        this.store.dispatch(new SetTaskStatus(res.data.taskStatus));
      }
      if (this.showTimeLogTaskDetail) {
        document.body.style.overflow = 'auto';
      }
      document.body.removeChild(overlayDiv);
    });
  }

  onDelete(data: any): void {
    const timelogDescription = data.timelogDescription ? data.timelogDescription : 'timelog';
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + `${timelogDescription}` + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.timelogService.delete(data.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteTimelogtSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );

            if (this.allowGrouping) {
              if (this.gridView.data[0].items.length === 1 && this.skip > 0) {
                this.skip = Math.max(0, this.skip - this.pageSize);
              }

              this.getTimelogList();
            } else {
              this.store.dispatch(new DeleteTimelogList(data, this.allowGrouping)).subscribe(() => {
                this.store
                  .select(state => state.task.timeLogList)
                  .pipe(take(1))
                  .subscribe(updatedTimeLogList => {
                    if (updatedTimeLogList.length === 0 && this.skip > 0) {
                      this.skip = Math.max(0, this.skip - this.pageSize);
                      this.getTimelogList();
                    }
                  });
              });
            }
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  onProjectSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());
    this.projectList = this.projectListItems.filter(contains(searchTerm));
  }

  onUserSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { userName: string; id: number }) =>
      item.userName.toLowerCase().includes(value.toLowerCase());
    this.userList = this.userListItems.filter(contains(searchTerm));
  }
}
