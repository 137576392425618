<kendo-dialog-titlebar (click)="onCloseClick()">
  <div>Create a new Task</div>
</kendo-dialog-titlebar>
<div>
  <form class="k-form k-form-md" [formGroup]="formGroup" validateOnSubmit>
    <fieldset class="k-form-fieldset">
      <kendo-formfield class="mb-15">
        <kendo-label [for]="taskName" text="Task Name" labelCssClass="k-form-label"
          ><span class="required">*</span></kendo-label
        >
        <kendo-textbox
          formControlName="taskName"
          class="k-input-inner-text-box"
          aria-placeholder="Give your project a name"
          #taskName
        ></kendo-textbox>
        <kendo-formerror *ngIf="formGroup.controls.taskName.errors?.required"
          >Error: Task Name is required!</kendo-formerror
        >
      </kendo-formfield>
    </fieldset>
  </form>
</div>

<kendo-dialog-actions>
  <button kendoButton class="k-button-solid-base" (click)="onCloseClick()" fillMode="outline">
    Cancel
  </button>
  <div [ngClass]="{ 'curser-not-allowd': !formGroup.valid }" class="flex-0">
    <button
      kendoButton
      (click)="onAddTaskClick()"
      themeColor="primary"
      [disabled]="!formGroup.valid"
      class="k-button-solid-base"
    >
      Add Task
    </button>
  </div>
</kendo-dialog-actions>
