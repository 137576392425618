<div class="q-content">
  <div class="q-content-bg calculate-height">
    <div class="q-content-header">
      <div class="border-bottom project-heading" *ngIf="projectName !== ''">
        <div class="project-name d-flex">
          <p class="m-0 font-size-18 fw-500">
            <kendo-avatar
              size="large"
              [border]="true"
              [initials]="commonService.getInitials(projectName)"
              class="project-logo mr-5"
            ></kendo-avatar
            ><span class="display-inline clamp vertical-align-middle mr-1">{{ projectName }}</span>
          </p>
          <div *ngIf="isAdmin" class="project-name-header-tab d-flex align-items-center">
            <a class="wmx-100 clamp header-tab" (click)="gotoDashboard()">Dashboard</a>
            <a class="wmx-100 clamp header-tab" (click)="gotoTime()">Time</a>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between flex-wrap gap-20 mb-24">
        <kendo-textbox
          placeholder="Search for task list / task name"
          [(ngModel)]="searchText"
          [ngModelOptions]="{ standalone: true }"
          (keyup.enter)="getTaskListList()"
          (ngModelChange)="onSearchTextChange($event)"
          class="search"
        >
          <ng-template kendoTextBoxPrefixTemplate>
            <i class="fas fa-search k-icon font-size-14"></i>
          </ng-template>
        </kendo-textbox>
        <div class="d-flex gap-15 align-items-center">
          <kendo-button kendoButton (click)="onAddTaskList()"
            ><i class="far fa-plus pr-5"></i>Add Task List
          </kendo-button>
          <div style="display: none">
            <kendo-dropdownlist [data]="statusList"></kendo-dropdownlist>
            <a class="flat-icon-container"><i class="fa-light fa-bars-filter"></i></a>
            <a class="flat-icon-container"><i class="fas fa-ellipsis-h"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-wrapper task-list-grid" *ngIf="taskListsList.length > 0">
      <kendo-expansionpanel
        *ngFor="let item of taskListsList; index as i"
        [expanded]="currentExpandedPanelId === item.id"
        (action)="onTaskListsListExpandedPanelClick(i, item)"
        [svgExpandIcon]="commonService.arrowDown"
        [svgCollapseIcon]="commonService.arrowUp"
      >
        <ng-template kendoExpansionPanelTitleDirective>
          <div
            class="d-flex align-items-center justify-content-between expansion-panel-title-wrapper wp-100 flex-wrap"
          >
            <div class="d-flex flex-wrap align-items-center">
              <span class="font-size-16 mr-1">{{ item.name }}</span>
              <span class="blue-label"
                >{{ item.completeCount }} Completed out of {{ item.totalNumberOfTask }} Tasks</span
              >
              <kendo-dropdownbutton
                [data]="taksListActionItem"
                class="dropdown-button ml-10"
                (itemClick)="ontaskListAxctionClick($event, item.id, item.name)"
                (click)="stopPropagation($event)"
                ><i class="fas fa-ellipsis-v"></i>
              </kendo-dropdownbutton>
              <span
                class="add-task-icon"
                title="Add task"
                (click)="stopPropagation($event); onAddTask(item)"
              >
                <i class="fa-solid fa-plus"></i>
              </span>
            </div>
            <div class="d-flex gap-10">
              <div class="blue-label gap-5">
                <span>
                  <i class="fa-solid fa-hourglass mr-5"></i>
                  {{ item.totalEstimatedTime }}
                </span>
                <span>/</span>
                <span>
                  <i class="fa-regular fa-clock mr-5"></i>
                  {{ item.totalTimelogged }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <div class="grid-wrapper">
          <kendo-treelist
            kendoTreeListExpandable
            [kendoTreeListFlatBinding]="taskList"
            idField="id"
            [fetchChildren]="fetchChildren"
            [hasChildren]="hasChildren"
            (add)="addTaskHandler($event)"
            (edit)="editTaskHandler($event)"
            (remove)="removeTaskHandler($event)"
            (save)="saveTaskHandler($event, item.id)"
            (cancel)="cancelTaskHandler($event)"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            class="task-list-table"
          >
            <kendo-treelist-column field="name" title="Task / Bug Name" [expandable]="true">
              <ng-template
                kendoTreeListEditTemplate
                let-dataItem="dataItem"
                let-column="column"
                let-formGroup="formGroup"
                let-isNew="isNew"
              >
                <form class="k-form k-form-md" [formGroup]="formGroup" validateOnSubmit>
                  <fieldset class="k-form-fieldset d-flex gap-5">
                    <kendo-formfield class="mt-0 wp-100 task-name-input">
                      <kendo-textbox
                        [formControl]="formGroup.get('taskName')"
                        class="k-input-inner-text-box"
                        aria-placeholder="Give your project a name"
                        #listName
                        (keydown.enter)="
                          saveTaskHandler(
                            {
                              sender: sender,
                              dataItem: dataItem,
                              parent: parent,
                              formGroup: formGroup,
                              isNew: isNew,
                              isNew: isNew,
                            },
                            item.id
                          )
                        "
                      ></kendo-textbox>
                      <kendo-formerror *ngIf="formGroup.controls.taskName.errors?.maxlength"
                        >Error: Task Name cannot be more than 255 character!</kendo-formerror
                      >
                      <kendo-formerror *ngIf="formGroup.controls.taskName.errors?.required"
                        >Error: Task Name is required!</kendo-formerror
                      >
                    </kendo-formfield>
                  </fieldset>
                </form>
              </ng-template>

              <ng-template kendoTreeListCellTemplate let-dataItem>
                <div class="display-inline">
                  <div class="display-inline">
                    <div
                      class="display-inline"
                      [ngClass]="{ 'curser-not-allowd': checkIsDisable(dataItem) }"
                    >
                      <input
                        [disabled]="checkIsDisable(dataItem)"
                        [checked]="dataItem.taskStatus === taskStatusType.Completed"
                        type="checkbox"
                        kendoCheckBox
                        class="billable-checkbox"
                        size="large"
                        (change)="onTaskCompleteChange(dataItem)"
                      />
                    </div>
                    <kendo-dropdownbutton
                      [iconClass]="getIconClass(dataItem.taskType)"
                      [data]="taskTypeList"
                      textField="name"
                      (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                      [popupSettings]="{ popupClass: popupClass }"
                    >
                      <ng-template kendoDropDownButtonItemTemplate let-dataItemDropDownButton>
                        <div
                          class="dropdown-menu-links"
                          [ngClass]="{
                            taskTypeSelected: dataItem.taskType === dataItemDropDownButton.id,
                          }"
                        >
                          <i
                            class="{{ dataItemDropDownButton.iconClass }} dropdown-item-color mr-5"
                          ></i>
                          {{ dataItemDropDownButton.name }}
                        </div>
                      </ng-template>
                    </kendo-dropdownbutton>
                  </div>

                  <div
                    class="display-inline mr-10"
                    *ngIf="dataItem.assignee && dataItem.assignee.length > 0"
                  >
                    <ng-container *ngFor="let user of dataItem.assignee">
                      <kendo-avatar
                        rounded="full"
                        size="large"
                        [border]="true"
                        [initials]="getInitials(user).avatar"
                        [style.backgroundColor]="getInitials(user).generateAvtarColor"
                        class="social-profile-text assignee-container"
                        [attr.title]="getInitials(user).userName"
                      ></kendo-avatar>
                    </ng-container>
                  </div>
                  <a
                    (click)="onGoToTaskDetailsClick(dataItem, item.id)"
                    title="{{ dataItem.taskName }}"
                    ><span
                      class="clamp display-inline task-title"
                      [ngClass]="{
                        'task-all-fields':
                          dataItem.startDate &&
                          dataItem.dueDate &&
                          dataItem.priority &&
                          dataItem.taskStatusText &&
                          dataItem.estimateMinutes > 0,
                        'task-status':
                          dataItem.taskStatusText &&
                          !dataItem.startDate &&
                          !dataItem.dueDate &&
                          !dataItem.priority &&
                          dataItem.estimateMinutes <= 0,
                        'task-name':
                          dataItem.startDate ||
                          dataItem.dueDate ||
                          dataItem.priority ||
                          dataItem.taskStatusText ||
                          dataItem.estimateMinutes > 0,
                      }"
                      >{{ dataItem.taskName }}</span
                    ></a
                  >
                  <div class="display-inline">
                    <div
                      class="display-inline mr-10"
                      [ngClass]="{ 'q-label': dataItem.startDate && dataItem.dueDate }"
                    >
                      <ng-container *ngIf="dataItem.startDate">
                        <span>{{ dataItem.startDate | date: 'dd-MMM-yyyy' }} - </span>
                      </ng-container>
                      <ng-container *ngIf="dataItem.dueDate">
                        <span class="text-danger">
                          {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}</span
                        >
                      </ng-container>
                    </div>

                    <div
                      *ngIf="dataItem.priority"
                      class="task-status-container mr-10 priority-{{
                        priorityEnum[dataItem.priority]
                      }}"
                      title="{{ priorityEnum[dataItem.priority] }}"
                    >
                      <span>
                        <i class="fa-solid fa-flag"></i>
                      </span>
                    </div>

                    <div *ngIf="dataItem.estimateMinutes > 0" class="display-inline q-label mr-10">
                      <span>
                        Est:
                        {{
                          commonService.getHoursAndMinutesFromTotalMinutes(dataItem.estimateMinutes)
                        }}
                      </span>
                    </div>
                    <div class="display-inline q-label">
                      <span>
                        {{ dataItem.taskStatusText }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column [width]="120">
              <ng-template
                kendoTreeListCellTemplate
                let-dataItem
                let-isNew="isNew"
                let-cellContext="cellContext"
              >
                <ul class="grid-action-list grid-command-action d-flex justify-content-end gap-5">
                  <li>
                    <a
                      [kendoTreeListAddCommand]="cellContext"
                      [svgIcon]="commonService.addExpressionIcon"
                      title="Add SubTask"
                      class="grid-command-action-links"
                    ></a>
                  </li>

                  <li (click)="addMoreDetails(dataItem)" *ngIf="!isNew && dataItem.id !== 0">
                    <a class="grid-command-action-links" title="More Action">
                      <i class="far fa-pencil"></i
                    ></a>
                  </li>

                  <li *ngIf="!checkIsDisable(dataItem)">
                    <a
                      [kendoTreeListRemoveCommand]="cellContext"
                      [svgIcon]="commonService.trashIcon"
                      title="Remove"
                      class="grid-command-action-links"
                    ></a>
                  </li>

                  <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
                    <a
                      [kendoTreeListSaveCommand]="cellContext"
                      [disabled]="formGroup?.invalid"
                      [svgIcon]="commonService.saveIcon"
                      title="{{ isNew ? 'Add' : 'Update' }}"
                      class="grid-command-action-links"
                    ></a>
                  </li>

                  <li>
                    <a
                      [kendoTreeListCancelCommand]="cellContext"
                      [svgIcon]="commonService.cancelIcon"
                      title="{{ isNew ? 'Discard changes' : 'Cancel' }}"
                      class="grid-command-action-links"
                    ></a>
                  </li>
                </ul>
              </ng-template>
            </kendo-treelist-column>
            <ng-template kendoTreeListToolbarTemplate [position]="'bottom'">
              <button kendoTreeListAddCommand type="button" class="add-new-btn">
                <i class="far fa-plus mr-5"></i> Add new
              </button>
            </ng-template>
          </kendo-treelist>
          <a href="javascript:void(0)" (click)="showCompletedTask()">
            <span *ngIf="!isShowCompleteTask">
              <i class="fa-solid fa-chevron-down"></i> Show Completed Task</span
            >
            <span *ngIf="isShowCompleteTask"
              ><i class="fa-solid fa-chevron-up"></i> Hide Completed Task</span
            >
          </a>
          <div *ngIf="isShowCompleteTask">
            <kendo-treelist
              kendoTreeListExpandable
              [kendoTreeListFlatBinding]="completedTaskList"
              idField="id"
              [fetchChildren]="fetchChildren"
              [hasChildren]="hasChildren"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
              class="task-list-table"
            >
              <kendo-treelist-column field="name" title="Task / Bug Name" [expandable]="true">
                <ng-template kendoTreeListCellTemplate let-dataItem>
                  <div class="display-inline">
                    <div class="display-inline">
                      <div
                        class="display-inline"
                        [ngClass]="{ 'curser-not-allowd': checkIsDisable(dataItem) }"
                      >
                        <input
                          [disabled]="checkIsDisable(dataItem)"
                          [checked]="dataItem.taskStatus === taskStatusType.Completed"
                          type="checkbox"
                          kendoCheckBox
                          class="billable-checkbox"
                          size="large"
                          (change)="onTaskCompleteChange(dataItem)"
                        />
                      </div>
                      <kendo-dropdownbutton
                        [iconClass]="getIconClass(dataItem.taskType)"
                        [data]="taskTypeList"
                        textField="name"
                        (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                        [popupSettings]="{ popupClass: popupClass }"
                      >
                        <ng-template kendoDropDownButtonItemTemplate let-dataItemDropDownButton>
                          <div
                            class="dropdown-menu-links"
                            [ngClass]="{
                              taskTypeSelected: dataItem.taskType === dataItemDropDownButton.id,
                            }"
                          >
                            <i
                              class="{{
                                dataItemDropDownButton.iconClass
                              }} dropdown-item-color mr-5"
                            ></i>
                            {{ dataItemDropDownButton.name }}
                          </div>
                        </ng-template>
                      </kendo-dropdownbutton>
                    </div>

                    <div
                      class="display-inline mr-10"
                      *ngIf="dataItem.assignee && dataItem.assignee.length > 0"
                    >
                      <ng-container *ngFor="let user of dataItem.assignee">
                        <kendo-avatar
                          rounded="full"
                          size="large"
                          [border]="true"
                          [initials]="getInitials(user).avatar"
                          [style.backgroundColor]="getInitials(user).generateAvtarColor"
                          class="social-profile-text assignee-container"
                        ></kendo-avatar>
                      </ng-container>
                    </div>
                    <a
                      (click)="onGoToTaskDetailsClick(dataItem, item.id)"
                      title="{{ dataItem.taskName }}"
                      ><span
                        class="clamp display-inline task-title"
                        [ngClass]="{
                          'task-name':
                            dataItem.startDate ||
                            dataItem.dueDate ||
                            dataItem.priority ||
                            dataItem.estimateMinutes > 0,
                        }"
                        >{{ dataItem.taskName }}</span
                      ></a
                    >
                    <div class="display-inline">
                      <div
                        class="display-inline mr-10"
                        [ngClass]="{ 'q-label': dataItem.startDate && dataItem.dueDate }"
                      >
                        <ng-container *ngIf="dataItem.startDate">
                          <span>{{ dataItem.startDate | date: 'dd-MMM-yyyy' }} - </span>
                        </ng-container>
                        <ng-container *ngIf="dataItem.dueDate">
                          <span class="text-danger">
                            {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}</span
                          >
                        </ng-container>
                      </div>

                      <div
                        *ngIf="dataItem.priority"
                        class="task-status-container mr-10 priority-{{
                          priorityEnum[dataItem.priority]
                        }}"
                        title="{{ priorityEnum[dataItem.priority] }}"
                      >
                        <span>
                          <i class="fa-solid fa-flag"></i>
                        </span>
                      </div>

                      <div *ngIf="dataItem.estimateMinutes > 0" class="display-inline q-label">
                        <span>
                          Est:
                          {{
                            commonService.getHoursAndMinutesFromTotalMinutes(
                              dataItem.estimateMinutes
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </kendo-treelist-column>
              <kendo-treelist-column [width]="120">
                <ng-template
                  kendoTreeListCellTemplate
                  let-dataItem
                  let-isNew="isNew"
                  let-cellContext="cellContext"
                >
                  <ul class="grid-action-list grid-command-action d-flex justify-content-end gap-5">
                    <li (click)="addMoreDetails(dataItem)" *ngIf="!isNew && dataItem.id !== 0">
                      <a class="grid-command-action-links" title="More Action">
                        <i class="far fa-pencil"></i
                      ></a>
                    </li>

                    <li *ngIf="!checkIsDisable(dataItem)">
                      <a
                        [kendoTreeListRemoveCommand]="cellContext"
                        [svgIcon]="commonService.trashIcon"
                        title="Remove"
                        class="grid-command-action-links"
                      ></a>
                    </li>

                    <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
                      <a
                        [kendoTreeListSaveCommand]="cellContext"
                        [disabled]="formGroup?.invalid"
                        [svgIcon]="commonService.saveIcon"
                        title="{{ isNew ? 'Add' : 'Update' }}"
                        class="grid-command-action-links"
                      ></a>
                    </li>

                    <li>
                      <a
                        [kendoTreeListCancelCommand]="cellContext"
                        [svgIcon]="commonService.cancelIcon"
                        title="{{ isNew ? 'Discard changes' : 'Cancel' }}"
                        class="grid-command-action-links"
                      ></a>
                    </li>
                  </ul>
                </ng-template>
              </kendo-treelist-column>
            </kendo-treelist>
          </div>
        </div>
      </kendo-expansionpanel>
    </div>
    <div
      *ngIf="taskListsList.length === 0 && isNoRecordFound"
      class="no-taskList d-flex align-items-center justify-content-center flex-column"
    >
      <img src="assets/images/no-tasklist.png" alt="no-taskList" />
      <p>No task-list found</p>
      <p class="desc">It seems there are no task-lists found for this project.</p>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>

<kendo-drawer-container class="add-more-details">
  <kendo-drawer
    [mode]="expandMode"
    [expanded]="isDrawerOpened"
    [position]="position"
    [autoCollapse]="false"
  >
    <ng-template kendoDrawerTemplate>
      <app-add-task-more-details
        [taskId]="taskId"
        [projectId]="projectId"
        [assigneeList]="assigneeList"
        [sortingName]="sorting"
        (triggerIsToggle)="checkIsToggle($event)"
      ></app-add-task-more-details>
    </ng-template>
  </kendo-drawer>
</kendo-drawer-container>
