<div
  *ngIf="(timeLogList$ | async)?.length === 0; else gridTemplate"
  class="d-flex justify-content-center align-items-center flex-column mt-4"
>
  <img src="assets/images/timelog.png" alt="timelog" class="mb-10" />
  <p class="mt-5">No Time Logged!</p>
  <p class="text-secondary-text font-size-12">It seems there is no time logged on this task.</p>
  <a title="Add Task Timelog" class="add-on-btn" (click)="openTimeLog(taskName)"
    ><i class="far fa-plus"></i> Log time on task
  </a>
</div>
<ng-template #gridTemplate>
  <div>
    <div class="d-flex gap-20 primary-card text-primary flex-wrap mb-24 font-size-12">
      <div>
        <i class="fa-regular fa-clock"></i>
        <span class="ml-5 fw-500">logged</span>
        <span
          >: {{ commonService.getHoursAndMinutesFromTotalMinutes(totalLoggedTime$ | async) }}</span
        >
      </div>
      <div>
        <i class="fa-solid fa-ban"></i>
        <span class="ml-5 fw-500">Non-billable</span
        ><span
          >:
          {{
            commonService.getHoursAndMinutesFromTotalMinutes(totalLoggedNonBillableTime$ | async)
          }}</span
        >
      </div>
      <div>
        <i class="fa-regular fa-circle-check"></i>
        <span class="ml-5 fw-500">Billable</span
        ><span>
          : {{ commonService.getHoursAndMinutesFromTotalMinutes(totalLoggedBillableTime$ | async) }}
        </span>
      </div>
    </div>
    <div class="grid-wrapper mb-15" (scroll)="onScroll($event)">
      <kendo-grid
        [kendoGridBinding]="timeLogList$ | async"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [scrollable]="'none'"
        class="timelog-grid"
      >
        <kendo-grid-column field="timelogDatetime" title="Date" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span title="{{ dataItem.timelogDatetime | date: 'yyyy-MM-dd' }}">{{
              dataItem.timelogDatetime | date: 'dd-MM-yyyy'
            }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="name" title="Who" [width]="150" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex align-items-center gap-10">
              <kendo-avatar
                rounded="full"
                size="large"
                [border]="true"
                [initials]="commonService.getInitials(dataItem.userName)"
                [style.backgroundColor]="
                  commonService.generateAvtarColor(dataItem.userId, dataItem.userName)
                "
                class="social-profile-text task-detail-avatar"
              ></kendo-avatar>
              <span class="clamp" title="{{ dataItem.userName }}">{{ dataItem.userName }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="timelogDescription" title="Description" [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span title="{{ dataItem.timelogDescription }}">{{ dataItem.timelogDescription }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="timelogDatetime" title="Start" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span title="{{ dataItem.timelogDatetime | date: 'shortTime' }}">{{
              dataItem.timelogDatetime | date: 'shortTime'
            }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="end" title="End" [sortable]="false" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{
              commonService.getEndDate(dataItem.timelogDatetime, dataItem.timelogMinutes)
                | date: 'shortTime'
            }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="timelogIsBillable"
          title="Billable"
          [width]="100"
          [hidden]="!isProjectBillable"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              type="checkbox"
              kendoCheckBox
              [checked]="dataItem.timelogIsBillable === 1 ? true : false"
              class="billable-checkbox grid-checkbox"
              size="large"
              disabled
            />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="timelogBillableMinutes"
          title="Billable Hours"
          [sortable]="false"
          [width]="120"
          [hidden]="!isProjectBillable"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span title="{{ dataItem.timelogBillableMinutes }}">{{
              commonService.getHoursAndMinutesFromTotalMinutes(dataItem.timelogBillableMinutes)
            }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="timelogMinutes" title="Time" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span title="{{ dataItem.timelogMinutes }}m">{{ dataItem.timelogMinutes }}m</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="hours" title="Hours" [sortable]="false" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span title="{{ dataItem.hours }}">{{ dataItem.hours }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="" title="" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="d-flex align-items-center justify-content-between">
              <ul class="grid-action-list">
                <div class="d-flex gap-10" *ngIf="dataItem.canEditOrDelete">
                  <li>
                    <a title="Edit" (click)="onEdit(rowIndex, dataItem)">
                      <i class="far fa-pen"></i>
                    </a>
                  </li>
                  <li *ngIf="!dataItem.isDefault">
                    <a title="Delete" (click)="onDelete(dataItem)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <ng-container *ngIf="(timeLogList$ | async).length > 0">
      <a title="Add Task Timelog" class="add-on-btn display-inline" (click)="openTimeLog(taskName)">
        <i class="far fa-plus"></i> Log time on task
      </a>
    </ng-container>
  </div>
</ng-template>
