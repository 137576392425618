import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  AddSubTask,
  AddTimelogList,
  DeleteSubTask,
  DeleteTimelogList,
  GetFileList,
  GetList,
  GetSubTaskList,
  GetTimeLogList,
  SetTaskStatus,
  MarkTaskAsCompletedSubTask,
  UpdateSubTask,
} from './task.action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { FileService } from 'projects/task-service/src/lib/proxy/service/task/file.service';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog';

export class TaskStateInfo {
  taskList?: any;
  subTaskList?: any;
  fileList?: any;
  timeLogList: any;
  totalLoggedTime: any;
  totalLoggedBillableTime: any;
  totalLoggedNonBillableTime: any;
  taskStatus?: any;
  timelogTotalCount?: number;
}

@State<TaskStateInfo>({
  name: 'task',
  defaults: {
    taskList: [],
    subTaskList: [],
    fileList: [],
    timeLogList: [],
    totalLoggedTime: '',
    totalLoggedBillableTime: '',
    totalLoggedNonBillableTime: '',
    taskStatus: '',
  },
})
@Injectable()
export class TaskState {
  constructor(
    private taskService: TaskService,
    private fileService: FileService,
    private timelogService: TimelogService,
  ) {}
  @Selector()
  static getList(state: TaskStateInfo) {
    return state.taskList;
  }

  @Selector()
  static getSubTaskList(state: TaskStateInfo) {
    return state.subTaskList;
  }

  @Selector()
  static getFileList(state: TaskStateInfo) {
    return state.fileList;
  }

  @Selector()
  static getTimelogList(state: TaskStateInfo) {
    return state.timeLogList;
  }

  @Selector()
  static getTotalLoggedTime(state: TaskStateInfo) {
    return state.totalLoggedTime;
  }

  @Selector()
  static getTotalLoggedBillableTime(state: TaskStateInfo) {
    return state.totalLoggedBillableTime;
  }

  @Selector()
  static getTotalLoggedNonBillableTime(state: TaskStateInfo) {
    return state.totalLoggedNonBillableTime;
  }

  @Selector()
  static getTaskStatus(state: TaskStateInfo) {
    return state.taskStatus;
  }

  @Action(GetList)
  getList({ getState, setState }: StateContext<TaskStateInfo>, action: any) {
    return this.taskService.getList(action.queryParams).pipe(
      tap(res => {
        const state = getState();
        setState({
          ...state,
          taskList: res,
        });
      }),
    );
  }

  @Action(GetSubTaskList)
  getSubTaskList({ getState, setState }: StateContext<TaskStateInfo>, action: any) {
    return this.taskService
      .getSubTasksByParentTaskIdAndPagedAndSortedResultRequest(action.taskId, action.param)
      .pipe(
        tap(res => {
          const state = getState();

          setState({
            ...state,
            subTaskList: res.items,
          });
        }),
      );
  }

  @Action(AddSubTask)
  addSubTask({ getState, patchState }: StateContext<TaskStateInfo>, { subTask }: AddSubTask) {
    const state = getState();

    patchState({
      subTaskList: [...state.subTaskList, subTask],
    });
  }

  @Action(AddTimelogList)
  addTimelogList(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { addTimelog }: AddTimelogList,
  ) {
    const state = getState();

    addTimelog.canEditOrDelete = true;

    addTimelog.timelogDatetime = addTimelog.timelogDatetime.replace('Z', '');

    const timeLogList = {
      ...addTimelog,
      hours: this.removeTrailingZeros((addTimelog.timelogMinutes / 60).toFixed(3)),
    };

    const updatedTimeLogList = [...state.timeLogList, timeLogList];

    const timelogBillableMinutes = addTimelog.timelogBillableMinutes ?? 0;

    patchState({
      timeLogList: updatedTimeLogList,
      totalLoggedTime: state.totalLoggedTime + addTimelog.timelogMinutes,
      totalLoggedBillableTime: state.totalLoggedBillableTime + timelogBillableMinutes,
      totalLoggedNonBillableTime:
        state.totalLoggedNonBillableTime + addTimelog.timelogMinutes - timelogBillableMinutes,
    });
  }

  @Action(UpdateSubTask)
  updateSubTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { index, updatedSubTask }: UpdateSubTask,
  ) {
    const state = getState();
    const subTaskList = [...state.subTaskList];
    subTaskList[index] = updatedSubTask; // Update the subtask at the specified index
    patchState({
      subTaskList: subTaskList,
    });
  }

  @Action(DeleteSubTask)
  deleteSubTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { subTaskId }: DeleteSubTask,
  ) {
    const state = getState();
    const updatedSubTaskList = state.subTaskList.filter(subtask => subtask.id !== subTaskId);

    patchState({
      subTaskList: updatedSubTaskList,
    });
  }

  @Action(MarkTaskAsCompletedSubTask)
  markTaskAsCompletedSubTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { taskId, taskStatus }: MarkTaskAsCompletedSubTask,
  ) {
    const state = getState();
    const updatedSubTaskList = state.subTaskList.map(subtask =>
      subtask.id === taskId ? { ...subtask, taskStatus: taskStatus } : subtask,
    );
    patchState({
      subTaskList: updatedSubTaskList,
    });
  }

  @Action(SetTaskStatus)
  setTaskStatus(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { taskStatus }: SetTaskStatus,
  ) {
    patchState({
      taskStatus: taskStatus,
    });
  }

  @Action(GetFileList)
  getFileList({ getState, patchState }: StateContext<TaskStateInfo>, action: any) {
    return this.fileService.getList(action.taskId).pipe(
      tap(res => {
        const state = getState();

        let fileList = [];

        if (res.length > 0) {
          fileList = res.map(element => {
            const latestFileVersion = element.fileVersions.reduce((prev, current) =>
              prev.versionNo > current.versionNo ? prev : current,
            );
            return {
              ...latestFileVersion,
              previewImage: element.previewImage,
              src: element.previewImage,
            };
          });
        }

        patchState({
          ...state,
          fileList: fileList,
        });
      }),
    );
  }

  @Action(GetTimeLogList)
  getTimeLogList({ getState, patchState }: StateContext<TaskStateInfo>, action: GetTimeLogList) {
    return this.timelogService.getList(action.params).pipe(
      tap(res => {
        const state = getState();

        const timeLogList = res.items
          .map((element: any) => {
            element.hours = this.removeTrailingZeros((element.timelogMinutes / 60).toFixed(3));
            return element;
          })
          .filter((element: any) => element.taskId === action.params.taskId);

        patchState({
          ...state,
          timeLogList: timeLogList,
          timelogTotalCount: res.totalCount,
          totalLoggedTime: timeLogList.reduce((total, log) => total + log.timelogMinutes, 0),
          totalLoggedBillableTime: timeLogList.reduce(
            (total, log) => total + log.timelogBillableMinutes,
            0,
          ),
          totalLoggedNonBillableTime:
            timeLogList.reduce((total, log) => total + log.timelogMinutes, 0) -
            timeLogList.reduce((total, log) => total + log.timelogBillableMinutes, 0),
        });
      }),
    );
  }

  @Action(DeleteTimelogList)
  deleteTimelogList(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { timelogData }: DeleteTimelogList,
  ) {
    const state = getState();
    const updatedTimeLogList = state.timeLogList.filter(timelog => timelog.id !== timelogData.id);

    patchState({
      timeLogList: updatedTimeLogList,
      totalLoggedTime: state.totalLoggedTime - timelogData.timelogMinutes,
      totalLoggedBillableTime: state.totalLoggedBillableTime - timelogData.timelogBillableMinutes,
      totalLoggedNonBillableTime: state.totalLoggedNonBillableTime - timelogData.timelogMinutes,
    });
  }

  removeTrailingZeros(str: string): string {
    return str.replace(/(\.0+|(?<=\..*?)0+)$/, '');
  }
}
