export class GetList {
  static readonly type = '[TASK] Get Task';

  constructor(public queryParams: any) {}
}

export class GetSubTaskList {
  static readonly type = '[TASK] Get Sub Task List';

  constructor(
    public taskId: any,
    public param: any,
  ) {}
}

export class AddSubTask {
  static readonly type = '[Task] Add SubTask';
  constructor(public subTask: any) {}
}

export class UpdateSubTask {
  static readonly type = '[Task] Update SubTask';
  constructor(
    public index: number,
    public updatedSubTask: any,
  ) {}
}

export class DeleteSubTask {
  static readonly type = '[Task] Delete SubTask';
  constructor(public subTaskId: number) {}
}

export class MarkTaskAsCompletedSubTask {
  static readonly type = '[Task] Mark Task As Completed Sub Task';
  constructor(
    public taskId: number,
    public taskStatus: number,
  ) {}
}

export class SetTaskStatus {
  static readonly type = '[Task] Mark Task As Completed Main Task';
  constructor(public taskStatus: number) {}
}

export class GetFileList {
  static readonly type = '[File] Get File List';
  constructor(public taskId: number) {}
}

export class GetTimeLogList {
  static readonly type = '[TimeLog] Get TimeLog List';
  constructor(
    public params: any,
    public allowGrouping: boolean = false,
  ) {}
}

export class DeleteTimelogList {
  static readonly type = '[Task] Delete Timelog List';
  constructor(
    public timelogData: any,
    public allowGrouping: boolean = false,
  ) {}
}

export class AddTimelogList {
  static readonly type = '[Task] Add Timelog List';
  constructor(public addTimelog: any) {}
}

export class GetCommentList {
  static readonly type = '[Comment] Get Comment List';
  constructor(public params: any) {}
}

export class SetTimelogList {
  static readonly type = '[Timelog] Set Timelog list';
}

export class SetSubTaskList {
  static readonly type = '[Task] Set Sub TaskList';
}
