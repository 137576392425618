<div class="q-content p-20">
  <div class="q-content-bg calculate-height">
    <div class="q-content-header">
      <div class="border-bottom pb-1 mb-2 q-content-header-title">
        <div class="d-flex gap-10 align-items-center">
          <i class="fa-regular fa-bullseye-arrow text-icon-red-color font-size-20"></i>
          <p class="m-0 font-size-18">Assigned to me</p>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex-row">
        <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
          <div class="q-card mb-20 flex-1 border">
            <div class="q-card-header d-flex justify-content-between no-border pb-0">
              <div>
                <p class="q-card-title">{{ dashboradCardDetails?.openTask.cardName }}</p>
                <p
                  class="q-card-value m-0 cursor-pointer"
                  (click)="
                    onTaskCount(
                      taskTypeStatus.OpenTasks,
                      dashboradCardDetails?.openTask.cardName,
                      dashboradCardDetails?.openTask.total
                    )
                  "
                >
                  {{ dashboradCardDetails?.openTask.total }}
                </p>
              </div>
              <div class="q-card-icon q-card-icon-first">
                <i class="fa-regular fa-clipboard-list-check"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
          <div class="q-card mb-20 flex-1 border cursor-pointer">
            <div class="q-card-header d-flex justify-content-between no-border pb-0">
              <div>
                <p class="q-card-title">{{ dashboradCardDetails?.closeTask.cardName }}</p>
                <p
                  class="q-card-value m-0 cursor-pointer"
                  (click)="
                    onTaskCount(
                      taskTypeStatus.CloseTasks,
                      dashboradCardDetails?.closeTask.cardName,
                      dashboradCardDetails?.closeTask.total
                    )
                  "
                >
                  {{ dashboradCardDetails?.closeTask.total }}
                </p>
              </div>
              <div class="q-card-icon q-card-icon-second">
                <i class="fa-regular fa-file-slash"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
          <div class="q-card mb-20 flex-1 border cursor-pointer">
            <div class="q-card-header d-flex justify-content-between no-border pb-0">
              <div>
                <p class="q-card-title">{{ dashboradCardDetails?.openIssue.cardName }}</p>
                <p
                  class="q-card-value m-0 cursor-pointer"
                  (click)="
                    onTaskCount(
                      taskTypeStatus.OpenBugs,
                      dashboradCardDetails?.openIssue.cardName,
                      dashboradCardDetails?.openIssue.total
                    )
                  "
                >
                  {{ dashboradCardDetails?.openIssue.total }}
                </p>
              </div>
              <div class="q-card-icon q-card-icon-third">
                <i class="fa-regular fa-bug"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
          <div class="q-card mb-20 flex-1 border cursor-pointer">
            <div class="q-card-header d-flex justify-content-between no-border pb-0">
              <div>
                <p class="q-card-title">{{ dashboradCardDetails?.closeIssue.cardName }}</p>
                <p
                  class="q-card-value m-0 cursor-pointer"
                  (click)="
                    onTaskCount(
                      taskTypeStatus.CloseBugs,
                      dashboradCardDetails?.closeIssue.cardName,
                      dashboradCardDetails?.closeIssue.total
                    )
                  "
                >
                  {{ dashboradCardDetails?.closeIssue.total }}
                </p>
              </div>
              <div class="q-card-icon q-card-icon-forth">
                <i class="fa-regular fa-bug-slash"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="q-content-content">
      <kendo-expansionpanel
        [expanded]="currentlyExpandedPanel === dashboardEnum.Open"
        (action)="onExpandedPanelClick(dashboardEnum.Open)"
        [svgExpandIcon]="commonService.arrowDown"
        [svgCollapseIcon]="commonService.arrowUp"
        class="dashboard-expasion-panel"
      >
        <ng-template kendoExpansionPanelTitleDirective>
          <div
            class="d-flex align-items-center justify-content-between expansion-panel-title-wrapper wp-100 flex-wrap gap-10"
          >
            <div class="d-flex flex-wrap align-items-center gap-10">
              <span class="font-size-16">Open Task/Bug</span>
            </div>
          </div>
        </ng-template>
        <app-task-list
          [isFromDashboard]="true"
          [triggerGetOpenData]="triggerGetOpenData"
          (updateDashboardCount)="getDashboardCardDetails()"
        ></app-task-list>
      </kendo-expansionpanel>

      <kendo-expansionpanel
        [expanded]="currentlyExpandedPanel === dashboardEnum.DueToday"
        (action)="onExpandedPanelClick(dashboardEnum.DueToday)"
        [svgExpandIcon]="commonService.arrowDown"
        [svgCollapseIcon]="commonService.arrowUp"
        class="dashboard-expasion-panel"
      >
        <ng-template kendoExpansionPanelTitleDirective>
          <div
            class="d-flex align-items-center justify-content-between expansion-panel-title-wrapper wp-100 flex-wrap gap-10"
          >
            <div class="d-flex flex-wrap align-items-center gap-10">
              <span class="font-size-16">Due Today Task/Bug</span>
            </div>
          </div>
        </ng-template>
        <app-task-list
          [isFromDashboard]="true"
          [triggerGetDueData]="triggerGetDueData"
          (updateDashboardCount)="getDashboardCardDetails()"
        ></app-task-list>
      </kendo-expansionpanel>

      <kendo-expansionpanel
        [expanded]="currentlyExpandedPanel === dashboardEnum.Complete"
        (action)="onExpandedPanelClick(dashboardEnum.Complete)"
        [svgExpandIcon]="commonService.arrowDown"
        [svgCollapseIcon]="commonService.arrowUp"
        class="dashboard-expasion-panel"
      >
        <ng-template kendoExpansionPanelTitleDirective>
          <div
            class="d-flex align-items-center justify-content-between expansion-panel-title-wrapper wp-100 flex-wrap gap-10"
          >
            <div class="d-flex flex-wrap align-items-center gap-10">
              <span class="font-size-16">Complete Task/Bug</span>
            </div>
          </div>
        </ng-template>
        <app-task-list
          [isFromDashboard]="true"
          [triggerGetCompleteData]="triggerGetCompleteData"
          (updateDashboardCount)="getDashboardCardDetails()"
        ></app-task-list>
      </kendo-expansionpanel>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
