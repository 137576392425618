import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonService } from 'src/core/services';
import { Priority } from 'tasks/task/config/src/enums/priority';

@Component({
  selector: 'app-set-priority',
  templateUrl: './set-priority.component.html',
  styleUrls: ['./set-priority.component.scss'],
})
export class SetPriorityComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Input() position: { top: number; left: number } | null = null;
  @Output() prioritySelected = new EventEmitter<number>();

  priorityList: { id: Priority; text: string }[] = [];

  popupSettings = {
    animate: true,
    appendTo: 'body',
  };

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.priorityList = this.commonService.getPriorityList();
    this.priorityList = this.priorityList.filter(x => x.id !== 4);
  }

  setPriority(priority: number): void {
    this.prioritySelected.emit(priority);
  }

  getThemeColor(priorityText: string): string {
    switch (priorityText) {
      case 'High':
        return 'error';
      case 'Medium':
        return 'warning';
      case 'Low':
        return 'success';
      case 'None':
      default:
        return 'default';
    }
  }
}
