import { Component } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { StatusTypeEnum } from 'src/app/enum/status-type-enum';
import { AddPortfolioComponent } from './add-portfolio/add-portfolio.component';
import { PageChangeEvent, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CommonService } from 'src/core/services';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { GetPortfolioListDto, PortfolioDto } from '@proxy/project-service/portfolios/dtos';
import { IdentityUserService } from '@abp/ng.identity/proxy';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PortfolioService } from 'projects/project-service/src/lib/proxy/project-service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent {
  searchText = '';
  gridHeight: number;
  public selectedFilterValue = 1;
  public statusFilter: Array<{ text: string; value: number }> = [{ text: 'All Status', value: 1 }];
  statusType = StatusTypeEnum;
  //#region  Pagination
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 10;
  public skip = 0;
  public sorting = null;
  public multiple = false;
  public allowUnsort = true;
  isNoRecords = false;
  openInNewTab = false;

  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  portfolioData = { items: [], totalCount: 0 } as PagedResultDto<PortfolioDto>;
  userList: any[];

  constructor(
    private dialogService: DialogService,
    public commonService: CommonService,
    private portfolioService: PortfolioService,
    public readonly list: ListService,
    protected service: IdentityUserService,
    private toasterService: ToasterService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.calculateGridHeight();
    });
    this.getUserList();
    this.getPortfolioList();
  }

  getUserList() {
    this.list
      .hookToQuery(query => this.service.getList(query))
      .subscribe(res => {
        this.userList = res.items;
      });
  }

  onSearchTextChange(searchText: string): void {
    if (!searchText.trim()) {
      this.getPortfolioList();
    }
  }

  getPortfolioList(): void {
    this.spinnerService.show();
    const param: GetPortfolioListDto = {
      sorting: this.sorting,
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      searchTerm: this.searchText,
    };
    this.portfolioService.getList(param).subscribe((res: any) => {
      this.spinnerService.hide();
      this.portfolioData = res;
      this.isNoRecords = true;
    });
  }

  getCreatedByName(createdById) {
    if (!this.userList) {
      return null;
    }
    const foundItem = this.userList.find(item => createdById === item.id);
    if (foundItem) {
      return foundItem.name;
    } else {
      return null;
    }
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 72;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  selectionChange(event): void {}

  onPortfolioAdd(id?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddPortfolioComponent,
      width: 450,
    });
    const attachFileInfo = dialogRef.content.instance as AddPortfolioComponent;
    attachFileInfo.portfolioId = id;
    attachFileInfo.setOnLoadData();
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getPortfolioList();
      }
    });
  }

  onEdit(data: any): void {}

  onDelete(portfolioData) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + portfolioData.name + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.portfolioService.delete(portfolioData.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deletePortfolioSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.getPortfolioList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.spinnerService.show();
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.spinnerService.hide();
  }

  sortChange(sort): void {
    this.spinnerService.show();
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.spinnerService.hide();
    this.getPortfolioList();
  }

  formatAMPM(date: Date): string {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  }

  transformLastVisitTime(value: string): string {
    const date = new Date(value);
    const today = new Date();

    if (date.toDateString() === today.toDateString()) {
      return `Today, at ${this.formatAMPM(date)}`;
    } else {
      return this.formatAMPM(date);
    }
  }

  onPortfolioClick(data, event): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    if (this.openInNewTab) return;

    event.preventDefault();

    if (!this.openInNewTab) {
      var params = {
        portfolioId: btoa(data.id),
        portfolioName: btoa(data.name),
        isFromPortfolio: btoa(JSON.stringify(true)),
      };

      this.router.navigate(['project-list'], { queryParams: params });
    }
  }

  getQueryParams(data: any) {
    return {
      portfolioId: btoa(data.id),
      portfolioName: btoa(data.name),
      isFromPortfolio: btoa(JSON.stringify(true)),
    };
  }
}
