import { Component, Input } from '@angular/core';
import { imageIcon, SVGIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-set-date-popup',
  templateUrl: './set-date-popup.component.html',
  styleUrls: ['./set-date-popup.component.scss'],
})
export class SetDatePopupComponent {
  dateRange: { start: Date | null; end: Date | null } = { start: null, end: null };
  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date(2099, 11, 31);
  public dates: { start?: Date; end?: Date } = {};

  public range = { start: null, end: null };

  public imageSVG: SVGIcon = imageIcon;

  isStartCalendarOpen = false; // Start date calendar visibility
  isEndCalendarOpen = false; // End date calendar visibility

  @Input() isSetDatePopupVisible: boolean = false;
  @Input() position: { top: number; left: number } | null = null;

  openCalendar(type: string): void {
    if (type === 'start') {
      this.isStartCalendarOpen = true;
      this.isEndCalendarOpen = false; // Close End Calendar if open
    } else if (type === 'end') {
      this.isEndCalendarOpen = true;
      this.isStartCalendarOpen = false; // Close Start Calendar if open
    }
  }

  onDateSelect(type: string): void {
    if (type === 'start') {
      this.isStartCalendarOpen = false; // Close Start Calendar after selection
    } else if (type === 'end') {
      this.isEndCalendarOpen = false; // Close End Calendar after selection
    }
  }

  setToday(): void {
    const today = new Date();
    this.range.start = today;
    this.range.end = today;
  }

  addDaysToStartDate(days: number): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + days);
    this.range.start = startDate;
  }

  setMonday(): void {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1); // Monday is 1
    this.range.start = new Date(today.setDate(diff));
  }

  clearDates(): void {
    this.range.start = null;
    this.range.end = null;
  }
}
