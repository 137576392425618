import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/core/services';
import { DashboardCardModel } from 'src/core/model/dashboard-card-model';
import { ToasterService } from '@abp/ng.theme.shared';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DashboardCountPopupComponent } from './dashboard-count-popup/dashboard-count-popup.component';
import {
  DashboardService,
  TaskTypeStatus,
} from 'projects/project-service/src/lib/proxy/task-service/dashboard';
import { Subject, takeUntil } from 'rxjs';
import { DashboardEnum } from 'src/app/enum/dashboard-enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  triggerGetOpenData = new Subject<number>();
  triggerGetDueData = new Subject<number>();
  triggerGetCompleteData = new Subject<number>();

  dashboradCardDetails: DashboardCardModel;
  currentlyExpandedPanel: number | null = null;

  dashboardEnum = DashboardEnum;
  taskTypeStatus = TaskTypeStatus;

  constructor(
    private toasterService: ToasterService,
    private dashboardService: DashboardService,
    public commonService: CommonService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    document.body.style.overflow = '';
    this.getDashboardCardDetails();
  }

  onExpandedPanelClick(panelId: number): void {
    if (this.currentlyExpandedPanel !== panelId) {
      this.currentlyExpandedPanel = panelId;

      if (panelId === DashboardEnum.Open) {
        this.triggerGetOpenData.next(DashboardEnum.Open);
      }
      if (panelId === DashboardEnum.DueToday) {
        this.triggerGetDueData.next(DashboardEnum.DueToday);
      }
      if (panelId === DashboardEnum.Complete) {
        this.triggerGetCompleteData.next(DashboardEnum.Complete);
      }
    } else {
      this.currentlyExpandedPanel = null;
    }
  }

  onTaskCount(cardType: any, cardName: any, total: number): void {
    if (total === 0) {
      this.toasterService.info(
        'Sorry, No ' + cardName + ' available!',
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return;
    }
    document.body.style.overflow = 'hidden';
    const dialogRef = this.dialogService.open({
      content: DashboardCountPopupComponent,
      width: 950,
      cssClass: 'task-count-popup',
    });
    const taskInfo = dialogRef.content.instance as DashboardCountPopupComponent;
    taskInfo.headerText = cardName;
    taskInfo.cardType = cardType;
    taskInfo.getTaskList();
    dialogRef.result.subscribe(result => {
      document.body.style.overflow = '';
      this.getDashboardCardDetails();
    });
  }

  getDashboardCardDetails() {
    this.dashboardService.getDashboardCard().subscribe(res => {
      this.dashboradCardDetails = {
        openTask: { cardName: res.card.cardName, total: res.card.total },
        closeTask: { cardName: res.card1.cardName, total: res.card1.total },
        openIssue: { cardName: res.card2.cardName, total: res.card2.total },
        closeIssue: { cardName: res.card3.cardName, total: res.card3.total },
      };
    });
  }
}
