import { ToasterService } from '@abp/ng.theme.shared';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateUpdateTimelogDto } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import { ConfigStateService } from '@abp/ng.core';
import { ProjectUserService } from '@proxy/project-service/project';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { AddTimelogList, SetTaskStatus } from 'src/app/core/store/task.action';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { TaskStatusType } from 'projects/project-service/src/lib/proxy/task-service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-time-log-popup',
  templateUrl: './time-log-popup.component.html',
  styleUrls: ['./time-log-popup.component.scss'],
})
export class TimeLogPopupComponent implements OnInit, AfterViewInit {
  currentDate = new Date();
  @Input() startTime: any = new Date();
  @Input() endTime: any = new Date();
  @Input() taskId: any = null;
  @Input() projectId: any;
  @Input() timeLogId: any;
  @Input() taskName: any;
  @Input() isProjectBillable: boolean = false;
  @Input() userId: any;
  @Input() isQuickAdd = false;

  currentUser: any;
  currentUrlProjectId: any;
  searchDelay: any;

  popupClass = 'timer-picker-popup';

  timeSpent: string;
  totalSpentMinutes: number;

  logTimeForm: FormGroup;
  logTimeTitle = 'Log Time';

  userList: any[] = [];
  selectedUserValue: any[] = [];
  projectList: any[] = [];
  taskListsList: any[] = [];
  taskList: any[] = [];
  taskGroupedData: GroupResult[];

  isAdmin = false;
  isEdit = false;

  @ViewChild('datePickerInput', { static: false, read: ElementRef })
  datePickerInput!: ElementRef;

  searchTerm = '';
  minSearchLength = 3;
  public windowTop = 10;
  public windowLeft = 10;
  constructor(
    public windowRef: WindowRef,
    private timelogService: TimelogService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private datePipe: DatePipe,
    private spinnerService: NgxSpinnerService,
    private config: ConfigStateService,
    public projectUser: ProjectUserService,
    private projectService: ProjectService,
    private taskService: TaskService,
    private readonly store: Store,
    private readonly router: Router,
  ) {
    this.currentUser = this.config.getOne('currentUser');
    this.preprocessCurrentUser();

    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    if (currentUserRole.includes('admin')) {
      this.isAdmin = true;
    }
  }

  preprocessCurrentUser() {
    if (this.currentUser) {
      const { name, surName, userName } = this.currentUser;
      const fullName = name?.trim() && surName?.trim() ? `${name} ${surName}` : userName;
      this.currentUser = {
        ...this.currentUser,
        userName: fullName,
      };
    }
  }

  ngOnInit(): void {
    if (this.timeLogId) {
      this.isEdit = true;
      this.logTimeTitle = 'Update Log Time';
      this.editTimeLogForm(this.timeLogId);
    }
    this.setForm();

    if (!this.isEdit) {
      this.logTimeForm.get('timeSpent')?.valueChanges.subscribe((timeSpent: string) => {
        if (timeSpent && this.isProjectBillable) {
          const [hours, minutes] = this.parseTimeSpent(timeSpent);
          this.logTimeForm.patchValue({ hours, minutes }, { emitEvent: false });
        }
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setFocusOnDatePicker();
    }, 0);
  }

  restrictInput(event: any, maxLength: number): void {
    const inputElement = event.target;
  
    if (inputElement.value.includes('.')) {
      inputElement.value = inputElement.value.split('.')[0];
    }
  
    if (inputElement.value.length > maxLength) {
      inputElement.value = inputElement.value.slice(0, maxLength);
    }
  }
  

  setFocusOnDatePicker() {
    const datePickerInput = this.datePickerInput.nativeElement.querySelector('.k-dateinput');
    if (datePickerInput) {
      datePickerInput.focus();
      datePickerInput.classList.add('k-focus');
      setTimeout(() => {
        datePickerInput.classList.remove('k-focus');
      }, 3000);
    }
  }

  getUserList(): void {
    const params = {
      sorting: 'name asc',
      skipCount: 0,
      maxResultCount: 1000,
    };
    if (!!this.projectId) {
      this.projectUser.getPeople(this.projectId, params).subscribe(
        res => {
          if (res.items.length > 0) {
            this.userList = res.items.map(user => {
              const fullName =
                user.name?.trim() && user.surname?.trim()
                  ? `${user.name} ${user.surname}`
                  : user.userName;

              return {
                ...user,
                userName: fullName,
              };
            });
          } else {
            this.userList = [];
          }
        },
        err => {
          console.error(err);
        },
      );
    }
  }

  quickAddTime(unit: 'hours' | 'minutes', value: number): void {
    const control = unit === 'hours' ? 'hours' : 'minutes';
    this.logTimeForm.get(control)?.setValue(value);

    if (unit === 'hours') {
      this.logTimeForm.get('minutes')?.setValue(0);
    } else {
      this.logTimeForm.get('hours')?.setValue(0);
    }

    if (unit === 'minutes') {
      this.onMinutesBlur();
    }
  }

  onMinutesBlur(): void {
    const minutes = this.logTimeForm.get('minutes')?.value;
    this.updateTime(minutes);
  }

  updateTime(minutes: number): void {
    if (minutes >= 60) {
      const extraHours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      this.logTimeForm.patchValue({
        hours: (this.logTimeForm.get('hours')?.value || 0) + extraHours,
        minutes: remainingMinutes,
      });
    } else {
      this.logTimeForm.patchValue({
        hours: this.logTimeForm.get('hours')?.value,
        minutes,
      });
    }
  }

  updateDate(date: Date): void {
    this.currentDate = date;
    this.logTimeForm.get('date').setValue(date);
  }

  setForm(isLogAnotherTime: boolean = false): void {
    this.logTimeForm = new FormGroup({
      date: new FormControl(this.currentDate),
      startTime: new FormControl(),
      endTime: new FormControl(),
      timeSpent: new FormControl(''),
      hours: new FormControl(0),
      minutes: new FormControl(0),
      billable: new FormControl(''),
      description: new FormControl('', Validators.maxLength(5000)),
      taskComplete: new FormControl(false),
      userId: new FormControl(this.currentUser.id),
      projectId: new FormControl(''),
      taskId: new FormControl(this.taskId, Validators.required),
    });

    this.logTimeForm.setValidators(this.timeRangeValidator);
    if (this.isAdmin) {
      this.logTimeForm.get('userId')?.setValidators(Validators.required);
    } else {
      this.logTimeForm.get('userId')?.clearValidators();
    }

    this.logTimeForm.get('userId')?.updateValueAndValidity();

    if (isLogAnotherTime) return;

    if (this.isQuickAdd) {
      this.getProjectList();
    } else {
      this.getUserList();
    }
  }

  timeRangeValidator(group: FormGroup) {
    const startTime = group.get('startTime')?.value;
    const endTime = group.get('endTime')?.value;
    const selectedDate = group.get('date')?.value;

    const normalizeDateTime = (time: string | Date, baseDate: string | Date): Date => {
      if (!time || !baseDate) return null;
      const date = new Date(time);
      const base = new Date(baseDate);
      if (!isNaN(date.getTime()) && !isNaN(base.getTime())) {
        date.setFullYear(base.getFullYear(), base.getMonth(), base.getDate());
        date.setSeconds(0, 0);
        return date;
      }
      return null;
    };

    const startDate = normalizeDateTime(startTime, selectedDate);
    const endDate = normalizeDateTime(endTime, selectedDate);

    if (startDate && endDate) {
      if (startDate.getTime() > endDate.getTime()) {
        return { invalidTimeRange: true };
      }
    }

    return null;
  }

  editTimeLogForm(timeLogId): void {
    this.timelogService.get(timeLogId).subscribe(res => {
      const convertedUTCDateToIST = this.datePipe.transform(
        new Date(res.timelogDatetime),
        'yyyy-MM-dd HH:mm:ss',
        '+0530',
      );

      const startTimeParts = convertedUTCDateToIST.slice(11, 16).split(':');
      const startTimeDate = new Date(this.datePipe.transform(convertedUTCDateToIST, 'yyyy-MM-dd'));
      startTimeDate.setHours(parseInt(startTimeParts[0], 10));
      startTimeDate.setMinutes(parseInt(startTimeParts[1], 10));

      const endTimeDate = new Date(startTimeDate.getTime() + res.timelogMinutes * 60000);

      const diff = endTimeDate.getTime() - startTimeDate.getTime();
      const totalMinutes = Math.ceil(diff / (1000 * 60));
      const spentHours = Math.floor(totalMinutes / 60);
      const spentMinutes = totalMinutes % 60;

      const billableMinutes = res.timelogBillableMinutes || 0;
      const billableHours = Math.floor(billableMinutes / 60);
      const remainingBillableMinutes = billableMinutes % 60;

      this.logTimeForm.patchValue({
        date: new Date(this.datePipe.transform(convertedUTCDateToIST, 'yyyy-MM-dd')),
        timeSpent: `${spentHours}:${spentMinutes.toString().padStart(2, '0')}`,
        description: res.timelogDescription,
        billable: res.timelogIsBillable,
        startTime: startTimeDate,
        endTime: endTimeDate,
        userId: this.userId,
        hours: billableHours,
        minutes: remainingBillableMinutes,
        taskComplete: res.taskStatus === TaskStatusType.Completed ? true : false,
      });
    });
  }

  getEndDate(data): any {
    let timelogDatetime = new Date(data.timelogDatetime);
    timelogDatetime.setMinutes(timelogDatetime.getMinutes() + data.timelogMinutes);
    return timelogDatetime;
  }

  getStartDate(data) {
    const timelogDatetime = new Date(data.timelogDatetime);
    const timelogStartTime = new Date(timelogDatetime.getTime() - data.timelogHasStartTime * 60000);
    const startTimeWithSubtraction = new Date(
      timelogStartTime.getTime() + data.timelogMinutes * 60000,
    );
    return startTimeWithSubtraction;
  }

  getTimelogDatetime(data): any {
    var getDate = new Date(this.datePipe.transform(data.date, 'yyyy-MM-dd'));
    var datePart = getDate.toLocalISOString().slice(0, 10);
    var timePart = data.startTime.toLocalISOString().slice(11, 23);
    var newDate = datePart + 'T' + timePart + 'Z';

    return newDate;
  }

  checkBillableHoursValidation(): boolean {
    if (!this.isProjectBillable) {
      return false;
    }

    const controls = ['hours', 'minutes'];
    let hasValidationError = false;

    controls.forEach(controlName => {
      const control = this.logTimeForm.get(controlName);

      if (control) {
        const value = control.value;

        if (value === null || value < 0) {
          control.setErrors({ negativeValue: value < 0 });
          control.markAsTouched();
          hasValidationError = true;
        } else {
          control.setErrors(null);
        }
      }
    });

    return hasValidationError;
  }

  saveLogTime(data, isLogAnotherTime: boolean): void {
    if (!this.checkLogTimeValidation()) return;
    if (this.checkBillableHoursValidation()) return;
    this.spinnerService.show();

    const hours = this.logTimeForm.get('hours')?.value || 0;
    const minutes = this.logTimeForm.get('minutes')?.value || 0;
    var timelogBillableMinutes = this.isProjectBillable
      ? Number(hours) * 60 + Number(minutes)
      : null;

    const userId = this.logTimeForm.get('userId')?.value;
    if (this.isAdmin && !userId) {
      this.logTimeForm.get('userId')?.markAsTouched();
      this.spinnerService.hide();
      this.toasterService.error(
        NotificationTextMessage.userSelection,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return;
    }

    const param: CreateUpdateTimelogDto = {
      taskId: this.taskId,
      projectId: this.projectId,
      timelogDatetime: this.getTimelogDatetime(data),
      timelogMinutes: this.totalSpentMinutes,
      timelogDescription: data.description,
      timelogIsBillable: data.billable === true ? 1 : 0,
      timelogHasStartTime: this.totalSpentMinutes,
      timelogBillableMinutes: timelogBillableMinutes,
      userId: this.isAdmin ? userId : this.currentUser.id,
      markTaskAsComplete: this.logTimeForm.get('taskComplete')?.value,
    };

    if (!this.isEdit) {
      this.timelogService.create(param).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            if (isLogAnotherTime) {
              this.setForm(isLogAnotherTime);
              this.startTime = this.endTime;
              this.endTime = new Date(this.startTime);
              this.store.dispatch(new AddTimelogList(res)).subscribe();
              this.store.dispatch(new SetTaskStatus(res.taskStatus));
              this.commonService.triggerTimelogAdd(res);
            } else {
              const dialogResult = { confirmed: true, data: res };
              this.windowRef.close(dialogResult);
            }

            const currentUrl = this.router.url.split('?')[0];
            if (currentUrl === '/time') {
              this.commonService.triggerTimeRefresh();
            }

            this.toasterService.success(
              NotificationMessage.timelogAddedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.timelogService.update(this.timeLogId, param).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            const dialogResult = { confirmed: true, data: res };
            this.windowRef.close(dialogResult);
            this.toasterService.success(
              NotificationMessage.timelogUpdatedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }
  }

  calculateTimeSpent() {
    if (this.startTime && this.endTime) {
      const selectedDate = new Date(this.logTimeForm.get('date')?.value);

      const startDate = new Date(this.startTime);
      if (!isNaN(startDate.getTime())) {
        startDate.setFullYear(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
        );
      }
      const endDate = new Date(this.endTime);
      if (!isNaN(endDate.getTime())) {
        endDate.setFullYear(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
        );
      }
      const start = new Date(startDate);
      const end = new Date(endDate);

      start.setSeconds(0, 0);
      end.setSeconds(0, 0);

      if (start >= end) {
        this.timeSpent = '0:00';
        this.totalSpentMinutes = 0;
        return;
      }

      const diff = end.getTime() - start.getTime();

      const totalMinutes = Math.ceil(diff / (1000 * 60));

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      this.totalSpentMinutes = totalMinutes;
      this.timeSpent = `${hours}:${minutes.toString().padStart(2, '0')}`;

      if (this.isProjectBillable) {
        this.logTimeForm.get('hours').setValue(hours);
        this.logTimeForm.get('minutes').setValue(minutes);
      }
    }
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.windowRef, false);
    const overlayDiv = document.querySelector('.k-overlay');
    if (overlayDiv) {
      document.body.removeChild(overlayDiv);
    }
  }

  parseTimeSpent(timeSpent: string): [number, number] {
    const timeParts = timeSpent.split(':');
    const hours = parseInt(timeParts[0], 10) || 0;
    const minutes = parseInt(timeParts[1], 10) || 0;
    return [hours, minutes];
  }

  getProjectList(): void {
    const data: any = {
      includeProjectUserInfo: true,
      include: [],
      fields: [],
      orderByCustomFieldId: 0,
      onlyStarredProjects: true,
      hideObservedProjects: true,
      includeCounts: true,
      projectStatuses: true,
      iIncludeCustomFields: true,
      searchByLetter: true,
      skipCount: 0,
      maxResultCount: 1000,
      projectIds: [],
      sorting: 'name asc',
    };

    this.spinnerService.show();
    this.projectService.getList(data).subscribe(
      res => {
        this.spinnerService.hide();
        this.projectList = res.items;
        if (this.projectId === null || this.projectId === undefined) {
          this.projectId = this.projectList[0].id;
          this.logTimeForm.get('projectId')?.setValue(this.projectId);
          this.isProjectBillable = this.projectList[0].isBillable;

          if (this.isAdmin) {
            this.getUserList();
          }

          this.getTaskList();
        }
      },
      errpr => {
        this.spinnerService.hide();
      },
    );
  }

  onFilterChange(value: string): void {
    this.searchTerm = value;

    if (this.searchDelay) {
      clearTimeout(this.searchDelay);
    }

    this.searchDelay = setTimeout(() => {
      if (this.searchTerm.length > this.minSearchLength) {
        this.getTaskList();
      } else if (this.searchTerm.length === 0) {
        this.getTaskList();
      }
    }, 300);
  }

  getTaskList() {
    const param: any = {
      taskListId: null,
      sorting: null,
      skipCount: 0,
      maxResultCount: 1000,
      getSubTasks: true,
      includeLoggedTime: true,
      completedOnly: false,
      projectId: this.projectId,
      searchTerm: this.searchTerm,
      isFromDashboard: true,
    };
    this.taskService.getList(param).subscribe(res => {
      this.taskList = res.items;

      if (this.taskList.length > 0) {
        this.taskGroupedData = groupBy(this.taskList, [{ field: 'taskListName' }]) as GroupResult[];
        if (this.taskId === null || this.taskId === undefined) {
          this.logTimeForm.get('taskId')?.setValue(this.taskList[0]?.id);
          this.taskId = this.logTimeForm.get('taskId').value;
          this.taskName = this.taskList[0].name;
        }
      } else {
        this.taskGroupedData = [];
        this.logTimeForm.get('taskId')?.setValue(null);
        this.taskId = null;
        this.taskName = '';
      }
    });
  }

  private clearValidators(controls: string[]): void {
    controls.forEach(controlName => {
      const control = this.logTimeForm.get(controlName);
      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });
  }

  onProjectChange(projectId: any) {
    const project = this.projectList.find(x => x.id === projectId);
    this.isProjectBillable = project.isBillable;
    this.projectId = project.id;
    if (!this.isProjectBillable) {
      this.clearValidators(['hours', 'minutes']);
    }
    this.setDefaultValuesOfDropdown();

    this.getTaskList();
    if (this.isAdmin) {
      this.getUserList();
    }
  }

  onTaskChange(taskId: any): void {
    const taskControl = this.logTimeForm.get('taskId');

    if (taskId === null) {
      taskControl?.markAsTouched();
      taskControl?.setErrors({ required: true });
    }
    this.taskId = taskId;
    if (taskId) this.taskName = this.taskList.filter(x => x.id === taskId)[0].name;
  }

  checkLogTimeValidation(): boolean {
    const startTime = this.logTimeForm.get('startTime')?.value;
    const endTime = this.logTimeForm.get('endTime')?.value;

    if (!startTime || !endTime) {
      this.toasterService.error(
        NotificationTextMessage.bothStartEndTimeRequired,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return false;
    }

    const selectedDate = new Date(this.logTimeForm.get('date')?.value);

    const normalizeTime = (time: string | Date): Date => {
      const date = new Date(time);
      if (!isNaN(date.getTime())) {
        date.setFullYear(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
        );
      }
      date.setSeconds(0, 0);
      return date;
    };

    const normalizedStartTime = normalizeTime(startTime);
    const normalizedEndTime = normalizeTime(endTime);

    if (normalizedStartTime.getTime() === normalizedEndTime.getTime()) {
      this.showInvalidTimeRangeError();
      return false;
    }

    if (normalizedStartTime.getTime() > normalizedEndTime.getTime()) {
      return false;
    }

    const field = this.logTimeForm.get('taskId');

    if (field) {
      if (field.touched && (field.value === null || field.value === undefined)) {
        field.markAsTouched();
        return false;
      }

      if (field.touched && field.invalid) {
        field.markAsTouched();
        return false;
      }
    }

    return true;
  }

  markFieldAsTouched(fieldName: string): void {
    const field = this.logTimeForm.get(fieldName);
    if (field && !field.touched) {
      field.markAsTouched();
    }
  }

  showInvalidTimeRangeError(): void {
    this.toasterService.error(
      NotificationTextMessage.addValidateDateErrorMessage,
      '',
      this.commonService.toasterMessageConfiguration,
    );
  }

  setDefaultValuesOfDropdown() {
    this.taskList = [];

    this.logTimeForm.get('taskId')?.setValue(null);
    this.logTimeForm.get('taskId')?.markAsUntouched();

    this.taskId = null;
    this.taskName = null;
  }
}
