import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Component, Input, OnInit } from '@angular/core';
import { TagsListComponent } from 'projects/project/src/pages';
import { AddPeopleTeamComponent } from 'projects/project/src/pages/project/add-people-team/add-people-team.component';
import { TaskListStatusType } from 'projects/task-service/src/lib/proxy/task-service';
import { CreateUpdateTaskListDto } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TaskListService } from 'projects/task-service/src/lib/proxy/task-service/task-list';
import { ToasterService } from '@abp/ng.theme.shared';
import { NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import { NgxSpinnerService } from 'ngx-spinner';

export enum PopupEnum {
  Name = 0,
  Privacy = 1,
  Follower = 2,
}

@Component({
  selector: 'lib-add-task-list',
  templateUrl: './add-task-list.component.html',
  styleUrls: ['./add-task-list.component.scss'],
})
export class AddTaskListComponent implements OnInit {
  formGroup: FormGroup;

  //#region  Default tab  Need to implement it dynamically
  nameList: any[] = [];
  privacyList: any[] = [];
  followerList: any[] = [];
  popupEnum = PopupEnum;
  existingTagList: any[] = [{ id: 1, name: 'tags 1' }];
  tagsList: any[] = [
    { id: 1, name: 'tags 1' },
    { id: 2, name: 'tags 2' },
    { id: 3, name: 'tags 3' },
    { id: 4, name: 'tags 4' },
  ];

  boardData: any[] = [{ id: 1, label: 'None' }];
  priorityData: any[] = [
    { id: 1, label: 'None' },
    { id: 2, label: 'low' },
    { id: 3, label: 'medium' },
    { id: 4, label: 'high' },
  ];
  dropdownData: { id: number; label: string }[] = [
    { id: 1, label: 'No date' },
    { id: 2, label: 'Today' },
    { id: 3, label: 'Tomorrow' },
    { id: 4, label: 'Next week' },
  ];
  //#endregion

  @Input() projectId: any;
  @Input() taskListId: any;

  constructor(
    public dialog: DialogRef,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private taskListService: TaskListService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.fb.group({
      listName: ['', [Validators.required, Validators.maxLength(255)]],
      templateId: [null],
      notes: ['', Validators.maxLength(5000)],
      gender: [true],
      setDefault: [true],
      istoggle: [false],
      defaultNotes: [''],
      assignedTo: [null],
      startDate: [null],
      dueDate: [null],
      tags: [],
      priority: [null],
      boardColumn: [null],
      estimatedtime: [''],
      isPinned: [false],
      isDefault: [true],
    });

    if (this.taskListId !== null && this.taskListId !== undefined) {
      this.getTaskListData();
    }
  }

  getTaskListData() {
    this.spinnerService.show();
    this.taskListService.get(this.taskListId).subscribe(res => {
      this.formGroup.patchValue({
        listName: res.name,
        notes: res.description,
      });
      setTimeout(() => {
        this.spinnerService.hide();
      }, 0);
    });
  }

  onCloseClick(): void {
    this.commonService.onDialogClose(this.dialog, false);
  }

  onAddTaskListClick(): void {
    this.spinnerService.show();

    if (this.formGroup.valid) {
      const param: CreateUpdateTaskListDto = {
        projectId: +this.projectId,
        name: this.formGroup.controls.listName.value,
        description: this.formGroup.controls.notes.value,
        displayOrder: 0,
        milestoneId: 0,
        status: TaskListStatusType.New,
        isPrivate: false,
        isPinned: this.formGroup.controls.isPinned.value,
        lockdownId: 0,
        isBillable: this.formGroup.controls.setDefault.value,
      };

      if (this.taskListId !== null && this.taskListId !== undefined) {
        this.taskListService.update(this.taskListId, param).subscribe(
          res => {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationTextMessage.taskLisUpdatedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.commonService.onDialogClose(this.dialog, true);
          },
          err => {
            this.spinnerService.hide();
          },
        );
      } else {
        this.taskListService.create(param).subscribe(
          response => {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationTextMessage.taskListAddedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.commonService.onDialogClose(this.dialog, true);
          },
          err => {
            this.spinnerService.hide();
          },
        );
      }
    } else {
      this.toasterService.error(
        NotificationTextMessage.formInvalid,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  //#region default tab
  removeTag(data: any, i: number): void {
    data.splice(i, 1);
  }

  openTags(list: any): void {
    const dialogRef = this.dialogService.open({
      content: TagsListComponent,
      width: 300,
    });

    const tagInfo = dialogRef.content.instance as TagsListComponent;
    tagInfo.tagsList = this.tagsList;
    tagInfo.selectedtagsList = list.length > 0 ? list : [];

    dialogRef.result.subscribe(res => {
      if (res) {
        const newObj: any = this.tagsList.filter(x => x.name === tagInfo.formGroup.value.tagName);
        list.push(newObj[0]);
      }
    });
  }

  openSetPrivacy(type: any): void {
    const dialog: DialogRef = this.dialogService.open({
      title: '',
      content: AddPeopleTeamComponent,
      actions: [{ text: 'Close' }, { text: 'Update', themeColor: 'primary', type: 'submit' }],
      width: 450,
      height: 600,
      minWidth: 250,
    });
    const tagInfo: any = dialog.content.instance as AddPeopleTeamComponent;
    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (type === PopupEnum.Name) {
          this.nameList = [];
        } else if (type === PopupEnum.Privacy) {
          this.privacyList = [];
        } else {
          this.followerList = [];
        }

        tagInfo.peopleList.forEach(element => {
          tagInfo.selected.forEach(el => {
            if (element.id === el.itemKey) {
              if (type === PopupEnum.Name) {
                this.nameList.push(element.name);
              } else if (type === PopupEnum.Privacy) {
                this.privacyList.push(element.name);
              } else {
                this.followerList.push(element.name);
              }
            }
          });
        });
      }
    });
  }
  //#endregion
}
