import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CommonService } from 'src/core/services';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FileInfo,
  FileSelectComponent,
  FileState,
  SelectEvent,
} from '@progress/kendo-angular-upload';
// import { FileService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { CompanyService } from 'projects/project-service/src/lib/proxy/project-service';
import {
  CreateCompanyDto,
  UpdateCompanyDto,
} from 'projects/project-service/src/lib/proxy/project-service/companies';
import { CreateUpdateCompanyFileDto } from 'projects/project-service/src/lib/proxy/project-service/companies/dto';
import { FileService } from 'projects/task-service/src/lib/proxy/service/task';

@Component({
  selector: 'app-add-company-popup',
  templateUrl: './add-company-popup.component.html',
  styleUrls: ['./add-company-popup.component.scss'],
})
export class AddCompanyPopupComponent {
  companyForm: FormGroup; // Declare a FormGroup variable
  @Output() formReset = new EventEmitter<void>();
  @Input() companyId: any;
  @Input() companyList: any[];

  headerText: string;
  submitButtonText: string;
  language: string;
  status: string;
  logoUploadedtoServer: string;
  isCompanyNameExist: boolean;
  selectedFiles: any[] = [];
  public imageUrl: any;
  constructor(
    private companyService: CompanyService,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private fileService: FileService,
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.companyForm = this.fb.group({
      companyName: ['', [Validators.required, Validators.maxLength(255)]],
      website: ['', Validators.maxLength(255)],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      companyLogo: [''],
    });
  }

  setOnLoadData(): void {
    this.headerText = this.companyId === undefined ? 'Create a new Company' : 'Update Company';
    this.submitButtonText = this.companyId === undefined ? 'Create Company' : 'Update Company';
    if (this.companyId > 0) {
      this.spinnerService.show();
      this.editCompanyForm();
    }
  }

  fileShowButton(state: FileState): boolean {
    return state === FileState.Selected ? true : false;
  }

  fileRemove(fileSelect: FileSelectComponent, uid: string): void {
    fileSelect.removeFileByUid(uid);
  }

  editCompanyForm(): void {
    this.companyService.get(this.companyId).subscribe(res => {
      this.companyForm.patchValue({
        companyName: res.name,
        email: res.email1,
        website: res.website,
        phone: res.phone,
      });
      this.language = res.language;
      this.status = res.status;
      this.logoUploadedtoServer = res.logoUploadedtoServer;
      this.spinnerService.hide();
    });
  }

  onFileSelect(event: any): void {
    this.imageUrl = event.files[0];
  }

  getFileObjectURL(file: File): string {
    return URL.createObjectURL(file[0].rawFile);
  }

  onSaveCompany(): void {
    if (this.companyForm.invalid) {
      this.companyForm.controls.companyName.markAsTouched();
      this.companyForm.controls.email.markAsTouched();
      return;
    }

    this.spinnerService.show();

    if (this.companyId !== null && this.companyId !== undefined) {
      const updateParam: UpdateCompanyDto = {
        name: this.companyForm.value.companyName,
        email1: this.companyForm.value.email,
        website: this.companyForm.value.website,
        phone: this.companyForm.value.phone,
        language: this.language,
        status: this.status,
        logoUploadedtoServer: this.logoUploadedtoServer,
      };
      this.companyService.update(this.companyId, updateParam).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.commonService.onDialogClose(this.dialogRef, true);
            this.toasterService.success(
              NotificationTextMessage.companyUpdatedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      const myFormData = new FormData();
      if (this.imageUrl !== undefined) {
        const file: File = this.imageUrl.rawFile;
        myFormData.append('logo', file);
      } else {
        myFormData.append('logo', null);
      }

      myFormData.append('name', this.companyForm.value.companyName);
      myFormData.append('email1', this.companyForm.value.email);
      myFormData.append('website', this.companyForm.value.website);
      myFormData.append('phone', this.companyForm.value.phone);

      // const logo: any = {};

      const createParam: any = {
        name: this.companyForm.value.companyName,
        email1: this.companyForm.value.email,
        website: this.companyForm.value.website,
        phone: this.companyForm.value.phone,
        logo: myFormData,
      };
      this.companyService.checkCompanyNameExists(createParam.name).subscribe(isNotExist => {
        if (isNotExist) {
          this.companyService.create(createParam).subscribe(
            res => {
              if (res) {
                this.spinnerService.hide();
                this.commonService.onDialogClose(this.dialogRef, true);
                this.toasterService.success(
                  NotificationTextMessage.companyAddedMessage,
                  '',
                  this.commonService.toasterMessageConfiguration,
                );
              }
            },
            err => {
              this.spinnerService.hide();
            },
          );
        } else {
          this.companyForm.controls.companyName.setErrors({ incorrect: true });
          this.spinnerService.hide();
          return this.toasterService.error(
            NotificationTextMessage.companyNameAlreadyExistMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        }
      });
    }
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }
}
