import { AccountConfigModule } from '@abp/ng.account/config';
import { CoreModule, ListService } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { ThemeLeptonXModule } from '@abp/ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@abp/ng.theme.lepton-x/layouts';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { ApiInterceptor } from '@abp/ng.core';
import { YourApiInterceptor } from 'projects/core/src/lib//interceptors/api.interceptor';
import { SharedModule } from './shared/shared.module';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import {
  AddCategoryComponent,
  AddCompanyPopupComponent,
  AddPortfolioComponent,
  AddProductComponent,
  AddUserComponent,
  CategoryListComponent,
  CompanyListComponent,
  PortfolioComponent,
  ProductComponent,
  ProjectCategoryComponent,
  UserListComponent,
} from './modules';
import {
  CustomExtensibleTableComponent,
  CustomManageFeatureComponent,
  CustomPermissionComponent,
  CustomRolesComponent,
  CustomUserComponent,
  CustomeTenantComponent,
  CustomAddTenantComponent,
  CustomEmailComponent,
  CustomSettingComponent,
  CustomSettingFeaturesComponent,
} from './shared';
import { CommonService } from 'src/core/services';
import { PageModule } from '@abp/ng.components/page';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DashboardCountPopupComponent } from './modules/dashboard/dashboard-count-popup/dashboard-count-popup.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgxsModule } from '@ngxs/store';
import { TaskState } from './core/store/task.state';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import {
  AddTaskDetailComponent,
  AddTaskListComponent,
  AttachTaskFilePopupComponent,
  LogTimePanelComponent,
  ProjectDashboardComponent,
  TaskListComponent,
  TimeComponent,
  TimeLogPopupComponent,
} from 'tasks/task/src/pages';
import { SetAnEstimateComponent } from 'tasks/task/src/pages/task/shared/set-an-estimate/set-an-estimate.component';
import { SetPriorityComponent } from 'tasks/task/src/pages/task/shared/set-priority/set-priority.component';
import { SetDatePopupComponent } from 'tasks/task/src/pages/task/shared/set-date-popup/set-date-popup.component';
import { SetStatusPopupComponent } from 'tasks/task/src/pages/task/shared/set-status-popup/set-status-popup.component';
import { TaskFilterComponent } from 'tasks/task/src/pages/task/task-list/task-filter/task-filter.component';
import { SetAssigneeComponent } from 'tasks/task/src/pages/task/shared/set-assignee/set-assignee.component';
import { SettingComponent } from 'tasks/task/src/pages/task/task-list/setting/setting.component';
import { AddTaskMoreDetailsComponent } from 'tasks/task/src/pages/task/task-list/add-task-more-details/add-task-more-details.component';
import { SubTasksComponent } from 'tasks/task/src/pages/task/shared/sub-tasks/sub-tasks.component';
import { FilesComponent } from 'tasks/task/src/pages/task/shared/files/files.component';
import { CommentComponent } from 'tasks/task/src/pages/task/shared/comment/comment.component';
import { TaskEditPopupComponent } from 'tasks/task/src/pages/task/add-task-detail/task-edit-popup/task-edit-popup.component';
import { AddTaskComponent } from 'tasks/task/src/pages/task/task-list/add-task/add-task.component';
import { WindowRef } from '@progress/kendo-angular-dialog';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    LightboxModule,
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    FeatureManagementModule.forRoot(),
    PageModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxSpinnerModule,
    NgxsModule.forRoot([TaskState]),
    NgxValidateCoreModule.forRoot({}),
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    CategoryListComponent,
    AddCategoryComponent,
    ProjectCategoryComponent,
    CompanyListComponent,
    AddCompanyPopupComponent,
    CustomExtensibleTableComponent,
    CustomManageFeatureComponent,
    CustomPermissionComponent,
    CustomRolesComponent,
    CustomUserComponent,
    CustomeTenantComponent,
    CustomAddTenantComponent,
    CustomEmailComponent,
    CustomSettingComponent,
    CustomSettingFeaturesComponent,
    PortfolioComponent,
    AddPortfolioComponent,
    ProductComponent,
    AddProductComponent,
    UserListComponent,
    AddUserComponent,
    DashboardCountPopupComponent,
    TaskListComponent,
    SetAnEstimateComponent,
    SetPriorityComponent,
    SetDatePopupComponent,
    SetStatusPopupComponent,
    TaskFilterComponent,
    SetAssigneeComponent,
    ProjectDashboardComponent,
    SettingComponent,
    AddTaskMoreDetailsComponent,
    LogTimePanelComponent,
    SubTasksComponent,
    FilesComponent,
    CommentComponent,
    TimeComponent,
    AddTaskDetailComponent,
    AttachTaskFilePopupComponent,
    TimeLogPopupComponent,
    AddTaskListComponent,
    TaskEditPopupComponent,
    AddTaskComponent,
  ],
  exports: [NgxSpinnerModule, LightboxModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    APP_ROUTE_PROVIDER,
    {
      provide: ApiInterceptor,
      useClass: YourApiInterceptor,
    },
    CommonService,
    ListService,
    DatePipe,
    WindowRef,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
