<div
  [ngClass]="
    isDrawerEditContent || showTimeLogTaskDetail || showProjectSummary ? '' : 'q-content p-20'
  "
>
  <div
    *ngIf="
      ((timeLogList$ | async).length === 0 && filterCount === 0) ||
        ((timeLogList$ | async).length === 0 && !allowGrouping);
      else gridTemplate
    "
    class="q-content-bg d-flex justify-content-center align-items-center flex-column"
  >
    <img src="assets/images/timelog.png" alt="timelog" class="mb-10" />
    <p class="mt-10 font-size-16 fw-500">No Data to Display</p>
    <p class="text-secondary-text font-size-14 mt-10 text-align-center" *ngIf="allowGrouping">
      It seems there is no filter apply to display timelog.
    </p>
    <p class="text-secondary-text font-size-14 mt-10 text-align-center" *ngIf="!allowGrouping">
      It seems there is no time logged on this task.
    </p>
    <a
      title="Apply Filter"
      class="add-on-btn font-size-13 fw-500"
      *ngIf="allowGrouping"
      (click)="openFilter()"
      ><i class="far fa-filter mr-5 mt-5"></i> Apply Filter
    </a>
    <a
      title="Add Task Timelog"
      class="add-on-btn"
      *ngIf="!allowGrouping"
      (click)="openTimeLog(taskName)"
      ><i class="far fa-plus"></i> Log time on task
    </a>
  </div>
</div>

<div>
  <ng-template #gridTemplate>
    <div
      [ngClass]="
        isDrawerEditContent || showTimeLogTaskDetail || showProjectSummary ? '' : 'q-content-bg'
      "
    >
      <div #filterContainer>
        <div *ngIf="allowGrouping" class="q-content-header mb-20">
          <div class="d-flex gap-5 align-items-start justify-content-between filter-section">
            <div class="d-flex gap-5 align-items-center flex-wrap">
              <span class="font-size-12">Filter by:</span>
              <div *ngIf="showFilterDate" class="font-size-12 filter-label">
                <div>
                  <i class="far fa-calendar-days mr-5"></i><span> {{ formattedDateRange }} </span>
                </div>
                <a (click)="onRemoveFilterClick(filterTypes.DateFilter)"
                  ><i class="far fa-xmark mr-5"></i
                ></a>
              </div>
              <div class="font-size-12 filter-label" *ngFor="let user of selectedUsers">
                <div>
                  <kendo-avatar
                    rounded="full"
                    size="small"
                    [border]="true"
                    [initials]="commonService.getInitials(user.userName)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(user.id, user.userName)
                    "
                    class="social-profile-text assignee-container mr-5"
                    [attr.title]="user.userName"
                  ></kendo-avatar>
                  <span>{{ user.userName }}</span>
                </div>
                <a (click)="onRemoveFilterClick(filterTypes.SelectedUsersFilter, user.id)"
                  ><i class="far fa-xmark mr-5"></i
                ></a>
              </div>
              <div class="font-size-12 filter-label" *ngFor="let projectName of selectedProject">
                <div>
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(projectName.name)"
                    class="project-logo project-logo-time-page mr-5"
                  ></kendo-avatar>
                  <span>{{ projectName.name }}</span>
                </div>
                <a (click)="onRemoveFilterClick(filterTypes.SelectedProjectsFilter, projectName.id)"
                  ><i class="far fa-xmark mr-5"></i
                ></a>
              </div>
              <div *ngIf="showBillableFilter" class="font-size-12 filter-label">
                <div>
                  <i class="far fa-clock mr-5"></i><span> {{ selectedBillableFilterText }} </span>
                </div>
              </div>
            </div>
            <div class="d-flex gap-5 align-items-center">
              <div
                class="filter-icon-container p-10 refresh-icon"
                (click)="getTimelogList()"
                title="Refresh"
              >
                <span><i title="Refresh" class="far fa-sync-alt font-size-14"></i></span>
              </div>
              <div class="export-dropDown" title="Export File">
                <kendo-dropdownbutton
                  [data]="exportActionList"
                  #additionalSetting
                  (itemClick)="onActionItemClicked($event)"
                >
                  <i class="far fa-download font-size-14 mr-5"></i><span> Export </span>
                </kendo-dropdownbutton>
              </div>
              <div class="filter-icon-container p-10" (click)="openFilter()" title="Filter">
                <span><i title="Filter" class="far fa-filter font-size-14"></i></span>
                <span>
                  {{ filterCount }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-count-section">
          <div class="d-flex gap-15 primary-card text-primary flex-wrap mb-20 font-size-13">
            <span *ngIf="allowGrouping" class="fw-500"> Filtered Totals: </span>
            <div>
              <i class="fa-regular fa-clock"></i>
              <span class="ml-5 fw-500">Logged</span> :
              {{ commonService.getHoursAndMinutesFromTotalMinutes(totalLoggedTime$ | async) }} ({{
                commonService.getOnlyHoursFromMinutes(totalLoggedTime$ | async) | number: '1.2-2'
              }}
              h)
            </div>
            <div>
              <i class="far fa-ban"></i>
              <span class="ml-5 fw-500">Non-billable</span> :
              {{
                commonService.getHoursAndMinutesFromTotalMinutes(
                  totalLoggedNonBillableTime$ | async
                )
              }}
              ({{
                commonService.getOnlyHoursFromMinutes(totalLoggedNonBillableTime$ | async)
                  | number: '1.2-2'
              }}
              h)
            </div>
            <div *ngIf="isProjectBillable || isTimePage">
              <i class="fa-regular fa-circle-check"></i>
              <span class="ml-5 fw-500">Billable</span> :
              {{
                commonService.getHoursAndMinutesFromTotalMinutes(totalLoggedBillableTime$ | async)
              }}
              ({{
                commonService.getOnlyHoursFromMinutes(totalLoggedBillableTime$ | async)
                  | number: '1.2-2'
              }}
              h)
            </div>
            <div *ngIf="allowGrouping">
              <i class="fa-regular fa-clock"></i>
              <span class="ml-5 fw-500">Estimated</span> :
              {{ commonService.getHoursAndMinutesFromTotalMinutes(totalLoggedEstimate$ | async) }}
              ({{
                commonService.getOnlyHoursFromMinutes(totalLoggedEstimate$ | async)
                  | number: '1.2-2'
              }})
            </div>
            <!-- <div *ngIf="!isBillableColumnVisible && allowGrouping">
              <i class="fa-regular fa-clock"></i>
              <span class="ml-5 fw-500">Not Billed</span> :
              {{ commonService.getHoursAndMinutesFromTotalMinutes(totalLoggedNotBilled$ | async) }}
              ({{
                commonService.getOnlyHoursFromMinutes(totalLoggedNotBilled$ | async)
                  | number: '1.2-2'
              }})
            </div> -->
          </div>
          <div
            *ngIf="showProjectSummary"
            class="d-flex gap-15 primary-card text-primary flex-wrap mb-20 font-size-13"
          >
            <div>
              <kendo-label
                text="Total Time Logged"
                class="fw-500 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <span class="text-primary"
                  >{{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeTotals?.totalMinsSum
                    )
                  }}
                </span>
              </div>
            </div>
            <div *ngIf="isProjectBillable">
              <kendo-label
                text="Total Billable Time"
                class="fw-500 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break mb-0 text-primary">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeTotals?.billableMinsSum
                    )
                  }}
                </p>
              </div>
            </div>
            <!-- <div *ngIf="!isBillableColumnVisible">
              <kendo-label
                text="Total Billed Time"
                class="fw-500 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break mb-0">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeTotals?.billedMinsSum
                    )
                  }}
                </p>
              </div>
            </div> -->
            <div>
              <kendo-label
                text="Total Estimated Time"
                class="fw-500 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break mb-0 text-primary">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeEstimates?.totalMinsEstimated
                    )
                  }}
                </p>
              </div>
            </div>
            <div>
              <kendo-label
                text="Overall Project Estimated Time"
                class="fw-500 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break mb-0 text-primary">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeEstimates?.totalMinsEstimated
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="timeLog-grid-section">
        <div class="grid-wrapper">
          <kendo-grid
            #grid
            [data]="timeList$ | async"
            [resizable]="true"
            [pageSize]="pageSize"
            [skip]="skip"
            [total]="timeLogTotal$ | async"
            [pageable]="{
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: [5, 10, 20, 100],
              previousNext: previousNext,
              position: pagerposition,
            }"
            (pageChange)="pageChange($event)"
            [height]="gridHeight"
            [groupable]="false"
            class="custom-no-records-container time-dashborad-grid grid-header-padding"
            [ngClass]="showTimeLogTaskDetail || isDrawerEditContent ? 'fix-grid-height' : ''"
          >
            <ng-template kendoGridNoRecordsTemplate>
              <div *ngIf="!(timeLogTotal$ | async)" class="no-records-text-container">
                No Records Available
              </div>
            </ng-template>
            <kendo-grid-column
              *ngIf="!showProjectSummary && allowGrouping"
              [columnMenu]="false"
              [filterable]="true"
              field="projectName"
              title="Project"
              filter="string"
              [width]="100"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="word-break line-height-normal">
                  {{ dataItem.projectName }}
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              *ngIf="!allowGrouping"
              [columnMenu]="false"
              [filterable]="true"
              field="timelogDatetime"
              title="Date"
              filter="string"
              [width]="100"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-flex gap-5">
                  {{ dataItem.timelogDatetime | date: 'yyyy-MM-dd' }}
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [columnMenu]="false"
              [filterable]="true"
              field="timelogUserFirstName"
              title="Who"
              filter="string"
              [width]="100"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-flex gap-5 align-items-baseline">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(dataItem.userName)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(dataItem.userId, dataItem.userName)
                    "
                    class="social-profile-text"
                    [attr.title]="userName"
                  ></kendo-avatar>
                  <div class="word-break line-height-normal" title="{{ dataItem.timelogUserName }}">
                    {{ dataItem.timelogUserName }}
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [columnMenu]="false"
              [filterable]="true"
              field="taskName"
              title="Description"
              filter="string"
              [width]="150"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-flex gap-5" *ngIf="allowGrouping">
                  <span class="task-name task-title" *ngIf="dataItem.taskName"> Task: </span>
                  <a
                    class="text-underline line-height-normal word-break"
                    (click)="onGoToTaskDetailsClick(dataItem, $event)"
                    [target]="openInNewTab ? '_blank' : null"
                    title="{{ dataItem.taskName }}"
                  >
                    {{ dataItem.taskName }}
                  </a>
                </div>
                <p
                  class="mb-0 mt-5 line-height-normal word-break description-text"
                  *ngIf="dataItem.timelogDescription"
                >
                  {{ dataItem.timelogDescription }}
                </p>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              *ngIf="allowGrouping"
              [columnMenu]="false"
              [filterable]="true"
              field="tasklistName"
              title="Task List"
              filter="string"
              [width]="50"
              [sortable]="false"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-flex gap-5">
                  <span class="clamp" title="{{ dataItem.tasklistName }}">
                    {{ dataItem.tasklistName }}
                  </span>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [columnMenu]="false"
              [filterable]="true"
              field="start"
              title="Start"
              filter="string"
              [width]="70"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.startTime }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [columnMenu]="false"
              [filterable]="true"
              field="end"
              title="End"
              filter="string"
              [width]="70"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.endTime }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [columnMenu]="false"
              [filterable]="true"
              field="time"
              title="Actual Time"
              filter="string"
              [width]="allowGrouping ? '100' : '60'"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ commonService.getHoursAndMinutesFromTotalMinutes(dataItem.timelogMinutes) }}
              </ng-template>
              <ng-template kendoGridGroupFooterTemplate let-aggregates>
                {{
                  commonService.getHoursAndMinutesFromTotalMinutes(aggregates['timelogMinutes'].sum)
                }}
                ({{ aggregates['timelogMinutes'].sum / 60 | number: '1.2-2' }}h)
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [columnMenu]="false"
              [filterable]="true"
              field="hoursDecimal"
              title="Actual Hours"
              filter="number"
              [width]="60"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.hoursDecimal | number: '1.2-2' }}h
              </ng-template>
              <ng-template kendoGridGroupFooterTemplate let-aggregates>
                {{ aggregates['timelogMinutes'].sum / 60 | number: '1.2-2' }}h
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              *ngIf="isProjectBillable || isTimePage"
              [columnMenu]="false"
              [filterable]="true"
              field="timelogBillableMinutes"
              title="Billable Hours"
              filter="number"
              [width]="60"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span title="{{ dataItem.timelogBillableMinutes }}">
                  {{ dataItem.timelogBillableMinutes / 60 | number: '1.2-2' }}h</span
                >
              </ng-template>
              <ng-template kendoGridGroupFooterTemplate let-aggregates>
                <span *ngIf="isProjectBillable || isTimePage">
                  {{ aggregates['timelogBillableMinutes'].sum / 60 | number: '1.2-2' }}h</span
                >
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [columnMenu]="false"
              [filterable]="true"
              field="status"
              title=""
              filter="boolean"
              [width]="80"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <ul class="grid-action-list">
                  <div class="d-flex gap-10 justify-content-end">
                    <li *ngIf="dataItem.canEditOrDelete">
                      <a title="Edit" (click)="onEdit(dataItem)">
                        <i class="far fa-pen"></i>
                      </a>
                    </li>
                    <li *ngIf="dataItem.canEditOrDelete">
                      <a title="Delete" (click)="onDelete(dataItem)">
                        <i class="far fa-trash-alt"></i>
                      </a>
                    </li>
                  </div>
                </ul>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
        <div *ngIf="!allowGrouping">
          <ng-container *ngIf="(timeLogList$ | async).length > 0">
            <a
              title="Add Task Timelog"
              class="add-on-btn display-inline"
              (click)="openTimeLog(taskName)"
            >
              <i class="far fa-plus"></i> Log time on task
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<kendo-drawer-container *ngIf="allowGrouping" class="filter-drawer-container">
  <kendo-drawer
    [mode]="expandMode"
    [expanded]="isDrawerOpened"
    [position]="position"
    [autoCollapse]="false"
  >
    <ng-template kendoDrawerTemplate>
      <div class="d-flex justify-content-between drawer-header align-items-center">
        <h4 class="title">Filter</h4>
        <a class="close-icon" (click)="closeDrawer()"><i class="far fa-xmark"></i></a>
      </div>

      <div class="drawer-content">
        <fieldset class="mb-20">
          <kendo-label text="Date" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="dateFilter"
            textField="text"
            valueField="value"
            [value]="selectedFilterValue"
            [valuePrimitive]="true"
            (selectionChange)="selectionChange($event)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              {{ dataItem.text }}
            </ng-template>
          </kendo-dropdownlist>
        </fieldset>

        <div *ngIf="selectedFilterValue === 1">
          <fieldset class="mb-20">
            <kendo-label text="Start Date" labelCssClass="k-form-label"></kendo-label>
            <kendo-datepicker
              calendarType="classic"
              [format]="'EEE, d MMM yyyy'"
              (valueChange)="updateStartDate($event)"
              [(ngModel)]="startDate"
            ></kendo-datepicker>
          </fieldset>
          <fieldset class="mb-20">
            <kendo-label text="End Date" labelCssClass="k-form-label"></kendo-label>
            <kendo-datepicker
              calendarType="classic"
              [format]="'EEE, d MMM yyyy'"
              (valueChange)="updateEndDate($event)"
              [(ngModel)]="endDate"
            ></kendo-datepicker>
            <div *ngIf="dateError" class="text-danger">
              End Date cannot be earlier than Start Date.
            </div>
          </fieldset>
        </div>
        <fieldset class="k-form-fieldset mb-20" *ngIf="isAdmin || isCurrentUserResponsiblePerson">
          <kendo-label text="People" labelCssClass="k-form-label"></kendo-label>
          <kendo-multiselect
            [(ngModel)]="selectedUsers"
            [clearButton]="false"
            [checkboxes]="true"
            [data]="userList"
            valueField="id"
            textField="userName"
            [filterable]="true"
            (valueChange)="onUserSelectionChange($event)"
            (filterChange)="onUserSearch($event)"
          ></kendo-multiselect>
        </fieldset>

        <fieldset class="k-form-fieldset mb-20" *ngIf="!showProjectSummary">
          <kendo-label text="Projects" labelCssClass="k-form-label"></kendo-label>
          <kendo-multiselect
            [(ngModel)]="selectedProject"
            [clearButton]="false"
            [checkboxes]="true"
            [data]="projectList"
            valueField="id"
            textField="name"
            [filterable]="true"
            (valueChange)="onProjectSelectionChange($event)"
            (filterChange)="onProjectSearch($event)"
          ></kendo-multiselect>
        </fieldset>

        <fieldset class="k-form-fieldset mb-20">
          <kendo-label text="Billable" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="billableFilterList"
            textField="text"
            valueField="value"
            [value]="selectedBillableFilterValue"
            [valuePrimitive]="true"
            (selectionChange)="billableFilterselectionChange($event)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              {{ dataItem.text }}
            </ng-template>
          </kendo-dropdownlist>
        </fieldset>
      </div>

      <div class="drawer-footer d-flex justify-content-end gap-15">
        <button kendoButton (click)="onSubmit()">Submit</button>

        <button kendoButton fillMode="outline" (click)="clearFilter(true)">Clear All</button>
      </div>
    </ng-template>
  </kendo-drawer>
</kendo-drawer-container>
<div *ngIf="isTimePage || showProjectSummary" kendoDialogContainer></div>
