import { ConfigStateService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Align } from '@progress/kendo-angular-popup';
import { orderBy } from '@progress/kendo-data-query';
import { ProjectUserService } from '@proxy/project-service/project';
import { ProjectBoardColumnService } from 'projects/project-service/src/lib/proxy/project-service';
import { TaskStatusType } from 'projects/project-service/src/lib/proxy/task-service';
import { QuickFilterEnum } from 'src/app/enum/quick-filter-task';
import { CommonService } from 'src/core/services';
import { Priority } from 'tasks/task/config/src/enums/priority';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss'],
})
export class TaskFilterComponent implements OnInit {
  @Input() showTaskFilterPopup: boolean = false;
  @Input() position: { top: number; left: number } | null = null;
  @Input() projectId = 0;

  @Output() triggerAppliedFilter = new EventEmitter();

  quickFilter = QuickFilterEnum;
  taskStatusType = TaskStatusType;
  filterForm: FormGroup;
  count = 0;
  selectedDateFilterValue = 0;
  public popupAlign: Align = { horizontal: 'center', vertical: 'top' };

  filterRequestParam: any = {
    assignee: [],
    startDate: undefined,
    dueDate: undefined,
    taskStatusId: null,
    priority: null,
    isLateTask: null,
    isDueWeek: null,
    completedOnly: false,
    isNotCompleted: null,
    isDueNextWeek: null,
    createdByMe: null,
  };

  togglecompletedOnly: boolean = false;
  toggleIncompleteAndcompletedOnly: boolean = false;
  toggleCreatedByMe: boolean = false;
  toggleisLateTask: boolean = false;
  toggleDueThisWeek: boolean = false;
  toggleDueNextWeek: boolean = false;
  dateError = false;
  isFilterApply = false;
  isAdmin: boolean = false;

  assigneeList: any[] = [];
  assigneListItems = [];
  priorityList: { id: Priority; text: string }[] = [];
  dateFilter: Array<{ text: string; value: number }> = [
    { text: 'Anytime', value: 0 },
    { text: 'Custom date range', value: 1 },
  ];

  taskStatusTypeList = [];

  startDate = new Date();
  dueDate = new Date();

  currentUser: any;

  constructor(
    private projectUser: ProjectUserService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private config: ConfigStateService,
    private projectBoardColumnService: ProjectBoardColumnService,
  ) {
    this.priorityList = this.commonService.getPriorityList();
    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    this.isAdmin = currentUserRole.includes('admin');
    this.currentUser = this.config.getOne('currentUser');
  }

  ngOnInit(): void {
    this.setForm();
    this.getAssigneeList();
    this.getProjectTaskStatusList();
  }

  getProjectTaskStatusList(): void {
    const data: any = {
      projectId: this.projectId,
    };

    this.projectBoardColumnService.getList(data).subscribe(
      response => {
        this.taskStatusTypeList = response.items.map((item: any) => ({
          key: item.id,
          value: item.columnname,
        }));

        this.taskStatusTypeList = orderBy(this.taskStatusTypeList, [{ field: 'key', dir: 'asc' }]);
        this.taskStatusTypeList.unshift({ key: 0, value: 'All' });
        this.filterForm.controls.taskStatus.setValue(0);
      },
      err => {},
    );
  }

  setForm(): void {
    this.filterForm = new FormGroup({
      assignee: new FormControl(null),
      date: new FormControl(0),
      startDate: new FormControl(new Date()),
      dueDate: new FormControl(new Date()),
      taskStatus: new FormControl(0),
      priority: new FormControl(Priority.All),
    });
  }

  clearFilter(): void {
    this.filterRequestParam = {
      assignee: [],
      startDate: undefined,
      dueDate: undefined,
      taskStatusId: null,
      priority: null,
      isLateTask: null,
      isDueWeek: null,
      completedOnly: false,
      createdByMe: null,
    };

    this.isFilterApply = false;
    this.togglecompletedOnly = false;
    this.toggleIncompleteAndcompletedOnly = false;
    this.toggleCreatedByMe = false;
    this.toggleisLateTask = false;
    this.toggleDueThisWeek = false;
    this.toggleDueNextWeek = false;

    this.selectedDateFilterValue = 0;
    this.count = 0;
    this.setForm();

    this.triggerFilter();
  }

  checkIsQuickFilterApplied(): void {
    if (
      this.togglecompletedOnly === true ||
      this.toggleCreatedByMe === true ||
      this.toggleisLateTask === true ||
      this.toggleDueThisWeek === true ||
      this.toggleDueNextWeek === true ||
      this.filterRequestParam.assignee.length > 0 ||
      this.filterRequestParam.dueDate !== undefined ||
      this.filterRequestParam.taskStatusId !== null ||
      this.filterRequestParam.priority !== null
    ) {
      this.isFilterApply = true;
    } else {
      this.isFilterApply = false;
    }
  }

  onClickQuickFilterComplete(): void {
    this.togglecompletedOnly = !this.togglecompletedOnly;
    this.isFilterApply = true;

    if (!this.togglecompletedOnly) {
      this.filterRequestParam.completedOnly = false;
    } else {
      this.filterRequestParam.completedOnly = true;
    }

    this.checkIsQuickFilterApplied();
    this.triggerFilter();
  }

  onClickQuickFilterInComplete(): void {
    this.toggleIncompleteAndcompletedOnly = !this.toggleIncompleteAndcompletedOnly;

    const count = [
      this.togglecompletedOnly,
      this.toggleCreatedByMe,
      this.toggleisLateTask,
      this.toggleDueThisWeek,
      this.toggleDueNextWeek,
    ].filter(condition => condition).length;

    if (count > 0) {
      this.isFilterApply = true;
    } else {
      this.isFilterApply = false;
    }

    if (!this.toggleIncompleteAndcompletedOnly) {
      this.filterRequestParam.isNotCompleted = false;
    } else {
      this.filterRequestParam.isNotCompleted = true;
    }
    this.checkIsQuickFilterApplied();
    this.triggerFilter();
  }

  onClickQuickFilterCreatedByMe(): void {
    this.toggleCreatedByMe = !this.toggleCreatedByMe;
    this.isFilterApply = true;

    if (!this.toggleCreatedByMe) {
      this.filterRequestParam.assignee = [];
    } else {
      this.filterRequestParam.assignee = [this.config.getOne('currentUser').id];
    }
    this.checkIsQuickFilterApplied();
    this.triggerFilter();
  }

  onClickQuickFilterLateTask(): void {
    this.toggleisLateTask = !this.toggleisLateTask;
    this.isFilterApply = true;

    if (!this.toggleisLateTask) {
      this.filterRequestParam.isLateTask = null;
    } else {
      this.filterRequestParam.isLateTask = true;
    }
    this.checkIsQuickFilterApplied();
    this.triggerFilter();
  }

  onClickQuickFilterDueThisWeekTask(): void {
    this.toggleDueThisWeek = !this.toggleDueThisWeek;
    this.isFilterApply = true;

    if (!this.toggleDueThisWeek) {
      this.filterRequestParam.isDueWeek = null;
    } else {
      this.filterRequestParam.isDueWeek = true;
    }
    this.checkIsQuickFilterApplied();
    this.triggerFilter();
  }

  onClickQuickFilterDueNextWeekTask(): void {
    this.toggleDueNextWeek = !this.toggleDueNextWeek;
    this.isFilterApply = true;

    if (!this.toggleDueNextWeek) {
      this.filterRequestParam.isDueNextWeek = null;
    } else {
      this.filterRequestParam.isDueNextWeek = true;
    }
    this.checkIsQuickFilterApplied();
    this.triggerFilter();
  }

  triggerFilter(): void {
    this.setFilterValue();
    this.setCount();
    const result = {
      filterCount: this.count,
      param: this.filterRequestParam,
      isFilterApply: this.isFilterApply,
    };
    this.triggerAppliedFilter.next(result);

    this.isFilterApply = false;
  }

  onSubmit(): void {
    if (this.dateError) return;
    this.isFilterApply = true;
    this.triggerFilter();
  }

  updateStartDate(event): void {
    this.startDate = event;
    this.validateDates();
  }

  updateEndDate(event): void {
    this.dueDate = event;
    this.validateDates();
  }

  selectionChange(event): void {
    this.dateError = false;
    this.selectedDateFilterValue = event.value;
    if (this.selectedDateFilterValue == 0) {
      this.startDate = undefined;
      this.dueDate = undefined;
    } else {
      this.startDate = new Date();
      this.dueDate = new Date();
    }
  }

  validateDates() {
    if (this.startDate && this.dueDate) {
      this.dateError = this.dueDate < this.startDate;
    } else {
      this.dateError = false;
    }
  }

  setLoggedUser(): void {
    const currentUserObject = this.assigneeList.find(user => user.userId === this.currentUser.id);
    if (currentUserObject !== null && currentUserObject !== undefined && !this.isAdmin) {
      this.filterForm.controls.assignee.setValue([currentUserObject]);
    }
    if (!this.isAdmin) this.onSubmit();
  }

  getAssigneeList(): void {
    const params = {
      sorting: 'name asc',
      skipCount: 0,
      maxResultCount: 1000,
    };

    this.projectUser.getPeople(this.projectId, params).subscribe(res => {
      if (res.items.length > 0) {
        this.assigneeList = this.commonService.preprocessAssigneeList(res.items);
        this.assigneListItems = this.assigneeList.map(assignee => ({
          userName: assignee.userName,
          userId: assignee.userId,
        }));
        // this.setLoggedUser();
        this.triggerFilter();
      }
    });
  }

  onAssigneeSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { userName: string; userId: number }) =>
      item.userName.toLowerCase().includes(value.toLowerCase());

    this.assigneeList = this.assigneListItems.filter(contains(searchTerm));
  }

  setFilterValue(): void {
    if (this.filterForm.controls.assignee.value?.length) {
      this.filterRequestParam.assignee = this.filterForm.controls.assignee.value.map(x => x.userId);
    } else {
      this.filterRequestParam.assignee = [];
    }

    if (this.selectedDateFilterValue == 1) {
      this.filterRequestParam.startDate = new Date(
        this.datePipe.transform(this.filterForm.controls.startDate.value, 'yyyy-MM-dd'),
      )
        .toLocalISOString()
        .slice(0, 10);
    } else {
      this.filterRequestParam.startDate = undefined;
    }

    if (this.selectedDateFilterValue == 1) {
      this.filterRequestParam.dueDate = new Date(
        this.datePipe.transform(this.filterForm.controls.dueDate.value, 'yyyy-MM-dd'),
      )
        .toLocalISOString()
        .slice(0, 10);
    } else {
      this.filterRequestParam.dueDate = undefined;
    }

    if (
      this.filterForm.controls.taskStatus.value !== 0 &&
      this.filterForm.controls.taskStatus.value !== null
    ) {
      this.filterRequestParam.taskStatusId = this.filterForm.controls.taskStatus.value;
    } else {
      this.filterRequestParam.taskStatusId = null;
    }

    if (
      this.filterForm.controls.priority.value !== null &&
      this.filterForm.controls.priority.value !== 4
    ) {
      this.filterRequestParam.priority = this.filterForm.controls.priority.value;
    } else {
      this.filterRequestParam.priority = null;
    }

    if (this.toggleCreatedByMe) {
      this.filterRequestParam.createdByMe = this.currentUser.id;
    }

    if (this.toggleIncompleteAndcompletedOnly) {
      this.filterRequestParam.isNotCompleted = null;
    }
  }

  setCount(): void {
    this.count = 0;
    if (
      !this.togglecompletedOnly &&
      !this.toggleIncompleteAndcompletedOnly &&
      !this.toggleCreatedByMe &&
      !this.toggleisLateTask &&
      !this.toggleDueThisWeek &&
      !this.toggleDueNextWeek
    ) {
      this.count = 0;
    } else {
      this.count = [
        this.togglecompletedOnly,
        this.toggleIncompleteAndcompletedOnly,
        this.toggleCreatedByMe,
        this.toggleisLateTask,
        this.toggleDueThisWeek,
        this.toggleDueNextWeek,
      ].filter(condition => condition).length;
    }

    if (this.filterForm.controls.assignee.value?.length) this.count++;

    if (this.selectedDateFilterValue == 1) this.count = this.count + 2;

    if (
      this.filterForm.controls.taskStatus.value !== 0 &&
      this.filterForm.controls.taskStatus.value !== null
    )
      this.count++;

    if (
      this.filterForm.controls.priority.value !== null &&
      this.filterForm.controls.priority.value !== 4
    )
      this.count++;
  }
}
