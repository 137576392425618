<div class="container-wrapper">
  <ng-container *ngIf="this.authService.isAuthenticated">
    <lib-q-menu
      [mode]="mode"
      [logoImage]="logoImage"
      [iconlogoImage]="iconlogoImage"
      [profileImage]="profileImage"
      [headerData]="headerDataList"
      [items]="menuList"
      [isQPMS]="isQPMS"
      [downarrowImage]="downarrowImage"
      (triggerAdminClick)="onHeaderClick($event)"
      [currentUser]="currentUser"
      #qMenu
    >
      <router-outlet></router-outlet>
    </lib-q-menu>
  </ng-container>
</div>
<kendo-window
  *ngIf="isTimerRunning"
  class="start-timer"
  [top]="calculateWindowTop()"
  [left]="calculateWindowLeft()"
  (leftChange)="restrictMovement()"
  (topChange)="restrictMovement()"
>
  <kendo-window-titlebar>
    <button kendoWindowMaximizeAction class="display-none"></button>
    <button kendoWindowMinimizeAction class="display-none"></button>
    <button kendoWindowRestoreAction class="display-none"></button>
    <button kendoWindowCloseAction class="display-none"></button>
  </kendo-window-titlebar>
  <div class="d-flex gap-20 align-items-center">
    <div class="d-flex gap-10">
      <button kendoButton (click)="onStopTimerClick()" class="timer timer-stop">
        <i class="fas fa-stop"></i>
      </button>
      <button
        kendoButton
        *ngIf="!isTimerPaused"
        (click)="onPauseTimerClick()"
        class="timer timer-pause"
      >
        <i class="fas fa-pause"></i>
      </button>
      <button
        kendoButton
        *ngIf="isTimerPaused"
        (click)="onResumeTimerClick()"
        class="timer timer-play"
      >
        <i class="fas fa-play"></i>
      </button>
    </div>
    <div>
      <p class="project-name font-size-16 mb-0" title="{{ projectName }}">{{ projectName }}</p>
      <p class="project-desc text-primary font-size-14 mb-5 height-clamp" title="{{ taskName }}">
        {{ taskName }}
      </p>
      <p class="mb-10" title="{{ totalTimeTracked }}">
        <i class="far fa-clock mr-5"></i>{{ totalTimeTracked }}
      </p>
    </div>
  </div>
  <div class="close-timer" (click)="closeTimerPopup()">
    <i class="fas fa-xmark"></i>
  </div>
</kendo-window>
