<div class="q-content">
  <div class="q-content-bg calculate-height">
    <div class="q-content-header">
      <div class="project-heading" *ngIf="projectName !== ''">
        <kendo-tabstrip class="project-name-header-tab d-flex align-items-center">
          <kendo-tabstrip-tab title="Project" [disabled]="true">
            <ng-template kendoTabTitle>
              <p class="m-0 font-size-18 fw-500">
                <kendo-avatar
                  size="large"
                  [border]="true"
                  [initials]="commonService.getInitials(projectName)"
                  class="project-logo mr-5"
                ></kendo-avatar
                ><span class="display-inline clamp vertical-align-middle mr-1">{{
                  projectName
                }}</span>
              </p>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab title="List" [selected]="selectedTabId === tasklistTab.List">
            <ng-template kendoTabContent>
              <div class="d-flex justify-content-between flex-wrap gap-20 mb-24">
                <kendo-textbox
                  placeholder="Search for task list / task name"
                  [(ngModel)]="searchText"
                  [ngModelOptions]="{ standalone: true }"
                  (keyup.enter)="getTaskListList()"
                  (ngModelChange)="onSearchTextChange($event)"
                  class="search"
                >
                  <ng-template kendoTextBoxPrefixTemplate>
                    <i class="fas fa-search k-icon font-size-14"></i>
                  </ng-template>
                </kendo-textbox>
                <div class="d-flex gap-15 align-items-center">
                  <kendo-button kendoButton (click)="onAddTaskList()"
                    ><i class="far fa-plus pr-5"></i>Add Task List
                  </kendo-button>
                  <div style="display: none">
                    <kendo-dropdownlist [data]="statusList"></kendo-dropdownlist>
                    <a class="flat-icon-container"><i class="fa-light fa-bars-filter"></i></a>
                    <a class="flat-icon-container"><i class="fas fa-ellipsis-h"></i></a>
                  </div>
                </div>
              </div>
              <div
                class="grid-wrapper task-list-grid scrollable-list-container"
                *ngIf="taskListsList.length > 0"
                (scroll)="onScrollTaskListList($event)"
              >
                <kendo-expansionpanel
                  *ngFor="let item of taskListsList; index as i"
                  [expanded]="currentExpandedPanelId === item.id"
                  (action)="onTaskListsListExpandedPanelClick(i, item)"
                  [svgExpandIcon]="commonService.arrowDown"
                  [svgCollapseIcon]="commonService.arrowUp"
                  (expand)="adjustMaxWidth()"
                >
                  <ng-template kendoExpansionPanelTitleDirective>
                    <div
                      class="d-flex align-items-center justify-content-between expansion-panel-title-wrapper wp-100 flex-wrap"
                    >
                      <div class="d-flex flex-wrap align-items-center">
                        <span class="font-size-16 mr-1">{{ item.name }}</span>
                        <span class="blue-label"
                          >{{ item.completeCount }} Completed out of
                          {{ item.totalNumberOfTask }} Tasks</span
                        >

                        <kendo-dropdownbutton
                          [data]="taksListActionItem"
                          class="dropdown-button ml-10"
                          (itemClick)="onTaskListActionClick($event, item.id, item.name)"
                          (click)="stopPropagation($event)"
                          ><i class="fas fa-ellipsis-v"></i>
                        </kendo-dropdownbutton>

                        <span
                          class="add-task-icon"
                          title="Add task"
                          (click)="stopPropagation($event); onAddTaskClickOnTop(true)"
                        >
                          <i class="fa-solid fa-plus"></i>
                        </span>
                      </div>
                      <div class="d-flex gap-10">
                        <div class="blue-label gap-5">
                          <span>
                            <i class="fa-solid fa-hourglass mr-5"></i>
                            {{
                              commonService.getHoursAndMinutesFromTotalMinutes(
                                item.totalEstimatedTime
                              )
                            }}
                          </span>
                          <span>/</span>
                          <span>
                            <i class="fa-regular fa-clock mr-5"></i>
                            {{
                              commonService.getHoursAndMinutesFromTotalMinutes(item.totalTimelogged)
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <div [ngClass]="{ 'add-new-task-area': addNewTaskTop }">
                    <div *ngIf="addNewTaskTop" class="d-flex justify-content-between gap-10">
                      <form
                        class="k-form wp-100"
                        [formGroup]="addNewTaskformGroup"
                        validateOnSubmit
                      >
                        <fieldset class="k-form-fieldset">
                          <kendo-formfield class="mt-0 wp-100">
                            <kendo-textbox
                              formControlName="taskName"
                              class="add-task-area"
                              aria-placeholder="Give your project a name"
                              #taskName
                              (keydown.enter)="onSaveNewTask(item.id)"
                            ></kendo-textbox>
                            <kendo-formerror
                              *ngIf="addNewTaskformGroup.controls.taskName.errors?.maxlength"
                              >Error: Task Name cannot be more than 255 character!</kendo-formerror
                            >
                            <kendo-formerror
                              *ngIf="addNewTaskformGroup.controls.taskName.errors?.required"
                              >Error: Task Name is required!</kendo-formerror
                            >
                          </kendo-formfield>
                        </fieldset>
                      </form>
                      <ul class="d-flex gap-10 action-menu d-flex gap-5 mt-10">
                        <li (click)="onSaveNewTask(item.id)">
                          <a class="grid-command-action-links" title="Save">
                            <i class="far fa-floppy-disk"></i>
                          </a>
                        </li>
                        <li (click)="onAddTaskClickOnTop(false)">
                          <a class="grid-command-action-links" title="Cancel">
                            <i class="far fa-ban"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="grid-wrapper">
                    <div
                      #treeviewContainer
                      style="max-height: 300px; overflow-y: auto"
                      (scroll)="onScrollTaskList($event)"
                    >
                      <kendo-treeview
                        #treeview
                        [nodes]="taskList"
                        textField="name"
                        [children]="fetchSubtask"
                        [hasChildren]="hasSubtask"
                        kendoTreeViewExpandable
                        [pageSize]="pageSize"
                        totalField="totalCount"
                        [totalRootNodes]="totalTaskCount"
                        expandBy="id"
                        kendoTreeViewDragAndDrop
                        kendoTreeViewDragAndDropEditing
                        (nodeDrop)="onNodeDrop($event)"
                        (nodeDragStart)="onNodeDragStart($event)"
                        class="task-list-table"
                      >
                        <ng-template kendoTreeViewNodeTemplate let-dataItem>
                          <div class="task-list-grid">
                            <div #taskmain>
                              <div class="display-inline">
                                <input
                                  [disabled]="checkIsDisable(dataItem)"
                                  [checked]="dataItem.taskStatus === taskStatusType.Completed"
                                  type="checkbox"
                                  kendoCheckBox
                                  class="billable-checkbox"
                                  size="large"
                                  (change)="onTaskCompleteChange(dataItem)"
                                />

                                <kendo-dropdownbutton
                                  [iconClass]="getIconClass(dataItem.taskType)"
                                  [data]="taskTypeList"
                                  textField="name"
                                  (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                                  [popupSettings]="{ popupClass: popupClass }"
                                >
                                  <ng-template
                                    kendoDropDownButtonItemTemplate
                                    let-dataItemDropDownButton
                                  >
                                    <div
                                      class="dropdown-menu-links"
                                      [ngClass]="{
                                        taskTypeSelected:
                                          dataItem.taskType === dataItemDropDownButton.id,
                                      }"
                                    >
                                      <i
                                        class="{{
                                          dataItemDropDownButton.iconClass
                                        }} dropdown-item-color mr-5"
                                      ></i>
                                      {{ dataItemDropDownButton.name }}
                                    </div>
                                  </ng-template>
                                </kendo-dropdownbutton>

                                <div
                                  class="display-inline mr-10"
                                  *ngIf="dataItem.assignee && dataItem.assignee.length > 0"
                                >
                                  <ng-container *ngFor="let user of dataItem.assignee">
                                    <kendo-avatar
                                      rounded="full"
                                      size="large"
                                      [border]="true"
                                      [initials]="
                                        commonService.getInitialsAndAvtarColor(user).avatar
                                      "
                                      [style.backgroundColor]="
                                        commonService.getInitialsAndAvtarColor(user)
                                          .generateAvtarColor
                                      "
                                      class="social-profile-text assignee-container"
                                      [attr.title]="
                                        commonService.getInitialsAndAvtarColor(user).userName
                                      "
                                    ></kendo-avatar>
                                  </ng-container>
                                </div>
                              </div>
                              <a
                                (click)="onTaskNameClick(dataItem, item.id, $event)"
                                [routerLink]="['/task-Detail']"
                                [queryParams]="getQueryParams(dataItem, item.id)"
                                [target]="openInNewTab ? '_blank' : ''"
                                innerHTMl="{{ dataItem.taskName }}"
                                class="display-inline"
                                #taskTitle
                                ><span
                                  title="{{ dataItem.taskName }}"
                                  #taskTitle
                                  class="display-inline task-title clamp"
                                  >{{ dataItem.taskName }}</span
                                ></a
                              >
                              <div class="display-inline ml-10">
                                <div
                                  class="display-inline mr-10"
                                  [ngClass]="{ 'q-label': dataItem.startDate && dataItem.dueDate }"
                                >
                                  <ng-container *ngIf="dataItem.startDate">
                                    <span>{{ dataItem.startDate | date: 'dd-MMM-yyyy' }} - </span>
                                  </ng-container>
                                  <ng-container *ngIf="dataItem.dueDate">
                                    <span class="text-danger">
                                      {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}</span
                                    >
                                  </ng-container>
                                </div>

                                <div
                                  *ngIf="dataItem.priority"
                                  class="task-status-container mr-10 priority-{{
                                    priorityEnum[dataItem.priority]
                                  }}"
                                  title="{{ priorityEnum[dataItem.priority] }}"
                                >
                                  <span class="status-section">
                                    <i class="fa-solid fa-flag"></i>
                                  </span>
                                </div>

                                <div
                                  *ngIf="dataItem.estimateMinutes > 0"
                                  class="display-inline q-label mr-10"
                                >
                                  <span>
                                    Est:
                                    {{
                                      commonService.getHoursAndMinutesFromTotalMinutes(
                                        dataItem.estimateMinutes
                                      )
                                    }}
                                  </span>
                                </div>
                                <div
                                  class="display-inline q-label mr-10"
                                  *ngIf="dataItem.timelogMinutes > 0"
                                >
                                  <span>
                                    Log:
                                    {{
                                      commonService.getHoursAndMinutesFromTotalMinutes(
                                        dataItem.timelogMinutes
                                      )
                                    }}
                                  </span>
                                </div>
                                <div
                                  class="display-inline q-label mr-10"
                                  *ngIf="dataItem.attachmentCount > 0"
                                >
                                  <span>
                                    <i class="fa-solid fa-paperclip"></i>
                                    {{ dataItem.attachmentCount }}
                                  </span>
                                </div>
                                <div class="display-inline q-label mr-10">
                                  <span>
                                    {{ dataItem.taskStatusText }}
                                  </span>
                                </div>
                              </div>
                              <div class="task-list-action display-inline ml-10">
                                <ul class="d-flex justify-content-end gap-5">
                                  <li (click)="onAddSubtaskClick(dataItem)">
                                    <a class="grid-command-action-links" title="Add SubTask">
                                      <i class="far fa-plus-square"></i
                                    ></a>
                                  </li>
                                  <li (click)="onEditClick(dataItem)">
                                    <a class="grid-command-action-links" title="Edit">
                                      <i class="far fa-pencil"></i
                                    ></a>
                                  </li>
                                  <li (click)="onDeleteClick(dataItem, false)">
                                    <a class="grid-command-action-links" title="Delete">
                                      <i class="far fa-trash-can"></i
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div
                              *ngIf="dataItem.isAddSubtask"
                              class="d-flex justify-content-between gap-10"
                            >
                              <div class="wp-100">
                                <span>{{ dataItem.name }}</span>
                                <kendo-textbox
                                  #SubTaskEditor
                                  class="add-task-area"
                                  (input)="onInputChange(SubTaskEditor.value, dataItem)"
                                  (keydown.enter)="onSaveSubTask(SubTaskEditor, dataItem, item.id)"
                                ></kendo-textbox>

                                <kendo-formerror *ngIf="subTaskErrors[dataItem.id]">
                                  Error: Subtask name is required.
                                </kendo-formerror>
                              </div>
                              <ul class="d-flex gap-10" class="action-menu d-flex gap-5 mt-10">
                                <li (click)="onSaveSubTask(SubTaskEditor, dataItem, item.id)">
                                  <a class="grid-command-action-links" title="save"
                                    ><i class="far fa-floppy-disk"></i
                                  ></a>
                                </li>
                                <li (click)="onCancelSubtask(dataItem)">
                                  <a class="grid-command-action-links" title="cancel"
                                    ><i class="far fa-ban"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </ng-template>
                      </kendo-treeview>
                    </div>
                  </div>
                  <div
                    *ngIf="taskList.length >= this.totalTaskCount"
                    [ngClass]="{ 'add-new-task-area': isAddNew }"
                  >
                    <div *ngIf="isAddNew" class="d-flex justify-content-between gap-10">
                      <form
                        class="k-form wp-100"
                        [formGroup]="addNewTaskformGroup"
                        validateOnSubmit
                      >
                        <fieldset class="k-form-fieldset">
                          <kendo-formfield class="mt-0 wp-100">
                            <kendo-textbox
                              formControlName="taskName"
                              class="add-task-area"
                              aria-placeholder="Give your project a name"
                              #taskName
                              (keydown.enter)="onSaveNewTask(item.id)"
                            ></kendo-textbox>
                            <kendo-formerror
                              *ngIf="addNewTaskformGroup.controls.taskName.errors?.maxlength"
                              >Error: Task Name cannot be more than 255 character!</kendo-formerror
                            >
                            <kendo-formerror
                              *ngIf="addNewTaskformGroup.controls.taskName.errors?.required"
                              >Error: Task Name is required!</kendo-formerror
                            >
                          </kendo-formfield>
                        </fieldset>
                      </form>
                      <ul class="d-flex gap-10 action-menu d-flex gap-5 mt-10">
                        <li (click)="onSaveNewTask(item.id)">
                          <a class="grid-command-action-links" title="Save">
                            <i class="far fa-floppy-disk"></i>
                          </a>
                        </li>
                        <li (click)="onAddTaskClick(false)">
                          <a class="grid-command-action-links" title="Cancel">
                            <i class="far fa-ban"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button (click)="onAddTaskClick(true)" class="add-new-btn">
                      <i class="far fa-plus mr-5"></i> Add new
                    </button>
                  </div>
                  <div *ngIf="totalCompletedTaskCount > 0" class="border-top pt-1 pb-1">
                    <a href="javascript:void(0)" (click)="showCompletedTask()" class="text-primary">
                      <span *ngIf="!isShowCompleteTask" class="font-size-14">
                        <i class="far fa-chevron-down"></i> Show Completed Task</span
                      >
                      <span *ngIf="isShowCompleteTask" class="font-size-14"
                        ><i class="far fa-chevron-up"></i> Hide Completed Task</span
                      >
                    </a>

                    <div *ngIf="isShowCompleteTask">
                      <div
                        style="height: 500px; overflow-y: auto"
                        (scroll)="onScrollCpmpleteTaskList($event)"
                      >
                        <kendo-treeview
                          #treeview1
                          [totalField]="'totalCount'"
                          [nodes]="completedTaskList"
                          textField="name"
                          childrenField="children"
                          kendoTreeViewExpandable
                          expandBy="id"
                          class="task-list-table"
                          [totalRootNodes]="totalCompletedTaskCount"
                          [pageSize]="pageSize"
                        >
                          <ng-template kendoTreeViewNodeTemplate let-dataItem>
                            <div>
                              <div class="d-flex align-items-center complete-action-menu" #taskmain>
                                <div class="display-inline">
                                  <input
                                    [disabled]="checkIsDisable(dataItem)"
                                    [checked]="dataItem.taskStatus === taskStatusType.Completed"
                                    type="checkbox"
                                    kendoCheckBox
                                    class="billable-checkbox"
                                    size="large"
                                    (change)="onTaskCompleteChange(dataItem)"
                                  />

                                  <kendo-dropdownbutton
                                    [iconClass]="getIconClass(dataItem.taskType)"
                                    [data]="taskTypeList"
                                    textField="name"
                                    (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                                    [popupSettings]="{ popupClass: popupClass }"
                                  >
                                    <ng-template
                                      kendoDropDownButtonItemTemplate
                                      let-dataItemDropDownButton
                                    >
                                      <div
                                        class="dropdown-menu-links"
                                        [ngClass]="{
                                          taskTypeSelected:
                                            dataItem.taskType === dataItemDropDownButton.id,
                                        }"
                                      >
                                        <i
                                          class="{{
                                            dataItemDropDownButton.iconClass
                                          }} dropdown-item-color mr-5"
                                        ></i>
                                        {{ dataItemDropDownButton.name }}
                                      </div>
                                    </ng-template>
                                  </kendo-dropdownbutton>

                                  <div
                                    class="display-inline mr-10"
                                    *ngIf="dataItem.assignee && dataItem.assignee.length > 0"
                                  >
                                    <ng-container *ngFor="let user of dataItem.assignee">
                                      <kendo-avatar
                                        rounded="full"
                                        size="large"
                                        [border]="true"
                                        [initials]="
                                          commonService.getInitialsAndAvtarColor(user).avatar
                                        "
                                        [style.backgroundColor]="
                                          commonService.getInitialsAndAvtarColor(user)
                                            .generateAvtarColor
                                        "
                                        class="social-profile-text assignee-container"
                                        [attr.title]="
                                          commonService.getInitialsAndAvtarColor(user).userName
                                        "
                                      ></kendo-avatar>
                                    </ng-container>
                                  </div>
                                </div>

                                <a
                                  (click)="onTaskNameClick(dataItem, item.id, $event)"
                                  [routerLink]="['/task-Detail']"
                                  [queryParams]="getQueryParams(dataItem, item.id)"
                                  [target]="openInNewTab ? '_blank' : ''"
                                  innerHTMl="{{ dataItem.taskName }}"
                                  class="display-inline"
                                  #taskTitle
                                  ><span
                                    class="display-inline task-title clamp"
                                    title="{{ dataItem.taskName }}"
                                    >{{ dataItem.taskName }}</span
                                  ></a
                                >
                                <div class="display-inline ml-10">
                                  <div
                                    class="display-inline mr-10"
                                    [ngClass]="{
                                      'q-label': dataItem.startDate && dataItem.dueDate,
                                    }"
                                  >
                                    <ng-container *ngIf="dataItem.startDate">
                                      <span>{{ dataItem.startDate | date: 'dd-MMM-yyyy' }} - </span>
                                    </ng-container>
                                    <ng-container *ngIf="dataItem.dueDate">
                                      <span class="text-danger">
                                        {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}</span
                                      >
                                    </ng-container>
                                  </div>

                                  <div
                                    *ngIf="dataItem.priority"
                                    class="task-status-container mr-10 priority-{{
                                      priorityEnum[dataItem.priority]
                                    }}"
                                    title="{{ priorityEnum[dataItem.priority] }}"
                                  >
                                    <span class="status-section">
                                      <i class="fa-solid fa-flag"></i>
                                    </span>
                                  </div>

                                  <div
                                    *ngIf="dataItem.estimateMinutes > 0"
                                    class="display-inline q-label mr-10"
                                  >
                                    <span>
                                      Est:
                                      {{
                                        commonService.getHoursAndMinutesFromTotalMinutes(
                                          dataItem.estimateMinutes
                                        )
                                      }}
                                    </span>
                                  </div>

                                  <div
                                    class="display-inline q-label mr-10"
                                    *ngIf="dataItem.timelogMinutes > 0"
                                  >
                                    <span>
                                      Log:
                                      {{
                                        commonService.getHoursAndMinutesFromTotalMinutes(
                                          dataItem.timelogMinutes
                                        )
                                      }}
                                    </span>
                                  </div>

                                  <div
                                    class="display-inline q-label mr-10"
                                    *ngIf="dataItem.attachmentCount > 0"
                                  >
                                    <span>
                                      <i class="fa-solid fa-paperclip"></i>
                                      {{ dataItem.attachmentCount }}
                                    </span>
                                  </div>

                                  <div class="display-inline q-label mr-10">
                                    <span>
                                      {{ dataItem.taskStatusText }}
                                    </span>
                                  </div>
                                </div>

                                <div class="action-menu ml-10">
                                  <ul class="d-flex justify-content-end gap-5">
                                    <li
                                      (click)="onEditClick(dataItem)"
                                      *ngIf="!isNew && dataItem.id !== 0"
                                    >
                                      <a class="grid-command-action-links" title="Edit">
                                        <i class="far fa-pencil"></i
                                      ></a>
                                    </li>

                                    <li (click)="onDeleteClick(dataItem, true)">
                                      <a class="grid-command-action-links" title="Delete">
                                        <i class="far fa-trash-alt"></i
                                      ></a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </kendo-treeview>
                      </div>
                    </div>
                  </div>
                </kendo-expansionpanel>
              </div>
              <div
                *ngIf="taskListsList.length === 0 && isNoRecordFound"
                class="no-taskList d-flex align-items-center justify-content-center flex-column"
              >
                <img src="assets/images/no-tasklist.png" alt="no-taskList" />
                <p>No task-list found</p>
                <p class="desc">It seems there are no task-lists found for this project.</p>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab
            title="Dashboard"
            [selected]="selectedTabId === tasklistTab.Dashboard"
          >
            <ng-template kendoTabContent>
              <app-project-dashboard></app-project-dashboard>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Time" [selected]="selectedTabId === tasklistTab.Time">
            <ng-template kendoTabContent>
              <app-time [isFromTaskList]="true"></app-time>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
      <div kendoDialogContainer></div>
    </div>

    <kendo-drawer-container class="add-more-details">
      <kendo-drawer
        [mode]="expandMode"
        [expanded]="isDrawerOpened"
        [position]="position"
        [autoCollapse]="false"
      >
        <ng-template kendoDrawerTemplate>
          <app-add-task-more-details
            [taskId]="taskId"
            [taskDetails]="taskDetails"
            [projectId]="projectId"
            [sortingName]="sorting"
            (reloadTaskList)="onTaskDrawerClose($event)"
          ></app-add-task-more-details>
        </ng-template>
      </kendo-drawer>
    </kendo-drawer-container>
  </div>
</div>
