import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid';
import { DrawerMode, DrawerPosition } from '@progress/kendo-angular-layout';
import { process } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  TimeEntryDataDto,
  TimelogGetListInput,
} from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { CommonService } from 'src/core/services';
import { TimeLogPopupComponent } from '../../time-log-popup/time-log-popup.component';
import { BillableEnum, ExportFormat } from 'projects/task-service/src/lib/proxy/service/enum';
import { ConfigStateService, PagedResultDto } from '@abp/ng.core';
import { NotificationMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { saveAs } from 'file-saver';
import { ProjectUserService } from '@proxy/project-service/project';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { IdentityUserDto, IdentityUserService } from '@abp/ng.identity/proxy';
import { TaskListTabEnum } from 'src/app/enum/tasklist-tab-enum';
import { GetProjectListInputDto } from 'projects/project-service/src/lib/proxy/project-service/projects/dtos';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent implements OnInit {
  gridView: GridDataResult;
  summary: TimeEntryDataDto;

  @Input() isFromTaskList: boolean = false;
  dateError = false;
  isNoRecords = false;
  isDrawerOpened = false;
  openInNewTab = false;
  isAdmin: boolean = false;
  info = true;
  pageSizes = true;
  previousNext = true;
  isBillableColumnVisible = true;
  isFilteredDataAvailable = false;

  gridHeight: number;
  projectId: any;
  projectSummary: any;

  type: PagerType = 'numeric';
  pagerposition: PagerPosition = 'bottom';
  expandMode: DrawerMode = 'overlay';
  position: DrawerPosition = 'end';

  selectedFilterValue = 0;
  selectedBillableFilterValue = 0;
  pageSize = 10;
  skip = 0;
  filterCount: number = 0;
  buttonCount = 5;

  startDate = new Date();
  endDate = new Date();

  userList: any[] = [];
  selectedUserIds: string[] = [];
  selectedUsers: any[] = [];
  projectList: any[] = [];
  selectedProjectIds: string[] = [];
  selectedProject: any[] = [];

  peopleList = { items: [], totalCount: 0 } as PagedResultDto<IdentityUserDto>;

  exportActionList = [
    { id: 1, iconClass: 'fas fa-file-pdf', text: 'To PDF', actionTypeId: ExportFormat.PDF },
    {
      id: 2,
      iconClass: 'fas fa-file-excel',
      text: 'To Excel',
      actionTypeId: ExportFormat.Excel,
    },
  ];

  dateFilter: Array<{ text: string; value: number }> = [
    { text: 'Anytime', value: 0 },
    { text: 'Custom date range', value: 1 },
  ];

  billableFilterList: Array<{ text: string; value: number }> = [
    { text: 'Billable and non billable', value: BillableEnum.All },
    { text: 'Billable only', value: BillableEnum.Billable },
    { text: 'Non billable only', value: BillableEnum.NonBillable },
  ];

  constructor(
    private timelogService: TimelogService,
    private spinnerService: NgxSpinnerService,
    private _Activatedroute: ActivatedRoute,
    private datePipe: DatePipe,
    public commonService: CommonService,
    private dialogService: DialogService,
    private router: Router,
    private toasterService: ToasterService,
    public projectUser: ProjectUserService,
    private projectService: ProjectService,
    private config: ConfigStateService,
    protected service: IdentityUserService,
  ) {
    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    this.isAdmin = currentUserRole.includes('admin');
  }

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe(params => {
      if (params) {
        this.projectId = params['projectId'] ? +atob(params['projectId']) : undefined;
      }
      this.clearFilter();
      this.getProjectList();
      if (this.projectId) this.getProjectSummary();
      if (this.isAdmin) this.getPeopleList();
    });
  }

  onSubmit(): void {
    if (!this.dateError) this.getData();
  }

  onActionItemClicked(event: any): void {
    this.exportTimesheet(event.actionTypeId);
  }

  getData(): void {
    this.getTimelogList();
    this.updateFilterCount();
  }

  getProjectList(): void {
    const data: GetProjectListInputDto = {
      includeProjectUserInfo: true,
      include: [],
      fields: [],
      orderByCustomFieldId: 0,
      onlyStarredProjects: true,
      hideObservedProjects: true,
      includeCounts: true,
      projectStatuses: true,
      iIncludeCustomFields: true,
      searchByLetter: true,
      skipCount: this.skip,
      maxResultCount: 1000,
      projectIds: [],
    };

    this.projectService.getList(data).subscribe(
      response => {
        this.spinnerService.hide();
        if (response.items.length > 0) {
          this.projectList = response.items;
        }
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  getProjectSummary(): void {
    const params: any = {
      projectIds: [this.projectId],
    };
    this.projectService.getTotal(params).subscribe(
      res => {
        this.projectSummary = res.items?.[0];
      },
      err => {
        console.error(err);
      },
    );
  }

  billableFilterselectionChange(event): void {
    this.selectedBillableFilterValue = event.value;
  }

  onProjectSelectionChange(selectedProject: any[]): void {
    this.selectedProjectIds = selectedProject.map(project => project.id);
  }

  onUserSelectionChange(selectedUsers: any[]): void {
    this.selectedUserIds = selectedUsers.map(user => user.id);
  }

  getPeopleList(): void {
    const param = {
      skipCount: 0,
      maxResultCount: 500,
    };

    this.service.getList(param).subscribe(response => {
      if (response.items.length > 0) {
        this.userList = response.items.map(user => {
          const fullName =
            user.name?.trim() && user.surname?.trim()
              ? `${user.name} ${user.surname}`
              : user.userName;

          return {
            ...user,
            userName: fullName,
          };
        });
      } else {
        this.userList = [];
      }
    });
  }

  exportTimesheet(format: ExportFormat): void {
    this.spinnerService.show();
    const params: TimelogGetListInput = {
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      sorting: 'TimelogDatetime desc',
      startDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.startDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      endDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.endDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      format: format,
      isPagination: false,
      userIds: this.selectedUserIds,
      billable: this.selectedBillableFilterValue,
      projectIds: this.projectId ? [this.projectId] : this.selectedProjectIds,
    };

    this.commonService.exportTimesheet(params).subscribe(
      response => {
        saveAs(response.blob, response.filename);
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.exportSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      },
      error => {
        console.error('Download failed:', error);
        this.spinnerService.hide();
      },
    );
  }

  onEdit(data) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
    });
    document.body.style.overflow = 'hidden';

    dialogRef.content.instance.timeLogId = data.id;
    dialogRef.content.instance.taskName = data.taskName;
    dialogRef.content.instance.taskId = data.taskId;
    dialogRef.content.instance.projectId = this.projectId ?? data.projectId;
    dialogRef.content.instance.isProjectBillable = !this.isBillableColumnVisible;
    dialogRef.content.instance.userId = data.userId;

    dialogRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.getTimelogList();
        }
        document.body.style.overflow = 'scroll';
      }
    });
  }

  calculateEndTime(timelogDatetime: string, timelogMinutes: number): Date {
    const startTime = new Date(timelogDatetime);
    startTime.setMinutes(startTime.getMinutes() + timelogMinutes);
    return startTime;
  }

  updateStartDate(event): void {
    this.startDate = event;
    this.validateDates();
  }

  updateEndDate(event): void {
    this.endDate = event;
    this.validateDates();
  }

  validateDates() {
    if (this.startDate && this.endDate) {
      this.dateError = this.endDate < this.startDate;
    } else {
      this.dateError = false;
    }
  }

  closeDrawer() {
    this.isDrawerOpened = !this.isDrawerOpened;
    document.body.style.overflow = 'scroll';
  }

  selectionChange(event): void {
    this.dateError = false;
    this.selectedFilterValue = event.value;
    if (this.selectedFilterValue == 0) {
      this.startDate = undefined;
      this.endDate = undefined;
    } else {
      this.startDate = new Date();
      this.endDate = new Date();
    }
  }

  openFilter(): void {
    this.isDrawerOpened = !this.isDrawerOpened;
    this.projectId = this.projectId;
    document.body.style.overflow = 'hidden';
  }

  pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.getTimelogList();
  }

  clearFilter(isDrawerClose: boolean = false): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.selectedFilterValue = 0;
    this.selectedBillableFilterValue = 0;
    this.selectedUsers = [];
    this.selectedUserIds = [];
    this.selectedProject = [];
    this.selectedProjectIds = [];
    this.startDate = new Date();
    this.endDate = new Date();
    this.gridView = {
      data: [],
      total: 0,
    };
    this.updateFilterCount();
    if (isDrawerClose) this.closeDrawer();
  }

  getTimelogList(): void {
    this.spinnerService.show();
    const params: TimelogGetListInput = {
      skipCount: this.isDrawerOpened ? 0 : this.skip,
      maxResultCount: this.pageSize,
      sorting: 'TimelogDatetime desc',
      startDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.startDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      endDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.endDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      format: ExportFormat.Excel,
      isPagination: true,
      userIds: this.selectedUserIds,
      billable: this.selectedBillableFilterValue,
      projectIds: this.projectId ? [this.projectId] : this.selectedProjectIds,
    };
    this.timelogService.getTimeEntryList(params).subscribe(
      response => {
        this.spinnerService.hide();
        this.summary = response;
        const processedItems = response.timeEntry.map(item => {
          const fullName =
            item.timelogUserFirstName?.trim() && item.timelogUserLastName?.trim()
              ? `${item.timelogUserFirstName} ${item.timelogUserLastName}`
              : item.timelogUserName;

          return {
            ...item,
            timelogUserName: fullName,
            Date: this.datePipe.transform(
              new Date(item.timelogDatetime).toISOString().slice(0, 10),
              'EEEE dd, MMMM',
            ),
          };
        });

        const groupedData = process(processedItems, {
          group: [
            {
              field: 'Date',
              aggregates: [
                { field: 'timelogMinutes', aggregate: 'sum' },
                { field: 'timelogBillableMinutes', aggregate: 'sum' },
                { field: 'hoursDecimal', aggregate: 'sum' },
              ],
            },
          ],
        });

        groupedData.data = groupedData.data.sort(
          (a, b) => new Date(b.value).getTime() - new Date(a.value).getTime(),
        );

        this.gridView = {
          data: groupedData.data,
          total: response.totalCount,
        };

        this.isNoRecords = response.totalCount > 0 ? false : true;
        this.isBillableColumnVisible = processedItems.every(item => !item.isBillable);
      },
      error => {
        this.spinnerService.hide();
      },
    );
  }

  updateFilterCount(): void {
    let count = 0;
    if (this.selectedFilterValue == 1) count++;
    if (this.selectedUserIds.length) count++;
    if (this.selectedBillableFilterValue > 0) count++;
    if (this.selectedProjectIds.length) count++;
    this.filterCount = count;
  }

  getQueryParams(data: any) {
    return {
      taskId: btoa(data.taskId),
      projectId: btoa(this.projectId ?? data.projectId),
      projectName: btoa(data.projectName),
      taskListId: btoa(data.tasklistId),
    };
  }

  onGoToTaskDetailsClick(data, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    if (this.openInNewTab) return;

    event.preventDefault();

    if (!this.openInNewTab) {
      const params: { [key: string]: string } = {
        taskId: btoa(data.taskId),
        projectId: btoa(this.projectId ?? data.projectId),
        projectName: btoa(data.projectName),
        taskListId: btoa(data.tasklistId),
      };

      if (this.isFromTaskList) {
        params.tabId = btoa(TaskListTabEnum.Time.toString());
      }

      this.router.navigate(['task-Detail'], { queryParams: params });
    }
  }
}
