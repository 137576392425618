import { mapEnumToOptions } from '@abp/ng.core';

export enum TaskStatusType {
  NewTaskDefaults = 0,
  Deleted = 1,
  Completed = 2,
  ReOpened = 3,
  New = 4,
  InProgress = 5,
  QAAssigned = 6,
  QAProgress = 7,
  ClientAssign = 8,
  ProductionReady = 9,
  Closed = 10,
}

export const taskStatusTypeOptions = mapEnumToOptions(TaskStatusType);
