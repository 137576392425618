<kendo-dialog-titlebar (close)="onCloseDialog()">
  <div>Attach Files to this task</div>
</kendo-dialog-titlebar>
<div>
  <div class="primary-card mb-20">
    <p class="mb-0">
      Task: <span class="text-primary">{{ taskName }}</span>
    </p>
  </div>
  <form class="k-form k-form-md mb-15">
    <fieldset class="k-form-fieldset">
      <kendo-fileselect #myFileSelect="kendoFileSelect" (select)="onFileSelect($event)">
        <ng-template kendoFileSelectFileTemplate let-files let-i="index" let-state="state">
          <div class="d-flex gap-5 wp-100">
            <div *ngIf="files" class="file-image-container">
              <div
                class="file-icon-image-type"
                *ngIf="commonService.isFileTypeImage(files[0].name); else showInitialLogo"
              >
                <img [src]="getImageSrc(files[0].uid)" alt="Image Preview" height="25" width="50" />
              </div>
              <ng-template #showInitialLogo>
                <div class="file-icon-attachment">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(files[0].name)"
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar>
                </div>
              </ng-template>
            </div>
            <div class="wp-100 d-flex align-items-center">
              {{ files[0].name }}
            </div>
          </div>
          <div class="delete-container">
            <a *ngIf="fileShowButton(state)" (click)="fileRemove(myFileSelect, files[0].uid)">
              <i class="far fa-trash-alt"></i>
            </a>
          </div>
        </ng-template>
      </kendo-fileselect>
    </fieldset>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">Close</button>
  <div [ngClass]="{ 'curser-not-allowd': this.selectedFiles.length === 0 }" class="flex-0">
    <button
      kendoButton
      [disabled]="this.selectedFiles.length === 0 || isAttachButtonDisabled"
      (click)="onSave()"
    >
      Attach Files
    </button>
  </div>
</kendo-dialog-actions>
