<div class="q-content">
  <div class="q-content-bg">
    <div>
      <ul class="nav flex-column nav-pills mb-20" id="nav-tab" role="tablist">
        <ng-container *abpFor="let setting of settings; trackBy: trackByFn">
          <li
            (click)="selected = setting"
            class="nav-item pointer"
            *abpPermission="setting.requiredPolicy"
          >
            <a
              class="nav-link"
              [id]="setting.name + '-tab'"
              role="tab"
              [class.active]="setting.name === selected.name"
              >{{ setting.name | abpLocalization }}</a
            >
          </li>
        </ng-container>
      </ul>
      <div>
        <div *ngIf="settings.length" class="tab-content">
          <div class="tab-pane fade show active" [id]="selected.name + '-tab'" role="tabpanel">
            <div
              *ngIf="'AbpSettingManagement::Menu:Emailing' === selected.name; else featureComponent"
            >
              <app-custom-email></app-custom-email>
            </div>
            <ng-template #featureComponent>
              <p class="text-wrap">
                {{ 'AbpFeatureManagement::ManageHostFeaturesText' | abpLocalization }}
              </p>

              <button class="btn btn-primary" type="button" (click)="openFeaturesModal()">
                <i class="fa fa-cog" aria-hidden="true"></i>
                {{ 'AbpFeatureManagement::ManageHostFeatures' | abpLocalization }}
              </button>
              <kendo-dialog
                *ngIf="visibleFeatures"
                (close)="closeFeature('cancel')"
                [minWidth]="250"
              >
                <app-custom-setting-features
                  *abpReplaceableTemplate="{
                    inputs: {
                      providerName: { value: 'T' },
                      providerKey: { value: providerKey },
                      visible: { value: visibleFeatures, twoWay: true },
                    },
                    outputs: { visibleChange: onVisibleFeaturesChange },
                    componentKey: 'FeatureManagement.FeatureManagementComponent',
                  }"
                  [(visible)]="visibleFeatures"
                  providerName="T"
                  [providerKey]="providerKey"
                >
                </app-custom-setting-features>
              </kendo-dialog>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
