import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateFileBaseDto, FileBaseDto, FileInputDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class FileBaseService {
  apiName = 'FileService';

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/file/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  download = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/file/${id}/download`,
      },
      { apiName: this.apiName, ...config },
    );

  getAccessUrl = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        responseType: 'text',
        url: `/api/file/${id}/accessurl`,
      },
      { apiName: this.apiName, ...config },
    );

  getByFileIds = (fileInput: FileInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileBaseDto[]>(
      {
        method: 'POST',
        url: '/api/file/filelistbyfileids',
        body: fileInput,
      },
      { apiName: this.apiName, ...config },
    );

  save = (input: CreateUpdateFileBaseDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileBaseDto>(
      {
        method: 'POST',
        url: '/api/file',
        body: input.formFileStreamContent,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
