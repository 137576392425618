export enum CommentObjectType {
  Task = 1,
  File = 2,
  Notebook = 3,
  Milestone = 4,
  Link = 5,
  Event = 6,
  Status = 7,
  ProjectUpdate = 8,
}
