import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FileSelectComponent, FileState } from '@progress/kendo-angular-upload';
import { FileBaseService } from 'projects/file-service/src/lib/proxy/file-service/files';
import { FileService } from 'projects/task-service/src/lib/proxy/service/task';
import { NotificationMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-attach-task-file-popup',
  templateUrl: './attach-task-file-popup.component.html',
  styleUrls: ['./attach-task-file-popup.component.scss'],
})
export class AttachTaskFilePopupComponent {
  @Input() taskId: any;

  selectedFiles: any[] = [];
  imagePreviews: any[] = [];
  taskName: any;

  constructor(
    public dialogRef: DialogRef,
    private fileService: FileService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    public fileBaseService: FileBaseService,
  ) {}

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }

  onFileSelect(event: any): void {
    this.selectedFiles.push(event);

    event.files.forEach(file => {
      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = (ev: any) => {
          const image: any = {
            src: ev.target.result,
            uid: file.uid,
          };

          this.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });
  }

  getImageSrc(uid: any) {
    return this.imagePreviews.filter(x => x.uid == uid)[0]?.src;
  }

  onSave(): void {
    for (let i = 0; i < this.selectedFiles[0].files.length; i++) {
      const myFormData = new FormData();
      const file: File = this.selectedFiles[0].files[i].rawFile;

      myFormData.append('taskId', this.taskId);
      myFormData.append('fileStreamContent', file);

      const param: any = {
        fileStreamContent: myFormData,
      };

      this.fileService.save(param).subscribe(res => {
        if (res) {
          this.toasterService.success(
            NotificationMessage.saveFileSuccessMsg,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        }
        this.commonService.onDialogClose(this.dialogRef, true);
      });
    }
  }

  fileShowButton(state: FileState): boolean {
    return state === FileState.Selected ? true : false;
  }

  fileRemove(fileSelect: FileSelectComponent, uid: string): void {
    fileSelect.removeFileByUid(uid);

    const fileIndex = this.selectedFiles[0].files.findIndex(file => file.uid === uid);
    if (fileIndex > -1) {
      this.selectedFiles[0].files.splice(fileIndex, 1);
    }
  }

  getFileObjectURL(file: File): string {
    return URL.createObjectURL(file[0].rawFile);
  }
}
