<div *ngIf="this.visible">
  <kendo-dialog-titlebar class="mx-0 pt-0 pb-10" (close)="this.visible = false">
    <div>
      {{ 'AbpFeatureManagement::Features' | abpLocalization }}
    </div>
  </kendo-dialog-titlebar>
  <div>
    <kendo-tabstrip>
      <kendo-tabstrip-tab
        title="{{ selectedGroupDisplayName }}"
        [selected]="selectedGroupDisplayName"
      >
        <ng-template kendoTabContent>
          <!-- <h4>{{ selectedGroupDisplayName }}</h4> -->
          <div *ngFor="let group of groups">
            <div
              *ngFor="let feature of features[group.name]; let i = index; trackBy: track.by('id')"
              [ngStyle]="feature.style"
              [ngSwitch]="feature.valueType?.name"
              (keyup.enter)="save()"
            >
              <ng-container *ngSwitchCase="valueTypes.ToggleStringValueType">
                <div class="mb-20">
                  <kendo-label
                    class="k-checkbox-label mr-10"
                    [for]="feature.name"
                    [text]="feature.displayName"
                  ></kendo-label>
                  <ng-container
                    *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                  ></ng-container>
                  <input
                    type="checkbox"
                    [id]="feature.name"
                    [checked]="feature.value"
                    kendoCheckBox
                    (ngModelChange)="onCheckboxClick($event, feature)"
                  />
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="valueTypes.FreeTextStringValueType">
                <div class="mb-20">
                  <kendo-label
                    [htmlFor]="feature.name"
                    labelCssClass="form-label mr-10"
                    text="{{ feature.displayName }}"
                  ></kendo-label>
                  <kendo-textbox
                    type="text"
                    [id]="feature.name"
                    [(ngModel)]="feature.value"
                    [abpFeatureManagementFreeText]="feature"
                  ></kendo-textbox>

                  <ng-container
                    *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                  ></ng-container>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="valueTypes.SelectionStringValueType">
                <ng-container *ngIf="feature.valueType.itemSource?.items?.length">
                  <div>
                    <kendo-label
                      [htmlFor]="feature.name"
                      labelCssClass="form-label"
                      text="{{ feature.displayName }}"
                    ></kendo-label>
                    <select class="form-select" [id]="feature.name" [(ngModel)]="feature.value">
                      <option
                        *ngFor="
                          let item of feature.valueType.itemSource?.items;
                          trackBy: track.by('value')
                        "
                        [ngValue]="item.value"
                      >
                        {{
                          item.displayText?.resourceName + '::' + item.displayText?.name
                            | abpLocalization
                        }}
                      </option>
                    </select>
                    <ng-container
                      *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                    ></ng-container>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>{{ feature.displayName }}</ng-container>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>

  <kendo-dialog-actions class="mx-0 pb-10">
    <button *ngIf="groups.length" kendoButton (click)="resetToDefault()" [disabled]="modalBusy">
      {{ 'AbpFeatureManagement::ResetToDefault' | abpLocalization }}
    </button>
    <button kendoButton (click)="this.visible = false" fillMode="outline">
      {{ 'AbpFeatureManagement::Cancel' | abpLocalization }}
    </button>
    <button kendoButton (click)="save()" *ngIf="groups.length">
      {{ 'AbpFeatureManagement::Save' | abpLocalization }}
    </button>
  </kendo-dialog-actions>
</div>
