import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  DrawerMode,
  DrawerPosition,
  ExpansionPanelComponent,
} from '@progress/kendo-angular-layout';
import { TaskListService } from 'projects/task-service/src/lib/proxy/task-service/task-list';
import { TaskType } from 'src/app/enum/task-type';
import { AddTaskListComponent } from './add-task-list/add-task-list.component';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskListActionEnumEnum } from 'src/app/enum/tasklist-action-filter';
import { Observable, combineLatest, map, of, take } from 'rxjs';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NotificationTextMessage } from 'src/app/enum/notification';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { ToasterService } from '@abp/ng.theme.shared';
import { IdentityUserService } from '@abp/ng.identity/proxy';
import { AddTaskComponent } from './add-task/add-task.component';
import { ConfigStateService } from '@abp/ng.core';
import { LoadMoreRequestArgs, TreeViewComponent } from '@progress/kendo-angular-treeview';
import { UpdateTaskParentTaskIdDto } from 'projects/task-service/src/lib/proxy/service/project/task/dtos';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskListTabEnum } from 'src/app/enum/tasklist-tab-enum';
import { Store } from '@ngxs/store';
import { TaskState } from 'src/app/core/store/task.state';
import { tap } from 'rxjs/operators';

export class TaskDateDto {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  taskListsList: any[] = [];
  completedTaskList: any = [];
  taskList = [];

  statusList: Array<string> = ['All status', 'abc'];
  priorityEnum = Priority;
  taskStatusType = TaskStatusType;

  editedItem: any;
  projectId: any;
  taskId: any;
  taskDetails: any;
  currentExpandedPanelId: any;

  projectName = '';
  searchText: string = '';

  pageSize = 10;
  maxResultCountForTaskListList: number = 10;

  skipcount = 0;
  skipCountForTaskListList: number = 0;
  skipCountForCompletedTask = 0;
  skipCountForSubTaskList = 0;

  popupClass = 'task-type-dropdown';
  expandMode: DrawerMode = 'overlay';
  position: DrawerPosition = 'end';

  isDrawerOpened = false;
  isNoRecordFound = false;
  isAdmin = false;
  isShowCompleteTask = false;
  isAddNew = false;
  openInNewTab = false;
  addNewTaskTop = false;
  isLoadingForTaskListList = true;
  isLoadingForTaskList = false;
  isLoadingForCompletedTaskList = false;

  subTaskErrors: { [key: string]: boolean } = {};

  taskListIdFromUrl: number = null;
  sorting = null;

  sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  @ViewChildren(ExpansionPanelComponent) panels: QueryList<ExpansionPanelComponent>;
  @ViewChildren('taskmain') taskmains: QueryList<ElementRef>;
  @ViewChildren('taskTitle') taskTitles: QueryList<ElementRef>;

  @ViewChild('treeview', { static: false })
  public treeview: TreeViewComponent;

  priorityList: any[] = [
    {
      id: Priority.High,
      text: Priority[Priority.High],
    },
    {
      id: Priority.Low,
      text: Priority[Priority.Low],
    },
    {
      id: Priority.Medium,
      text: Priority[Priority.Medium],
    },
    {
      id: Priority.None,
      text: Priority[Priority.None],
    },
  ];

  taskTypeList: { id: TaskType; name: string; iconClass: string }[] = [];
  subtaskCache: Map<number, any[]> = new Map();

  taksListActionItem = [
    {
      text: TaskListActionEnumEnum[TaskListActionEnumEnum.Edit],
      iconClass: 'far fa-pen',
    },
    {
      text: TaskListActionEnumEnum[TaskListActionEnumEnum.Delete],
      iconClass: 'far fa-trash-alt',
    },
  ];

  totalTaskCount: number;
  totalCompletedTaskCount: number;

  addNewTaskformGroup: FormGroup;
  tasklistTab = TaskListTabEnum;
  selectedTabId = TaskListTabEnum.List;

  constructor(
    private _Activatedroute: ActivatedRoute,
    private taskListService: TaskListService,
    private taskService: TaskService,
    private dialogService: DialogService,
    private router: Router,
    public datepipe: DatePipe,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private toasterService: ToasterService,
    protected service: IdentityUserService,
    private config: ConfigStateService,
    private fb: FormBuilder,
    private store: Store,
  ) {
    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    if (currentUserRole.includes('admin')) {
      this.isAdmin = true;
    }
    this.taskTypeList = this.commonService.getTaskTypeList();
  }

  ngOnInit(): void {
    this.setForm();
    this._Activatedroute.queryParams.subscribe(params => {
      if (params) {
        this.projectId = +atob(params['projectId']);
        this.projectName = atob(params['projectName']);
        this.currentExpandedPanelId = params['panelId'] ? atob(params['panelId']) : undefined;
        this.taskListIdFromUrl = params['taskListId'] ? +atob(params['taskListId']) : null;
        this.selectedTabId = params['tabId'] ? +atob(params['tabId']) : TaskListTabEnum.List;

        this.getTaskListList();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustMaxWidth();
  }

  adjustMaxWidth() {
    if (this.taskmains) {
      this.taskmains.toArray().forEach(main => {
        const container = main.nativeElement;
        const childrenArray = Array.from(container.children) as HTMLElement[];
        let nonAnchorWidth = 0;
        let totalwidth = 0;

        childrenArray.forEach((child: HTMLElement) => {
          totalwidth += child.offsetWidth;
          if (child.tagName.toLowerCase() !== 'a') {
            nonAnchorWidth += child.offsetWidth;
          }
        });

        const parentWidth = container.parentElement.offsetWidth;
        const availableWidth = parentWidth - nonAnchorWidth;

        if (totalwidth > parentWidth) {
          const anchorTag = childrenArray.find((child: HTMLElement) => {
            if (child.tagName.toLowerCase() === 'a') {
              return true;
            }
            const childs = Array.from(child.children) as HTMLElement[];
            return childs.some((x: HTMLElement) => x.tagName.toLowerCase() === 'a');
          });

          if (anchorTag) {
            const spanTag = anchorTag.querySelector('span') as HTMLElement;

            if (spanTag) {
              spanTag.style.maxWidth = `${availableWidth - 10}px`;
            }
          }
        }
      });
    }
  }

  setForm(): void {
    this.addNewTaskformGroup = this.fb.group({
      taskName: ['', [Validators.required, Validators.maxLength(255)]],
    });
  }

  loadMoreItems = (args: LoadMoreRequestArgs): Observable<any[]> => {
    this.skipcount = args.skip;

    const param = this.buildTaskListParams(this.currentExpandedPanelId, args.skip, false);
    if (!args.dataItem) {
      return this.taskService.getList(param).pipe(
        map(({ items, totalCount }) => {
          this.totalTaskCount = totalCount;
          return this.mapTaskItems(items);
        }),
        tap(() => setTimeout(() => this.adjustMaxWidth(), 0)),
      );
    } else {
      this.skipCountForSubTaskList = args.skip;

      return this.getSubtask(args.dataItem.id).pipe(
        map((elements: any[]) =>
          elements.map(element => this.mapSubTask(element, args.dataItem.id)),
        ),
      );
    }
  };

  loadMoreItemsForCompletedTask = (args: LoadMoreRequestArgs): Observable<any[]> => {
    this.skipCountForCompletedTask = args.skip;
    const param = this.buildTaskListParams(this.currentExpandedPanelId, args.skip, true);

    if (!args.dataItem) {
      return this.taskService.getList(param).pipe(
        map(({ items, totalCount }) => {
          this.totalCompletedTaskCount = totalCount;
          return this.mapTaskItems(items);
        }),
        tap(() => setTimeout(() => this.adjustMaxWidth(), 0)),
      );
    }
  };

  gotoTime(): void {
    var params = {
      projectId: btoa(this.projectId),
    };
    this.router.navigate(['time', params]);
  }

  gotoDashboard(): void {
    var params = {
      projectId: btoa(this.projectId),
      projectName: btoa(this.projectName),
    };
    this.router.navigate(['project-dashboard', params]);
  }

  onSearchTextChange(searchText: string): void {
    if (!searchText.trim()) {
      this.getTaskListList();
    }
  }

  getTaskListList(isDefaultExpanded = false): void {
    if (!this.isLoadingForTaskListList) return;

    let newData = {
      projectId: this.projectId,
      showMilestones: true,
      getCompleteCount: true,
      getNewDefaultTask: true,
      sorting: null,
      skipCount: this.skipCountForTaskListList,
      maxResultCount: this.maxResultCountForTaskListList,
      searchTerm: this.searchText,
    };

    this.spinnerService.show();

    this.taskListService.getList(newData).subscribe(res => {
      if (res.items.length > 0) {
        res.items.forEach(element => {
          const data = {
            id: element.id,
            name: element.name,
            totalNumberOfTask: element.totalNumberOfTask,
            completeCount: element.completeCount,
            totalTimelogged: element.totalTimelogged,
            totalEstimatedTime: element.totalEstimatedTime,
            expanded: this.currentExpandedPanelId === element.id ? isDefaultExpanded : false,
          };
          this.taskListsList.push(data);
        });

        this.skipCountForTaskListList += this.maxResultCountForTaskListList;
        this.isLoadingForTaskListList = this.taskListsList.length < res.totalCount;
      } else {
        this.isLoadingForTaskListList = false;
      }

      if (this.taskListIdFromUrl)
        this.onTaskListsListExpandedPanelClick(this.currentExpandedPanelId);

      this.isNoRecordFound = res.items.length > 0 ? false : true;
      this.spinnerService.hide();
    });
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getTaskList(this.currentExpandedPanelId);
  }

  showCompletedTask(): void {
    this.skipCountForCompletedTask = 0;
    this.isShowCompleteTask = !this.isShowCompleteTask;
    if (this.isShowCompleteTask) this.getCompletedTaskList(this.currentExpandedPanelId);
  }

  onAddTaskClick(isNew: boolean): void {
    this.isAddNew = isNew;
    this.addNewTaskformGroup.reset();
  }

  onAddTaskClickOnTop(addNewTaskTop: boolean): void {
    this.addNewTaskTop = addNewTaskTop;
    this.addNewTaskformGroup.reset();
  }

  private mapSubTask(element: any, parentTaskId: number): any {
    return {
      id: element.id,
      taskName: element.taskName,
      assignee: element.assignee,
      dueDate: element.dueDate,
      priority: element.priority,
      taskId: parentTaskId,
      hasChildren: !!element.subTaskIds,
      taskType: element.taskType,
      taskStatus: element.taskStatus,
      estimateMinutes: element.estimateMinutes,
      isAllSubTaskCompleted: element.isAllSubTaskCompleted,
      taskStatusText: element.taskStatusText,
      isAddSubtask: false,
      description: element.description,
      taskListId: element.taskListId,
      parentTaskId: element.parentTaskId,
      totalCount: element.totalCount,
      children: this.getInitialChildren(element),
    };
  }

  /**
   * Returns the initial children array for a subtask.
   */
  private getInitialChildren(element: any): any[] {
    return element.subTaskIds?.length > 0
      ? [this.createPlaceholderChild(element.id, element.subTaskIds.length)]
      : [];
  }

  private createPlaceholderChild(parentTaskId: number, subTaskCount: number): any {
    return {
      id: 0,
      taskName: '',
      assignee: '',
      startDate: '',
      dueDate: '',
      priority: '',
      taskId: parentTaskId,
      hasChildren: '',
      taskType: 0,
      taskStatus: '',
      estimateMinutes: '',
      isAllSubTaskCompleted: '',
      taskStatusText: '',
      attachmentCount: '',
      timelogMinutes: '',
      isAddSubtask: false,
      description: '',
      taskListId: '',
      parentTaskId,
      children: [],
      totalCount: subTaskCount,
    };
  }

  onExpand(event: any): void {
    event.dataItem.children = [];

    this.getSubtask(event.dataItem.id).subscribe((elements: any[]) => {
      event.dataItem.children = elements.map(element =>
        this.mapSubTask(element, event.dataItem.id),
      );
    });

    setTimeout(() => this.adjustMaxWidth(), 0);
  }

  public hasSubtask(item: any): boolean {
    return item.hasChildren;
  }

  public fetchSubtask = (dataitem: any): Observable<any[]> => {
    this.skipCountForSubTaskList = 0;

    return this.getSubtask(dataitem.id).pipe(
      map((elements: any[]) => elements.map(element => this.mapSubTask(element, dataitem.id))),
    );
  };

  getCompletedTaskList(taskListId: number): void {
    const param = this.buildTaskListParams(taskListId, this.skipCountForCompletedTask, true);

    this.taskService.getList(param).subscribe(({ items, totalCount }) => {
      this.totalCompletedTaskCount = totalCount;

      this.completedTaskList = this.mapTaskItems(items);

      setTimeout(() => this.adjustMaxWidth(), 0);
    });
  }

  getTaskList(taskListId: number, isFromMarkCompleted: boolean = false): void {
    const param = this.buildTaskListParams(taskListId, this.skipcount, false);
    this.spinnerService.show();

    this.taskService.getList(param).subscribe(({ items, totalCount }) => {
      this.totalTaskCount = totalCount;

      this.taskList = this.mapTaskItems(items);

      // Add a placeholder if no tasks are found but more can be loaded
      if (this.taskList.length === 0 && this.pageSize < totalCount) {
        this.taskList = [
          { id: 0, taskName: 'No tasks available', hasChildren: false, children: [] },
        ];
      }

      // Update page size for the root node
      // this.treeview.setNodePageSize(null, this.pageSize);

      this.spinnerService.hide();

      setTimeout(() => this.adjustMaxWidth(), 0);
    });

    if (isFromMarkCompleted) {
      this.getCompletedTaskList(taskListId);
    }
  }

  private buildTaskListParams(taskListId: number, skipCount: number, completedOnly: boolean): any {
    return {
      taskListId,
      sorting: this.sorting,
      skipCount,
      maxResultCount: this.pageSize,
      getSubTasks: true,
      includeLoggedTime: true,
      completedOnly,
    };
  }

  private mapTaskItems(items: any[]): any[] {
    return items.map(element => ({
      id: element.id,
      taskName: element.name,
      assignee: element.users,
      startDate: element.startdate,
      dueDate: element.duedate,
      priority: element.priority,
      taskId: null,
      hasChildren: !!element.subTaskIds,
      taskType: element.taskType,
      taskStatus: element.taskStatus,
      estimateMinutes: element.estimateMinutes,
      isAllSubTaskCompleted: element.isAllSubTaskCompleted,
      taskStatusText: element.taskStatusText,
      attachmentCount: element.attachmentCount,
      timelogMinutes: element.timelogMinutes,
      isAddSubtask: false,
      description: element.description,
      taskListId: element.taskListId,
      parentTaskId: element.parentTaskId,
      totalCount: element.subTaskIds?.length,
      children: this.getInitialChildren(element),
    }));
  }

  onTaskCompleteChange(dataItem: any): void {
    const isTaskCompleted = dataItem.taskStatus === TaskStatusType.Completed;

    const action = isTaskCompleted
      ? this.taskService.markTaskAsReopenedById(dataItem.id)
      : this.taskService.markTaskAsCompleteById(dataItem.id);

    action.subscribe(res => {
      this.toasterService.success(
        NotificationTextMessage.taskUpdatedMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );

      const taskList = this.taskListsList.find(x => x.id === this.currentExpandedPanelId);
      if (taskList) {
        taskList.completeCount += isTaskCompleted ? -1 : 1;
      }

      if (!dataItem.parentTaskId) {
        this.skipcount = 0;
        this.getTaskList(this.currentExpandedPanelId, true);
        //this.adjustMaxWidth();
      }
    });
  }

  getSubtask(id: any): Observable<any[]> {
    this.spinnerService.show();

    let param: any = {
      sorting: this.sorting,
      maxResultCount: this.pageSize,
      skipCount: this.skipCountForSubTaskList,
    };

    if (this.skipCountForSubTaskList === 0 && this.subtaskCache.has(id)) {
      this.spinnerService.hide();
      return of(this.subtaskCache.get(id));
    }

    return this.taskService.getSubTasksByParentTaskIdAndPagedAndSortedResultRequest(id, param).pipe(
      map(x => {
        const subTaskData = x.items.map(element => ({
          id: element.id,
          taskName: element.name,
          assignee: element.users,
          dueDate: element.duedate,
          priority: element.priority,
          taskId: id,
          hasChildren: !!element.subTaskIds,
          taskType: element.taskType,
          taskStatus: element.taskStatus,
          estimateMinutes: element.estimateMinutes,
          isAllSubTaskCompleted: element.isAllSubTaskCompleted,
          taskStatusText: element.taskStatusText,
          subTaskIds: element.subTaskIds,
          taskListId: element.taskListId,
          parentTaskId: element.parentTaskId,
          totalCount: x.totalCount,
          description: element.description,
        }));
        this.spinnerService.hide();
        return subTaskData;
      }),
      tap(subTaskData => {
        const cachedData = this.subtaskCache.get(id) || [];
        this.subtaskCache.set(id, cachedData.concat(subTaskData));
        this.spinnerService.hide();
        setTimeout(() => this.adjustMaxWidth(), 0);
      }),
    );
  }

  checkIsDisable(dataItem: any): boolean {
    if (dataItem.hasChildren) {
      return !dataItem.isAllSubTaskCompleted;
    }
    return false;
  }

  onCancelSubtask(dataItem) {
    this.subTaskErrors[dataItem.id] = false;
    dataItem.isAddSubtask = false;
  }

  onInputChange(value: string, dataItem: any): void {
    if (value?.trim()) {
      this.subTaskErrors[dataItem.id] = false;
    }
  }

  onSaveNewTask(taskListId: any, textbox: any) {
    if (this.addNewTaskformGroup.invalid) {
      this.addNewTaskformGroup.markAllAsTouched();
      return;
    }

    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: 0,
      name: this.addNewTaskformGroup.value.taskName,
      description: '',
      progress: 20,
      taskListId: taskListId,
      startdate: null,
      duedate: null,
      priority: 0,
      estimateMinutes: 0,
      parentTaskId: null,
      notify: true,
      assignedToUserIds: [],
      taskType: 0,
      taskStatus: TaskStatusType.New,
      subTasks: [],
    };

    this.taskService.create(param).subscribe(
      res => {
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationTextMessage.taskAddedMessage,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.skipcount = 0;
        this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalNumberOfTask += 1;

        this.getTaskList(taskListId, false);
        this.isAddNew = false;
        this.addNewTaskTop = false;
      },

      err => {
        this.spinnerService.hide();
      },
    );

    setTimeout(() => this.adjustMaxWidth(), 0);
  }

  onSaveSubTask(subTaskEditor: any, dataItem: any, taskListId: any): void {
    if (!this.isSubTaskNameValid(subTaskEditor.value, dataItem.id)) {
      return;
    }

    this.subTaskErrors[dataItem.id] = false;
    dataItem.isAddSubtask = false;

    const param = this.prepareSubTaskParam(subTaskEditor.value, dataItem, taskListId);

    this.taskService.create(param).subscribe(
      res => {
        this.handleSubTaskSaveSuccess(res, subTaskEditor.value, dataItem);
        this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalNumberOfTask += 1;
      },
      err => this.handleSubTaskSaveError(),
    );
  }

  /**
   * Validates the subtask name and updates the error state if invalid.
   */
  private isSubTaskNameValid(subTaskName: string, dataItemId: number): boolean {
    const isValid = subTaskName && subTaskName.trim() !== '';
    this.subTaskErrors[dataItemId] = !isValid;
    return isValid;
  }

  /**
   * Prepares the parameter object for creating a new subtask.
   */
  private prepareSubTaskParam(
    subTaskName: string,
    dataItem: any,
    taskListId: any,
  ): CreateUpdateTaskDto {
    return {
      isQuickEdit: true,
      id: 0,
      name: subTaskName.trim(),
      description: '',
      progress: 20,
      taskListId,
      startdate: null,
      duedate: null,
      priority: +dataItem.priority,
      estimateMinutes: 0,
      parentTaskId: dataItem.id,
      notify: true,
      assignedToUserIds: dataItem.assignee.length ? dataItem.assignee.map(item => item.userId) : [],
      taskType: dataItem.taskType,
      taskStatus: TaskStatusType.New,
      subTasks: [],
    };
  }

  /**
   * Handles the success response for subtask creation.
   */
  private handleSubTaskSaveSuccess(res: any, subTaskName: string, dataItem: any): void {
    this.spinnerService.hide();
    this.toasterService.success(
      NotificationTextMessage.taskAddedMessage,
      '',
      this.commonService.toasterMessageConfiguration,
    );

    this.subtaskCache = new Map<number, any[]>();

    this.skipCountForSubTaskList = 0;

    this.getSubtask(dataItem.id)
      .pipe(
        map((elements: any[]) => elements.map(element => this.mapSubTask(element, dataItem.id))),
      )
      .subscribe(x => {
        dataItem.hasChildren = true;
        dataItem.children.push(x);
      });

    this.taskList = [...this.taskList];
  }

  /**
   * Handles the error response for subtask creation.
   */
  private handleSubTaskSaveError(): void {
    this.spinnerService.hide();
  }

  /**
   * Creates a new subtask node for the tree structure.
   */
  private createSubTaskNode(id: number, subTaskName: string, parentTaskId: number): any {
    return {
      id,
      taskName: subTaskName,
      assignee: '',
      startDate: '',
      dueDate: '',
      priority: '',
      taskId: parentTaskId,
      hasChildren: '',
      taskType: 0,
      taskStatus: '',
      estimateMinutes: '',
      isAllSubTaskCompleted: '',
      taskStatusText: 'New',
      attachmentCount: '',
      timelogMinutes: '',
      isAddSubtask: false,
      description: '',
      taskListId: '',
      parentTaskId,
      children: [],
    };
  }

  onDeleteClick(dataItem: any, isCompleted: boolean): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + dataItem.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.taskService.delete(dataItem.id).subscribe(res => {
          this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalNumberOfTask -= 1;
          this.toasterService.success(
            NotificationTextMessage.taskRemoveMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          if (!isCompleted) {
            ///this.taskList = this.taskList.filter(x => x.id != dataItem.id);
            this.getTaskList(this.currentExpandedPanelId);
          } else {
            // this.completedTaskList = this.completedTaskList.filter(x => x.id != dataItem.id);
            this.getCompletedTaskList(this.currentExpandedPanelId);
            //this.totalCompletedTaskCount -= 1;
            this.taskListsList.find(x => x.id === this.currentExpandedPanelId).completeCount =
              this.totalCompletedTaskCount;
          }
        });
      }
    });
  }

  onAddTask(data?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddTaskComponent,
      width: 450,
    });

    const addTaskListInfo = dialogRef.content.instance as AddTaskListComponent;

    addTaskListInfo.taskListId = data.id;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.isLoadingForTaskListList = true;
        this.skipCountForTaskListList = 0;
        this.taskListsList = [];
        this.getTaskListList();
        this.getTaskList(this.currentExpandedPanelId, false);
        setTimeout(() => this.adjustMaxWidth(), 0);
      }
    });
  }

  onAddTaskList(tasklistId?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddTaskListComponent,
      width: 450,
    });

    dialogRef.content.instance.projectId = this.projectId;
    const addTaskListInfo = dialogRef.content.instance as AddTaskListComponent;
    addTaskListInfo.taskListId = tasklistId;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.taskListsList = [];
        this.skipCountForTaskListList = 0;
        this.maxResultCountForTaskListList = 10;
        this.isLoadingForTaskListList = true;
        this.getTaskListList();
        this.adjustMaxWidth();
      }
    });
  }

  onTaskNameClick(data: any, taskListId: string, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    if (this.openInNewTab) return;

    event.preventDefault();

    if (!this.openInNewTab) {
      var params = {
        taskId: btoa(data.id),
        projectId: btoa(this.projectId),
        projectName: btoa(this.projectName),
        taskListId: btoa(taskListId),
        panelId: btoa(this.currentExpandedPanelId),
        tabId: btoa(TaskListTabEnum.List.toString()),
      };

      this.router.navigate(['task-Detail'], { queryParams: params });
    }
  }

  getQueryParams(data: any, taskListId: string) {
    return {
      taskId: btoa(data.id),
      projectId: btoa(this.projectId),
      projectName: btoa(this.projectName),
      taskListId: btoa(taskListId),
      panelId: btoa(this.currentExpandedPanelId),
    };
  }

  onTaskListsListExpandedPanelClick(index: number, item?: any): void {
    if (this.taskListIdFromUrl) {
      item = this.taskListsList.find(x => x.id === +this.taskListIdFromUrl);
      this.taskListIdFromUrl = null;
    }

    this.taskList = [];

    if (this.currentExpandedPanelId !== item.id) {
      const lastSelectedItem = this.taskListsList.find(x => x.id === this.currentExpandedPanelId);
      if (lastSelectedItem) {
        lastSelectedItem.expanded = false;
      }
    }

    this.currentExpandedPanelId = item.id;
    item.expanded = !item.expanded;

    this.panels.forEach((panel, idx) => {
      if (idx !== index && panel.expanded) {
        panel.toggle();
      }
    });

    this.totalCompletedTaskCount = item.completeCount;

    if (item.expanded) {
      this.isShowCompleteTask = false;
      this.skipcount = 0;
      this.getTaskList(item.id);
    }
    this.isAddNew = false;
  }

  onTaskListActionClick(data, tasklistId, taskListName) {
    const action = data.text;

    switch (action) {
      case TaskListActionEnumEnum[TaskListActionEnumEnum.Edit]:
        this.onAddTaskList(tasklistId);
        break;
      case TaskListActionEnumEnum[TaskListActionEnumEnum.Delete]:
        this.onDeleteTaskList(tasklistId, taskListName);
        break;
    }
  }

  onDeleteTaskList(taskListId, taskListName) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + taskListName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.taskListService.delete(taskListId).subscribe(res => {
          this.taskListsList = this.taskListsList.filter(x => x.id != taskListId);
          this.adjustMaxWidth();
        });
      }
    });
  }

  onTaskTypeClick(event: any, dataItem: any, taskListId: any) {
    const dueDate = new Date(dataItem.dueDate);

    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: dataItem.id,
      name: dataItem.taskName,
      description: '',
      progress: 20,
      taskListId: taskListId,
      startdate:
        dataItem.dueDate === null
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      duedate:
        dataItem.dueDate === null
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: dataItem.priority,
      estimateMinutes: dataItem.estimateMinutes,
      parentTaskId: dataItem.taskId,
      notify: true,
      assignedToUserIds:
        dataItem.assignee === null ? [] : dataItem.assignee.map(item => item.userId),
      taskType: event.id,
      taskStatus: dataItem.taskStatus,
      subTasks: [],
    };

    this.taskService.update(dataItem.id, param).subscribe(res => {
      dataItem.taskType = event.id;
    });
  }

  getIconClass(taskType: number): string {
    return this.taskTypeList.find(x => x.id === taskType).iconClass;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  onEditClick(data: any): void {
    this.isDrawerOpened = !this.isDrawerOpened;
    this.taskId = data.id;
    this.taskDetails = data;
    this.projectId = this.projectId;
    document.body.style.overflow = 'hidden';
  }

  onTaskDrawerClose(event) {
    combineLatest([
      this.store.select(TaskState.getFileList),
      this.store.select(TaskState.getTotalLoggedTime),
      this.store.select(TaskState.getSubTaskList),
      this.store.select(TaskState.getTaskStatus),
    ])
      .pipe(take(1))
      .subscribe(([fileList, totalLoggedTime, subTaskList, taskStatus]) => {
        this.taskDetails.attachmentCount = fileList.length;
        this.taskDetails.timelogMinutes = totalLoggedTime;
        this.taskDetails.taskStatusText = this.commonService.getTaskStatusName(taskStatus);

        this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalTimelogged =
          totalLoggedTime;

        let newData = {
          projectId: this.projectId,
          showMilestones: true,
          getCompleteCount: true,
          getNewDefaultTask: true,
          sorting: null,
          skipCount: this.skipCountForTaskListList - 2,
          maxResultCount: this.maxResultCountForTaskListList,
          searchTerm: this.searchText,
        };

        this.taskListService.getList(newData).subscribe(res => {
          if (res.items.length > 0) {
            var data = res.items.find(x => x.id === this.currentExpandedPanelId);
            if (data !== null || data !== undefined) {
              this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalTimelogged =
                data.totalTimelogged;
              this.taskListsList.find(
                x => x.id === this.currentExpandedPanelId,
              ).totalEstimatedTime = data.totalEstimatedTime;
              this.taskDetails.estimateMinutes = data.totalEstimatedTime;
              this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalNumberOfTask =
                data.totalNumberOfTask;
            }
          }
        });

        if (subTaskList.length === 0) {
          this.taskDetails.children = [];
        } else {
          this.taskDetails.children.push(subTaskList);
        }

        if (event.isSuccess) {
          this.taskDetails.taskName = event.data.name;
          this.taskDetails.assignee = event.data.assignedToUserIds;
          this.taskDetails.priority = event.data.priority;
          this.taskDetails.taskType = event.data.taskType;
          if (event.data.startdate !== null) {
            this.taskDetails.startDate = `${event.data.startdate.year}-${String(
              event.data.startdate.month,
            ).padStart(2, '0')}-${String(event.data.startdate.day).padStart(2, '0')}`;
          }
          if (event.data.duedate !== null) {
            this.taskDetails.dueDate = `${event.data.duedate.year}-${String(
              event.data.duedate.month,
            ).padStart(2, '0')}-${String(event.data.duedate.day).padStart(2, '0')}`;
          }
        }

        if (this.taskDetails.taskStatus !== taskStatus) {
          this.taskDetails.taskStatus = taskStatus;
          this.getTaskList(
            this.currentExpandedPanelId,
            taskStatus === TaskStatusType.Completed || taskStatus === TaskStatusType.ReOpened,
          );
        }

        this.isDrawerOpened = !this.isDrawerOpened;
      });
  }

  onAddSubtaskClick(dataItem: any): void {
    dataItem.isAddSubtask = true;
  }

  onNodeDrop(event: any): void {
    const { sourceItem, destinationItem, dropPosition } = event;

    if (dropPosition === 'over') {
      destinationItem.children = destinationItem.children || [];
      destinationItem.children.push(sourceItem);

      const hasUnderscore = sourceItem.item.index.includes('_');
      const underscoreCount = hasUnderscore ? (sourceItem.item.index.match(/_/g) || []).length : 0;

      const params: UpdateTaskParentTaskIdDto = {
        parentTaskId: underscoreCount == 1 ? null : destinationItem.item.dataItem.id,
        taskId: sourceItem.item.dataItem.id,
      };

      this.taskService.updateTaskParentTaskId(params).subscribe();
    } else if (dropPosition === 'before' || dropPosition === 'after') {
      const parent = this.findParent(this.taskList, destinationItem);
      if (parent) {
        const siblings = parent.children;
        const destIndex = siblings.indexOf(destinationItem);
        siblings.splice(dropPosition === 'before' ? destIndex : destIndex + 1, 0, sourceItem);

        const hasUnderscore = sourceItem.item.index.includes('_');
        const underscoreCount = hasUnderscore
          ? (sourceItem.item.index.match(/_/g) || []).length
          : 0;

        const params: UpdateTaskParentTaskIdDto = {
          parentTaskId: underscoreCount == 1 ? null : destinationItem.item.dataItem.id,
          taskId: sourceItem.item.dataItem.id,
        };

        this.taskService.updateTaskParentTaskId(params).subscribe();
      }
    }

    this.removeNode(this.taskList, sourceItem);
  }

  onNodeDragStart(event: any): void {
    console.log('Drag started:', event.sourceItem);
  }

  findParent(items: any[], child: any): any {
    for (let item of items) {
      if (item.children?.includes(child)) {
        return item;
      }
      if (item.children) {
        const parent = this.findParent(item.children, child);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }

  removeNode(items: any[], node: any): void {
    for (let i = 0; i < items.length; i++) {
      if (items[i] === node) {
        items.splice(i, 1);
        return;
      }
      if (items[i].children) {
        this.removeNode(items[i].children, node);
      }
    }
  }

  onScrollTaskListList(event: any): void {
    const target = event.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.getTaskListList();
      setTimeout(() => this.adjustMaxWidth(), 0);
    }
  }

  onScrollTaskList(event: any): void {
    // Exit if data is currently being fetched or if all tasks have been loaded
    if (this.isLoadingForTaskList || this.taskList.length >= this.totalTaskCount) {
      return;
    }

    // Set loading flag to true to prevent further calls until data is loaded
    this.isLoadingForTaskList = true;

    // Show spinner
    this.spinnerService.show();

    // Increment the skip count for pagination
    this.skipcount += this.pageSize;

    // Build parameters for the task list request
    const param = this.buildTaskListParams(this.currentExpandedPanelId, this.skipcount, false);

    // Fetch the data
    this.taskService.getList(param).subscribe(
      ({ items, totalCount }) => {
        // Update total count and task list
        this.totalTaskCount = totalCount;

        // Map new data to the task list
        const newData = this.mapTaskItems(items);
        this.taskList = [...this.taskList, ...newData];

        console.log(this.taskList.length, this.totalTaskCount);

        // Hide the spinner and reset loading flag
        this.spinnerService.hide();
        this.isLoadingForTaskList = false;
      },
      error => {
        // Handle error, hide spinner, and reset loading flag
        this.spinnerService.hide();
        this.isLoadingForTaskList = false;
      },
    );
  }

  onScrollCpmpleteTaskList(event: any): void {
    // Exit if data is currently being fetched or if all tasks have been loaded
    if (
      this.isLoadingForCompletedTaskList ||
      this.completedTaskList.length >= this.totalCompletedTaskCount
    ) {
      return;
    }

    // Set loading flag to true to prevent further calls until data is loaded
    this.isLoadingForCompletedTaskList = true;

    // Show spinner
    this.spinnerService.show();

    // Increment the skip count for pagination
    this.skipCountForCompletedTask += this.pageSize;

    // Build parameters for the task list request
    const param = this.buildTaskListParams(
      this.currentExpandedPanelId,
      this.skipCountForCompletedTask,
      true,
    );

    // Fetch the data
    this.taskService.getList(param).subscribe(
      ({ items, totalCount }) => {
        // Update total count and task list
        this.totalCompletedTaskCount = totalCount;

        // Map new data to the task list
        const newData = this.mapTaskItems(items);
        this.completedTaskList = [...this.completedTaskList, ...newData];

        // Hide the spinner and reset loading flag
        this.spinnerService.hide();
        this.isLoadingForCompletedTaskList = false;
      },
      error => {
        console.error('Error loading tasks:', error);

        // Handle error, hide spinner, and reset loading flag
        this.spinnerService.hide();
        this.isLoadingForCompletedTaskList = false;
      },
    );
  }
}
