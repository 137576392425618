import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input, NgZone, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  GridComponent,
  GridDataResult,
  PageChangeEvent,
  RemoveEvent,
  RowClassArgs,
  SaveEvent,
} from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor, State, process } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectBoardColumnService } from 'projects/project-service/src/lib/proxy/project-service';
import { fromEvent, Subscription, take, tap } from 'rxjs';
import { NotificationMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';

const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingComponent implements OnInit {
  gridView: GridDataResult;
  formGroup;
  gridData = { data: [], total: 0 };
  currentSubscription: Subscription;
  dataItem;
  gridHeight: number;
  isNoRecords = false;
  isAddHandle = false;

  statusLists = [];

  searchText: string = '';

  @Input() taskId = 0;
  @Input() projectId = 0;

  sorting = null;
  editedRowIndex: number;

  skip = 0;
  pageSize = 10;

  sort: SortDescriptor[] = [
    {
      field: 'columnname',
    },
  ];

  state: State = {
    skip: 0,
    take: 10,
  };

  constructor(
    private spinnerService: NgxSpinnerService,
    private projectBoardColumnService: ProjectBoardColumnService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private renderer: Renderer2,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.getStatusList();
    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.calculateGridHeight();
    });
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;
    const tabHeight = 80;

    this.gridHeight = screenHeight - (gridHeaderHeight + wrapperpadding + tabHeight);
  }

  getStatusList(): void {
    const data: any = {
      columnId: 0,
      projectId: this.projectId,
      sorting: this.sorting,
      skipCount: this.skip,
      maxResultCount: this.pageSize,
    };

    this.spinnerService.show();

    this.projectBoardColumnService.getList(data).subscribe(
      response => {
        this.spinnerService.hide();
        this.isNoRecords = response.items.length > 0 ? false : true;
        const sortedItems = orderBy(response.items, [{ field: 'columnDisplayorder', dir: 'asc' }]);
        this.statusLists = sortedItems;
        this.gridData = {
          data: this.statusLists,
          total: response.totalCount,
        };
      },
      err => {
        this.spinnerService.hide();
        this.gridData = { data: [], total: 0 };
      },
    );
  }

  public addHandler(args: AddEvent): void {
    this.isAddHandle = true;
    this.closeEditor(args.sender);
    this.formGroup = new FormGroup({
      id: new FormControl(),
      columnname: new FormControl('', Validators.required),
    });
    args.sender.addRow(this.formGroup);
  }

  public editHandler(args: EditEvent): void {
    const { dataItem } = args;
    this.closeEditor(args.sender);
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      columnname: new FormControl(dataItem.columnname, Validators.required),
    });

    this.editedRowIndex = args.rowIndex;
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    this.closeEditor(args.sender, args.rowIndex);
  }

  public createHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    sender.closeRow(rowIndex);
  }

  public removeHandler(args: RemoveEvent): void {
    this.projectBoardColumnService.delete(args.dataItem.id).subscribe(
      res => {
        this.toasterService.success(
          NotificationMessage.deleteStatusSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.getStatusList();
      },
      error => {
        const errorMessage = error.error.error.message || 'Delete Failed';
        this.toasterService.error(errorMessage, '', this.commonService.toasterMessageConfiguration);
      },
    );
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const gridItems = this.gridData.data || [];
    const maxDisplayOrder = gridItems.reduce(
      (max, item) => Math.max(max, item.columnDisplayorder || 1),
      1,
    );
    const newDisplayOrder = maxDisplayOrder + 1;

    const param = {
      columnname: formGroup.value.columnname,
      columnColor: null,
      columnDisplayorder: isNew ? newDisplayOrder : formGroup.value.columnDisplayorder,
      columnShowCompletedCards: true,
      columnSort: '',
      columnSettings: '',
      columnTasklistId: this.taskId,
      projectId: this.projectId,
    };

    if (isNew) {
      this.projectBoardColumnService.create(param).subscribe(
        res => {
          this.spinnerService.hide();
          this.getStatusList();
          this.toasterService.success(
            NotificationMessage.statusSuccessMsg,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.projectBoardColumnService.update(formGroup.value.id, param).subscribe(
        res => {
          this.spinnerService.hide();
          this.getStatusList();
          this.toasterService.success(
            NotificationMessage.statusUpdateSuccessMsg,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }

    sender.closeRow(rowIndex);
  }

  pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.getStatusList();
  }

  sortChange(sort): void {
    this.sort = sort;
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getStatusList();
  }

  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  onDrop(args) {
    console.log(args);
  }

  public dataStateChange(state: State): void {
    this.state = state;
    this.gridData = process(this.statusLists, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable
      .pipe(take(1))
      .subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => {});
    let draggedItemIndex;

    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement('span');
                dragImgEl.setAttribute(
                  'style',
                  'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;',
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {}
              try {
                dataTransfer.setData('application/json', '');
              } catch (err) {}
            }),
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.gridData.data[draggedItemIndex];
            dataItem.dragging = true;
          }),
      );

      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.gridData.data[dropIndex];

          draggedItemIndex = dropIndex;
          this.zone.run(() => this.gridData.data.splice(dropIndex, 0, dataItem));
        }),
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          console.log(draggedItemIndex, 'draggedItemIndex');
          const dataItem = this.gridData.data[draggedItemIndex];
          this.dataItem = dataItem;
          dataItem.dragging = false;
          const param = {
            columnname: this.dataItem.columnname,
            columnColor: null,
            columnDisplayorder: draggedItemIndex,
            columnShowCompletedCards: true,
            columnSort: '',
            columnSettings: '',
            columnTasklistId: this.taskId,
            projectId: this.projectId,
          };
          this.projectBoardColumnService.update(this.dataItem.id, param).subscribe(
            res => {
              this.spinnerService.hide();
              this.getStatusList();
              this.toasterService.success(
                NotificationMessage.statusUpdateSuccessMsg,
                '',
                this.commonService.toasterMessageConfiguration,
              );
            },
            err => {
              this.spinnerService.hide();
            },
          );
        }),
      );
    });

    return sub;
  }
}
