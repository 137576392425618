export enum NotificationTextMessage {
  portfolioAddedMessage = 'Portfolio Saved Successfully!',
  portfolioUpdatedMessage = 'Portfolio Updated Successfully!',
  portfolioDeletedMessage = 'Portfolio Deleted Successfully!',
  userAddedMessage = 'User Saved Successfully!',
  userUpdatedMessage = 'User Updated Successfully!',
  userDeletedMessage = 'User Deleted Successfully!',
  roleAddedMessage = 'Role Saved Successfully!',
  roleUpdatedMessage = 'Role Updated Successfully!',
  roleDeletedMessage = 'Role Deleted Successfully!',
  productAddedMessage = 'Product Updated Successfully!',
  productUpdatedMessage = 'Product Saved Successfully!',
  productDeletedMessage = 'Product Deleted Successfully!',
  formAddedMessage = 'Form Created Successfully!',
  formUpdatedMessage = 'Form Updated Successfully!',
  formDeletedMessage = 'Form Deleted Successfully!',
  reponseAddedMessage = 'Reponse Saved Successfully!',
  shareLinkMessage = 'Share link Copied',
  emailSendMessage = 'Email Send Successfully!',
  memberAddedMessage = 'User added to the group successfully!',
  memberRemovedMessage = 'User deleted from the group successfully!',
  taskListAddedMessage = 'Task List Added Successfully!',
  taskLisUpdatedMessage = 'Task List Updated Successfully!',
  taskAddedMessage = 'Task Added Successfully!',
  taskUpdatedMessage = 'Task Updated Successfully!',
  taskRemoveMessage = 'Task Delete Successfully!',
  categoryAddedMessage = 'Category Added Successfully!',
  categoryUpdatedMessage = 'Category Updated Successfully!',
  areYouSureMessage = 'Are you sure?',
  deleteMessageHeader = 'You want to delete : ',
  formInvalid = 'Invalid Form!',
  addValidateDateErrorMessage = 'Start time and end time cannot be same!',
  bothStartEndTimeRequired = 'Both start time and end time are required',
  companyUpdatedMessage = 'Company Updated Successfully!',
  companyAddedMessage = 'Company Saved Successfully!',
  commentAddedMessage = 'Comment Added Successfully!',
  commentUpdatedMessage = 'Comment Updated Successfully!',
  projectNameAlreadyExistMessage = 'Project name already exists!',
  companyNameAlreadyExistMessage = 'Company name already exists!',
  taskListIdMessage = 'Please Select Tasklist!',
  userSelection = 'Please select user',
  taskCompletedErrorMessage = 'Sorry! All the Sub task  are not completed.',
  commentDelete = 'Comment deleted',
  noTaskStatusListAvailableMessage = 'No task status list available.',
}

export enum NotificationMessage {
  deleteProjectSuccessMsg = 'Project Deleted Successfully!',
  deleteCategorySuccessMsg = 'Category Deleted Successfully!',
  deleteFileSuccessMsg = 'File Deleted Successfully!',
  projectAddedMessage = 'Project Saved Successfully!',
  projectUpdatedMessage = 'Project Updated Successfully!',
  timelogAddedMessage = 'Timelog Added successfully!',
  timelogUpdatedMessage = 'Timelog Updated successfully!',
  deleteTimelogtSuccessMsg = 'Timelog Delete successfully!',
  saveFileSuccessMsg = 'File Saved successfully!',
  downloadFileSuccessMsg = 'File download successfully!',
  deleteTaskSuccessMsg = 'Task Delete successfully!',
  updateTaskSuccessMsg = 'Task Updated successfully!',
  deleteCompanySuccessMsg = 'Company Deleted Successfully!',
  saveProductSuccessMsg = 'Product Saved Successfully!',
  deleteProductSuccessMsg = 'Product Deleted Successfully!',
  updateProductSuccessMsg = 'Product Updated successfully!',
  deletePortfolioSuccessMsg = 'Portfolio Deleted Successfully!',
  taskNotCompleMessage = 'Please complete all subtask to move it to complete state!',
  exportSuccessMsg = 'Timesheet exported successfully!',
  statusSuccessMsg = 'Status Added successfully',
  statusUpdateSuccessMsg = 'Status Update successfully',
  deleteStatusSuccessMsg = 'Status Deleted Successfully!',
  deleteTaskListSuccessMsg = 'Task list Deleted Successfully!',
  saveEstimationSuccessMsg = 'Estimation hours added successfully!',
  updateEstimationSuccessMsg = 'Estimation hours updated successfully!',
  deleteSubtaskSuccessMsg = 'Subtask Deleted Successfully!',
  startAndDueDateValidation = 'Due date must be on or after the start date.',
  estimateCanNotZero = 'Estimation cannot be zero.',
}
