<div class="grid-wrapper">
  <kendo-grid
    [data]="subTaskList$ | async"
    (edit)="onEditSubTask($event)"
    (cancel)="onCancelSubTask($event)"
    (save)="onSaveSubTask($event)"
    (add)="onAddSubTask($event)"
    (remove)="onRemoveSubTask($event)"
    class="add-sub-task-grid"
  >
    <ng-template kendoGridToolbarTemplate [position]="'bottom'">
      <a
        kendoGridAddCommand
        class="add-sub-task"
        [ngClass]="{ 'mt-10 pl-10': (subTaskList$ | async)?.length > 0 }"
        ><i class="far fa-plus pr-5"></i>Add new SubTask</a
      >
    </ng-template>
    <ng-template kendoGridNoRecordsTemplate>
      <div class="display-none"></div>
    </ng-template>
    <kendo-grid-column field="name">
      <ng-template kendoGridHeaderTemplate></ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="d-flex gap-10 align-items-center">
          <input
            [disabled]="checkIsSubDisable(dataItem)"
            [checked]="dataItem.taskStatus === taskStatusType.Completed"
            type="checkbox"
            kendoCheckBox
            class="billable-checkbox mr-5"
            size="large"
            (change)="onSubTaskCompleteChange(dataItem, rowIndex)"
          />
          {{ dataItem.name }}
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <form class="k-form k-form-md" [formGroup]="formGroup">
          <fieldset class="k-form-fieldset d-flex gap-5">
            <kendo-textbox
              [formControl]="formGroup.get('name')"
              class="k-input-inner-text-box"
              placeholder="Enter Task Name for SubTask"
            ></kendo-textbox>
          </fieldset>
        </form>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column [width]="110">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
        <ul class="grid-action-list grid-command-action">
          <div class="d-flex gap-5 justify-content-end">
            <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
              <a
                title="Save"
                class="grid-command-action-links"
                kendoGridSaveCommand
                [disabled]="formGroup?.invalid"
              >
                <i class="far fa-floppy-disk"></i>
              </a>
            </li>
            <li>
              <a title="Discard change" class="grid-command-action-links" kendoGridCancelCommand>
                <i class="far fa-ban"></i>
              </a>
            </li>
            <li>
              <a title="Edit" class="grid-command-action-links" kendoGridEditCommand *ngIf="!isNew">
                <i class="far fa-pen"></i>
              </a>
            </li>
            <li>
              <a
                title="Delete"
                class="grid-command-action-links"
                kendoGridRemoveCommand
                *ngIf="!isNew"
              >
                <i class="far fa-trash-alt"></i>
              </a>
            </li>
            <!-- <li *ngIf="!isNew && dataItem.id !== 0" (click)="getSubTaskDataById(dataItem)">
              <a class="grid-command-action-links" title="More Action">
                <i class="far fa-circle-ellipsis"></i
              ></a>
            </li> -->
          </div>
        </ul>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
