import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CreateUpdateTimelogDto,
  TimeEntryDataDto,
  TimelogDto,
  TimelogGetListInput,
} from '../dtos/models';
import type { IActionResult } from '../../microsoft/asp-net-core/mvc/models';

@Injectable({
  providedIn: 'root',
})
export class TimelogService {
  apiName = 'TaskService';

  create = (input: CreateUpdateTimelogDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TimelogDto>(
      {
        method: 'POST',
        url: '/api/timelog',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/timelog/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  exportTimeEntryFile = (input: TimelogGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IActionResult>(
      {
        method: 'POST',
        url: '/api/timelog/exportentries',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TimelogDto>(
      {
        method: 'GET',
        url: `/api/timelog/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: TimelogGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<TimelogDto>>(
      {
        method: 'GET',
        url: '/api/timelog',
        params: {
          userId: input.userId,
          taskId: input.taskId,
          projectId: input.projectId,
          timelogDatetime: input.timelogDatetime,
          timelogMinutes: input.timelogMinutes,
          timelogDescription: input.timelogDescription,
          timelogIsBillable: input.timelogIsBillable,
          timelogHasStartTime: input.timelogHasStartTime,
          taskIdPreMove: input.taskIdPreMove,
          deskTicketId: input.deskTicketId,
          timelogInvoiceNumber: input.timelogInvoiceNumber,
          projectBillingInvoiceId: input.projectBillingInvoiceId,
          timelogFinalCost: input.timelogFinalCost,
          startDate: input.startDate,
          endDate: input.endDate,
          isPagination: input.isPagination,
          format: input.format,
          userIds: input.userIds,
          billable: input.billable,
          projectIds: input.projectIds,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getTimeEntryList = (input: TimelogGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TimeEntryDataDto>(
      {
        method: 'POST',
        url: '/api/timelog/entries',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateTimelogDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TimelogDto>(
      {
        method: 'PUT',
        url: `/api/timelog/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
