<div class="q-content">
  <div class="q-content-bg pb-0">
    <div>
      <div class="border-bottom">
        <div
          class="font-size-18 fw-500 mb-20 d-flex gap-15 align-items-center justify-content-between"
        >
          <div class="d-flex gap-10 align-items-center">
            <span class="task-icon"><i class="far fa-clipboard-list font-size-14"></i></span
            ><span>Task Details</span>
          </div>
          <div>
            <span class="d-flex gap-15 justify-content-end text-primary">
              <a
                href="javascript:void(0)"
                (click)="panelId || tabId ? redirectToTaskList() : back()"
                ><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a
              ></span
            >
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex task-details-height">
          <div>
            <div class="pt-2 left-side-details border-right">
              <form [formGroup]="taskForm" validateOnSubmit class="k-form k-form-md">
                <div class="mb-2">
                  <kendo-label
                    text="Task List"
                    class="fw-500 mb-5 display-inline"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div>
                    <a
                      (click)="redirectToTaskList($event)"
                      [routerLink]="['/task-list']"
                      [queryParams]="getQueryParams()"
                      [target]="openInNewTab ? '_blank' : ''"
                      class="text-primary word-break"
                      >{{ taskDetails?.taskListName }}</a
                    >
                  </div>
                </div>
                <div class="pb-2">
                  <kendo-label
                    text="Assigned To"
                    class="fw-500 mb-10 display-inline mr-5"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div
                    *ngIf="!isShowAssigneeMultiSelect"
                    (click)="isShowAssigneeMultiSelect = true"
                  >
                    <div
                      *ngIf="taskDetails?.users?.length === 0; else assigneeTemplate"
                      class="d-flex align-items-center"
                    >
                      <i class="fal fa-circle-user mr-10 font-size-22"></i>
                    </div>
                    <ng-template #assigneeTemplate>
                      <ng-container *ngIf="taskDetails?.users && taskDetails?.users?.length > 0">
                        <ng-container *ngFor="let user of taskDetails?.users">
                          <kendo-avatar
                            rounded="full"
                            size="large"
                            [border]="true"
                            [initials]="commonService.getInitialsAndAvtarColor(user).avatar"
                            [style.backgroundColor]="
                              commonService.getInitialsAndAvtarColor(user).generateAvtarColor
                            "
                            class="social-profile-text assignee-container"
                            [attr.title]="commonService.getInitialsAndAvtarColor(user).userName"
                            class="social-profile-text assignee-profile"
                          ></kendo-avatar>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </div>
                  <kendo-multiselect
                    *ngIf="isShowAssigneeMultiSelect"
                    [clearButton]="false"
                    [checkboxes]="true"
                    [data]="assigneeList"
                    valueField="userId"
                    textField="userName"
                    formControlName="assignee"
                    [ngModelOptions]="{ standalone: true }"
                    (close)="isShowAssigneeMultiSelect = false"
                    (valueChange)="onSave()"
                    [filterable]="true"
                    (filterChange)="onAssigneeSearch($event)"
                  ></kendo-multiselect>
                </div>

                <div class="pb-2">
                  <kendo-label
                    text="Start Date"
                    class="fw-500 mb-5 display-inline"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div>
                    <p>
                      {{
                        taskDetails?.isStartDate
                          ? (taskDetails?.startDate | date: 'EEE, d MMM yyyy')
                          : '-'
                      }}
                    </p>
                  </div>
                </div>
                <div class="pb-2">
                  <kendo-label
                    text="Due Date"
                    class="fw-500 mb-5 display-inline"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div *ngIf="!isShowDueDateInput" (click)="isShowDueDateInput = true">
                    <div
                      *ngIf="
                        this.taskForm.controls.dueDate?.value === '' ||
                          this.taskForm.controls.dueDate?.value === null;
                        else dueDate
                      "
                    >
                      <i class="fal fa-calendar-circle-plus font-size-18"></i>
                    </div>
                    <ng-template #dueDate>
                      <div>
                        {{ this.taskForm.controls.dueDate?.value | date: 'EEE, d MMM yyyy' }}
                      </div>
                    </ng-template>
                  </div>

                  <kendo-datepicker
                    *ngIf="isShowDueDateInput"
                    placeholder="Choose a date ..."
                    [format]="'EEE, d MMM yyyy'"
                    (close)="isShowDueDateInput = false"
                    (valueChange)="onSave()"
                    formControlName="dueDate"
                    calendarType="classic"
                  ></kendo-datepicker>
                </div>
              </form>
              <div class="pb-2">
                <kendo-label
                  text="Created By"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div class="mb-5 d-flex align-items-center">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(taskDetails?.creatorName)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(
                        taskDetails?.creatorId,
                        taskDetails?.creatorName
                      )
                    "
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar
                  ><span class="ml-5 word-break">{{ taskDetails?.creatorName }}</span>
                </div>
                <p>{{ taskDetails?.creationTime | date: 'EEE, d MMM yyyy' }}</p>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Updated By"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div
                  class="mb-5 d-flex align-items-center"
                  *ngIf="taskDetails?.lastModifierUserName"
                >
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(taskDetails?.lastModifierUserName)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(
                        taskDetails.creatorId,
                        taskDetails?.lastModifierUserName
                      )
                    "
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar>
                  <span class="ml-5 word-break">{{ taskDetails?.lastModifierUserName }}</span>
                </div>
                <ng-container *ngIf="taskDetails?.lastModifierUserName; else noUpdateBy">
                  <p>{{ taskDetails.lastModificationTime | date: 'EEE, d MMM yyyy' }}</p>
                </ng-container>
                <ng-template #noUpdateBy>
                  <p>-</p>
                </ng-template>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Task ID"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <p class="mb-0">{{ taskId }}</p>
              </div>
            </div>
          </div>

          <div class="right-side-details">
            <div>
              <div class="pt-2">
                <div class="primary-card mb-20">
                  <div class="d-flex justify-content-between gap-15 task-details-section">
                    <div class="d-flex gap-10 align-items-start">
                      <input
                        type="checkbox"
                        kendoCheckBox
                        class="billable-checkbox"
                        size="large"
                        (change)="onTaskCompleteChange($event)"
                        [checked]="(taskStatus$ | async) === taskStatusType.Completed"
                        #notification
                        kendoCheckBox
                      />
                      <div>
                        <div class="d-flex align-items-start mb-15">
                          <ng-container
                            *ngIf="taskDetails?.users && taskDetails?.users?.length > 0"
                          >
                            <ng-container *ngFor="let user of taskDetails?.users">
                              <kendo-avatar
                                rounded="full"
                                size="large"
                                [border]="true"
                                [initials]="commonService.getInitials(user.userName)"
                                [style.backgroundColor]="
                                  commonService.generateAvtarColor(user.userId, user.userName)
                                "
                                [attr.title]="user.userName"
                                class="social-profile-text assignee-profile"
                              ></kendo-avatar>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="taskDetails?.users?.length < 0">
                            <i class="fal fa-circle-user font-size-24"></i>
                          </ng-container>
                          <span
                            class="text-primary display-inline line-height-normal task-name ml-10"
                            title="{{ taskDetails?.name }}"
                            >{{ taskDetails?.name }}</span
                          >
                        </div>
                        <div class="d-flex flex-wrap gap-15">
                          <div class="d-flex align-items-center flex-wrap">
                            <span class="font-size-12 mr-5"
                              >{{ taskDetails?.startDate | date: 'd-MMM-yyyy' }} -
                            </span>
                            <span class="font-size-12 due-date ml-5">
                              {{ taskDetails?.dueDate | date: 'd-MMM-yyyy' }}
                            </span>
                          </div>
                          <div class="d-flex flex-wrap gap-10">
                            <span class="q-label font-size-12"
                              ><i class="far fa-stopwatch mr-5"></i> Est:
                              {{
                                commonService.getHoursAndMinutesFromTotalMinutes(
                                  taskDetails?.estimateMinutes
                                )
                              }}</span
                            >
                            <span class="q-label font-size-12"
                              ><i class="far fa-clock mr-5"></i> Log:
                              {{
                                commonService.getHoursAndMinutesFromTotalMinutes(
                                  totalLoggedTime$ | async
                                )
                              }}</span
                            >

                            <span class="q-label font-size-12">{{
                              commonService.getTaskStatusName(taskStatus$ | async)
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex gap-15 time-manage-button">
                      <button kendoButton (click)="openTimeLog(taskDetails?.name)">
                        <i class="fa-regular fa-clock font-size-14 mr-5"></i> Log time
                      </button>
                      <button
                        kendoButton
                        (click)="onStartTimerClick()"
                        fillMode="outline"
                        [disabled]="isTimerRunning"
                      >
                        <i class="far fa-stopwatch font-size-14 mr-5"></i> Start timer
                      </button>
                      <kendo-dropdownbutton
                        [data]="timerActionList"
                        #additionalSetting
                        (itemClick)="onActionItemClicked($event)"
                        ><i class="fas fa-ellipsis-v"></i>
                      </kendo-dropdownbutton>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pb-1 mb-20" *ngIf="taskDetails?.description">
                <div class="description-section">
                  <span [innerHTML]="safeDescription"></span>
                </div>
              </div>

              <div class="pb-1 mb-20 border-bottom">
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-5"
                  text="Sub Tasks"
                ></kendo-label>
                <app-sub-tasks
                  [taskId]="taskId"
                  [sortingName]="sortingName"
                  [taskListId]="taskListId"
                  [loadStateData]="false"
                ></app-sub-tasks>
              </div>

              <div class="pb-1 mb-20 border-bottom">
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-5"
                  text="Files"
                ></kendo-label>
                <app-files
                  [taskId]="taskId"
                  [taskName]="taskDetails?.name"
                  [loadStateData]="false"
                ></app-files>
              </div>
              <div class="pb-1 mb-20 border-bottom">
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-5"
                  text="Time Logs"
                ></kendo-label>
                <app-time-log-grid
                  [taskId]="taskId"
                  [projectId]="projectId"
                  [isProjectBillable]="isProjectBillable"
                  [taskName]="taskDetails?.name"
                  [loadStateData]="false"
                ></app-time-log-grid>
              </div>
              <div class="mb-20">
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-20"
                  text="Comments"
                ></kendo-label>

                <div
                  *ngFor="let comment of commentList; let last = last"
                  class="list-of-comments-container mb-20"
                  [ngClass]="{ 'no-border': last }"
                  [hidden]="editingCommentId === comment.id"
                >
                  <div class="d-flex justify-content-between gap-10">
                    <div class="d-flex gap-10">
                      <kendo-avatar
                        rounded="full"
                        size="large"
                        [border]="true"
                        [initials]="commonService.getInitials(comment.creatorName)"
                        [style.backgroundColor]="
                          commonService.generateAvtarColor(comment.creatorId, comment.creatorName)
                        "
                        class="social-profile-text task-detail-avatar user-avatar"
                      ></kendo-avatar>
                      <div class="comment-metadata d-flex flex-column">
                        <span class="creator-name font-size-14 text-black">{{
                          comment.creatorName
                        }}</span>
                        <span class="comment-date">{{ formatDate(comment.creationTime) }}</span>
                      </div>
                    </div>
                    <div class="action-dropdown">
                      <button
                        *ngIf="comment.canEditOrDelete"
                        (click)="toggleDropdown(comment.id)"
                        class="dots-btn"
                      >
                        ⋮
                      </button>
                      <div *ngIf="activeCommentId === comment.id" class="dropdown-menu">
                        <a href="javascript:void(0)" (click)="editComment(comment.id)"> Edit </a>
                        <a href="javascript:void(0)" (click)="deleteComment(comment.id)">
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="comment-body">
                    <p [innerHTML]="getSanitizedHtml(comment.htmlBody)"></p>
                  </div>
                </div>

                <div class="comment-container d-flex align-items-start gap-10">
                  <div class="avatar-container">
                    <kendo-avatar
                      rounded="full"
                      size="large"
                      [border]="true"
                      [initials]="commonService.getInitials(currentUser)"
                      [style.backgroundColor]="
                        commonService.generateAvtarColor(currentUserId, currentUser)
                      "
                      class="social-profile-text task-detail-avatar"
                    ></kendo-avatar>
                  </div>

                  <div class="editor-container">
                    <textarea
                      *ngIf="!isEditorActive"
                      class="initial-textbox"
                      placeholder="Add a comment"
                      (focus)="activateEditor()"
                    ></textarea>

                    <ng-container *ngIf="isEditorActive">
                      <kendo-editor
                        class="mb-20 comment-kendo-editor"
                        [(ngModel)]="commentText"
                        placeholder="Add a comment"
                        [iframeCss]="{ content: customStyleContent }"
                        [tools]="customTools"
                      ></kendo-editor>

                      <div class="comment-actions d-flex justify-content-between mt-3">
                        <div class="notify-section d-flex align-items-center">
                          <span>Notify:</span>
                          <span class="notify-avatar">VU</span>
                          <button kendoButton icon="plus" class="add-button"></button>
                          <button kendoButton icon="attachment" class="attachment-button"></button>
                          <button kendoButton icon="lock" class="lock-button"></button>
                        </div>
                        <div class="action-buttons">
                          <button kendoButton (click)="cancelEdit()" fillMode="outline">
                            Cancel
                          </button>
                          <button kendoButton (click)="saveComment()">
                            {{ updateCommentId ? 'Update' : 'Save' }}
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div kendoDialogContainer></div>

<kendo-drawer-container class="add-more-details">
  <kendo-drawer
    [mode]="expandMode"
    [expanded]="isDrawerOpened"
    [position]="position"
    [autoCollapse]="false"
  >
    <ng-template kendoDrawerTemplate>
      <app-add-task-more-details
        [taskId]="taskId"
        [projectId]="projectId"
        [assigneListItems]="assigneListItems"
        [sortingName]="sorting"
        (reloadTaskList)="onTaskDrawerClose($event)"
        [loadStateData]="true"
      ></app-add-task-more-details>
    </ng-template>
  </kendo-drawer>
</kendo-drawer-container>
