import { ToasterService } from '@abp/ng.theme.shared';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ExportFormat } from 'projects/task-service/src/lib/proxy/service/enum';
import { TimelogGetListInput } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { TimeLogPopupComponent } from '../../time-log-popup/time-log-popup.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { TaskState } from 'src/app/core/store/task.state';
import { Store } from '@ngxs/store';
import { AddTimelogList, DeleteTimelogList, GetTimeLogList } from 'src/app/core/store/task.action';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-time-log-grid',
  templateUrl: './time-log-grid.component.html',
  styleUrls: ['./time-log-grid.component.scss'],
})
export class TimeLogGridComponent implements OnInit {
  @Input() taskId: any;
  @Input() projectId: any;
  @Input() isProjectBillable: boolean = false;
  @Input() taskName: string = '';
  @Input() loadStateData: any;

  timeLogList: any[] = [];
  sorting = null;
  sort: any;
  isLoading = true;

  startTime: Date;
  endTime: Date;

  maxResultCount: number = 5;
  skipCount: number = 0;
  timeLogTotal: number;

  timeLogList$: Observable<any[]>;
  totalLoggedTime$: Observable<any>;
  totalLoggedNonBillableTime$: Observable<any>;
  totalLoggedBillableTime$: Observable<any>;
  timeLogTotal$: Observable<any>;

  constructor(
    public commonService: CommonService,
    private timelogService: TimelogService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.timeLogList$ = this.store.select(TaskState.getTimelogList);
    this.timeLogTotal$ = this.store.select(TaskState.getTotalLoggedTime);
    this.totalLoggedTime$ = this.store.select(TaskState.getTotalLoggedTime);
    this.totalLoggedNonBillableTime$ = this.store.select(TaskState.getTotalLoggedNonBillableTime);
    this.totalLoggedBillableTime$ = this.store.select(TaskState.getTotalLoggedBillableTime);

    this.getAllTimeLogList();
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getAllTimeLogList();
  }

  onEdit(rowIndex, data): void {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });

    dialogRef.content.instance.timeLogId = data.id;
    dialogRef.content.instance.taskName = this.taskName;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.isProjectBillable = this.isProjectBillable;
    dialogRef.content.instance.userId = data.userId;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.store.dispatch(new GetTimeLogList(this.getParamter())).subscribe();
      }
    });
  }

  onDelete(data: any): void {
    const timelogDescription = data.timelogDescription ? data.timelogDescription : 'timelog';
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + `${timelogDescription}` + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.timelogService.delete(data.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteTimelogtSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.store.dispatch(new DeleteTimelogList(data));
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  openTimeLog(taskName) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });

    this.startTime = new Date();
    this.endTime = new Date();

    dialogRef.content.instance.startTime = this.startTime;
    dialogRef.content.instance.endTime = this.endTime;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = taskName;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.isProjectBillable = this.isProjectBillable;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.store.dispatch(new AddTimelogList(res.data)).subscribe();
      }
    });
  }

  getParamter(): TimelogGetListInput {
    const params: TimelogGetListInput = {
      userId: null,
      taskId: this.taskId,
      projectId: null,
      timelogDatetime: null,
      timelogMinutes: null,
      timelogDescription: null,
      timelogIsBillable: null,
      timelogHasStartTime: null,
      taskIdPreMove: null,
      deskTicketId: null,
      timelogInvoiceNumber: null,
      projectBillingInvoiceId: null,
      timelogFinalCost: null,
      maxResultCount: this.maxResultCount,
      skipCount: this.skipCount,
      sorting: this.sorting,
      format: ExportFormat.Excel,
      userIds: [],
      projectIds: [],
    };

    return params;
  }

  getAllTimeLogList(): void {
    if (this.loadStateData) {
      this.store.select(TaskState.getTimelogList).subscribe();
    } else {
      if (!this.isLoading) return;
      this.store.dispatch(new GetTimeLogList(this.getParamter())).subscribe(res => {
        if (res['task'].timeLogList.length > 0) {
          this.maxResultCount += this.maxResultCount;
          this.isLoading = res['task'].timeLogList.length < res['task'].timelogTotalCount;
        } else {
          this.isLoading = false;
        }
      });
    }
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement;

    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 1) {
      this.getAllTimeLogList();
      this.cdr.detectChanges();
    }
  }
}
