import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  DrawerMode,
  DrawerPosition,
  ExpansionPanelComponent,
} from '@progress/kendo-angular-layout';

import {
  AddEvent,
  CancelEvent,
  EditEvent,
  RemoveEvent,
  SaveEvent,
  TreeListComponent,
} from '@progress/kendo-angular-treelist';

import { TaskListService } from 'projects/task-service/src/lib/proxy/task-service/task-list';
import { TaskType } from 'src/app/enum/task-type';
import { AddTaskListComponent } from './add-task-list/add-task-list.component';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskListActionEnumEnum } from 'src/app/enum/tasklist-action-filter';
import { Observable, forkJoin, map } from 'rxjs';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { DatePipe } from '@angular/common';
import { ProjectUserService } from '@proxy/project-service/project';
import { CommonService } from 'src/core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NotificationTextMessage } from 'src/app/enum/notification';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { ToasterService } from '@abp/ng.theme.shared';
import { IdentityUserService } from '@abp/ng.identity/proxy';
import { AddTaskComponent } from './add-task/add-task.component';
import { ConfigStateService } from '@abp/ng.core';

export class TaskDateDto {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  taskList = [];
  assigneeList: any[] = [];
  statusList: Array<string> = ['All status', 'abc'];

  formGroup: FormGroup;
  dueDateDefaultValue: Date;
  priorityEnum = Priority;

  editedItem: any;
  projectId: any;
  taskId: any;
  currentExpandedPanelId: any;
  projectName = '';
  searchText: string = '';
  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];
  public popupClass = 'task-type-dropdown';
  isDrawerOpened = false;
  public expandMode: DrawerMode = 'overlay';
  public position: DrawerPosition = 'end';

  //#region  expandedPanel
  taskListsList: any[] = [];
  @ViewChildren(ExpansionPanelComponent) panels: QueryList<ExpansionPanelComponent>;
  //#endregion

  priorityList: any[] = [
    {
      id: Priority.High,
      text: Priority[Priority.High],
    },
    {
      id: Priority.Low,
      text: Priority[Priority.Low],
    },
    {
      id: Priority.Medium,
      text: Priority[Priority.Medium],
    },
    {
      id: Priority.None,
      text: Priority[Priority.None],
    },
  ];

  taskTypeList = [
    {
      id: TaskType.Task,
      name: TaskType[TaskType.Task],
      iconClass: 'far fa-clipboard-list font-size-14',
    },
    {
      id: TaskType.Bug,
      name: TaskType[TaskType.Bug],
      iconClass: 'far fa-bug font-size-14 bug-icon-color',
    },
  ];

  taksListActionItem = [
    {
      text: TaskListActionEnumEnum[TaskListActionEnumEnum.Edit],
      iconClass: 'far fa-pen',
    },
    {
      text: TaskListActionEnumEnum[TaskListActionEnumEnum.Delete],
      iconClass: 'far fa-trash-alt',
    },
  ];
  taskStatusType = TaskStatusType;
  isNoRecordFound = false;
  public sorting = null;
  isAdmin: boolean = false;
  isShowCompleteTask: boolean = false;
  completedTaskList: any = [];
  constructor(
    private _Activatedroute: ActivatedRoute,
    private taskListService: TaskListService,
    private taskService: TaskService,
    private dialogService: DialogService,
    private router: Router,
    public datepipe: DatePipe,
    private projectUser: ProjectUserService,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private toasterService: ToasterService,
    protected service: IdentityUserService,
    private config: ConfigStateService,
  ) {
    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    if (currentUserRole.includes('admin')) {
      this.isAdmin = true;
    }
  }

  ngOnInit(): void {
    this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.projectId = atob(params.get('projectId'));
        this.projectName = atob(params.get('projectName'));
        this.getAssigneeList();
        this.getTaskListList();
      }
    });
  }

  gotoTime(): void {
    var params = {
      projectId: btoa(this.projectId),
    };
    this.router.navigate(['time', params]);
  }

  gotoDashboard(): void {
    var params = {
      projectId: btoa(this.projectId),
      projectName: btoa(this.projectName),
    };
    this.router.navigate(['project-dashboard', params]);
  }

  getAssigneeList(): void {
    const params = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    };
    this.projectUser.getPeople(this.projectId, params).subscribe(res => {
      if (res.items.length > 0) {
        this.assigneeList = res.items;
      }
    });
  }

  onSearchTextChange(searchText: string): void {
    if (!searchText.trim()) {
      this.getTaskListList();
    }
  }

  getTaskListList(isDefaultExpanded = false): void {
    let newData = {
      projectId: this.projectId,
      showMilestones: true,
      getCompleteCount: true,
      getNewDefaultTask: true,
      sorting: null,
      skipCount: 0,
      maxResultCount: 1000,
      searchTerm: this.searchText,
    };
    this.spinnerService.show();
    this.taskListService.getList(newData).subscribe(res => {
      this.taskListsList = [];
      if (res.items.length > 0) {
        res.items.forEach(element => {
          const data = {
            id: element.id,
            name: element.name,
            totalNumberOfTask: element.totalNumberOfTask,
            completeCount: element.completeCount,
            totalTimelogged: this.commonService.getHoursAndMinutesFromTotalMinutes(
              element.totalTimelogged,
            ),
            totalEstimatedTime: this.commonService.getHoursAndMinutesFromTotalMinutes(
              element.totalEstimatedTime,
            ),
            expanded: this.currentExpandedPanelId === element.id ? isDefaultExpanded : false,
          };
          this.taskListsList.push(data);
        });
      }
      this.isNoRecordFound = res.items.length > 0 ? false : true;
      this.spinnerService.hide();
    });
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getTaskList(this.currentExpandedPanelId);
  }

  showCompletedTask(): void {
    this.isShowCompleteTask = !this.isShowCompleteTask;
    if (this.isShowCompleteTask) this.getTaskList(this.currentExpandedPanelId);
  }

  getTaskList(taskListId, isFromMarkCompleted: boolean = false): void {
    let param: any = {
      taskListId: taskListId,
      sorting: this.sorting,
      skipCount: 0,
      maxResultCount: 1000,
      getSubTasks: true,
      includeLoggedTime: true,
    };

    this.spinnerService.show();

    if (!this.taskList.length || isFromMarkCompleted) {
      param.completedOnly = false;
      this.taskService.getList(param).subscribe(({ items }) => {
        this.taskList = items.map(element => ({
          id: element.id,
          taskName: element.name,
          assignee: element.users,
          startDate: element.startdate,
          dueDate: element.duedate,
          priority: element.priority,
          comments: '1',
          taskId: null,
          hasChildren: !!element.subTaskIds,
          taskType: element.taskType,
          taskStatus: element.taskStatus,
          estimateMinutes: element.estimateMinutes,
          isAllSubTaskCompleted: element.isAllSubTaskCompleted,
          taskStatusText: element.taskStatusText,
        }));
      });
    }

    if (this.isShowCompleteTask || isFromMarkCompleted) {
      param.completedOnly = true;
      this.taskService.getList(param).subscribe(({ items }) => {
        this.completedTaskList = items.map(element => ({
          id: element.id,
          taskName: element.name,
          assignee: element.users,
          startDate: element.startdate,
          dueDate: element.duedate,
          priority: element.priority,
          comments: '1',
          taskId: null,
          hasChildren: !!element.subTaskIds,
          taskType: element.taskType,
          taskStatus: element.taskStatus,
          estimateMinutes: element.estimateMinutes,
          isAllSubTaskCompleted: element.isAllSubTaskCompleted,
          taskStatusText: element.taskStatusText,
        }));
      });
    }
    this.spinnerService.hide();
  }

  onTaskCompleteChange(data: any): void {
    const action =
      data.taskStatus !== TaskStatusType.Completed
        ? this.taskService.markTaskAsCompleteById(data.id)
        : this.taskService.markTaskAsReopenedById(data.id);

    action.subscribe(() => {
      this.getTaskListList(true);
      this.getTaskList(this.currentExpandedPanelId, true);
    });
  }

  fetchChildren = (item: any): Observable<any> => {
    return this.getSubtask(item.id);
  };

  hasChildren = (item: any): boolean => {
    return item.hasChildren;
  };

  getSubtask(id: any): Observable<any[]> {
    this.spinnerService.show();
    return this.taskService.getSubTasksByParentTaskIdAndSorting(id, this.sorting).pipe(
      map(x => {
        const subTaskData = x.items.map(element => ({
          id: element.id,
          taskName: element.name,
          assignee: element.users,
          dueDate: element.duedate,
          priority: element.priority,
          comments: '1',
          taskId: id,
          hasChildren: !!element.subTaskIds,
          taskType: element.taskType,
          taskStatus: element.taskStatus,
          estimateMinutes: element.estimateMinutes,
          isAllSubTaskCompleted: element.isAllSubTaskCompleted,
          taskStatusText: element.taskStatusText,
        }));
        this.spinnerService.hide();
        return subTaskData;
      }),
    );
  }

  getInitials(assigneeId: any): any {
    if (assigneeId) {
      return {
        avatar: this.commonService.getInitials(assigneeId.userName),
        generateAvtarColor: this.commonService.generateAvtarColor(
          assigneeId.userId,
          assigneeId.userName,
        ),
        userName: assigneeId.userName,
      };
    }
    return '';
  }

  checkIsDisable(dataItem: any): boolean {
    if (dataItem.hasChildren) {
      return !dataItem.isAllSubTaskCompleted;
    }
    return false;
  }

  addTaskHandler({ sender, parent }: AddEvent): void {
    this.closeEditor(sender);
    if (parent) {
      sender.expand(parent);
    }

    this.formGroup = new FormGroup({
      taskId: new FormControl(parent ? parent.id : null),
      taskName: new FormControl('', [Validators.required, Validators.maxLength(5000)]),
      assignee: new FormControl(''),
      dueDate: new FormControl(''),
      priority: new FormControl(''),
      taskType: new FormControl(this.taskTypeList[0].id),
      comments: new FormControl(''),
      estimateHour: new FormControl(''),
      estimateMinut: new FormControl(''),
      taskStatus: new FormControl(''),
    });
    sender.addRow(this.formGroup, parent);
  }

  editTaskHandler({ sender, dataItem }: EditEvent): void {
    this.closeEditor(sender, dataItem);

    const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(
      dataItem.estimateMinutes,
    );

    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      taskId: new FormControl(dataItem.taskId),
      taskName: new FormControl(dataItem.taskName, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      assignee: new FormControl(dataItem.assignee),
      dueDate: new FormControl(dataItem.dueDate ? new Date(dataItem.dueDate) : ''),
      priority: new FormControl(dataItem.priority),
      taskType: new FormControl(dataItem.taskType),
      comments: new FormControl(dataItem.comments),
      estimateHour: new FormControl(hours),
      estimateMinut: new FormControl(minutes),
      taskStatus: new FormControl(dataItem.taskStatus),
    });
    sender.editRow(dataItem, this.formGroup);
  }

  cancelTaskHandler({ sender, dataItem, isNew }: CancelEvent): void {
    this.closeEditor(sender, dataItem, isNew);
  }

  saveTaskHandler({ sender, dataItem, parent, formGroup, isNew }: SaveEvent, id: any): void {
    if (formGroup.controls.taskName.invalid) {
      formGroup.controls.taskName.markAsTouched();
      return;
    }
    this.spinnerService.show();
    const newTaskData: any = formGroup.value;
    let hasChildren: boolean = false;
    if (!isNew) {
      Object.assign(dataItem, newTaskData);
    } else if (parent) {
      parent.hasChildren = true;
      hasChildren = true;
    }
    newTaskData.estimateHour = newTaskData.estimateHour === '' ? 0 : newTaskData.estimateHour;
    newTaskData.estimateMinut = newTaskData.estimateMinut === '' ? 0 : newTaskData.estimateMinut;

    const estimateTime = `${newTaskData.estimateHour}:${newTaskData.estimateMinut}`;

    const estimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes(estimateTime);
    const taskListId = this.taskListsList.filter(x => x.id === id)[0].id;
    const dueDate = new Date(newTaskData.dueDate);
    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: isNew ? 0 : newTaskData.id,
      name: newTaskData.taskName,
      description: '',
      progress: 20,
      taskListId: taskListId,
      startdate:
        newTaskData.dueDate === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      duedate:
        newTaskData.dueDate === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: +newTaskData.priority,
      estimateMinutes: estimateMinutes,
      parentTaskId: newTaskData.taskId,
      notify: true,
      assignedToUserIds:
        newTaskData.assignee.length === 0 ? [] : newTaskData.assignee.map(item => item.userId),
      taskType: newTaskData.taskType,
      taskStatus: isNew ? TaskStatusType.New : newTaskData.taskStatus,
      subTasks: [],
    };
    if (isNew) {
      this.taskService.create(param).subscribe(
        res => {
          this.spinnerService.hide();
          this.toasterService.success(
            NotificationTextMessage.taskAddedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.getTaskListList(true);
          this.getTaskList(res.taskListId);
          sender.reload(parent);
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.taskService.update(newTaskData.id, param).subscribe(
        res => {
          this.spinnerService.hide();
          this.toasterService.success(
            NotificationTextMessage.taskUpdatedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.getTaskListList(true);
          this.getTaskList(res.taskListId);
          sender.reload(parent);
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }

    sender.closeRow(dataItem, isNew);
  }

  removeTaskHandler({ sender, dataItem, parent }: RemoveEvent): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + dataItem.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();

        this.taskService.delete(dataItem.id).subscribe(res => {
          this.toasterService.success(
            NotificationTextMessage.taskRemoveMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.spinnerService.hide();
          this.getTaskListList(true);
          this.getTaskList(this.currentExpandedPanelId);
          sender.reload(parent);
        });
      }
    });
  }

  private closeEditor(
    treelist: TreeListComponent,
    dataItem: any = this.editedItem,
    isNew = false,
  ): void {
    treelist.closeRow(dataItem, isNew);
    this.editedItem = undefined;
    this.formGroup = undefined;
  }

  onAddTask(data?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddTaskComponent,
      width: 450,
    });
    const addTaskListInfo = dialogRef.content.instance as AddTaskListComponent;
    addTaskListInfo.taskListId = data.id;
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getTaskListList();
        this.getTaskList(this.currentExpandedPanelId);
      }
    });
  }

  onAddTaskList(tasklistId?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddTaskListComponent,
      width: 450,
    });

    dialogRef.content.instance.projectId = this.projectId;
    const addTaskListInfo = dialogRef.content.instance as AddTaskListComponent;
    addTaskListInfo.taskListId = tasklistId;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getTaskListList();
      }
    });
  }

  onGoToTaskDetailsClick(data, taskListId): void {
    var params = {
      taskId: btoa(data.id),
      projectId: btoa(this.projectId),
      projectName: btoa(this.projectName),
      taskListId: btoa(taskListId),
    };

    this.router.navigate(['task-Detail', params]);
  }

  onTaskListsListExpandedPanelClick(index: number, item: any): void {
    this.taskList = [];
    if (this.currentExpandedPanelId !== item.id) {
      const lastSelectedItem = this.taskListsList.find(x => x.id === this.currentExpandedPanelId);
      if (lastSelectedItem) {
        lastSelectedItem.expanded = false;
      }
    }
    this.currentExpandedPanelId = item.id;
    item.expanded = !item.expanded;
    this.panels.forEach((panel, idx) => {
      if (idx !== index && panel.expanded) {
        panel.toggle();
      }
    });
    if (item.expanded) {
      this.isShowCompleteTask = false;
      this.getTaskList(item.id);
    }
  }

  ontaskListAxctionClick(data, tasklistId, taskListName) {
    const action = data.text;

    switch (action) {
      case TaskListActionEnumEnum[TaskListActionEnumEnum.Edit]:
        this.onAddTaskList(tasklistId);
        break;
      case TaskListActionEnumEnum[TaskListActionEnumEnum.Delete]:
        this.onDeleteTaskList(tasklistId, taskListName);
        break;
    }
  }

  onDeleteTaskList(taskListId, taskListName) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + taskListName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();

        this.taskListService.delete(taskListId).subscribe(res => {
          this.spinnerService.hide();
          this.getTaskListList();
        });
      }
    });
  }

  onTaskTypeClick(event, data, taskListId) {
    this.spinnerService.show();
    const dueDate = new Date(data.dueDate);
    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: data.id,
      name: data.taskName,
      description: '',
      progress: 20,
      taskListId: taskListId,
      startdate:
        data.dueDate === null
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      duedate:
        data.dueDate === null
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: data.priority,
      estimateMinutes: data.estimateMinutes,
      parentTaskId: data.taskId,
      notify: true,
      assignedToUserIds: data.assignee === null ? [] : data.assignee.map(item => item.userId),
      taskType: event.id,
      taskStatus: data.taskStatus,
      subTasks: [],
    };
    this.taskService.update(data.id, param).subscribe(res => {
      this.spinnerService.hide();
      this.getTaskList(res.taskListId);
    });
  }

  getIconClass(taskType: number): string {
    return this.taskTypeList.find(x => x.id === taskType).iconClass;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  addMoreDetails(data: any): void {
    this.isDrawerOpened = !this.isDrawerOpened;
    this.taskId = data.id;
    this.projectId = this.projectId;
    this.assigneeList = this.assigneeList;
    document.body.style.overflow = 'hidden';
  }

  checkIsToggle(event) {
    if (event) {
      this.getTaskListList(true);
      this.getTaskList(this.currentExpandedPanelId, true);
    }
    this.isDrawerOpened = !this.isDrawerOpened;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
}
