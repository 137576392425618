<kendo-dialog-titlebar (close)="onCloseDialog()">
  <div>
    {{
      taskListId !== null && taskListId !== undefined
        ? 'Update Task List'
        : 'Create a new Task List'
    }}
  </div>
</kendo-dialog-titlebar>
<div>
  <form class="k-form k-form-md" [formGroup]="formGroup" validateOnSubmit>
    <fieldset class="k-form-fieldset">
      <kendo-formfield class="mb-15">
        <kendo-label [for]="listName" text="Task List Name" labelCssClass="k-form-label"
          ><span class="required">*</span></kendo-label
        >
        <kendo-textbox
          formControlName="listName"
          [title]="formGroup.get('listName')?.value"
          class="k-input-inner-text-box"
          aria-placeholder="Give your project a name"
          #listName
        ></kendo-textbox>
        <kendo-formerror *ngIf="formGroup.controls.listName.errors?.maxlength"
          >Error: List Name cannot be more than 255 character!</kendo-formerror
        >
        <kendo-formerror *ngIf="formGroup.controls.listName.errors?.required"
          >Error: List Name is required!</kendo-formerror
        >
        <kendo-formerror *ngIf="formGroup.controls.listName.errors?.whitespace">
          Error: List Name cannot be empty or contain only spaces!
        </kendo-formerror>
      </kendo-formfield>
    </fieldset>
    <div>
      <kendo-tabstrip>
        <kendo-tabstrip-tab title="Description" [selected]="true">
          <ng-template kendoTabContent class="d-flex">
            <kendo-formfield class="mt-0">
              <kendo-textarea
                #notes
                formControlName="notes"
                resizable="vertical"
                placeholder="Add a description (optional)"
                [rows]="5"
              ></kendo-textarea>
              <kendo-formerror *ngIf="formGroup.controls.notes.errors?.maxlength"
                >Error: Notes cannot be more than 5000 character!</kendo-formerror
              >
            </kendo-formfield>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Default" class="w-100" *ngIf="false">
          <ng-template kendoTabContent class="d-flex">
            <div class="content">
              <div class="my-2">
                <input type="checkbox" formControlName="isPinned" #notification kendoCheckBox />
                <kendo-label
                  class="k-checkbox-label"
                  [for]="notification"
                  text="Pin in this task list"
                ></kendo-label>
                <button
                  kendoButton
                  [svgIcon]="commonService.infoSVG"
                  fillMode="flat"
                  title="A Pinned task will never close"
                ></button>
              </div>
              <div class="my-2">
                <kendo-label text="Time" labelCssClass="k-form-label"
                  ><button
                    kendoButton
                    [svgIcon]="commonService.infoSVG"
                    fillMode="flat"
                    title="Choose the default settin for the time logged in this task list.this can be overridden when logged in time."
                  ></button
                ></kendo-label>
                <input
                  type="radio"
                  name="isDefault"
                  [value]="true"
                  #male
                  formControlName="isDefault"
                  kendoRadioButton
                />
                <kendo-label
                  [for]="male"
                  text="Use project setting: Billable"
                  labelCssClass="k-form-label"
                ></kendo-label>

                <input
                  type="radio"
                  name="isDefault"
                  [value]="false"
                  #female
                  formControlName="isDefault"
                  kendoRadioButton
                />
                <kendo-label
                  [for]="female"
                  text="Set default for task list"
                  labelCssClass="k-form-label"
                ></kendo-label>

                <div *ngIf="!formGroup.controls.isDefault.value">
                  <input
                    type="radio"
                    name="setDefault"
                    [value]="true"
                    #maleDefault
                    formControlName="setDefault"
                    kendoRadioButton
                  />
                  <kendo-label
                    [for]="maleDefault"
                    text="Billable"
                    labelCssClass="k-form-label"
                  ></kendo-label>

                  <input
                    type="radio"
                    name="setDefault"
                    [value]="false"
                    #femaleDefault
                    formControlName="setDefault"
                    kendoRadioButton
                  />
                  <kendo-label
                    [for]="femaleDefault"
                    text="Non-billable"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                </div>
              </div>
              <div class="my-2">
                <div class="my-2">
                  <kendo-label
                    text="Defaults for new tasks"
                    style="font-weight: bold"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                </div>
                <div class="my-2">
                  <kendo-label
                    text="Tasks created on this list will use the following default values"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                </div>
                <div class="my-2">
                  <kendo-switch formControlName="istoggle"></kendo-switch>
                  <kendo-label
                    text="Apply these defaults to existing tasks in the list?"
                    labelCssClass="k-form-label"
                  >
                  </kendo-label>
                </div>
                <div class="my-3">
                  <kendo-editor formControlName="defaultNotes" style="width: 700px"></kendo-editor>
                </div>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Assigned To" class="text" labelCssClass="k-form-label">
                </kendo-label>
                <kendo-dropdownlist
                  class="k-input-inner-text-box"
                  style="width: 200px"
                  formControlName="assignedTo"
                  [data]="templateList"
                  [textField]="'name'"
                  [valueField]="'id'"
                ></kendo-dropdownlist>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Start Date" labelCssClass="k-form-label"> </kendo-label>
                <kendo-dropdownlist
                  class="k-input-inner-text-box"
                  style="width: 200px"
                  [data]="dropdownData"
                  formControlName="startDate"
                  [allowCustom]="true"
                  [textField]="'label'"
                  [valueField]="'id'"
                >
                </kendo-dropdownlist>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Due Date" labelCssClass="k-form-label"> </kendo-label>
                <kendo-dropdownlist
                  class="k-input-inner-text-box"
                  style="width: 200px"
                  [data]="dropdownData"
                  formControlName="dueDate"
                  [allowCustom]="true"
                  [textField]="'label'"
                  [valueField]="'id'"
                >
                </kendo-dropdownlist>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Tags" labelCssClass="k-form-label"> </kendo-label>
                <div class="d-flex">
                  <span
                    class="buttonanchor mx-2"
                    style="cursor: pointer; font-weight: bold"
                    (click)="openTags(existingTagList)"
                    [svgIcon]="commonService.tagIcon"
                  >
                    Add tags
                  </span>
                  <div *ngFor="let item of existingTagList; let i = index">
                    {{ item.name }}
                    <span
                      class="k-icon k-font-icon k-i-close"
                      (click)="removeTag(existingTagList, i)"
                      >X</span
                    >
                  </div>
                </div>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Priority" labelCssClass="k-form-label"> </kendo-label>
                <kendo-dropdownlist
                  class="k-input-inner-text-box"
                  style="width: 200px"
                  [data]="priorityData"
                  formControlName="priority"
                  [allowCustom]="true"
                  [textField]="'label'"
                  [valueField]="'id'"
                >
                </kendo-dropdownlist>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Board Column" labelCssClass="k-form-label"> </kendo-label>
                <kendo-dropdownlist
                  class="k-input-inner-text-box"
                  style="width: 200px"
                  [data]="boardData"
                  formControlName="boardColumn"
                  [allowCustom]="true"
                  [textField]="'label'"
                  [valueField]="'id'"
                >
                </kendo-dropdownlist>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Follwers" labelCssClass="k-form-label"> </kendo-label>
                <div class="d-flex">
                  <span
                    class="buttonanchor mx-2"
                    style="cursor: pointer; font-weight: bold"
                    (click)="openSetPrivacy(popupEnum.Follower)"
                    [svgIcon]="commonService.tagIcon"
                  >
                    Add followers
                  </span>
                  <div *ngFor="let item of followerList; let i = index">
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Privacy" labelCssClass="k-form-label"> </kendo-label>
                <div class="d-flex">
                  <span
                    class="buttonanchor mx-2"
                    style="cursor: pointer; font-weight: bold"
                    (click)="openSetPrivacy(popupEnum.Privacy)"
                    [svgIcon]="commonService.tagIcon"
                  >
                    Add Privacy
                  </span>
                  <div *ngFor="let item of privacyList; let i = index">
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="my-2 d-flex justify-content-between" style="width: 50%">
                <kendo-label text="Estimated time" labelCssClass="k-form-label"> </kendo-label>

                <kendo-timepicker
                  class="k-input-inner-text-box"
                  formControlName="estimatedtime"
                ></kendo-timepicker>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </form>
</div>

<kendo-dialog-actions>
  <button kendoButton class="k-button-solid-base" (click)="onCloseDialog()" fillMode="outline">
    Cancel
  </button>
  <div [ngClass]="{ 'curser-not-allowd': !formGroup.valid }" class="flex-0">
    <button
      kendoButton
      (click)="onAddTaskListClick()"
      themeColor="primary"
      [disabled]="!formGroup.valid"
      class="k-button-solid-base"
    >
      {{ taskListId !== null && taskListId !== undefined ? 'Update Task List' : 'Add Task List' }}
    </button>
  </div>
</kendo-dialog-actions>
