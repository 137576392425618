<kendo-dialog title="Edit Task" (close)="onCloseDialog()" [width]="450">
  <form [formGroup]="taskForm" validateOnSubmit class="k-form k-form-md">
    <fieldset class="k-form-fieldset mb-20">
      <kendo-label text="Task / Bug Name" labelCssClass="k-form-label" class="d-flex"
        ><span class="required">*</span></kendo-label
      >
      <div class="d-flex gap-5">
        <kendo-dropdownlist
          [data]="taskTypeList"
          textField="name"
          valueField="id"
          [valuePrimitive]="true"
          formControlName="taskType"
          class="w-200"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <i class="{{ dataItem.iconClass }}"></i>{{ dataItem.name }}
          </ng-template>
        </kendo-dropdownlist>
        <kendo-textbox
          formControlName="taskName"
          class="k-input-inner-text-box"
          aria-placeholder="Give your project a name"
          #listName
        ></kendo-textbox>
      </div>
    </fieldset>
    <div class="mb-20">
      <kendo-label text="Description" labelCssClass="k-form-label"></kendo-label>
      <kendo-editor
        style="height: 150px"
        class="mb-20 kendo-editor"
        placeholder="| Add a description"
        [iframeCss]="{ content: customStyleContent }"
        formControlName="description"
      ></kendo-editor>
    </div>
    <fieldset class="k-form-fieldset mb-20">
      <kendo-label text="Assignee" labelCssClass="k-form-label"></kendo-label>
      <kendo-multiselect
        [clearButton]="false"
        [checkboxes]="true"
        [data]="assigneeList"
        valueField="userId"
        textField="userName"
        formControlName="assignee"
      ></kendo-multiselect>
    </fieldset>
    <fieldset class="k-form-fieldset mb-20">
      <kendo-label text="Due Date" labelCssClass="k-form-label"></kendo-label>
      <kendo-datepicker
        placeholder="Choose a date ..."
        [format]="'EEE, d MMM yyyy'"
        formControlName="dueDate"
        [value]="dueDateDefaultValue"
        calendarType="classic"
      ></kendo-datepicker>
    </fieldset>
    <fieldset class="k-form-fieldset mb-20">
      <kendo-label text="Priority" labelCssClass="k-form-label"></kendo-label>
      <kendo-dropdownlist
        [data]="priorityList"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="priority"
      >
      </kendo-dropdownlist>
    </fieldset>
    <fieldset class="k-form-fieldset mb-20">
      <kendo-label text="Estimate Hours" labelCssClass="k-form-label"></kendo-label>
      <div class="d-flex gap-10 flex-wrap">
        <div class="d-flex">
          <kendo-textbox
            formControlName="estimateHour"
            [clearButton]="false"
            class="k-input-inner-text-box w-100"
            aria-placeholder="hour"
            #listName
            maxlength="5"
            data-field="estimateHour"
            (keydown)="onKeyDownForHour($event)"
            (paste)="onPaste($event)"
          ></kendo-textbox>
          <span class="m-auto ml-5">/h</span>
        </div>
        <div class="d-flex">
          <kendo-textbox
            formControlName="estimateMinute"
            [clearButton]="false"
            class="k-input-inner-text-box w-100"
            aria-placeholder="minuts"
            #listName
            data-field="estimateMinute"
            (keydown)="onKeyDownForMinutes($event)"
            (paste)="onPaste($event)"
          ></kendo-textbox>
          <span class="m-auto ml-5">/m</span>
        </div>
      </div>
    </fieldset>
    <fieldset class="k-form-fieldset mb-20">
      <kendo-label text="Status" labelCssClass="k-form-label"></kendo-label>
      <kendo-dropdownlist
        [data]="taskStatusTypeList"
        textField="value"
        valueField="key"
        placeholder="Select Status"
        [(ngModel)]="selectedTaskStatus"
        [ngModelOptions]="{ standalone: true }"
        [valuePrimitive]="true"
        class="w-200"
        (selectionChange)="statusChange($event)"
      ></kendo-dropdownlist>
    </fieldset>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onCloseDialog()" fillMode="outline">Cancel</button>
    <button kendoButton (click)="onSubmit()">Update Task</button>
  </kendo-dialog-actions>
</kendo-dialog>
