<kendo-popup *ngIf="showAssigneePopup" [offset]="position" (click)="$event.stopPropagation()">
  <div class="k-menu-popup pt-0 pb-10 pl-10 pr-10">
    <p class="font-size-16 fw-500 text-primary mb-10">Assignee</p>
    <form [formGroup]="assigneeForm" validateOnSubmit class="k-form k-form-md">
      <div class="mb-15">
        <kendo-multiselect
          [clearButton]="false"
          [checkboxes]="true"
          [data]="assigneeList"
          valueField="userId"
          textField="userName"
          formControlName="assignee"
          [filterable]="true"
          class="w-200"
          (filterChange)="onAssigneeSearch($event)"
          (click)="$event.stopPropagation()"
          [autoClose]="false"
        ></kendo-multiselect>
      </div>
      <div>
        <button kendoButton (click)="onSubmitAssignee()">Submit</button>
      </div>
    </form>
  </div>
</kendo-popup>
