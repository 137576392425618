<kendo-dialog
  title="{{ 'AbpFeatureManagement::Features' | abpLocalization }}"
  *ngIf="visible"
  (close)="visible = false"
  [minWidth]="250"
  [width]="800"
  [height]="650"
>
  <div class="row">
    <ng-container *ngIf="groups.length">
      <div class="col-md-12">
        <kendo-tabstrip>
          <kendo-tabstrip-tab
            *ngFor="let group of groups; let x = index; trackBy: track.by('name')"
            [selected]="selectedTabIndex === x"
          >
            <ng-template kendoTabTitle>{{ group.displayName }}</ng-template>
            <ng-template kendoTabContent>
              <h4>{{ group.displayName }}</h4>
              <hr class="mt-2 mb-3" />
              <div
                class="mt-2"
                *ngFor="let feature of features[group.name]; let i = index; trackBy: track.by('id')"
                [ngStyle]="feature.style"
                [ngSwitch]="feature.valueType?.name"
                (keyup.enter)="save()"
              >
                <ng-container *ngSwitchCase="valueTypes.ToggleStringValueType">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      kendoCheckBox
                      [id]="feature.name"
                      [(ngModel)]="feature.value"
                      (ngModelChange)="onCheckboxClick($event, feature)"
                    />
                    <label class="form-check-label" [htmlFor]="feature.name">{{
                      feature.displayName
                    }}</label>
                    <ng-container
                      *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                    ></ng-container>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="valueTypes.FreeTextStringValueType">
                  <div class="mb-3 form-group">
                    <label [htmlFor]="feature.name" class="form-label">{{
                      feature.displayName
                    }}</label>
                    <input
                      kendoTextBox
                      [id]="feature.name"
                      [(ngModel)]="feature.value"
                      [abpFeatureManagementFreeText]="feature"
                    />
                    <ng-container
                      *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                    ></ng-container>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="valueTypes.SelectionStringValueType">
                  <ng-container *ngIf="feature.valueType.itemSource?.items?.length">
                    <div class="mb-3 form-group">
                      <label [htmlFor]="feature.name" class="form-label">{{
                        feature.displayName
                      }}</label>
                      <kendo-dropdownlist
                        [data]="feature.valueType.itemSource?.items"
                        [textField]="'displayText.name'"
                        [valueField]="'value'"
                        [(ngModel)]="feature.value"
                      ></kendo-dropdownlist>
                      <ng-container
                        *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                      ></ng-container>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>{{ feature.displayName }}</ng-container>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>

      <ng-template #descTmp let-description>
        <small *ngIf="description" class="d-block form-text text-muted">{{ description }}</small>
      </ng-template>
    </ng-container>

    <div class="col" *ngIf="!groups.length">
      {{ 'AbpFeatureManagement::NoFeatureFoundMessage' | abpLocalization }}
    </div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="resetToDefault()" themeColor="primary">
      {{ 'AbpFeatureManagement::ResetToDefault' | abpLocalization }}
    </button>
    <button kendoButton (click)="visible = false">
      {{ 'AbpFeatureManagement::Cancel' | abpLocalization }}
    </button>
    <button kendoButton (click)="save()" themeColor="primary">
      {{ 'AbpIdentity::Save' | abpLocalization }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
