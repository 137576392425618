<div class="q-content">
  <div class="q-content-bg pb-0">
    <div>
      <div class="border-bottom">
        <p class="font-size-18 fw-500 mb-20 d-flex gap-15 align-items-center">
          <span class="task-icon"><i class="far fa-clipboard-list font-size-14"></i></span
          ><span>Task Details</span>
        </p>
      </div>
      <div>
        <div class="d-flex task-details-height">
          <div>
            <div class="pt-2 left-side-details border-right">
              <div class="mb-2">
                <kendo-label
                  text="Task List"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <a (click)="redirectToTaskList()" class="text-primary word-break">{{
                    taskDetails.taskListName
                  }}</a>
                </div>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Assigned To"
                  class="fw-500 mb-10 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <div *ngFor="let assignee of taskDetails.assignTo">
                    <p class="word-break">{{ assignee.userName }}</p>
                  </div>
                </div>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Start Date"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <p>
                    {{
                      taskDetails.isStartDate
                        ? (taskDetails.startDate | date: 'EEE, d MMM yyyy')
                        : '-'
                    }}
                  </p>
                </div>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Due Date"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <p>
                    {{
                      taskDetails.isDueDate ? (taskDetails.dueDate | date: 'EEE, d MMM yyyy') : '-'
                    }}
                  </p>
                </div>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Created By"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div class="mb-5 d-flex align-items-center">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(taskDetails.creatorName)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(
                        taskDetails.creatorId,
                        taskDetails.creatorName
                      )
                    "
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar
                  ><span class="ml-5 word-break">{{ taskDetails.creatorName }}</span>
                </div>
                <p>{{ taskDetails.createdDate | date: 'EEE, d MMM yyyy' }}</p>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Updated By"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div class="mb-5 d-flex align-items-center" *ngIf="taskDetails.updatedBy">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(taskDetails.updatedBy)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(taskDetails.creatorId, taskDetails.updatedBy)
                    "
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar>
                  <span class="ml-5 word-break">{{ taskDetails.updatedBy }}</span>
                </div>
                <ng-container *ngIf="taskDetails.updatedBy; else noUpdateBy">
                  <p>{{ taskDetails.updatedDate | date: 'EEE, d MMM yyyy' }}</p>
                </ng-container>
                <ng-template #noUpdateBy>
                  <p>-</p>
                </ng-template>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Task ID"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <p class="mb-0">{{ taskId }}</p>
              </div>
            </div>
          </div>

          <div class="right-side-details">
            <div>
              <div class="pt-2">
                <div class="primary-card mb-20">
                  <div class="d-flex justify-content-between gap-15 task-details-section">
                    <div class="d-flex gap-10 align-items-start">
                      <input
                        type="checkbox"
                        kendoCheckBox
                        class="billable-checkbox"
                        size="large"
                        #notification
                        kendoCheckBox
                      />
                      <div>
                        <div class="d-flex align-items-start mb-15">
                          <ng-container
                            *ngIf="taskDetails.assignTo && taskDetails.assignTo?.length > 0"
                          >
                            <ng-container *ngFor="let user of taskDetails.assignTo">
                              <kendo-avatar
                                rounded="full"
                                size="large"
                                [border]="true"
                                [initials]="commonService.getInitials(user.userName)"
                                [style.backgroundColor]="
                                  commonService.generateAvtarColor(user.userId, user.userName)
                                "
                                class="social-profile-text assignee-profile"
                              ></kendo-avatar>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="taskDetails.assignTo?.length < 0">
                            <i class="fal fa-circle-user font-size-24"></i>
                          </ng-container>
                          <span
                            class="text-primary display-inline line-height-normal task-name ml-10"
                            title="{{ taskDetails.taskName }}"
                            >{{ taskDetails.taskName }}</span
                          >
                        </div>
                        <div class="d-flex flex-wrap gap-15">
                          <div class="d-flex align-items-center flex-wrap">
                            <span class="font-size-12 mr-5"
                              >{{ taskDetails.startDate | date: 'd-MMM-yyyy' }} -
                            </span>
                            <span class="font-size-12 due-date ml-5">
                              {{ taskDetails.dueDate | date: 'd-MMM-yyyy' }}
                            </span>
                          </div>
                          <div class="d-flex flex-wrap gap-10">
                            <span class="q-label font-size-12"
                              ><i class="far fa-stopwatch mr-5"></i> Est:
                              {{ taskDetails.estimateHrs }}</span
                            >
                            <span class="q-label font-size-12"
                              ><i class="far fa-clock mr-5"></i> Log:
                              {{ totalLoggedTime ?? 0 }}</span
                            >

                            <span class="q-label font-size-12">{{
                              taskDetails.taskStatusText
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex gap-15 time-manage-button">
                      <button kendoButton (click)="openTimeLog(taskDetails.taskName)">
                        <i class="fa-regular fa-clock font-size-14 mr-5"></i> Log time
                      </button>
                      <button
                        kendoButton
                        (click)="onStartTimerClick()"
                        fillMode="outline"
                        [disabled]="isTimerRunning"
                      >
                        <i class="far fa-stopwatch font-size-14 mr-5"></i> Start timer
                      </button>
                      <kendo-dropdownbutton
                        [data]="timerActionList"
                        #additionalSetting
                        (itemClick)="onActionItemClicked($event)"
                        ><i class="fas fa-ellipsis-v"></i>
                      </kendo-dropdownbutton>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pb-1 mb-20" *ngIf="taskDetails.description">
                <div class="description-section">
                  <span [innerHTML]="safeDescription"></span>
                </div>
              </div>

              <div class="pb-1 mb-20 border-bottom">
                <!-- add subtask -->
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-5"
                  text="Sub Tasks"
                ></kendo-label>
                <div class="grid-wrapper">
                  <kendo-grid
                    [data]="subtaskList"
                    (edit)="onEditSubTask($event)"
                    (cancel)="onCancelSubTask($event)"
                    (save)="onSaveSubTask($event)"
                    (add)="onAddSubTask($event)"
                    (remove)="onRemoveSubTask($event)"
                    class="add-sub-task-grid"
                  >
                    <ng-template kendoGridToolbarTemplate [position]="'bottom'">
                      <a
                        kendoGridAddCommand
                        class="add-sub-task"
                        [ngClass]="{ 'mt-10 pl-10': subtaskList.length > 0 }"
                        ><i class="far fa-plus pr-5"></i>Add new SubTask</a
                      >
                    </ng-template>
                    <ng-template kendoGridNoRecordsTemplate>
                      <div class="display-none"></div>
                    </ng-template>
                    <kendo-grid-column field="taskName">
                      <ng-template kendoGridHeaderTemplate></ng-template>
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="d-flex gap-10 align-items-center">
                          <input
                            [disabled]="checkIsSubDisable(dataItem)"
                            [checked]="dataItem.taskStatus === taskStatusType.Completed"
                            type="checkbox"
                            kendoCheckBox
                            class="billable-checkbox mr-5"
                            size="large"
                            (change)="onSubTaskCompleteChange(dataItem, rowIndex)"
                          />
                          {{ dataItem.taskName }}
                        </div>
                      </ng-template>
                      <ng-template
                        kendoGridEditTemplate
                        let-dataItem="dataItem"
                        let-formGroup="formGroup"
                      >
                        <form class="k-form k-form-md" [formGroup]="formGroup">
                          <fieldset class="k-form-fieldset d-flex gap-5">
                            <kendo-textbox
                              [formControl]="formGroup.get('taskName')"
                              class="k-input-inner-text-box"
                              placeholder="Enter Task Name for SubTask"
                            ></kendo-textbox>
                          </fieldset>
                        </form>
                      </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-command-column [width]="110">
                      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
                        <ul class="grid-action-list grid-command-action">
                          <div class="d-flex gap-5 justify-content-end">
                            <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
                              <a
                                title="Save"
                                class="grid-command-action-links"
                                kendoGridSaveCommand
                                [disabled]="formGroup?.invalid"
                              >
                                <i class="far fa-floppy-disk"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                title="Discard change"
                                class="grid-command-action-links"
                                kendoGridCancelCommand
                              >
                                <i class="far fa-ban"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                title="Edit"
                                class="grid-command-action-links"
                                kendoGridEditCommand
                                *ngIf="!isNew"
                              >
                                <i class="far fa-pen"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                title="Delete"
                                class="grid-command-action-links"
                                kendoGridRemoveCommand
                                *ngIf="!isNew"
                              >
                                <i class="far fa-trash-alt"></i>
                              </a>
                            </li>
                          </div>
                        </ul>
                      </ng-template>
                    </kendo-grid-command-column>
                  </kendo-grid>
                </div>
              </div>

              <div class="pb-1 mb-20 border-bottom">
                <kendo-label
                  text="Files"
                  class="mb-5 display-inline font-size-16"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div *ngIf="fileList.length == 0">
                  <p class="font-size-12">
                    No files are attached to this task -
                    <a
                      class="font-size-12"
                      href="javascript:void(0)"
                      (click)="openAttachedFile()"
                      class="text-primary"
                      >Attach files to this task</a
                    >
                  </p>
                </div>

                <div class="pb-1 mb-20" *ngIf="fileList.length > 0">
                  <div class="file-container" *ngFor="let file of fileList">
                    <div class="file-icon">
                      <i
                        [ngClass]="getFileIconClass(file.fileName)"
                        class="k-icon font-size-14"
                      ></i>
                    </div>
                    <div class="file-info wp-100">
                      <div
                        class="file-name d-flex justify-content-between align-items-center"
                        (click)="downloadFile(file)"
                      >
                        <div class="d-flex gap-5 flex-column">
                          <span>{{ file.fileName }} </span>
                          <span class="file-details"
                            >by {{ file.creatorName }} - {{ file.fileSize }} kb</span
                          >
                        </div>
                        <div class="downloadFile-icon-container">
                          <i class="fas fa-cloud-download-alt k-icon font-size-14"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    <a class="text-primary" href="javascript:void(0)" (click)="openAttachedFile()"
                      >Manage task attachment</a
                    >
                  </p>
                </div>
              </div>
              <div class="pb-1">
                <kendo-label
                  text="Time Logs"
                  class="mb-20 display-inline font-size-16"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <ng-container *ngIf="timeLogList.length === 0; else gridTemplate">
                    <div class="border-bottom">
                      <p class="font-size-12">
                        No time entries have been logged against this task -
                        <a
                          class="font-size-12"
                          href="javascript:void(0)"
                          (click)="openTimeLog()"
                          class="text-primary"
                          >Log time on this task</a
                        >
                      </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center flex-column mt-4">
                      <img src="assets/images/timelog.png" alt="timelog" class="mb-10" />
                      <p class="mt-5">No Time Logged!</p>
                      <p class="text-secondary-text font-size-12">
                        It seems there is no time logged on this task.
                      </p>
                    </div>
                  </ng-container>
                </div>
              </div>
              <ng-template #gridTemplate>
                <div>
                  <div class="d-flex gap-30 primary-card text-primary flex-wrap mb-24">
                    <span class="fw-500"> Filtered Totals: </span>
                    <div>
                      <i class="fa-regular fa-clock"></i>
                      <span class="ml-5 fw-500">logged</span> : {{ totalLoggedTime }}
                    </div>
                    <div>
                      <i class="fa-solid fa-ban"></i>
                      <span class="ml-5 fw-500">Non-billable</span> :
                      {{ totalLoggedNonBillableTime }}
                    </div>
                    <div>
                      <i class="fa-regular fa-circle-check"></i>
                      <span class="ml-5 fw-500">Billable</span> : {{ totalLoggedBillableTime }}
                    </div>
                  </div>
                  <div class="grid-wrapper">
                    <kendo-grid
                      [kendoGridBinding]="timeLogList"
                      [sortable]="true"
                      [sort]="sort"
                      (sortChange)="sortChange($event)"
                      class="timelog-grid"
                    >
                      <kendo-grid-column field="timelogDatetime" title="Date" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDatetime | date: 'yyyy-MM-dd' }}">{{
                            dataItem.timelogDatetime | date: 'dd-MM-yyyy'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="name" title="Who" [width]="150" [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex align-items-center gap-10">
                            <kendo-avatar
                              rounded="full"
                              size="large"
                              [border]="true"
                              [initials]="commonService.getInitials(dataItem.userName)"
                              [style.backgroundColor]="
                                commonService.generateAvtarColor(dataItem.userId, dataItem.userName)
                              "
                              class="social-profile-text"
                            ></kendo-avatar>
                            <span class="clamp" [title]="dataItem.userName">{{
                              dataItem.userName
                            }}</span>
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="timelogDescription"
                        title="Description"
                        [width]="150"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDescription }}">{{
                            dataItem.timelogDescription
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="timelogDatetime" title="Start" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDatetime | date: 'shortTime' }}">{{
                            dataItem.timelogDatetime | date: 'shortTime'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="end" title="End" [sortable]="false" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{
                            commonService.getEndDate(
                              dataItem.timelogDatetime,
                              dataItem.timelogMinutes
                            ) | date: 'shortTime'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="timelogIsBillable"
                        title="Billable"
                        [width]="100"
                        [hidden]="!isProjectBillable"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <input
                            type="checkbox"
                            kendoCheckBox
                            [checked]="dataItem.timelogIsBillable === 1 ? true : false"
                            class="billable-checkbox grid-checkbox"
                            size="large"
                            disabled
                          />
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="timelogBillableMinutes"
                        title="Billable Hours"
                        [sortable]="false"
                        [width]="100"
                        [hidden]="!isProjectBillable"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogBillableMinutes }}">{{
                            commonService.getHoursAndMinutesFromTotalMinutes(
                              dataItem.timelogBillableMinutes
                            )
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="timelogMinutes" title="Time" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogMinutes }}m"
                            >{{ dataItem.timelogMinutes }}m</span
                          >
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column
                        field="hours"
                        title="Hours"
                        [sortable]="false"
                        [width]="80"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.hours }}">{{ dataItem.hours }}</span>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="" title="" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex align-items-center justify-content-between">
                            <ul class="grid-action-list">
                              <div class="d-flex gap-10">
                                <li>
                                  <a title="Edit" (click)="onEdit(dataItem, taskDetails.taskName)">
                                    <i class="far fa-pen"></i>
                                  </a>
                                </li>
                                <li *ngIf="!dataItem.isDefault">
                                  <a title="Delete" (click)="onDelete(dataItem)">
                                    <i class="far fa-trash-alt"></i>
                                  </a>
                                </li>
                              </div>
                            </ul>
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                    </kendo-grid>
                  </div>
                </div>
              </ng-template>
              <div class="pb-1" [hidden]="true">
                <kendo-label
                  text="Comments"
                  class="mb-20 display-inline font-size-16 border-bottom"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div class="comments-container">
                  <div
                    *ngFor="let comment of commentList"
                    class="comment-card"
                    [hidden]="editingCommentId === comment.id"
                  >
                    <div class="comment-header">
                      <div class="user-avatar">VB</div>
                      <div class="comment-metadata">
                        <span class="private-indicator" *ngIf="comment.isPrivate"
                          >Toshal Infotech</span
                        >
                      </div>
                      <div class="dropdown">
                        <button (click)="toggleDropdown(comment.id)" class="dots-btn">⋮</button>
                        <div *ngIf="activeCommentId === comment.id" class="dropdown-menu">
                          <a href="javascript:void(0)" (click)="editComment(comment.id)">Edit</a>
                          <a href="javascript:void(0)" (click)="deleteComment(comment.id)"
                            >Delete</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="comment-body">
                      <p>{{ comment.body }}</p>
                    </div>
                  </div>
                </div>

                <div class="comment-container">
                  <div class="mb-5 d-flex align-items-center">
                    <kendo-avatar
                      rounded="full"
                      size="large"
                      [border]="true"
                      [initials]="commonService.getInitials(taskDetails.creatorName)"
                      [style.backgroundColor]="
                        commonService.generateAvtarColor(
                          taskDetails.creatorId,
                          taskDetails.creatorName
                        )
                      "
                      class="social-profile-text task-detail-avatar"
                    ></kendo-avatar>
                  </div>
                  <textarea
                    *ngIf="!isEditorActive"
                    class="initial-textbox"
                    placeholder="Add a comment"
                    (focus)="activateEditor()"
                  ></textarea>
                  <ng-container *ngIf="isEditorActive">
                    <kendo-editor
                      style="height: 150px; border-radius: 8px; padding: 10px"
                      class="mb-20 kendo-editor"
                      [(ngModel)]="commentText"
                      placeholder="Add a comment"
                      [iframeCss]="{ content: customStyleContent }"
                      [tools]="customTools"
                    ></kendo-editor>
                    <div class="comment-actions">
                      <div class="notify-section">
                        <span>Notify:</span>
                        <span class="notify-avatar">VU</span>
                        <button kendoButton icon="plus" class="add-button"></button>
                        <button kendoButton icon="attachment" class="attachment-button"></button>
                        <button kendoButton icon="lock" class="lock-button"></button>
                      </div>
                      <div class="action-buttons">
                        <button kendoButton (click)="cancelEdit()" class="cancel-button">
                          Cancel
                        </button>
                        <button kendoButton (click)="saveComment()" class="save-button">
                          {{ updateCommentId ? 'Update' : 'Save' }}
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div kendoDialogContainer></div>
