import { Component, Input, OnInit } from '@angular/core';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AttachTaskFilePopupComponent } from '../attach-task-file-popup/attach-task-file-popup.component';
import { TimeLogPopupComponent } from '../time-log-popup/time-log-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/core/services';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { TaskListService } from 'projects/task-service/src/lib/proxy/task-service/task-list';
import { GetIdentityUsersInput, IdentityUserService } from '@abp/ng.identity/proxy';
import { ConfigStateService, ListService, SessionStateService } from '@abp/ng.core';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionTypeEnum } from 'src/app/enum/status-type-enum';
import { TaskEditPopupComponent } from './task-edit-popup/task-edit-popup.component';
import { FileService } from 'projects/task-service/src/lib/proxy/service/task';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FileVersionDto } from 'projects/file-service/src/lib/proxy/file-service/dtos';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  GridComponent,
  RemoveEvent,
  SaveEvent,
} from '@progress/kendo-angular-grid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskType } from 'src/app/enum/task-type';
import { TaskDateDto } from '../task-list';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommentService } from 'projects/comment-service/src/lib/proxy/comment-service';
import { CommentObjectType } from 'src/app/enum/comment-object-type-enum';
import { TimelogGetListInput } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { ExportFormat } from 'projects/task-service/src/lib/proxy/service/enum';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';

export class TaskDetails {
  assignTo: any;
  startDate: Date;
  dueDate: Date;
  createdDate: Date | string;
  createdBy: string;
  updatedBy: string;
  updatedDate: Date;
  taskId: number;
  taskName: string;
  estimateHrs: string;
  taskListName: string;
  isStartDate: boolean;
  isDueDate: boolean;
  creatorId: any;
  creatorName: string;
  description: string;
  taskStatusText: string;
}

const createSubTaskFormGroup = dataItem =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    taskName: new FormControl(dataItem.taskName, Validators.required),
    assignee: new FormControl(dataItem.assignee),
    dueDate: new FormControl(dataItem.dueDate),
    priority: new FormControl(dataItem.priority),
    comments: new FormControl('1'),
    taskId: new FormControl(dataItem.taskId),
    hasChildren: new FormControl(!!dataItem.subTaskIds),
    taskType: new FormControl(dataItem.taskType),
    taskStatus: new FormControl(dataItem.taskStatus),
    estimateMinutes: new FormControl(dataItem.estimateMinutes),
    isAllSubTaskCompleted: new FormControl(dataItem.isAllSubTaskCompleted),
  });

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-add-task-detail',
  templateUrl: './add-task-detail.component.html',
  styleUrls: ['./add-task-detail.component.scss'],
})
export class AddTaskDetailComponent implements OnInit {
  //#region timmer
  isTimerRunning: boolean = false;
  startTime: Date;
  timerInterval: any;
  safeDescription: SafeHtml | undefined;
  startDate: any;
  dueDate: any;
  endTime: Date;
  //#endregion
  public sorting = null;
  timerActionList = [
    { id: 1, iconClass: 'fa-solid fa-pen', text: 'Edit Task', actionTypeId: ActionTypeEnum.Edit },
    {
      id: 2,
      iconClass: 'fa-solid fa-download',
      text: 'Export Task Timelog',
      actionTypeId: ActionTypeEnum.Export,
    },
    {
      id: 3,
      iconClass: 'far fa-trash-alt',
      text: 'Delete Task',
      actionTypeId: ActionTypeEnum.Delete,
    },
  ];

  timeLogList: any[] = [];
  commentList: any[] = [];
  taskId: any;
  projectId: any;
  projectName: any;
  userList: any[];
  fileList: any[] = [];

  taskDetails: TaskDetails = new TaskDetails();
  actionTypeEnum = ActionTypeEnum;
  totalLoggedTime: string;
  totalLoggedNonBillableTime: string;
  totalLoggedBillableTime: string;
  subtaskList: any[] = [];
  @Input() sortingName = '';
  isAllSubTaskCompleted = false;
  editedRowIndex: number;
  formGroup: FormGroup;
  isEditorActive = false;
  commentText = '';
  updateCommentId: any;
  activeCommentId: number | null = null;
  editingCommentId: number | null = null;

  taskTypeList = [
    {
      id: TaskType.Task,
      name: TaskType[TaskType.Task],
      iconClass: 'far fa-clipboard-list font-size-14',
    },
    {
      id: TaskType.Bug,
      name: TaskType[TaskType.Bug],
      iconClass: 'far fa-bug font-size-14 bug-icon-color',
    },
  ];

  taskStatusType = TaskStatusType;
  taskListId: any;

  public customStyleContent = `
  .k-content * {
    font-family: 'Poppins', sans-serif;
    color: #404a5f;
  }

  .k-content .k-placeholder {
    font-size: 13px;
    color: #909EB2;
  }`;
  currentUser: any;
  isProjectBillable: boolean = false;

  constructor(
    private dialogService: DialogService,
    private _Activatedroute: ActivatedRoute,
    private taskService: TaskService,
    public commonService: CommonService,
    private timelogService: TimelogService,
    private commentService: CommentService,
    private taskListService: TaskListService,
    public readonly list: ListService<GetIdentityUsersInput>,
    protected service: IdentityUserService,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private fileService: FileService,
    private http: HttpClient,
    private sessionState: SessionStateService,
    private sanitizer: DomSanitizer,
    private config: ConfigStateService,
    private projectService: ProjectService,
  ) {
    this.currentUser = this.config.getOne('currentUser');
  }
  ngOnInit(): void {
    this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.taskId = +atob(params.get('taskId'));
        this.projectId = +atob(params.get('projectId'));
        this.projectName = atob(params.get('projectName'));
        this.taskListId = +atob(params.get('taskListId'));
        this.getTaskListDetails();
        this.getAllTimeLogList();
        this.getFiles();
        this.getSubtask();
        //this.getComments();
        this.getProjectDetail();
      }
    });

    this.commonService.allTimeLogListFromTimer$.subscribe(() => {
      this.getAllTimeLogList();
    });
  }

  getProjectDetail(): void {
    this.projectService.get(this.projectId).subscribe((res: any) => {
      this.isProjectBillable = res.isBillable;
    });
  }

  getSubtask(): void {
    this.taskService
      .getSubTasksByParentTaskIdAndSorting(this.taskId, this.sortingName)
      .subscribe(x => {
        this.spinnerService.show();
        this.subtaskList = x.items.map(element => ({
          id: element.id,
          taskName: element.name,
          assignee: element.users,
          dueDate: element.duedate,
          priority: element.priority,
          comments: '1',
          taskId: this.taskId,
          hasChildren: !!element.subTaskIds,
          taskType: element.taskType,
          taskStatus: element.taskStatus,
          estimateMinutes: element.estimateMinutes,
          isAllSubTaskCompleted: element.isAllSubTaskCompleted,
        }));
        this.isAllSubTaskCompleted = this.checkIsDisable();
        this.spinnerService.hide();
      });
  }

  checkIsDisable(): boolean {
    if (this.subtaskList.length > 0) {
      return !this.subtaskList.every(subtask => subtask.taskStatus === TaskStatusType.Completed);
    }
    return false;
  }

  onSubTaskCompleteChange(data: any): void {
    if (data.taskStatus === TaskStatusType.New || data.taskStatus === TaskStatusType.ReOpened) {
      this.taskService.markTaskAsCompleteById(data.id).subscribe(res => {
        this.getSubtask();
      });
    } else if (data.taskStatus === TaskStatusType.Completed) {
      this.taskService.markTaskAsReopenedById(data.id).subscribe(res => {
        this.getSubtask();
      });
    }
    this.isAllSubTaskCompleted = this.checkIsDisable();
  }

  getSubTaskDataById(data: any): void {
    this.taskId = data.id;
  }

  checkIsSubDisable(dataItem: any): boolean {
    if (dataItem.hasChildren) {
      return !dataItem.isAllSubTaskCompleted;
    }
    return false;
  }

  onEditSubTask({ sender, rowIndex, dataItem }: EditEvent): void {
    this.closeEditor(sender);
    this.formGroup = createSubTaskFormGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  onCancelSubTask({ sender, rowIndex }: CancelEvent): void {
    this.closeEditor(sender, rowIndex);
  }

  onSaveSubTask({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    let newTaskData = formGroup.value;

    this.isAllSubTaskCompleted = this.checkIsDisable();
    this.saveSubTask(isNew, newTaskData);
    sender.closeRow(rowIndex);
  }

  saveSubTask(isNew, newTaskData): void {
    this.spinnerService.show();
    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: isNew ? 0 : newTaskData.id,
      name: newTaskData.taskName,
      description: '',
      progress: 20,
      taskListId: this.taskListId,
      startdate:
        newTaskData.dueDate === '' || newTaskData.dueDate === null
          ? null
          : new TaskDateDto(
              newTaskData.dueDate?.getFullYear(),
              newTaskData.dueDate?.getMonth() + 1,
              newTaskData.dueDate?.getDate(),
            ),
      duedate:
        newTaskData.dueDate === '' || newTaskData.dueDate === null
          ? null
          : new TaskDateDto(
              newTaskData.dueDate?.getFullYear(),
              newTaskData.dueDate?.getMonth() + 1,
              newTaskData.dueDate?.getDate(),
            ),
      priority: +newTaskData.priority,
      estimateMinutes: newTaskData.estimateMinutes,
      parentTaskId: newTaskData.taskId,
      notify: true,
      assignedToUserIds:
        newTaskData.assignee.length === 0 ? [] : newTaskData.assignee.map(item => item.userId),
      taskType: newTaskData.taskType,
      taskStatus: isNew ? TaskStatusType.New : newTaskData.taskStatus,
      subTasks: [],
    };

    if (isNew) {
      this.taskService.create(param).subscribe(
        res => {
          this.spinnerService.hide();
          this.toasterService.success(
            NotificationTextMessage.taskAddedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.getSubtask();
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.taskService.update(newTaskData.id, param).subscribe(
        res => {
          this.spinnerService.hide();
          this.toasterService.success(
            NotificationTextMessage.taskUpdatedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.getSubtask();
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }
  }

  onAddSubTask({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = createSubTaskFormGroup({
      id: 0,
      taskName: '',
      assignee: '',
      dueDate: '',
      priority: Priority.Low,
      comments: '1',
      taskId: this.taskId,
      hasChildren: false,
      taskType: this.taskTypeList[0].id,
      taskStatus: TaskStatusType.New,
      estimateMinutes: 0,
      isAllSubTaskCompleted: true,
    });

    sender.addRow(this.formGroup);
  }

  onRemoveSubTask({ dataItem }: RemoveEvent) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + dataItem.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();

        this.taskService.delete(dataItem.id).subscribe(res => {
          this.spinnerService.hide();
          this.getSubtask();
        });
      }
    });
  }

  getFileIconClass(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'fas fa-image';
      case 'pdf':
        return 'fas fa-file-pdf';
      case 'xls':
      case 'xlsx':
        return 'fas fa-file-excel';
      default:
        return 'fas fa-file';
    }
  }

  onActionItemClicked(event: any): void {
    switch (event.actionTypeId) {
      case ActionTypeEnum.Edit:
        this.editTask();
        break;
      case ActionTypeEnum.Delete:
        this.removeTask();
        break;
      case ActionTypeEnum.Export:
        // Handle export action
        break;
      default:
        break;
    }
  }

  editTask(): void {
    const dialogRef = this.dialogService.open({
      content: TaskEditPopupComponent,
      width: 450,
    });
    const attachFileInfo = dialogRef.content.instance as TaskEditPopupComponent;
    attachFileInfo.taskId = this.taskId;
    attachFileInfo.projectId = this.projectId;
    attachFileInfo.setDataOnLoad();
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getTaskListDetails();
      }
    });
  }

  removeTask(): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + this.taskDetails.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();
        this.taskService.delete(this.taskId).subscribe(
          res => {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationMessage.deleteTaskSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.redirectToTaskList();
          },
          error => {
            this.spinnerService.hide();
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  redirectToTaskList(): void {
    var params = {
      projectId: btoa(this.projectId),
      projectName: btoa(this.projectName),
    };

    this.router.navigate(['task-list', params]);
  }

  downloadFile(file: FileVersionDto): void {
    let apiURL = environment.apis.default.url;
    const tenant = this.sessionState.getTenant();

    if (tenant?.id) {
      apiURL = apiURL.replace('{0}', tenant?.name);
    } else {
      apiURL = apiURL.replace('{0}.', '');
    }

    this.http
      .get(`${apiURL}api/file/${file.fileId}/download`, {
        responseType: 'blob',
      })
      .subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);

          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = file.fileName;
          anchor.click();

          window.URL.revokeObjectURL(url);
        },
        error: error => {
          console.error('Error downloading the file', error);
        },
        complete: () => {
          this.toasterService.success(
            NotificationMessage.downloadFileSuccessMsg,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        },
      });
  }

  getFiles(): void {
    this.spinnerService.show();
    this.fileService.getList(this.taskId).subscribe(res => {
      if (res.length > 0) {
        this.fileList = res.map(element => {
          return element.fileVersions.reduce((prev, current) =>
            prev.versionNo > current.versionNo ? prev : current,
          );
        });
      }
    });
  }

  getTaskListDetails(): void {
    this.spinnerService.show();
    this.taskService.get(this.taskId).subscribe(x => {
      this.taskDetails.isStartDate =
        x.startdate !== null && x.startdate !== undefined ? true : false;
      this.taskDetails.isDueDate = x.duedate !== null && x.duedate !== undefined ? true : false;
      this.taskDetails.taskName = x.name;
      this.taskDetails.assignTo = x.users;
      this.taskDetails.startDate = new Date(
        x.startdate !== null && x.startdate !== undefined ? x.startdate : '',
      );
      this.taskDetails.dueDate = new Date(
        x.duedate !== null && x.duedate !== undefined ? x.duedate : '',
      );
      this.taskDetails.createdDate = x.creationTime;
      this.taskDetails.creatorName = x.creatorName;
      this.taskDetails.updatedDate = new Date(x.lastModificationTime);
      this.taskDetails.updatedBy = x.lastModifierUserName;
      this.taskDetails.taskId = this.taskId;
      this.taskDetails.taskListName = x.taskListName;
      this.taskDetails.creatorId = x.creatorId;
      this.taskDetails.estimateHrs = this.commonService.getHoursAndMinutesFromTotalMinutes(
        x.estimateMinutes,
      );
      this.taskDetails.description = x.description;
      this.taskDetails.taskStatusText = x.taskStatusText;
      this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(this.taskDetails.description);
      this.spinnerService.hide();
    });
  }

  openAttachedFile(): void {
    const dialogRef = this.dialogService.open({
      content: AttachTaskFilePopupComponent,
      width: 500,
    });

    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = this.taskDetails.taskName;
    dialogRef.result.subscribe(res => {
      if (res) {
        this.getAllTimeLogList();
        this.getFiles();
      }
    });
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getAllTimeLogList();
  }

  getAllTimeLogList(): void {
    this.spinnerService.show();

    const params: TimelogGetListInput = {
      userId: null,
      taskId: this.taskId,
      projectId: null,
      timelogDatetime: null,
      timelogMinutes: null,
      timelogDescription: null,
      timelogIsBillable: null,
      timelogHasStartTime: null,
      taskIdPreMove: null,
      deskTicketId: null,
      timelogInvoiceNumber: null,
      projectBillingInvoiceId: null,
      timelogFinalCost: null,
      maxResultCount: null,
      sorting: this.sorting,
      format: ExportFormat.Excel,
    };
    this.timelogService.getList(params).subscribe(res => {
      this.spinnerService.hide();
      this.timeLogList = [];
      if (res.items.length > 0) {
        res.items.forEach((element: any) => {
          element.hours = this.removeTrailingZeros((element.timelogMinutes / 60).toFixed(3));
          if (element.taskId === this.taskId) {
            this.timeLogList.push(element);
          }
        });

        const nonBillableLogs = this.timeLogList.filter(log => log.timelogIsBillable !== 1);

        this.totalLoggedTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalTime(this.timeLogList),
        );
        this.totalLoggedBillableTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalBillableTime(this.timeLogList),
        );
        this.totalLoggedNonBillableTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalTime(nonBillableLogs),
        );
      }
    });
  }

  calculateTotalBillableTime(logs: any[]): number {
    return logs.reduce((total, log) => total + log.timelogBillableMinutes, 0);
  }

  calculateTotalTime(logs: any[]): number {
    return logs.reduce((total, log) => total + log.timelogMinutes, 0);
  }

  removeTrailingZeros(str: string): string {
    return str.replace(/(\.0+|(?<=\..*?)0+)$/, '');
  }

  openTimeLog(taskName) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
    });
    document.body.style.overflow = 'hidden';

    this.startTime = new Date();
    this.endTime = new Date();
    dialogRef.content.instance.startTime = this.startTime;
    dialogRef.content.instance.endTime = this.endTime;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = taskName;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.isProjectBillable = this.isProjectBillable;

    dialogRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.getAllTimeLogList();
        }
        document.body.style.overflow = 'scroll';
      }
    });
  }

  onStartTimerClick() {
    const timerData = {
      showTimer: true,
      taskId: this.taskId,
      projectId: this.projectId,
    };

    this.commonService.showTimerPopup(timerData);
  }

  onEdit(data, taskName) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
    });
    document.body.style.overflow = 'hidden';

    dialogRef.content.instance.timeLogId = data.id;
    dialogRef.content.instance.taskName = taskName;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.isProjectBillable = this.isProjectBillable;

    dialogRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.getAllTimeLogList();
        }
        document.body.style.overflow = 'scroll';
      }
    });
  }

  onDelete(data: any): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + data.timelogDescription + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.timelogService.delete(data.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteTimelogtSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.getAllTimeLogList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  activateEditor() {
    this.isEditorActive = true;
  }

  cancelEdit() {
    this.isEditorActive = false;
  }

  saveComment() {
    this.isEditorActive = false;
    this.spinnerService.show();
    const plainTextComment = this.commentText.replace(/<[^>]*>/g, '');
    const createUpdateCommentParam: any = {
      body: plainTextComment,
      isPrivate: true,
      objectId: this.taskId,
      objectType: CommentObjectType.Task,
      htmlBody: this.commentText,
      lockdownId: 0,
      contentType: 0,
    };
    if (this.updateCommentId !== null && this.updateCommentId !== undefined) {
      this.commentService.update(this.updateCommentId, createUpdateCommentParam).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationTextMessage.commentUpdatedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.commentText = '';
            this.editingCommentId = null;
            this.updateCommentId = null;
            this.getComments();
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.commentService.create(createUpdateCommentParam).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationTextMessage.commentAddedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.commentText = '';
            this.editingCommentId = null;
            this.getComments();
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }
  }

  getComments(): void {
    this.spinnerService.show();
    const params = {
      taskId: this.taskId,
      sorting: null,
      skipCount: null,
      maxResultCount: null,
    };
    this.commentService.getList(params).subscribe(res => {
      this.spinnerService.hide();
      this.commentList = [];
      if (res.items.length > 0) {
        this.commentList = res.items;
      }
    });
  }

  toggleDropdown(commentId: number): void {
    this.activeCommentId = this.activeCommentId === commentId ? null : commentId;
  }

  editComment(commentId: any): void {
    this.activeCommentId = null;
    this.spinnerService.show();
    if (commentId) {
      this.commentService.get(commentId).subscribe(res => {
        this.editingCommentId = res.id;
        this.commentText = res.htmlBody;
        this.updateCommentId = res.id;
        this.spinnerService.hide();
      });
      this.isEditorActive = true;
    }
  }

  deleteComment(commentId: any): void {
    this.activeCommentId = null;
    if (commentId) {
      this.commentService.delete(commentId).subscribe(res => {
        this.toasterService.success('Delete Comment Succesfully', '', {});
        this.getComments();
      });
    }
  }
}
