<div class="q-content p-20">
  <div class="q-content-bg">
    <div [ngClass]="{ 'custom-header-bg': this.isFromPortfolio || this.isFromProduct }">
      <div class="project-heading" *ngIf="this.isFromPortfolio || this.isFromProduct">
        <div class="project-name d-flex pb-20 mb-20">
          <p class="d-flex align-items-center mb-0">
            <i
              [ngClass]="{
                'fas fa-folder': this.isFromPortfolio,
                'far fa-envelope-open-text': this.isFromProduct,
              }"
            ></i
            ><span class="project-title-name">{{ this.titleName }}</span>
          </p>
        </div>
        <hr />
      </div>
      <div class="q-content-header d-flex justify-content-between mb-20 flex-wrap gap-20">
        <kendo-textbox
          placeholder="Search for projects name"
          class="search"
          [(ngModel)]="searchText"
          (keyup.enter)="onSearchKeyupEnter()"
          (ngModelChange)="onSearchTextChange($event)"
        >
          <ng-template kendoTextBoxPrefixTemplate>
            <i class="fas fa-search k-icon font-size-14"></i>
          </ng-template>
        </kendo-textbox>
        <div class="d-flex gap-15 justify-content-between flex-wrap gap-20">
          <kendo-button *ngIf="isAdmin" kendoButton (click)="onAddProject()"
            ><i class="far fa-plus pr-5"></i>Add New Project</kendo-button
          >
          <kendo-dropdownlist
            [data]="statusFilter | useEnumValues"
            textField="value"
            valueField="key"
            placeholder="value"
            [(ngModel)]="selectedStatus"
            [ngModelOptions]="{ standalone: true }"
            [valuePrimitive]="true"
            class="w-150"
            (selectionChange)="statusChange($event)"
          ></kendo-dropdownlist>
          <a class="flat-icon-container display-none"><i class="far fa-bars-filter"></i></a>
          <a class="flat-icon-container display-none"><i class="fas fa-ellipsis-v"></i></a>
        </div>
      </div>
      <hr *ngIf="isFromPortfolio || isFromProduct" />
    </div>
    <div [ngClass]="{ 'custom-header-grid-bg': this.isFromPortfolio || this.isFromProduct }">
      <div class="grid-wrapper">
        <kendo-grid
          #grid
          [data]="gridView"
          [resizable]="true"
          [pageSize]="pageSize"
          [skip]="skip"
          [pageable]="
            gridView === undefined
              ? false
              : {
                  buttonCount: buttonCount,
                  info: info,
                  type: type,
                  pageSizes: pageSizes,
                  previousNext: previousNext,
                  position: pagerposition,
                }
          "
          (pageChange)="pageChange($event)"
          [sortable]="true"
          [sort]="sort"
          (sortChange)="sortChange($event)"
          [height]="gridHeight"
          class="custom-no-records-container"
          [ngClass]="{ 'grid-header-padding': this.isFromPortfolio || this.isFromProduct }"
        >
          <ng-template kendoGridNoRecordsTemplate>
            <div *ngIf="isNoRecords" class="no-records-text-container">No Records Available</div>
          </ng-template>
          <kendo-grid-column
            [columnMenu]="false"
            [filterable]="true"
            field="name"
            title="Project Name"
            filter="string"
            [groupable]="false"
            [width]="200"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <a
                class="wmx-100 clamp text-underline"
                (click)="onProjectClick(dataItem, $event)"
                [routerLink]="['/task-list']"
                [queryParams]="getQueryParams(dataItem)"
                [target]="openInNewTab ? '_blank' : ''"
                title="{{ dataItem.name }}"
              >
                {{ dataItem.name }}
              </a>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            [groupable]="true"
            [columnMenu]="false"
            [filterable]="true"
            field="company"
            title="Company"
            filter="string"
            [groupable]="true"
            [width]="150"
          ></kendo-grid-column>

          <kendo-grid-column
            [groupable]="true"
            [columnMenu]="false"
            [filterable]="true"
            field="tasks"
            title="Tasks"
            filter="string"
            [groupable]="true"
            [width]="150"
            [sortable]="false"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="d-flex align-items-center gap-5 w-150">
                <span>{{ dataItem.totalTasks }}</span>
                <kendo-progressbar
                  [value]="
                    calculateGridProgressbar(dataItem.totalTasks, dataItem.totalCompletedTasks)
                  "
                  [label]="label"
                  class="progress-bar"
                ></kendo-progressbar>
                <span>{{ dataItem.totalCompletedTasks }}</span>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            [groupable]="true"
            [columnMenu]="false"
            [filterable]="true"
            field="creationTime"
            title="Start Date"
            filter="string"
            [groupable]="true"
            [width]="150"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span title="{{ dataItem.creationTime | date: 'dd-MMM-yyyy' }}">{{
                dataItem.creationTime | date: 'dd-MMM-yyyy'
              }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            [groupable]="true"
            [columnMenu]="false"
            [filterable]="true"
            field="endDate"
            title="End Date"
            filter="string"
            [groupable]="true"
            [width]="150"
            [hidden]="true"
          ></kendo-grid-column>

          <kendo-grid-column
            [columnMenu]="false"
            [filterable]="true"
            field="status"
            title="Status"
            filter="boolean"
            [groupable]="false"
            [width]="200"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="d-flex align-items-center justify-content-between">
                <div
                  class="status-container d-flex gap-10 align-items-center justify-content-center"
                  [ngClass]="{
                    'priority-OnTime': statusFilter[dataItem.projectStatus] === 'On Time',
                    'priority-Delay': statusFilter[dataItem.projectStatus] === 'Delay',
                    'priority-All': statusFilter[dataItem.projectStatus] === 'All',
                  }"
                >
                  <span class="indicator"></span>
                  <span class="status-data">{{ statusFilter[dataItem.projectStatus] }}</span>
                </div>
                <ul class="grid-action-list" *ngIf="isAdmin">
                  <div class="d-flex gap-10">
                    <li>
                      <a title="Edit" (click)="onEdit(dataItem)">
                        <i class="far fa-pen"></i>
                      </a>
                    </li>
                    <li *ngIf="!dataItem.isDefault">
                      <a title="Delete" (click)="onDelete(dataItem)">
                        <i class="far fa-trash-alt"></i>
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div kendoDialogContainer></div>
