import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid';
import { DrawerMode, DrawerPosition } from '@progress/kendo-angular-layout';
import { process } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimelogGetListInput } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { CommonService } from 'src/core/services';
import { TimeLogPopupComponent } from '../../time-log-popup/time-log-popup.component';
import { ExportFormat } from 'projects/task-service/src/lib/proxy/service/enum';
import { ConfigStateService, SessionStateService } from '@abp/ng.core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { NotificationMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { map, Observable, of, switchMap } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent implements OnInit {
  public gridView: GridDataResult;
  gridHeight: number;
  projectId: any;
  isDrawerOpened = false;
  public expandMode: DrawerMode = 'overlay';
  public position: DrawerPosition = 'end';
  public dateFilter: Array<{ text: string; value: number }> = [
    { text: 'Anytime', value: 0 },
    { text: 'Custom date range', value: 1 },
  ];
  public selectedFilterValue = 0;
  public pageSize = 10;
  public skip = 0;
  startDate = new Date();
  endDate = new Date();
  dateError = false;
  isNoRecords = false;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  isBillableColumnVisible = true;
  public filterCount: number = 0;

  constructor(
    private timelogService: TimelogService,
    private spinnerService: NgxSpinnerService,
    private _Activatedroute: ActivatedRoute,
    private datePipe: DatePipe,
    public commonService: CommonService,
    private dialogService: DialogService,
    private router: Router,
    private toasterService: ToasterService,
  ) {
    this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.projectId = +atob(params.get('projectId'));
        this.getTimelogList();
      }
    });
  }

  ngOnInit(): void {}

  exportTimesheet(): void {
    this.spinnerService.show();
    const params: TimelogGetListInput = {
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      projectId: this.projectId,
      sorting: 'TimelogDatetime desc',
      startDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.startDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      endDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.endDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      format: ExportFormat.Excel,
      isPagination: false,
    };

    this.commonService.exportTimesheet(params).subscribe(
      response => {
        saveAs(response.blob, response.filename);
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.exportSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      },
      error => {
        console.error('Download failed:', error);
        this.spinnerService.hide();
      },
    );
  }

  onEdit(data) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
    });
    document.body.style.overflow = 'hidden';

    dialogRef.content.instance.timeLogId = data.id;
    dialogRef.content.instance.taskName = data.taskName;
    dialogRef.content.instance.taskId = data.taskId;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.isProjectBillable = !this.isBillableColumnVisible;

    dialogRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.getTimelogList();
        }
        document.body.style.overflow = 'scroll';
      }
    });
  }

  calculateEndTime(timelogDatetime: string, timelogMinutes: number): Date {
    const startTime = new Date(timelogDatetime);
    startTime.setMinutes(startTime.getMinutes() + timelogMinutes);
    return startTime;
  }

  updateStartDate(event): void {
    this.startDate = event;
    this.validateDates();
    this.updateFilterCount();
    if (!this.dateError) this.getTimelogList();
  }

  updateEndDate(event): void {
    this.endDate = event;
    this.validateDates();
    this.updateFilterCount();
    if (!this.dateError) this.getTimelogList();
  }

  validateDates() {
    if (this.startDate && this.endDate) {
      this.dateError = this.endDate < this.startDate;
    } else {
      this.dateError = false;
    }
  }

  closeDrawer() {
    this.isDrawerOpened = !this.isDrawerOpened;
    document.body.style.overflow = 'scroll';
  }

  selectionChange(event): void {
    this.dateError = false;
    this.selectedFilterValue = event.value;
    if (this.selectedFilterValue == 0) {
      this.startDate = undefined;
      this.endDate = undefined;
    } else {
      this.startDate = new Date();
      this.endDate = new Date();
    }
    this.updateFilterCount();
    this.getTimelogList();
  }

  openFilter(): void {
    this.isDrawerOpened = !this.isDrawerOpened;
    this.projectId = this.projectId;
    document.body.style.overflow = 'hidden';
  }

  pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.getTimelogList();
  }

  clearFilter(): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.selectedFilterValue = 0;
    this.getTimelogList();
    this.startDate = new Date();
    this.endDate = new Date();
    this.updateFilterCount();
    this.closeDrawer();
  }

  getTimelogList(): void {
    this.spinnerService.show();
    const params: TimelogGetListInput = {
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      projectId: this.projectId,
      sorting: 'TimelogDatetime desc',
      startDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.startDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      endDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.endDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      format: ExportFormat.Excel,
      isPagination: true,
    };
    this.timelogService.getTimeEntryList(params).subscribe(response => {
      this.spinnerService.hide();
      const processedItems = response.items.map(item => ({
        ...item,
        Date: this.datePipe.transform(
          new Date(item.timelogDatetime).toISOString().slice(0, 10),
          'EEEE dd, MMMM',
        ),
      }));

      const groupedData = process(processedItems, {
        group: [
          {
            field: 'Date',
            aggregates: [
              { field: 'timelogMinutes', aggregate: 'sum' },
              { field: 'timelogBillableMinutes', aggregate: 'sum' },
              { field: 'hoursDecimal', aggregate: 'sum' },
            ],
          },
        ],
      });

      groupedData.data = groupedData.data.sort(
        (a, b) => new Date(b.value).getTime() - new Date(a.value).getTime(),
      );

      this.gridView = {
        data: groupedData.data,
        total: response.totalCount,
      };

      this.isNoRecords = response.totalCount > 0 ? false : true;
      this.isBillableColumnVisible = processedItems.every(item => !item.isBillable);
    });
  }

  updateFilterCount(): void {
    let count = 0;
    if (this.selectedFilterValue == 1) count++;
    this.filterCount = count;
  }

  onGoToTaskDetailsClick(data): void {
    var params = {
      taskId: btoa(data.taskId),
      projectId: btoa(this.projectId),
      projectName: btoa(data.projectName),
      taskListId: btoa(data.tasklistId),
    };

    this.router.navigate(['task-Detail', params]);
  }
}
