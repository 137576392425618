import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IconsModule, SVGIconModule } from '@progress/kendo-angular-icons';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { FilterModule } from '@progress/kendo-angular-filter';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { MenuModule } from '@progress/kendo-angular-menu';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UploadsModule } from '@progress/kendo-angular-upload';

import { ListViewModule } from '@progress/kendo-angular-listview';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { AddPeopleTeamComponent } from 'projects/project/src/pages/project/add-people-team/add-people-team.component';
import { UseEnumValuePipe } from '../pipes/use-enum-value.pipe';
import { DragAndDropModule } from '@progress/kendo-angular-utils';

@NgModule({
  declarations: [AddPeopleTeamComponent, UseEnumValuePipe],
  imports: [
    CoreModule,
    ThemeSharedModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    InputsModule,
    LabelModule,
    IconsModule,
    LayoutModule,
    DropDownsModule,
    TreeViewModule,
    TreeListModule,
    ButtonsModule,
    GridModule,
    DropDownListModule,
    FilterModule,
    SwitchModule,
    MenuModule,
    PopupModule,
    DialogsModule,
    TreeViewModule,
    UploadsModule,
    ListViewModule,
    EditorModule,
    DateInputsModule,
    IntlModule,
    ProgressBarModule,
    SVGIconModule,
    DragAndDropModule,
    WindowModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    InputsModule,
    LabelModule,
    IconsModule,
    LayoutModule,
    DropDownsModule,
    DateInputsModule,
    TreeListModule,
    ButtonsModule,
    GridModule,
    DropDownListModule,
    FilterModule,
    SwitchModule,
    MenuModule,
    PopupModule,
    DialogsModule,
    TreeViewModule,
    IntlModule,
    ListViewModule,
    EditorModule,
    UploadsModule,
    ProgressBarModule,
    AddPeopleTeamComponent,
    SVGIconModule,
    UseEnumValuePipe,
    DragAndDropModule,
    WindowModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
