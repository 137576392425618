<kendo-popup *ngIf="isSetDatePopupVisible" [offset]="position">
  <div class="set-date-popup">
    <div class="daterange-wrapper">
      <!-- Start Date Section -->
      <div class="date-input-wrapper">
        <label>Start Date:</label>
        <input
          class="k-input"
          [value]="range.start | date: 'yyyy-MM-dd'"
          (click)="openCalendar('start')"
          placeholder="Select Start Date"
          readonly
        />
        <kendo-calendar
          *ngIf="isStartCalendarOpen"
          [(value)]="range.start"
          [animateNavigation]="true"
          (valueChange)="onDateSelect('start')"
        >
        </kendo-calendar>
      </div>

      <!-- End Date Section -->
      <div class="date-input-wrapper">
        <label>End Date:</label>
        <input
          class="k-input"
          [value]="range.end | date: 'yyyy-MM-dd'"
          (click)="openCalendar('end')"
          placeholder="Select End Date"
          readonly
        />
        <kendo-calendar
          *ngIf="isEndCalendarOpen"
          [(value)]="range.end"
          [animateNavigation]="true"
          (valueChange)="onDateSelect('end')"
        >
        </kendo-calendar>
      </div>
    </div>

    <!-- Date Action Buttons -->
    <div class="date-actions">
      <button class="k-button action-button" (click)="setToday()">Today</button>
      <button class="k-button action-button" (click)="addDaysToStartDate(1)">+1 day</button>
      <button class="k-button action-button" (click)="addDaysToStartDate(7)">+7 days</button>
      <button class="k-button action-button" (click)="setMonday()">Mon</button>
      <button class="k-button k-clear action-button" (click)="clearDates()">None</button>
    </div>
  </div>
</kendo-popup>
