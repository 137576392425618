<ngx-datatable
  default
  [rows]="data"
  [scrollbarV]="true"
  [count]="recordsTotal"
  [list]="list"
  (activate)="tableActivate.emit($event)"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
>
  <ng-container *ngFor="let prop of propList; let i = index; trackBy: trackByFn">
    <ngx-datatable-column
      [width]="columnWidths[i + 1] || 200"
      [name]="prop.displayName | abpLocalization"
      [prop]="prop.name"
      [sortable]="prop.sortable"
    >
      <ng-template ngx-datatable-header-template let-column="column">
        <span
          *ngIf="prop.tooltip; else elseBlock"
          [ngbTooltip]="prop.tooltip | abpLocalization"
          container="body"
        >
          {{ column.name }} <i class="fa fa-info-circle" aria-hidden="true"></i>
        </span>
        <ng-template #elseBlock>
          {{ column.name }}
        </ng-template>
      </ng-template>
      <ng-template let-row="row" let-i="index" ngx-datatable-cell-template>
        <ng-container *abpPermission="prop.permission; runChangeDetection: false">
          <ng-container *ngIf="row['_' + prop.name]?.visible">
            <div
              *ngIf="!row['_' + prop.name].component; else component"
              [innerHTML]="row['_' + prop.name]?.value | async"
              (click)="
                prop.action && prop.action({ getInjected: getInjected, record: row, index: i })
              "
              [ngClass]="entityPropTypeClasses[prop.type]"
              [class.pointer]="prop.action"
            ></div>
          </ng-container>
          <ng-template #component>
            <ng-container
              *ngComponentOutlet="
                row['_' + prop.name].component;
                injector: row['_' + prop.name].injector
              "
            ></ng-container>
          </ng-template>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>
  </ng-container>

  <ngx-datatable-column
    *ngIf="actionsTemplate || (actionList.length && hasAtLeastOnePermittedAction)"
    [name]="actionsText | abpLocalization"
    [maxWidth]="columnWidths[0]"
    [width]="columnWidths[0]"
    [sortable]="false"
    headerClass="text-center"
  >
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <ng-container
        *ngTemplateOutlet="actionsTemplate || gridActions; context: { $implicit: row, index: i }"
      ></ng-container>
      <ng-template #gridActions>
        <div class="text-align-right wp-100">
          <ul class="grid-action-list justify-content-end">
            <div class="d-flex gap-10 justify-content-end">
              <li>
                <a title="Edit" (click)="onEditClick(row.id)">
                  <i class="far fa-pen"></i>
                </a>
              </li>

              <li>
                <a title="Delete" (click)="onDeleteClick(row)">
                  <i class="far fa-trash-alt"></i>
                </a>
              </li>

              <li>
                <a title="{{ permissiontxt }}" (click)="onPermissionClick(row)">
                  <i class="far fa-bars"></i>
                </a>
              </li>
            </div>
          </ul>
        </div>
      </ng-template>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
