<div class="q-content dashboard">
  <div class="q-content-bg calculate-height">
    <div class="q-content-header">
      <div class="border-bottom pb-1 mb-2 q-content-header-title">
        <div class="d-flex gap-10 align-items-center">
          <i class="fa-regular fa-bullseye-arrow text-icon-red-color font-size-20"></i>
          <p class="m-0 font-size-18">Assigned to me</p>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex-row">
        <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
          <div class="q-card mb-20 flex-1 border">
            <div class="q-card-header d-flex justify-content-between no-border pb-0">
              <div>
                <p class="q-card-title">{{ dashboradCardDetails?.openTask.cardName }}</p>
                <p
                  class="q-card-value m-0 cursor-pointer"
                  (click)="
                    onTaskCount(
                      taskTypeStatus.OpenTasks,
                      dashboradCardDetails?.openTask.cardName,
                      dashboradCardDetails?.openTask.total
                    )
                  "
                >
                  {{ dashboradCardDetails?.openTask.total }}
                </p>
              </div>
              <div class="q-card-icon q-card-icon-first">
                <i class="fa-regular fa-clipboard-list-check"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
          <div class="q-card mb-20 flex-1 border cursor-pointer">
            <div class="q-card-header d-flex justify-content-between no-border pb-0">
              <div>
                <p class="q-card-title">{{ dashboradCardDetails?.closeTask.cardName }}</p>
                <p
                  class="q-card-value m-0 cursor-pointer"
                  (click)="
                    onTaskCount(
                      taskTypeStatus.CloseTasks,
                      dashboradCardDetails?.closeTask.cardName,
                      dashboradCardDetails?.closeTask.total
                    )
                  "
                >
                  {{ dashboradCardDetails?.closeTask.total }}
                </p>
              </div>
              <div class="q-card-icon q-card-icon-second">
                <i class="fa-regular fa-file-slash"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
          <div class="q-card mb-20 flex-1 border cursor-pointer">
            <div class="q-card-header d-flex justify-content-between no-border pb-0">
              <div>
                <p class="q-card-title">{{ dashboradCardDetails?.openIssue.cardName }}</p>
                <p
                  class="q-card-value m-0 cursor-pointer"
                  (click)="
                    onTaskCount(
                      taskTypeStatus.OpenBugs,
                      dashboradCardDetails?.openIssue.cardName,
                      dashboradCardDetails?.openIssue.total
                    )
                  "
                >
                  {{ dashboradCardDetails?.openIssue.total }}
                </p>
              </div>
              <div class="q-card-icon q-card-icon-third">
                <i class="fa-regular fa-bug"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
          <div class="q-card mb-20 flex-1 border cursor-pointer">
            <div class="q-card-header d-flex justify-content-between no-border pb-0">
              <div>
                <p class="q-card-title">{{ dashboradCardDetails?.closeIssue.cardName }}</p>
                <p
                  class="q-card-value m-0 cursor-pointer"
                  (click)="
                    onTaskCount(
                      taskTypeStatus.CloseBugs,
                      dashboradCardDetails?.closeIssue.cardName,
                      dashboradCardDetails?.closeIssue.total
                    )
                  "
                >
                  {{ dashboradCardDetails?.closeIssue.total }}
                </p>
              </div>
              <div class="q-card-icon q-card-icon-forth">
                <i class="fa-regular fa-bug-slash"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="q-content-content">
      <div class="grid-wrapper">
        <kendo-treelist
          #grid
          kendoTreeListExpandable
          [data]="taskData"
          idField="taskId"
          [fetchChildren]="fetchChildren"
          [hasChildren]="hasChildren"
          (add)="addHandler($event)"
          (edit)="editHandler($event)"
          (remove)="removeHandler($event)"
          (save)="saveHandler($event)"
          (cancel)="cancelHandler($event)"
          class="taskList-dashboard"
        >
          <ng-template kendoTreeListNoRecordsTemplate>
            <div *ngIf="isNoRecord && this.taskData.length === 0" class="no-records-text-container">
              No Records Available
            </div>
          </ng-template>
          <kendo-treelist-column
            [expandable]="true"
            field="taskName"
            title="Task Name"
            [width]="450"
          >
            <ng-template
              kendoTreeListCellTemplate
              let-dataItem
              let-column="column"
              let-formGroup="formGroup"
              let-isNew="isNew"
            >
              <div class="display-inline vertical-align-middle" *ngIf="!dataItem.hasChildren">
                <input
                  type="checkbox"
                  kendoCheckBox
                  class="billable-checkbox mr-5"
                  size="large"
                  (change)="onTaskCompleteChange($event, dataItem)"
                  [ngModel]="dataItem.taskStatus === taskStatusType.Completed"
                />
                <span (click)="onGoToTaskDetailsClick(dataItem)" class="mr-5">
                  <i [class]="getTaskTypeIconClass(dataItem.taskType)"></i>
                </span>
              </div>
              <span
                class="clamp w-340 display-inline vertical-align-middle"
                title="{{ dataItem.taskName }}"
                >{{ dataItem.taskName }}</span
              >
            </ng-template>
            <ng-template
              kendoTreeListEditTemplate
              let-dataItem="dataItem"
              let-column="column"
              let-formGroup="formGroup"
              let-isNew="isNew"
            >
              <form class="k-form k-form-md" [formGroup]="formGroup" validateOnSubmit>
                <fieldset class="k-form-fieldset d-flex gap-5">
                  <kendo-dropdownlist
                    [data]="taskTypeList"
                    textField="name"
                    valueField="id"
                    [valuePrimitive]="true"
                    [formControl]="formGroup.get('taskType')"
                    class="w-200 h-fit-content"
                    (keydown.enter)="
                      saveHandler({
                        sender: grid,
                        dataItem: dataItem,
                        parent: parent,
                        formGroup: formGroup,
                        isNew: isNew,
                      })
                    "
                  >
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                      <i class="{{ dataItem.iconClass }}"></i>{{ dataItem.name }}
                    </ng-template>
                  </kendo-dropdownlist>
                  <kendo-formfield class="mt-0 wp-100">
                    <kendo-textbox
                      [formControl]="formGroup.get('taskName')"
                      class="k-input-inner-text-box wp-100"
                      placeholder="Enter Task Name!"
                      #listName
                      (keydown.enter)="
                        saveHandler({
                          sender: grid,
                          dataItem: dataItem,
                          parent: parent,
                          formGroup: formGroup,
                          isNew: isNew,
                        })
                      "
                    ></kendo-textbox>
                  </kendo-formfield>
                </fieldset>
              </form>
            </ng-template>
          </kendo-treelist-column>
          <kendo-treelist-column field="dueDate" title="Due Date" [width]="200">
            <ng-template
              kendoTreeListEditTemplate
              let-dataItem="dataItem"
              let-column="column"
              let-formGroup="formGroup"
              let-isNew="isNew"
            >
              <form class="k-form k-form-md" [formGroup]="formGroup">
                <fieldset class="k-form-fieldset">
                  <kendo-datepicker
                    placeholder="Choose a date ..."
                    [format]="'EEE, d MMM yyyy'"
                    [formControl]="formGroup.get('dueDate')"
                    calendarType="classic"
                    (keydown.enter)="
                      saveHandler({
                        sender: grid,
                        dataItem: dataItem,
                        parent: parent,
                        formGroup: formGroup,
                        isNew: isNew,
                      })
                    "
                  ></kendo-datepicker>
                </fieldset>
              </form>
            </ng-template>
          </kendo-treelist-column>
          <kendo-treelist-column field="project" title="Project" [width]="200">
            <ng-template
              kendoTreeListEditTemplate
              let-dataItem="dataItem"
              let-column="column"
              let-formGroup="formGroup"
              let-isNew="isNew"
            >
              <form class="k-form k-form-md" [formGroup]="formGroup">
                <fieldset class="k-form-fieldset">
                  <kendo-dropdownlist
                    [data]="projectList"
                    [textField]="'name'"
                    [valueField]="'id'"
                    [valuePrimitive]="true"
                    [formControl]="formGroup.get('project')"
                    (valueChange)="
                      getAllTaskListWithProjectId($event, true, formGroup);
                      this.taskListDropdown.reset();
                      this.formGroup.get('taskListId').setValue(null)
                    "
                    (keydown.enter)="
                      saveHandler({
                        sender: grid,
                        dataItem: dataItem,
                        parent: parent,
                        formGroup: formGroup,
                        isNew: isNew,
                      })
                    "
                  ></kendo-dropdownlist>
                </fieldset>
              </form>
            </ng-template>
            <ng-template kendoTreeListCellTemplate let-dataItem>
              <div class="d-flex align-items-center gap-5">
                <img
                  *ngIf="dataItem.projectLogo"
                  [src]="dataItem.projectLogo"
                  alt="Project Logo"
                  class="project-logo"
                />
                <kendo-avatar
                  *ngIf="dataItem.project && !dataItem.projectLogo"
                  size="large"
                  [border]="true"
                  [initials]="commonService.getInitials(dataItem.project)"
                  class="project-logo"
                ></kendo-avatar>
                <span class="clamp" title="{{ dataItem.project }}">{{ dataItem.project }}</span>
              </div>
            </ng-template>
          </kendo-treelist-column>

          <kendo-treelist-column field="taskListId" title="Task List" [width]="200">
            <ng-template
              kendoTreeListEditTemplate
              let-dataItem="dataItem"
              let-column="column"
              let-formGroup="formGroup"
              let-isNew="isNew"
            >
              <form class="k-form k-form-md" [formGroup]="formGroup">
                <fieldset class="k-form-fieldset">
                  <kendo-dropdownlist
                    #taskListDropdown
                    [data]="taskList"
                    [textField]="'taskName'"
                    [valueField]="'id'"
                    [valuePrimitive]="true"
                    [formControl]="formGroup.get('taskListId')"
                    (keydown.enter)="
                      saveHandler({
                        sender: grid,
                        dataItem: dataItem,
                        parent: parent,
                        formGroup: formGroup,
                        isNew: isNew,
                      })
                    "
                  ></kendo-dropdownlist>
                </fieldset>
              </form>
            </ng-template>
            <ng-template kendoTreeListCellTemplate let-dataItem>
              <div title="{{ dataItem.taskListName }}" class="clamp">
                {{ dataItem.taskListName }}
              </div>
            </ng-template>
          </kendo-treelist-column>

          <kendo-treelist-column field="priority" title="Priority" [width]="150">
            <ng-template
              kendoTreeListEditTemplate
              let-dataItem="dataItem"
              let-column="column"
              let-formGroup="formGroup"
              let-isNew="isNew"
            >
              <form class="k-form k-form-md" [formGroup]="formGroup">
                <fieldset class="k-form-fieldset">
                  <kendo-dropdownlist
                    [data]="priorityFilter | useEnumValues"
                    textField="value"
                    valueField="key"
                    [valuePrimitive]="true"
                    [formControl]="formGroup.get('priority')"
                    (keydown.enter)="
                      saveHandler({
                        sender: grid,
                        dataItem: dataItem,
                        parent: parent,
                        formGroup: formGroup,
                        isNew: isNew,
                      })
                    "
                  >
                  </kendo-dropdownlist>
                </fieldset>
              </form>
            </ng-template>
            <ng-template kendoTreeListCellTemplate let-dataItem>
              <div class="status-container priority-{{ dataItem.priority }}">
                <span class="indicator mr-5"></span>
                {{ dataItem.priority }}
              </div>
            </ng-template>
          </kendo-treelist-column>
          <kendo-treelist-column [width]="80">
            <ng-template
              kendoTreeListCellTemplate
              let-dataItem
              let-isNew="isNew"
              let-cellContext="cellContext"
            >
              <ul class="grid-action-list grid-command-action d-flex justify-content-end">
                <li>
                  <a
                    *ngIf="!dataItem.isHeader"
                    [kendoTreeListEditCommand]="cellContext"
                    [svgIcon]="commonService.pencilIcon"
                    title="Edit"
                    [primary]="true"
                    class="grid-command-action-links"
                  ></a>
                </li>

                <li *ngIf="!checkIsDisable(dataItem)">
                  <a
                    [kendoTreeListRemoveCommand]="cellContext"
                    [svgIcon]="commonService.trashIcon"
                    title="Remove"
                    class="grid-command-action-links"
                  ></a>
                </li>

                <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
                  <a
                    [kendoTreeListSaveCommand]="cellContext"
                    [disabled]="formGroup?.invalid"
                    [svgIcon]="commonService.saveIcon"
                    title="{{ isNew ? 'Add' : 'Update' }}"
                    class="grid-command-action-links"
                  ></a>
                </li>

                <li>
                  <a
                    [kendoTreeListCancelCommand]="cellContext"
                    [svgIcon]="commonService.cancelIcon"
                    title="{{ isNew ? 'Discard changes' : 'Cancel' }}"
                    class="grid-command-action-links"
                  ></a>
                </li>
              </ul>
            </ng-template>
          </kendo-treelist-column>
          <ng-template kendoTreeListToolbarTemplate [position]="'bottom'">
            <button kendoTreeListAddCommand type="button" class="add-new-btn">
              <i class="fa-regular fa-plus mr-5"></i> Add new task
            </button>
          </ng-template>
        </kendo-treelist>
      </div>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
