import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import {
  SVGIcon,
  cancelIcon,
  chevronDownIcon,
  chevronRightIcon,
  filterAddExpressionIcon,
  folderIcon,
  infoCircleIcon,
  pencilIcon,
  plusIcon,
  saveIcon,
  trashIcon,
} from '@progress/kendo-svg-icons';
import { Toaster, ToasterService } from '@abp/ng.theme.shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionStateService } from '@abp/ng.core';
import { TaskType } from 'src/app/enum/task-type';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskStatusType } from 'projects/project-service/src/lib/proxy/task-service';

@Injectable()
export class CommonService {
  themeColorChange = new Subject<any>();
  editChange = new Subject<any>();
  permissionChange = new Subject<any>();
  deleteChange = new Subject<any>();

  public addExpressionIcon: SVGIcon = filterAddExpressionIcon;
  public trashIcon: SVGIcon = trashIcon;
  public saveIcon: SVGIcon = saveIcon;
  public cancelIcon: SVGIcon = cancelIcon;
  public pencilIcon: SVGIcon = pencilIcon;
  public arrowDown: SVGIcon = chevronRightIcon;
  public arrowUp: SVGIcon = chevronDownIcon;
  public plusIcon: SVGIcon = plusIcon;
  public infoSVG: SVGIcon = infoCircleIcon;
  public tagIcon: SVGIcon = folderIcon;

  toasterMessageConfiguration: Partial<Toaster.ToastOptions> = {
    life: 2500,
    tapToDismiss: true,
  };
  constructor(
    private http: HttpClient,
    private sessionState: SessionStateService,
  ) {}

  private showTimer: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public showTimer$ = this.showTimer.asObservable();

  private getAllTimeLogListFromTimer = new Subject<any>();
  allTimeLogListFromTimer$ = this.getAllTimeLogListFromTimer.asObservable();

  refreshSubtaskList = new EventEmitter();
  refreshFileList = new EventEmitter();

  addValidation(form: any): void {
    (Object as any).values(form.controls).forEach(c => c.markAsTouched());
  }

  refreshSubtask(data) {
    this.refreshSubtaskList.emit(data);
  }

  refreshFiles(data) {
    this.refreshFileList.emit(data);
  }

  showTimerPopup(value): void {
    this.showTimer.next(value);
  }

  getInitials(name: string): string {
    if (name) {
      const nameParts = name.split(' ');
      const firstNameInitial = nameParts[0] ? nameParts[0][0].toUpperCase() : '';
      const lastInitial = nameParts[1] ? nameParts[1][0].toUpperCase() : '';

      return firstNameInitial + lastInitial;
    } else {
      return '';
    }
  }

  getTaskTypeList(): { id: TaskType; name: string; iconClass: string }[] {
    return [
      {
        id: TaskType.Task,
        name: TaskType[TaskType.Task],
        iconClass: 'far fa-clipboard-list font-size-14',
      },
      {
        id: TaskType.Bug,
        name: TaskType[TaskType.Bug],
        iconClass: 'far fa-bug font-size-14 bug-icon-color',
      },
    ];
  }

  getPriorityList(): { id: Priority; text: string }[] {
    return [
      {
        id: Priority.High,
        text: Priority[Priority.High],
      },
      {
        id: Priority.Low,
        text: Priority[Priority.Low],
      },
      {
        id: Priority.Medium,
        text: Priority[Priority.Medium],
      },
      {
        id: Priority.None,
        text: Priority[Priority.None],
      },
    ];
  }

  getInitialsAndAvtarColor(assignee: any): any {
    if (assignee) {
      return {
        avatar: this.getInitials(assignee.userName),
        generateAvtarColor: this.generateAvtarColor(assignee.userId, assignee.userName),
        userName: assignee.userName,
      };
    }
    return '';
  }

  generateAvtarColor = (userId: string, userName: string): string => {
    const combinedString = userId + userName;
    const hRange = [0, 360];
    const sRange = [50, 75];
    const lRange = [25, 60];

    const getHashOfString = (str: string) => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      hash = Math.abs(hash);
      return hash;
    };

    const normalizeHash = (hash: number, min: number, max: number) => {
      return Math.floor((hash % (max - min)) + min);
    };

    const hash = getHashOfString(combinedString);
    const h = normalizeHash(hash, hRange[0], hRange[1]);
    const s = normalizeHash(hash, sRange[0], sRange[1]);
    const l = normalizeHash(hash, lRange[0], lRange[1]);
    return `hsl(${h}, ${s}%, ${l}%)`;
  };

  getOnlyHoursFromMinutes(totalMinutes: number): number {
    return totalMinutes / 60;
  }

  getHoursAndMinutesFromTotalMinutes(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} h ${minutes} m`;
  }

  getTotalMinutesFromHoursAndMinutes(time: string): number {
    const timeParts = time.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    return hours * 60 + minutes;
  }

  getEndDate(timelogDatetime, timelogMinutes): any {
    let timelogDatetimeData = new Date(timelogDatetime);

    timelogDatetimeData.setMinutes(timelogDatetimeData.getMinutes() + timelogMinutes);

    return timelogDatetimeData;
  }

  getAllTimeLogList(value) {
    this.getAllTimeLogListFromTimer.next(value);
  }

  preventClose(event: MouseEvent) {
    event.stopPropagation();
  }

  onDialogClose(dialogRef, isSuccess): void {
    const dialogResult = { confirmed: isSuccess };
    dialogRef.close(dialogResult);
  }

  convertMinutesToHoursAndMinutes(totalMinutes: number): { hours: number; minutes: number } {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  }

  onKeyDownForEstHour(event: KeyboardEvent) {
    if (
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      (event.keyCode === 65 && event.ctrlKey === true) ||
      (event.keyCode === 67 && event.ctrlKey === true) ||
      (event.keyCode === 88 && event.ctrlKey === true) ||
      (event.keyCode === 86 && event.ctrlKey === true) ||
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      return;
    }
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  }

  onKeyDownForEstMinutes(event: KeyboardEvent) {
    if (
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      (event.keyCode === 65 && event.ctrlKey === true) ||
      (event.keyCode === 67 && event.ctrlKey === true) ||
      (event.keyCode === 88 && event.ctrlKey === true) ||
      (event.keyCode === 86 && event.ctrlKey === true) ||
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      return;
    }
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    } else {
      const currentValue = parseInt((event.target as HTMLInputElement).value + event.key, 10);
      if (currentValue > 59) {
        event.preventDefault();
      }
    }
  }

  astricValidationColor(cdr?, elementRef?, renderer?) {
    cdr.detectChanges();
    const labelElements = elementRef.nativeElement.querySelectorAll(
      'abp-extensible-form .form-label',
    );
    labelElements.forEach((label: HTMLElement) => {
      const labelText = label.textContent.trim();
      if (label.textContent.includes('*')) {
        const labelTextWithoutAsterisk = labelText.endsWith('*') ? labelText.slice(0, -1) : '';
        label.textContent = labelTextWithoutAsterisk;
        const asterisk = renderer.createElement('span');
        asterisk.textContent = '*';
        renderer.setStyle(asterisk, 'color', '#f44336');
        renderer.appendChild(label, asterisk);
      }
    });
  }

  exportTimesheet(param: any): Observable<{ blob: Blob; filename: string }> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let apiURL = environment.apis.default.url;
    const tenant = this.sessionState.getTenant();

    if (tenant?.id) {
      apiURL = apiURL.replace('{0}', tenant?.name);
    } else {
      apiURL = apiURL.replace('{0}.', '');
    }

    return this.http
      .post(`${apiURL}api/timelog/exportentries`, param, {
        headers: headers,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map(response => {
          const fileName =
            response.headers
              .get('Content-Disposition')
              ?.split(';')
              .map(x => (x ?? '').trim().split('='))
              .find(x => x[0] === 'filename')
              ?.pop() || 'timesheet.xlsx';
          return {
            blob: response.body as Blob,
            filename: fileName,
          };
        }),
      );
  }

  isFileTypeImage(fileName: string) {
    var fileType = fileName.split('.').pop();
    return fileType === 'jpeg' || fileType === 'png' || fileType === 'jpg';
  }

  getTaskStatusName(statusType: any): string {
    // Get the name of the enum value
    const statusName = TaskStatusType[statusType];

    return statusName;
  }

  preprocessAssigneeList(assigneeList: any[]): any[] {
    return assigneeList.map(assignee => {
      const fullName =
        assignee.name?.trim() && assignee.surname?.trim()
          ? `${assignee.name} ${assignee.surname}`
          : assignee.userName;

      return {
        ...assignee,
        userName: fullName,
      };
    });
  }
}
