import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlShortenerService {
  private storageKey = 'url_params';

  shortUrl(params: any): string {
    const taskId = atob(params.taskId);
    const shortKey = `T-${taskId}`;
    localStorage.setItem(this.storageKey, JSON.stringify(params));
    return shortKey;
  }

  getStoredUrlData(): any {
    const data = localStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : {};
  }
}
