<div [ngClass]="isFromDashboard ? '' : 'p-20 q-content'">
  <div [ngClass]="isFromDashboard ? '' : 'q-content-bg calculate-height'">
    <div class="q-content-header">
      <div *ngIf="!isFromDashboard">
        <kendo-tabstrip
          class="project-name-header-tab d-flex align-items-center"
          (tabSelect)="onTabChange($event)"
        >
          <kendo-tabstrip-tab title="Project" [disabled]="true">
            <ng-template kendoTabTitle>
              <p class="m-0 font-size-18 fw-500">
                <kendo-avatar
                  size="large"
                  [border]="true"
                  [initials]="commonService.getInitials(projectName)"
                  class="project-logo mr-5"
                ></kendo-avatar
                ><span class="display-inline clamp vertical-align-middle mr-1">{{
                  projectName
                }}</span>
              </p>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab title="List" [selected]="selectedTabId === tasklistTab.List">
            <ng-template kendoTabContent>
              <div class="d-flex justify-content-end flex-wrap gap-15 action-panel">
                <kendo-textbox
                  placeholder="Search for task list / task name"
                  [(ngModel)]="searchText"
                  [ngModelOptions]="{ standalone: true }"
                  (keyup.enter)="onSearchTextChange()"
                  class="search d-none"
                >
                  <ng-template kendoTextBoxPrefixTemplate>
                    <i class="fas fa-search k-icon font-size-14"></i>
                  </ng-template>
                </kendo-textbox>
                <div class="d-flex gap-10 align-items-center">
                  <div (click)="onShowTaskFilter($event)">
                    <span class="filter-icon-container p-10" title="Filter">
                      <i class="far fa-filter font-size-14"></i>
                      <span>{{ taskFilterCount }}</span>
                    </span>
                  </div>
                  <app-task-filter
                    [showTaskFilterPopup]="showTaskFilterPopup"
                    [position]="popupPosition"
                    [projectId]="projectId"
                    (triggerAppliedFilter)="getAppliedFilter($event)"
                  >
                  </app-task-filter>
                  <kendo-button kendoButton (click)="onAddTaskList()"
                    ><i class="far fa-plus pr-5"></i>Add Task List
                  </kendo-button>
                  <div style="display: none">
                    <kendo-dropdownlist [data]="statusList"></kendo-dropdownlist>
                    <a class="flat-icon-container"><i class="fa-light fa-bars-filter"></i></a>
                    <a class="flat-icon-container"><i class="fas fa-ellipsis-h"></i></a>
                  </div>
                </div>
              </div>

              <div
                id="taskList"
                class="grid-wrapper task-list-grid scrollable-list-container"
                *ngIf="taskListsList.length > 0"
                (scroll)="onScrollTaskListList($event)"
              >
                <kendo-expansionpanel
                  *ngFor="let item of taskListsList; index as i"
                  [expanded]="currentExpandedPanelId === item.id"
                  (action)="onTaskListsListExpandedPanelClick(i, item)"
                  [svgExpandIcon]="commonService.arrowDown"
                  [svgCollapseIcon]="commonService.arrowUp"
                  (expand)="adjustMaxWidth()"
                  (collapse)="onCollapse(i, item)"
                >
                  <ng-template kendoExpansionPanelTitleDirective>
                    <div
                      class="d-flex align-items-center justify-content-between expansion-panel-title-wrapper wp-100 flex-wrap gap-10"
                    >
                      <div class="d-flex flex-wrap align-items-center gap-10">
                        <span class="font-size-16">{{ item.name }}</span>
                        <span class="blue-label"
                          >{{ item.completeCount }} Completed out of
                          {{ item.totalNumberOfTask }} Tasks</span
                        >

                        <kendo-dropdownbutton
                          #taksListAction
                          [data]="taksListActionItem"
                          class="dropdown-button"
                          (itemClick)="onTaskListActionClick($event, item)"
                          (click)="stopPropagation($event)"
                          (open)="onDropdownOpen()"
                          ><i class="fas fa-ellipsis-v"></i>
                        </kendo-dropdownbutton>
                        <button
                          kendoButton
                          [disabled]="disableAddTaskListIcon[i]"
                          class="add-task-icon"
                          [class.disabled]="disableAddTaskListIcon[i]"
                          title="Add task"
                          (click)="stopPropagation($event); onAddTaskClickOnTop(true, i)"
                        >
                          <i class="fa-solid fa-plus"></i>
                        </button>
                      </div>
                      <div class="d-flex gap-10">
                        <div class="blue-label gap-5">
                          <span>
                            <i class="far fa-hourglass mr-5"></i>
                            {{
                              commonService.getHoursAndMinutesFromTotalMinutes(
                                item.totalEstimatedTime
                              )
                            }}
                          </span>
                          <span>/</span>
                          <span>
                            <i class="fa-regular fa-clock mr-5"></i>
                            {{
                              commonService.getHoursAndMinutesFromTotalMinutes(item.totalTimelogged)
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="grid-wrapper">
                    <div
                      [ngClass]="{ 'add-new-task-area': this.showAddNewTaskInput[i] }"
                      [hidden]="!this.showAddNewTaskInput[i]"
                    >
                      <div
                        #treeviewContainer
                        class="d-flex justify-content-between gap-10 align-items-baseline"
                      >
                        <form
                          class="k-form wp-100"
                          [formGroup]="addNewTaskformGroup"
                          validateOnSubmit
                        >
                          <fieldset class="k-form-fieldset mb-20">
                            <kendo-formfield class="mt-0 wp-100">
                              <kendo-textbox
                                formControlName="taskName"
                                class="add-task-area"
                                aria-placeholder="Give your project a name"
                                #taskName
                                (keydown.enter)="onSaveNewTask(item.id, true, i)"
                                [disabled]="isNewTaskSaving"
                              ></kendo-textbox>
                              <kendo-formerror
                                *ngIf="addNewTaskformGroup.controls.taskName.errors?.maxlength"
                                >Error: Task Name cannot be more than 255
                                character!</kendo-formerror
                              >
                              <kendo-formerror
                                *ngIf="addNewTaskformGroup.controls.taskName.errors?.required"
                                >Error: Task Name is required!</kendo-formerror
                              >

                              <kendo-formerror
                                *ngIf="addNewTaskformGroup.controls.taskName.errors?.whitespace"
                              >
                                Error: Task Name cannot be empty or contain only spaces!
                              </kendo-formerror>
                            </kendo-formfield>
                          </fieldset>
                          <div *ngIf="isShowDescriptionEditor">
                            <kendo-editor
                              class="mb-20 mt-10 kendo-editor vertical-resize-only"
                              placeholder="Add a description"
                              [iframeCss]="{ content: customStyleContent }"
                              formControlName="description"
                              [resizable]="true"
                            ></kendo-editor>
                          </div>
                        </form>
                        <div class="d-flex gap-10 mt-10">
                          <span *ngIf="taskEstimateMinutes">
                            <i class="fa-regular fa-hourglass"></i>
                            {{
                              commonService.getHoursAndMinutesFromTotalMinutes(taskEstimateMinutes)
                            }}
                          </span>
                        </div>
                        <ul class="d-flex gap-10 action-menu d-flex gap-5 mt-10">
                          <li>
                            <a
                              class="grid-command-action-links"
                              title="Set an estimate"
                              (click)="openSetEstimate($event, dataItem, true)"
                              (mouseleave)="onEstimateHoverOut($event)"
                            >
                              <i class="fa-regular fa-hourglass"></i>
                              {{ dataItem?.estimateMinutes }}</a
                            >
                          </li>

                          <li (click)="showDescriptionEditor(dataItem)">
                            <a class="grid-command-action-links" title="Add a description">
                              <i class="fa-solid fa-bars"></i>
                            </a>
                          </li>
                          <li (click)="onSaveNewTask(item.id, true, i)">
                            <a class="grid-command-action-links" title="Save">
                              <i class="far fa-floppy-disk"></i>
                            </a>
                          </li>
                          <li (click)="onAddTaskClickOnTop(false, i)">
                            <a class="grid-command-action-links" title="Cancel">
                              <i class="far fa-ban"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <kendo-treeview
                      #treeview
                      [nodes]="taskList"
                      textField="name"
                      [children]="fetchSubtask"
                      [hasChildren]="hasSubtask"
                      kendoTreeViewExpandable
                      [pageSize]="pageSize"
                      totalField="totalCount"
                      expandBy="id"
                      kendoTreeViewDragAndDrop
                      kendoTreeViewDragAndDropEditing
                      (nodeDrop)="onNodeDrop($event)"
                      (nodeDragStart)="onNodeDragStart($event)"
                      class="task-list-table"
                    >
                      <ng-template
                        kendoTreeViewNodeTemplate
                        let-dataItem
                        let-index="index"
                        let-parentDataItem="parentDataItem"
                      >
                        <div
                          class="task-list-grid"
                          (mouseenter)="onHoverTaskListLastItem(dataItem, index, parentDataItem)"
                          (mouseleave)="onLeaveTaskListPanel()"
                        >
                          <div #taskmain>
                            <div class="display-inline">
                              <input
                                [disabled]="checkIsDisable(dataItem)"
                                [checked]="dataItem.taskStatus === taskStatusType.Completed"
                                type="checkbox"
                                kendoCheckBox
                                class="billable-checkbox"
                                size="large"
                                (change)="onTaskCompleteChange(dataItem, $event)"
                              />

                              <kendo-dropdownbutton
                                #taskTypeDropdown
                                [iconClass]="getIconClass(dataItem.taskType)"
                                [data]="taskTypeList"
                                textField="name"
                                (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                                [popupSettings]="{ popupClass: popupClass }"
                              >
                                <ng-template
                                  kendoDropDownButtonItemTemplate
                                  let-dataItemDropDownButton
                                >
                                  <div
                                    class="dropdown-menu-links"
                                    [ngClass]="{
                                      taskTypeSelected:
                                        dataItem.taskType === dataItemDropDownButton.id,
                                    }"
                                  >
                                    <i
                                      class="{{
                                        dataItemDropDownButton.iconClass
                                      }} dropdown-item-color mr-5"
                                    ></i>
                                    {{ dataItemDropDownButton.name }}
                                  </div>
                                </ng-template>
                              </kendo-dropdownbutton>
                              <div class="display-inline mr-10 cursor-pointer">
                                <div
                                  *ngIf="
                                    (dataItem.assignee && dataItem.assignee.length === 0) ||
                                      dataItem.assignee === null;
                                    else assigneeTemplate
                                  "
                                  (click)="openSetAssignee($event, dataItem)"
                                  class="display-inline vertical-align-middle"
                                >
                                  <i class="fal fa-circle-user font-size-22"></i>
                                </div>

                                <ng-template #assigneeTemplate>
                                  <div>
                                    <ng-container *ngFor="let user of dataItem.assignee">
                                      <kendo-avatar
                                        rounded="full"
                                        size="large"
                                        [border]="true"
                                        [initials]="
                                          commonService.getInitialsAndAvtarColor(user).avatar
                                        "
                                        [style.backgroundColor]="
                                          commonService.getInitialsAndAvtarColor(user)
                                            .generateAvtarColor
                                        "
                                        class="social-profile-text assignee-container"
                                        [attr.title]="
                                          commonService.getInitialsAndAvtarColor(user).userName
                                        "
                                        (click)="openSetAssignee($event, dataItem)"
                                      ></kendo-avatar>
                                    </ng-container>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                            <a
                              (click)="onTaskNameClick(dataItem, $event)"
                              [target]="openInNewTab ? '_blank' : null"
                              class="display-inline"
                              #taskTitle
                            >
                              <span
                                title="{{ dataItem.taskName }}"
                                class="display-inline task-title clamp"
                              >
                                {{ dataItem.taskName }}
                              </span>
                            </a>
                            <div class="display-inline ml-10">
                              <div
                                class="display-inline mr-10"
                                [ngClass]="{ 'q-label': dataItem.startDate || dataItem.dueDate }"
                              >
                                <ng-container *ngIf="dataItem.startDate">
                                  <span>{{ dataItem.startDate | date: 'dd-MMM-yyyy' }}</span>
                                </ng-container>
                                <span *ngIf="dataItem.startDate && dataItem.dueDate"> - </span>
                                <ng-container *ngIf="dataItem.dueDate">
                                  <span class="text-danger">
                                    {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}</span
                                  >
                                </ng-container>
                              </div>

                              <div
                                *ngIf="dataItem.priority"
                                class="task-status-container mr-10 priority-{{
                                  priorityEnum[dataItem.priority]
                                }}"
                                title="{{ priorityEnum[dataItem.priority] }}"
                              >
                                <span class="status-section">
                                  <i class="fa-solid fa-flag"></i>
                                </span>
                              </div>

                              <div
                                *ngIf="dataItem.estimateMinutes > 0"
                                class="display-inline q-label mr-10"
                              >
                                <span
                                  class="cursor-pointer"
                                  (click)="openSetEstimate($event, dataItem)"
                                  (mouseleave)="onEstimateHoverOut($event)"
                                >
                                  Est:
                                  {{
                                    commonService.getHoursAndMinutesFromTotalMinutes(
                                      dataItem.estimateMinutes
                                    )
                                  }}
                                </span>
                              </div>
                              <div
                                class="display-inline q-label mr-10"
                                *ngIf="dataItem.timelogMinutes > 0"
                              >
                                <span
                                  (mouseleave)="onTimelogIconHoverOut($event)"
                                  (click)="onTimelogIconClick($event, dataItem)"
                                  style="cursor: pointer"
                                  class="timelog-span"
                                >
                                  Log:
                                  {{
                                    commonService.getHoursAndMinutesFromTotalMinutes(
                                      dataItem.timelogMinutes
                                    )
                                  }}
                                </span>
                              </div>
                              <div
                                class="display-inline q-label mr-10 cursor-pointer"
                                *ngIf="dataItem.attachmentCount > 0"
                                (click)="onEditClick(dataItem, false, drawerTab.Files)"
                              >
                                <span>
                                  <i class="far fa-paperclip"></i>
                                  {{ dataItem.attachmentCount }}
                                </span>
                              </div>
                              <div
                                class="display-inline q-label mr-10"
                                *ngIf="dataItem.projectTaskStatus"
                              >
                                <span
                                  class="cursor-pointer"
                                  (click)="toggleStatusPopup($event, dataItem)"
                                  (mouseleave)="onStatusHoverOut($event)"
                                >
                                  {{ dataItem.projectTaskStatusName }}
                                </span>
                              </div>
                            </div>
                            <div
                              class="task-list-action display-inline"
                              [ngClass]="{
                                'visible-task-list':
                                  hoveredTaskId === dataItem.id ||
                                  popupTaskId === dataItem.id ||
                                  (showTimelogPopup && hoveredTaskId === dataItem.id),
                              }"
                            >
                              <ul class="d-flex justify-content-end gap-5">
                                <li (click)="onAddSubtaskClick(dataItem)">
                                  <a class="grid-command-action-links" title="Add SubTask">
                                    <i class="far fa-plus-square"></i
                                  ></a>
                                </li>
                                <li (click)="onEditClick(dataItem, true, drawerTab.Timelog)">
                                  <a class="grid-command-action-links" title="Edit">
                                    <i class="far fa-pencil"></i
                                  ></a>
                                </li>
                                <li (click)="onDeleteClick(dataItem, false)">
                                  <a class="grid-command-action-links" title="Delete">
                                    <i class="far fa-trash-can"></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    class="grid-command-action-links"
                                    title="Set an estimate"
                                    (click)="openSetEstimate($event, dataItem)"
                                    (mouseleave)="onEstimateHoverOut($event)"
                                  >
                                    <i class="fa-regular fa-hourglass"></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    class="grid-command-action-links"
                                    title="Log Time"
                                    (mouseleave)="onTimelogIconHoverOut($event)"
                                    (click)="onTimelogIconClick($event, dataItem)"
                                  >
                                    <i class="fa-regular fa-timer"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="grid-command-action-links"
                                    title="Add Comment"
                                    (click)="onEditClick(dataItem, false, drawerTab.Comment)"
                                  >
                                    <i class="fa-regular fa-comment"></i
                                  ></a>
                                </li>
                                <li>
                                  <a
                                    class="grid-command-action-links"
                                    title="Add Priority"
                                    (mouseleave)="onPriorityHoverOut($event)"
                                    (click)="togglePriorityDropdown($event, dataItem)"
                                  >
                                    <i class="fa-regular fa-flag"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="grid-command-action-links"
                                    title="Add Status"
                                    (click)="toggleStatusPopup($event, dataItem)"
                                    (mouseleave)="onStatusHoverOut($event)"
                                  >
                                    <i class="fa-regular fa-battery-full"></i>
                                  </a>
                                </li>
                                <li (click)="openAttachedFile(dataItem)">
                                  <a class="grid-command-action-links" title="Attach Files">
                                    <i class="far fa-paperclip"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div
                            *ngIf="dataItem.isAddSubtask"
                            class="d-flex justify-content-between gap-10 align-items-baseline"
                          >
                            <div class="wp-100">
                              <span>{{ dataItem.name }}</span>
                              <kendo-textbox
                                #SubTaskEditor
                                class="add-task-area"
                                (input)="onInputChange(SubTaskEditor.value, dataItem)"
                                (keydown.enter)="onSaveSubTask(SubTaskEditor, dataItem, item.id)"
                              ></kendo-textbox>

                              <kendo-formerror *ngIf="subTaskErrors[dataItem.id]">
                                Error: Subtask name is required.
                              </kendo-formerror>
                              <kendo-formerror *ngIf="subTaskMaxLengthErrors[dataItem.id]">
                                Error: Subtask Name cannot be more than 255 character!
                              </kendo-formerror>
                              <kendo-formerror *ngIf="subTaskWhiteSpaceErrors[dataItem.id]">
                                Error: Subtask Name cannot be empty or contain only spaces!
                              </kendo-formerror>
                              <div *ngIf="isShowSubTaskDescriptionEditor[dataItem.id]">
                                <kendo-editor
                                  [(ngModel)]="dataItem.description"
                                  #SubTaskDescriptionEditor
                                  class="wp-100 kendo-editor mt-10 vertical-resize-only"
                                  placeholder="Add a description"
                                  [iframeCss]="{ content: customStyleContent }"
                                  [resizable]="true"
                                ></kendo-editor>
                              </div>
                            </div>
                            <div class="d-flex gap-10 mt-10 gap-5">
                              <span *ngIf="taskEstimateMinutes">
                                <i class="fa-regular fa-hourglass"></i>
                                {{
                                  commonService.getHoursAndMinutesFromTotalMinutes(
                                    taskEstimateMinutes
                                  )
                                }}
                              </span>
                            </div>
                            <ul class="d-flex gap-10" class="action-menu d-flex gap-5 mt-10">
                              <!-- darshan -->
                              <li>
                                <a
                                  class="grid-command-action-links"
                                  title="Set an estimate"
                                  (click)="openSetEstimate($event, undefined, true)"
                                  (mouseleave)="onEstimateHoverOut($event)"
                                >
                                  <i class="fa-regular fa-hourglass"></i
                                ></a>
                              </li>
                              <li (click)="showSubTaskDescriptionEditor(dataItem)">
                                <a class="grid-command-action-links" title="Add a description">
                                  <i class="fa-solid fa-bars"></i>
                                </a>
                              </li>
                              <li (click)="onSaveSubTask(SubTaskEditor, dataItem, item.id)">
                                <a class="grid-command-action-links" title="Save"
                                  ><i class="far fa-floppy-disk"></i
                                ></a>
                              </li>
                              <li (click)="onCancelSubtask(dataItem)">
                                <a class="grid-command-action-links" title="Cancel"
                                  ><i class="far fa-ban"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </ng-template>
                    </kendo-treeview>
                    <div [ngClass]="{ 'add-new-task-area': isAddNew }">
                      <div
                        *ngIf="isAddNew"
                        class="d-flex justify-content-between gap-10 align-items-baseline"
                      >
                        <form
                          class="k-form wp-100"
                          [formGroup]="addNewTaskformGroup"
                          validateOnSubmit
                        >
                          <fieldset class="k-form-fieldset mb-20">
                            <kendo-formfield class="mt-0 wp-100">
                              <kendo-textbox
                                formControlName="taskName"
                                class="add-task-area"
                                aria-placeholder="Give your project a name"
                                #taskName
                                (keydown.enter)="onSaveNewTask(item.id, false)"
                              ></kendo-textbox>
                              <kendo-formerror
                                *ngIf="addNewTaskformGroup.controls.taskName.errors?.maxlength"
                                >Error: Task Name cannot be more than 255
                                character!</kendo-formerror
                              >
                              <kendo-formerror
                                *ngIf="addNewTaskformGroup.controls.taskName.errors?.required"
                                >Error: Task Name is required!</kendo-formerror
                              >

                              <kendo-formerror
                                *ngIf="addNewTaskformGroup.controls.taskName.errors?.whitespace"
                              >
                                Error: Task Name cannot be empty or contain only spaces!
                              </kendo-formerror>
                            </kendo-formfield>
                          </fieldset>
                          <div *ngIf="isShowDescriptionEditor">
                            <kendo-editor
                              class="mb-20 kendo-editor vertical-resize-only"
                              placeholder="Add a description"
                              [iframeCss]="{ content: customStyleContent }"
                              formControlName="description"
                              [resizable]="true"
                            ></kendo-editor>
                          </div>
                        </form>
                        <div class="d-flex gap-10 mt-10 gap-5">
                          <span *ngIf="taskEstimateMinutes">
                            <i class="fa-regular fa-hourglass"></i>
                            {{
                              commonService.getHoursAndMinutesFromTotalMinutes(taskEstimateMinutes)
                            }}
                          </span>
                        </div>
                        <ul class="d-flex gap-10 action-menu d-flex gap-5 mt-10">
                          <li>
                            <a
                              class="grid-command-action-links"
                              title="Set an estimate"
                              (click)="openSetEstimate($event, dataItem, true)"
                              (mouseleave)="onEstimateHoverOut($event)"
                            >
                              <i class="fa-regular fa-hourglass"></i
                            ></a>
                          </li>
                          <li (click)="showDescriptionEditor(dataItem)">
                            <a class="grid-command-action-links" title="Add a description">
                              <i class="fa-solid fa-bars"></i>
                            </a>
                          </li>
                          <li (click)="onSaveNewTask(item.id, false)">
                            <a class="grid-command-action-links" title="Save">
                              <i class="far fa-floppy-disk"></i>
                            </a>
                          </li>
                          <li (click)="onAddTaskClick(false)">
                            <a class="grid-command-action-links" title="Cancel">
                              <i class="far fa-ban"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <button
                        *ngIf="!isAddNew && taskList.length === totalTaskCount"
                        (click)="onAddTaskClick(true)"
                        class="add-new-btn"
                      >
                        <i class="far fa-plus mr-5"></i> Add new
                      </button>
                    </div>
                    <div
                      *ngIf="
                        taskList.length === totalTaskCount || this.filterRequestParam?.completedOnly
                      "
                    >
                      <div
                        *ngIf="totalCompletedTaskCount > 0"
                        class="border-top"
                        [ngClass]="filterRequestParam?.completedOnly ? '' : 'pt-1 pb-1'"
                      >
                        <a
                          href="javascript:void(0)"
                          (click)="showCompletedTask()"
                          class="text-primary"
                          [ngClass]="filterRequestParam?.completedOnly ? 'd-none' : ''"
                        >
                          <span *ngIf="!isShowCompleteTask" class="font-size-14">
                            <i class="far fa-chevron-down"></i> Show Completed Task</span
                          >
                          <span *ngIf="isShowCompleteTask" class="font-size-14"
                            ><i class="far fa-chevron-up"></i> Hide Completed Task</span
                          >
                        </a>

                        <div *ngIf="isShowCompleteTask">
                          <kendo-treeview
                            #treeview1
                            [totalField]="'totalCount'"
                            [nodes]="completedTaskList"
                            textField="name"
                            childrenField="children"
                            kendoTreeViewExpandable
                            expandBy="id"
                            class="task-list-table"
                            [totalRootNodes]="totalCompletedTaskCount"
                            [pageSize]="pageSize"
                            [expandBy]="'id'"
                            [children]="fetchSubtask"
                            [hasChildren]="hasSubtask"
                          >
                            <ng-template
                              kendoTreeViewNodeTemplate
                              let-dataItem
                              let-index="index"
                              let-parentDataItem="parentDataItem"
                            >
                              <div
                                (mouseenter)="
                                  onHoverCompletedTaskListLastItem(dataItem, index, parentDataItem)
                                "
                              >
                                <div
                                  class="d-flex align-items-center complete-action-menu"
                                  #taskmain
                                >
                                  <div class="display-inline">
                                    <input
                                      [disabled]="checkIsDisable(dataItem)"
                                      [checked]="dataItem.taskStatus === taskStatusType.Completed"
                                      type="checkbox"
                                      kendoCheckBox
                                      class="billable-checkbox"
                                      size="large"
                                      (change)="onTaskCompleteChange(dataItem, $event)"
                                    />

                                    <kendo-dropdownbutton
                                      #taskTypeDropdown
                                      [iconClass]="getIconClass(dataItem.taskType)"
                                      [data]="taskTypeList"
                                      textField="name"
                                      (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                                      [popupSettings]="{ popupClass: popupClass }"
                                    >
                                      <ng-template
                                        kendoDropDownButtonItemTemplate
                                        let-dataItemDropDownButton
                                      >
                                        <div
                                          class="dropdown-menu-links"
                                          [ngClass]="{
                                            taskTypeSelected:
                                              dataItem.taskType === dataItemDropDownButton.id,
                                          }"
                                        >
                                          <i
                                            class="{{
                                              dataItemDropDownButton.iconClass
                                            }} dropdown-item-color mr-5"
                                          ></i>
                                          {{ dataItemDropDownButton.name }}
                                        </div>
                                      </ng-template>
                                    </kendo-dropdownbutton>

                                    <div
                                      class="display-inline mr-10"
                                      *ngIf="dataItem.assignee && dataItem.assignee.length > 0"
                                    >
                                      <ng-container *ngFor="let user of dataItem.assignee">
                                        <kendo-avatar
                                          rounded="full"
                                          size="large"
                                          [border]="true"
                                          [initials]="
                                            commonService.getInitialsAndAvtarColor(user).avatar
                                          "
                                          [style.backgroundColor]="
                                            commonService.getInitialsAndAvtarColor(user)
                                              .generateAvtarColor
                                          "
                                          class="social-profile-text assignee-container"
                                          [attr.title]="
                                            commonService.getInitialsAndAvtarColor(user).userName
                                          "
                                        ></kendo-avatar>
                                      </ng-container>
                                    </div>
                                  </div>
                                  <a
                                    (click)="onTaskNameClick(dataItem, $event)"
                                    [target]="openInNewTab ? '_blank' : null"
                                    class="display-inline"
                                    #taskTitle
                                  >
                                    <span
                                      title="{{ dataItem.taskName }}"
                                      class="display-inline task-title clamp"
                                    >
                                      {{ dataItem.taskName }}
                                    </span>
                                  </a>
                                  <div class="display-inline ml-10">
                                    <div
                                      class="display-inline mr-10"
                                      [ngClass]="{
                                        'q-label': dataItem.startDate || dataItem.dueDate,
                                      }"
                                    >
                                      <ng-container *ngIf="dataItem.startDate">
                                        <span>{{ dataItem.startDate | date: 'dd-MMM-yyyy' }}</span>
                                      </ng-container>
                                      <span *ngIf="dataItem.startDate && dataItem.dueDate">
                                        -
                                      </span>
                                      <ng-container *ngIf="dataItem.dueDate">
                                        <span class="text-danger">
                                          {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}</span
                                        >
                                      </ng-container>
                                    </div>
                                    <div
                                      *ngIf="dataItem.priority"
                                      class="task-status-container mr-10 priority-{{
                                        priorityEnum[dataItem.priority]
                                      }}"
                                      title="{{ priorityEnum[dataItem.priority] }}"
                                    >
                                      <span class="status-section">
                                        <i class="fa-solid fa-flag"></i>
                                      </span>
                                    </div>

                                    <div
                                      *ngIf="dataItem.estimateMinutes > 0"
                                      class="display-inline q-label mr-10"
                                    >
                                      <span>
                                        Est:
                                        {{
                                          commonService.getHoursAndMinutesFromTotalMinutes(
                                            dataItem.estimateMinutes
                                          )
                                        }}
                                      </span>
                                    </div>

                                    <div
                                      class="display-inline q-label mr-10"
                                      *ngIf="dataItem.timelogMinutes > 0"
                                    >
                                      <span>
                                        Log:
                                        {{
                                          commonService.getHoursAndMinutesFromTotalMinutes(
                                            dataItem.timelogMinutes
                                          )
                                        }}
                                      </span>
                                    </div>

                                    <div
                                      class="display-inline q-label mr-10"
                                      *ngIf="dataItem.attachmentCount > 0"
                                    >
                                      <span>
                                        <i class="far fa-paperclip"></i>
                                        {{ dataItem.attachmentCount }}
                                      </span>
                                    </div>

                                    <div
                                      class="display-inline q-label mr-10"
                                      *ngIf="dataItem.projectTaskStatus"
                                    >
                                      <span>
                                        {{ dataItem.projectTaskStatusName }}
                                      </span>
                                    </div>
                                  </div>

                                  <div class="action-menu ml-10">
                                    <ul class="d-flex justify-content-end gap-5">
                                      <li
                                        (click)="onEditClick(dataItem, true, drawerTab.Timelog)"
                                        *ngIf="!isNew && dataItem.id !== 0"
                                      >
                                        <a class="grid-command-action-links" title="Edit">
                                          <i class="far fa-pencil"></i
                                        ></a>
                                      </li>
                                      <li (click)="onDeleteClick(dataItem, true)">
                                        <a class="grid-command-action-links" title="Delete">
                                          <i class="far fa-trash-can"></i
                                        ></a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </kendo-treeview>
                        </div>
                      </div>
                    </div>
                  </div>
                </kendo-expansionpanel>
              </div>

              <div
                *ngIf="taskListsList.length === 0 && isNoRecordFound"
                class="no-taskList d-flex align-items-center justify-content-center flex-column"
              >
                <img src="assets/images/no-tasklist.png" alt="no-taskList" />
                <p>No task-list found</p>
                <p class="desc">It seems there are no task-lists found for this project.</p>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab
            title="Dashboard"
            [selected]="selectedTabId === tasklistTab.Dashboard"
          >
            <ng-template kendoTabContent>
              <app-project-dashboard></app-project-dashboard>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Time" [selected]="selectedTabId === tasklistTab.Time">
            <ng-template kendoTabContent>
              <app-time
                [showProjectSummary]="true"
                [taskId]="taskId"
                [projectId]="projectId"
                [isCurrentUserResponsiblePerson]="isCurrentUserResponsiblePerson"
              ></app-time>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab
            *ngIf="isAdmin"
            title="Settings"
            [selected]="selectedTabId === tasklistTab.Setting"
          >
            <ng-template kendoTabContent>
              <app-setting [taskId]="taskId" [projectId]="projectId"></app-setting>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
      <!--dashboard start -->
      <div *ngIf="isFromDashboard">
        <div
          id="taskList"
          class="grid-wrapper task-list-grid scrollable-list-container"
          *ngIf="taskListsList.length > 0"
          (scroll)="onScrollTaskListList($event)"
        >
          <kendo-expansionpanel
            *ngFor="let item of taskListsList; index as i"
            [expanded]="currentExpandedPanelId === item.id"
            (action)="onTaskListsListExpandedPanelClick(i, item)"
            [svgExpandIcon]="commonService.arrowDown"
            [svgCollapseIcon]="commonService.arrowUp"
            (expand)="adjustMaxWidth()"
            (collapse)="onCollapse(i, item)"
          >
            <ng-template kendoExpansionPanelTitleDirective>
              <div
                class="d-flex align-items-center justify-content-between expansion-panel-title-wrapper wp-100 flex-wrap gap-10"
              >
                <div class="d-flex flex-wrap align-items-center gap-10">
                  <span class="font-size-16">{{ item.name }}</span>
                  <span class="font-size-16">({{ item.projectName }})</span>
                  <span class="blue-label" [ngSwitch]="dashboardPanelId">
                    <span *ngSwitchCase="dashboardEnum.Open"
                      >{{ item.totalNumberOfTask }} Task/Bug Open</span
                    >
                    <span *ngSwitchCase="dashboardEnum.DueToday"
                      >{{ item.totalNumberOfTask }} Task/Bug Due Today</span
                    >
                    <span *ngSwitchCase="dashboardEnum.Complete"
                      >{{ item.totalNumberOfTask }} Task/Bug Complete</span
                    >
                  </span>

                  <kendo-dropdownbutton
                    #taksListAction
                    [data]="taksListActionItem"
                    class="dropdown-button"
                    (itemClick)="onTaskListActionClick($event, item)"
                    (click)="stopPropagation($event)"
                    (open)="onDropdownOpen(item.id)"
                    ><i class="fas fa-ellipsis-v"></i>
                  </kendo-dropdownbutton>
                  <button
                    kendoButton
                    [disabled]="disableAddTaskListIcon[i]"
                    class="add-task-icon"
                    [class.disabled]="disableAddTaskListIcon[i]"
                    title="Add task"
                    (click)="stopPropagation($event); onAddTaskClickOnTop(true, i)"
                  >
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </div>
                <div class="d-flex gap-10">
                  <div class="blue-label gap-5">
                    <span>
                      <i class="far fa-hourglass mr-5"></i>
                      {{
                        commonService.getHoursAndMinutesFromTotalMinutes(item.totalEstimatedTime)
                      }}
                    </span>
                    <span>/</span>
                    <span>
                      <i class="fa-regular fa-clock mr-5"></i>
                      {{ commonService.getHoursAndMinutesFromTotalMinutes(item.totalTimelogged) }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="grid-wrapper">
              <div
                [ngClass]="{ 'add-new-task-area': this.showAddNewTaskInput[i] }"
                [hidden]="!this.showAddNewTaskInput[i]"
              >
                <div
                  #treeviewContainer
                  class="d-flex justify-content-between gap-10 align-items-baseline"
                >
                  <form class="k-form wp-100" [formGroup]="addNewTaskformGroup" validateOnSubmit>
                    <fieldset class="k-form-fieldset mb-20">
                      <kendo-formfield class="mt-0 wp-100">
                        <kendo-textbox
                          formControlName="taskName"
                          class="add-task-area"
                          aria-placeholder="Give your project a name"
                          #taskName
                          (keydown.enter)="onSaveNewTask(item.id, true, i)"
                        ></kendo-textbox>
                        <kendo-formerror
                          *ngIf="addNewTaskformGroup.controls.taskName.errors?.maxlength"
                          >Error: Task Name cannot be more than 255 character!</kendo-formerror
                        >
                        <kendo-formerror
                          *ngIf="addNewTaskformGroup.controls.taskName.errors?.required"
                          >Error: Task Name is required!</kendo-formerror
                        >

                        <kendo-formerror
                          *ngIf="addNewTaskformGroup.controls.taskName.errors?.whitespace"
                        >
                          Error: Task Name cannot be empty or contain only spaces!
                        </kendo-formerror>
                      </kendo-formfield>
                    </fieldset>
                    <div *ngIf="isShowDescriptionEditor">
                      <kendo-editor
                        class="mb-20 mt-10 kendo-editor vertical-resize-only"
                        placeholder="Add a description"
                        [iframeCss]="{ content: customStyleContent }"
                        formControlName="description"
                        [resizable]="true"
                      ></kendo-editor>
                    </div>
                  </form>
                  <div class="d-flex gap-10 mt-10">
                    <span *ngIf="taskEstimateMinutes">
                      <i class="fa-regular fa-hourglass"></i>
                      {{ commonService.getHoursAndMinutesFromTotalMinutes(taskEstimateMinutes) }}
                    </span>
                  </div>
                  <ul class="d-flex gap-10 action-menu d-flex gap-5 mt-10">
                    <li>
                      <a
                        class="grid-command-action-links"
                        title="Set an estimate"
                        (click)="openSetEstimate($event, dataItem, true)"
                        (mouseleave)="onEstimateHoverOut($event)"
                      >
                        <i class="fa-regular fa-hourglass"></i>
                        {{ dataItem?.estimateMinutes }}</a
                      >
                    </li>

                    <li (click)="showDescriptionEditor(dataItem)">
                      <a class="grid-command-action-links" title="Add a description">
                        <i class="fa-solid fa-bars"></i>
                      </a>
                    </li>
                    <li (click)="onSaveNewTask(item.id, true, i)">
                      <a class="grid-command-action-links" title="Save">
                        <i class="far fa-floppy-disk"></i>
                      </a>
                    </li>
                    <li (click)="onAddTaskClickOnTop(false, i)">
                      <a class="grid-command-action-links" title="Cancel">
                        <i class="far fa-ban"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <kendo-treeview
                #treeview
                [nodes]="taskList"
                textField="name"
                [children]="fetchSubtask"
                [hasChildren]="hasSubtask"
                kendoTreeViewExpandable
                [pageSize]="pageSize"
                totalField="totalCount"
                expandBy="id"
                kendoTreeViewDragAndDrop
                kendoTreeViewDragAndDropEditing
                (nodeDrop)="onNodeDrop($event)"
                (nodeDragStart)="onNodeDragStart($event)"
                class="task-list-table"
              >
                <ng-template
                  kendoTreeViewNodeTemplate
                  let-dataItem
                  let-index="index"
                  let-parentDataItem="parentDataItem"
                >
                  <div
                    class="task-list-grid"
                    (mouseenter)="onHoverTaskListLastItem(dataItem, index, parentDataItem)"
                    (mouseleave)="onLeaveTaskListPanel()"
                  >
                    <div #taskmain>
                      <div class="display-inline">
                        <input
                          [disabled]="checkIsDisable(dataItem)"
                          [checked]="dataItem.taskStatus === taskStatusType.Completed"
                          type="checkbox"
                          kendoCheckBox
                          class="billable-checkbox"
                          size="large"
                          (change)="onTaskCompleteChange(dataItem, $event)"
                        />

                        <kendo-dropdownbutton
                          #taskTypeDropdown
                          [iconClass]="getIconClass(dataItem.taskType)"
                          [data]="taskTypeList"
                          textField="name"
                          (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                          [popupSettings]="{ popupClass: popupClass }"
                        >
                          <ng-template kendoDropDownButtonItemTemplate let-dataItemDropDownButton>
                            <div
                              class="dropdown-menu-links"
                              [ngClass]="{
                                taskTypeSelected: dataItem.taskType === dataItemDropDownButton.id,
                              }"
                            >
                              <i
                                class="{{
                                  dataItemDropDownButton.iconClass
                                }} dropdown-item-color mr-5"
                              ></i>
                              {{ dataItemDropDownButton.name }}
                            </div>
                          </ng-template>
                        </kendo-dropdownbutton>
                        <div class="display-inline mr-10 cursor-pointer">
                          <div
                            *ngIf="
                              (dataItem.assignee && dataItem.assignee.length === 0) ||
                                dataItem.assignee === null;
                              else assigneeTemplate
                            "
                            (click)="openSetAssignee($event, dataItem)"
                            class="display-inline vertical-align-middle"
                          >
                            <i class="fal fa-circle-user font-size-22"></i>
                          </div>

                          <ng-template #assigneeTemplate>
                            <div>
                              <ng-container *ngFor="let user of dataItem.assignee">
                                <kendo-avatar
                                  rounded="full"
                                  size="large"
                                  [border]="true"
                                  [initials]="commonService.getInitialsAndAvtarColor(user).avatar"
                                  [style.backgroundColor]="
                                    commonService.getInitialsAndAvtarColor(user).generateAvtarColor
                                  "
                                  class="social-profile-text assignee-container"
                                  [attr.title]="
                                    commonService.getInitialsAndAvtarColor(user).userName
                                  "
                                  (click)="openSetAssignee($event, dataItem)"
                                ></kendo-avatar>
                              </ng-container>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <a
                        (click)="onTaskNameClick(dataItem, $event)"
                        [target]="openInNewTab ? '_blank' : null"
                        class="display-inline"
                        #taskTitle
                      >
                        <span
                          title="{{ dataItem.taskName }}"
                          class="display-inline task-title clamp"
                        >
                          {{ dataItem.taskName }}
                        </span>
                      </a>

                      <div class="display-inline ml-10">
                        <div
                          class="display-inline mr-10"
                          [ngClass]="{ 'q-label': dataItem.startDate || dataItem.dueDate }"
                        >
                          <ng-container *ngIf="dataItem.startDate">
                            <span>{{ dataItem.startDate | date: 'dd-MMM-yyyy' }}</span>
                          </ng-container>
                          <span *ngIf="dataItem.startDate && dataItem.dueDate"> - </span>
                          <ng-container *ngIf="dataItem.dueDate">
                            <span class="text-danger">
                              {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}</span
                            >
                          </ng-container>
                        </div>

                        <div
                          *ngIf="dataItem.priority"
                          class="task-status-container mr-10 priority-{{
                            priorityEnum[dataItem.priority]
                          }}"
                          title="{{ priorityEnum[dataItem.priority] }}"
                        >
                          <span class="status-section">
                            <i class="fa-solid fa-flag"></i>
                          </span>
                        </div>

                        <div
                          *ngIf="dataItem.estimateMinutes > 0"
                          class="display-inline q-label mr-10"
                        >
                          <span
                            class="cursor-pointer"
                            (click)="openSetEstimate($event, dataItem)"
                            (mouseleave)="onEstimateHoverOut($event)"
                          >
                            Est:
                            {{
                              commonService.getHoursAndMinutesFromTotalMinutes(
                                dataItem.estimateMinutes
                              )
                            }}
                          </span>
                        </div>
                        <div
                          class="display-inline q-label mr-10"
                          *ngIf="dataItem.timelogMinutes > 0"
                        >
                          <span
                            (mouseleave)="onTimelogIconHoverOut($event)"
                            (click)="onTimelogIconClick($event, dataItem)"
                            style="cursor: pointer"
                            class="timelog-span"
                          >
                            Log:
                            {{
                              commonService.getHoursAndMinutesFromTotalMinutes(
                                dataItem.timelogMinutes
                              )
                            }}
                          </span>
                        </div>
                        <div
                          class="display-inline q-label mr-10 cursor-pointer"
                          *ngIf="dataItem.attachmentCount > 0"
                          (click)="onEditClick(dataItem, false, drawerTab.Files)"
                        >
                          <span>
                            <i class="far fa-paperclip"></i>
                            {{ dataItem.attachmentCount }}
                          </span>
                        </div>
                        <div
                          class="display-inline q-label mr-10"
                          *ngIf="dataItem.projectTaskStatus"
                        >
                          <span
                            class="cursor-pointer"
                            (click)="toggleStatusPopup($event, dataItem)"
                            (mouseleave)="onStatusHoverOut($event)"
                          >
                            {{ dataItem.projectTaskStatusName }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="task-list-action display-inline"
                        [ngClass]="{
                          'visible-task-list':
                            hoveredTaskId === dataItem.id ||
                            popupTaskId === dataItem.id ||
                            (showTimelogPopup && hoveredTaskId === dataItem.id),
                        }"
                      >
                        <ul class="d-flex justify-content-end gap-5">
                          <li (click)="onAddSubtaskClick(dataItem)">
                            <a class="grid-command-action-links" title="Add SubTask">
                              <i class="far fa-plus-square"></i
                            ></a>
                          </li>
                          <li (click)="onEditClick(dataItem, true, drawerTab.Timelog)">
                            <a class="grid-command-action-links" title="Edit">
                              <i class="far fa-pencil"></i
                            ></a>
                          </li>
                          <li (click)="onDeleteClick(dataItem, false)">
                            <a class="grid-command-action-links" title="Delete">
                              <i class="far fa-trash-can"></i
                            ></a>
                          </li>
                          <li>
                            <a
                              class="grid-command-action-links"
                              title="Set Estimate"
                              (click)="openSetEstimate($event, dataItem)"
                              (mouseleave)="onEstimateHoverOut($event)"
                            >
                              <i class="fa-regular fa-hourglass"></i
                            ></a>
                          </li>
                          <li>
                            <a
                              class="grid-command-action-links"
                              title="Log Time"
                              (mouseleave)="onTimelogIconHoverOut($event)"
                              (click)="onTimelogIconClick($event, dataItem)"
                            >
                              <i class="fa-regular fa-timer"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              class="grid-command-action-links"
                              title="Add Comment"
                              (click)="onEditClick(dataItem, false, drawerTab.Comment)"
                            >
                              <i class="fa-regular fa-comment"></i
                            ></a>
                          </li>
                          <li>
                            <a
                              class="grid-command-action-links"
                              title="Add Priority"
                              (mouseleave)="onPriorityHoverOut($event)"
                              (click)="togglePriorityDropdown($event, dataItem)"
                            >
                              <i class="fa-regular fa-flag"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              class="grid-command-action-links"
                              title="Add Status"
                              (click)="toggleStatusPopup($event, dataItem)"
                              (mouseleave)="onStatusHoverOut($event)"
                            >
                              <i class="fa-regular fa-battery-full"></i>
                            </a>
                          </li>
                          <li (click)="openAttachedFile(dataItem)">
                            <a class="grid-command-action-links" title="Attach Files">
                              <i class="far fa-paperclip"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      *ngIf="dataItem.isAddSubtask"
                      class="d-flex justify-content-between gap-10 align-items-baseline"
                    >
                      <div class="wp-100">
                        <span>{{ dataItem.name }}</span>
                        <kendo-textbox
                          #SubTaskEditor
                          class="add-task-area"
                          (input)="onInputChange(SubTaskEditor.value, dataItem)"
                          (keydown.enter)="onSaveSubTask(SubTaskEditor, dataItem, item.id)"
                        ></kendo-textbox>

                        <kendo-formerror *ngIf="subTaskErrors[dataItem.id]">
                          Error: Subtask name is required.
                        </kendo-formerror>
                        <kendo-formerror *ngIf="subTaskMaxLengthErrors[dataItem.id]">
                          Error: Subtask Name cannot be more than 255 character!
                        </kendo-formerror>
                        <kendo-formerror *ngIf="subTaskWhiteSpaceErrors[dataItem.id]">
                          Error: Subtask Name cannot be empty or contain only spaces!
                        </kendo-formerror>
                        <div *ngIf="isShowSubTaskDescriptionEditor[dataItem.id]">
                          <kendo-editor
                            [(ngModel)]="dataItem.description"
                            #SubTaskDescriptionEditor
                            class="wp-100 kendo-editor mt-10 vertical-resize-only"
                            placeholder="Add a description"
                            [iframeCss]="{ content: customStyleContent }"
                            [resizable]="true"
                          ></kendo-editor>
                        </div>
                      </div>
                      <div class="d-flex gap-10 mt-10 gap-5">
                        <span *ngIf="taskEstimateMinutes">
                          <i class="fa-regular fa-hourglass"></i>
                          {{
                            commonService.getHoursAndMinutesFromTotalMinutes(taskEstimateMinutes)
                          }}
                        </span>
                      </div>
                      <ul class="d-flex gap-10" class="action-menu d-flex gap-5 mt-10">
                        <!-- darshan -->
                        <li>
                          <a
                            class="grid-command-action-links"
                            title="Set an estimate"
                            (click)="openSetEstimate($event, undefined, true)"
                            (mouseleave)="onEstimateHoverOut($event)"
                          >
                            <i class="fa-regular fa-hourglass"></i
                          ></a>
                        </li>
                        <li (click)="showSubTaskDescriptionEditor(dataItem)">
                          <a class="grid-command-action-links" title="Add a description">
                            <i class="fa-solid fa-bars"></i>
                          </a>
                        </li>
                        <li (click)="onSaveSubTask(SubTaskEditor, dataItem, item.id)">
                          <a class="grid-command-action-links" title="Save"
                            ><i class="far fa-floppy-disk"></i
                          ></a>
                        </li>
                        <li (click)="onCancelSubtask(dataItem)">
                          <a class="grid-command-action-links" title="Cancel"
                            ><i class="far fa-ban"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </kendo-treeview>
              <div [ngClass]="{ 'add-new-task-area': isAddNew }">
                <div
                  *ngIf="isAddNew"
                  class="d-flex justify-content-between gap-10 align-items-baseline"
                >
                  <form class="k-form wp-100" [formGroup]="addNewTaskformGroup" validateOnSubmit>
                    <fieldset class="k-form-fieldset mb-20">
                      <kendo-formfield class="mt-0 wp-100">
                        <kendo-textbox
                          formControlName="taskName"
                          class="add-task-area"
                          aria-placeholder="Give your project a name"
                          #taskName
                          (keydown.enter)="onSaveNewTask(item.id, false)"
                        ></kendo-textbox>
                        <kendo-formerror
                          *ngIf="addNewTaskformGroup.controls.taskName.errors?.maxlength"
                          >Error: Task Name cannot be more than 255 character!</kendo-formerror
                        >
                        <kendo-formerror
                          *ngIf="addNewTaskformGroup.controls.taskName.errors?.required"
                          >Error: Task Name is required!</kendo-formerror
                        >
                        <kendo-formerror
                          *ngIf="addNewTaskformGroup.controls.taskName.errors?.whitespace"
                        >
                          Error: Task Name cannot be empty or contain only spaces!
                        </kendo-formerror>
                      </kendo-formfield>
                    </fieldset>
                    <div *ngIf="isShowDescriptionEditor">
                      <kendo-editor
                        class="mb-20 kendo-editor vertical-resize-only"
                        placeholder="Add a description"
                        [iframeCss]="{ content: customStyleContent }"
                        formControlName="description"
                        [resizable]="true"
                      ></kendo-editor>
                    </div>
                  </form>
                  <div class="d-flex gap-10 mt-10 gap-5">
                    <span *ngIf="taskEstimateMinutes">
                      <i class="fa-regular fa-hourglass"></i>
                      {{ commonService.getHoursAndMinutesFromTotalMinutes(taskEstimateMinutes) }}
                    </span>
                  </div>
                  <ul class="d-flex gap-10 action-menu d-flex gap-5 mt-10">
                    <li>
                      <a
                        class="grid-command-action-links"
                        title="Set an estimate"
                        (click)="openSetEstimate($event, dataItem, true)"
                        (mouseleave)="onEstimateHoverOut($event)"
                      >
                        <i class="fa-regular fa-hourglass"></i
                      ></a>
                    </li>
                    <li (click)="showDescriptionEditor(dataItem)">
                      <a class="grid-command-action-links" title="Add a description">
                        <i class="fa-solid fa-bars"></i>
                      </a>
                    </li>
                    <li (click)="onSaveNewTask(item.id, false)">
                      <a class="grid-command-action-links" title="Save">
                        <i class="far fa-floppy-disk"></i>
                      </a>
                    </li>
                    <li (click)="onAddTaskClick(false)">
                      <a class="grid-command-action-links" title="Cancel">
                        <i class="far fa-ban"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <button
                  *ngIf="
                    !isAddNew &&
                    taskList.length === totalTaskCount &&
                    dashboardPanelId !== dashboardEnum.Complete
                  "
                  (click)="onAddTaskClick(true)"
                  class="add-new-btn"
                >
                  <i class="far fa-plus mr-5"></i> Add new
                </button>
              </div>
              <div *ngIf="dashboardPanelId === dashboardEnum.Complete">
                <div *ngIf="totalCompletedTaskCount > 0" class="border-top">
                  <div *ngIf="isShowCompleteTask">
                    <kendo-treeview
                      #treeview1
                      [totalField]="'totalCount'"
                      [nodes]="completedTaskList"
                      textField="name"
                      childrenField="children"
                      kendoTreeViewExpandable
                      expandBy="id"
                      class="task-list-table"
                      [totalRootNodes]="totalCompletedTaskCount"
                      [pageSize]="pageSize"
                      [expandBy]="'id'"
                      [children]="fetchSubtask"
                      [hasChildren]="hasSubtask"
                    >
                      <ng-template
                        kendoTreeViewNodeTemplate
                        let-dataItem
                        let-index="index"
                        let-parentDataItem="parentDataItem"
                      >
                        <div
                          (mouseenter)="
                            onHoverCompletedTaskListLastItem(dataItem, index, parentDataItem)
                          "
                        >
                          <div class="d-flex align-items-center complete-action-menu" #taskmain>
                            <div class="display-inline">
                              <input
                                [disabled]="checkIsDisable(dataItem)"
                                [checked]="dataItem.taskStatus === taskStatusType.Completed"
                                type="checkbox"
                                kendoCheckBox
                                class="billable-checkbox"
                                size="large"
                                (change)="onTaskCompleteChange(dataItem, $event)"
                              />
                              <kendo-dropdownbutton
                                #taskTypeDropdown
                                [iconClass]="getIconClass(dataItem.taskType)"
                                [data]="taskTypeList"
                                textField="name"
                                (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                                [popupSettings]="{ popupClass: popupClass }"
                              >
                                <ng-template
                                  kendoDropDownButtonItemTemplate
                                  let-dataItemDropDownButton
                                >
                                  <div
                                    class="dropdown-menu-links"
                                    [ngClass]="{
                                      taskTypeSelected:
                                        dataItem.taskType === dataItemDropDownButton.id,
                                    }"
                                  >
                                    <i
                                      class="{{
                                        dataItemDropDownButton.iconClass
                                      }} dropdown-item-color mr-5"
                                    ></i>
                                    {{ dataItemDropDownButton.name }}
                                  </div>
                                </ng-template>
                              </kendo-dropdownbutton>

                              <div
                                class="display-inline mr-10"
                                *ngIf="dataItem.assignee && dataItem.assignee.length > 0"
                              >
                                <ng-container *ngFor="let user of dataItem.assignee">
                                  <kendo-avatar
                                    rounded="full"
                                    size="large"
                                    [border]="true"
                                    [initials]="commonService.getInitialsAndAvtarColor(user).avatar"
                                    [style.backgroundColor]="
                                      commonService.getInitialsAndAvtarColor(user)
                                        .generateAvtarColor
                                    "
                                    class="social-profile-text assignee-container"
                                    [attr.title]="
                                      commonService.getInitialsAndAvtarColor(user).userName
                                    "
                                  ></kendo-avatar>
                                </ng-container>
                              </div>
                            </div>
                            <a
                              (click)="onTaskNameClick(dataItem, $event)"
                              [target]="openInNewTab ? '_blank' : null"
                              class="display-inline"
                              #taskTitle
                            >
                              <span
                                title="{{ dataItem.taskName }}"
                                class="display-inline task-title clamp"
                              >
                                {{ dataItem.taskName }}
                              </span>
                            </a>
                            <div class="display-inline ml-10">
                              <div
                                class="display-inline mr-10"
                                [ngClass]="{ 'q-label': dataItem.startDate || dataItem.dueDate }"
                              >
                                <ng-container *ngIf="dataItem.startDate">
                                  <span>{{ dataItem.startDate | date: 'dd-MMM-yyyy' }}</span>
                                </ng-container>
                                <span *ngIf="dataItem.startDate && dataItem.dueDate"> - </span>
                                <ng-container *ngIf="dataItem.dueDate">
                                  <span class="text-danger">
                                    {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}</span
                                  >
                                </ng-container>
                              </div>

                              <div
                                *ngIf="dataItem.priority"
                                class="task-status-container mr-10 priority-{{
                                  priorityEnum[dataItem.priority]
                                }}"
                                title="{{ priorityEnum[dataItem.priority] }}"
                              >
                                <span class="status-section">
                                  <i class="fa-solid fa-flag"></i>
                                </span>
                              </div>

                              <div
                                *ngIf="dataItem.estimateMinutes > 0"
                                class="display-inline q-label mr-10"
                              >
                                <span>
                                  Est:
                                  {{
                                    commonService.getHoursAndMinutesFromTotalMinutes(
                                      dataItem.estimateMinutes
                                    )
                                  }}
                                </span>
                              </div>

                              <div
                                class="display-inline q-label mr-10"
                                *ngIf="dataItem.timelogMinutes > 0"
                              >
                                <span>
                                  Log:
                                  {{
                                    commonService.getHoursAndMinutesFromTotalMinutes(
                                      dataItem.timelogMinutes
                                    )
                                  }}
                                </span>
                              </div>

                              <div
                                class="display-inline q-label mr-10"
                                *ngIf="dataItem.attachmentCount > 0"
                              >
                                <span>
                                  <i class="far fa-paperclip"></i>
                                  {{ dataItem.attachmentCount }}
                                </span>
                              </div>

                              <div
                                class="display-inline q-label mr-10"
                                *ngIf="dataItem.projectTaskStatus"
                              >
                                <span>
                                  {{ dataItem.projectTaskStatusName }}
                                </span>
                              </div>
                            </div>

                            <div class="action-menu ml-10">
                              <ul class="d-flex justify-content-end gap-5">
                                <li
                                  (click)="onEditClick(dataItem, true, drawerTab.Timelog)"
                                  *ngIf="!isNew && dataItem.id !== 0"
                                >
                                  <a class="grid-command-action-links" title="Edit">
                                    <i class="far fa-pencil"></i
                                  ></a>
                                </li>
                                <li (click)="onDeleteClick(dataItem, true)">
                                  <a class="grid-command-action-links" title="Delete">
                                    <i class="far fa-trash-can"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </kendo-treeview>
                  </div>
                </div>
              </div>
            </div>
          </kendo-expansionpanel>
        </div>

        <div
          *ngIf="taskListsList.length === 0 && isNoRecordFound"
          class="no-taskList d-flex align-items-center justify-content-center flex-column"
        >
          <img src="assets/images/no-tasklist.png" alt="no-taskList" />
          <p>No task-list found</p>
          <p class="desc">It seems there are no task-lists for this section.</p>
        </div>
      </div>
      <!-- dashboard end -->
      <ng-container *ngIf="loadDialog">
        <div kendoDialogContainer></div>
      </ng-container>
    </div>
    <div *ngIf="!isFromDashboard" kendoDialogContainer></div>
    <kendo-drawer-container class="add-more-details">
      <kendo-drawer
        [mode]="expandMode"
        [expanded]="isDrawerOpened"
        [position]="position"
        [autoCollapse]="false"
      >
        <ng-template kendoDrawerTemplate>
          <app-add-task-more-details
            [taskId]="taskId"
            [taskDetails]="taskDetails"
            [projectId]="projectId"
            [sortingName]="sorting"
            (reloadTaskList)="onTaskDrawerClose($event)"
            [isFromEditAction]="isFromEditAction"
            [drawerSelectedTabId]="drawerSelectedTabId"
            (saveTaskAction)="TaskListNameWidth($event)"
          ></app-add-task-more-details>
        </ng-template>
      </kendo-drawer>
    </kendo-drawer-container>
  </div>
</div>

<app-set-assignee
  [showAssigneePopup]="showAssigneePopup"
  [position]="popupPosition"
  [projectId]="projectId"
  [taskData]="taskDetails"
  [assigneeList]="assigneeList"
  [assigneListItems]="assigneListItems"
  [selectedAssignee]="selectedAssignee"
  (triggerUpdatedAssignee)="getUpdatedTaskAssignee($event, tempDataItem)"
>
</app-set-assignee>

<app-set-an-estimate
  [showEstimatePopup]="showEstimatePopup"
  [position]="popupPosition"
  [estimateTime]="tempTaskDetailsEstimateMinutes ?? 0"
  (mouseenter)="onPanelMouseEnter(taskDetails?.id)"
  (mouseleave)="onPanelMouseLeave()"
  (triggerEstimate)="getTaskEstimate($event, taskDetails)"
></app-set-an-estimate>

<app-set-priority
  [isVisible]="showPriorityDropdown"
  [position]="priorityDropdownPosition"
  (prioritySelected)="onPrioritySelected($event, taskDetails)"
  (mouseenter)="onPanelMouseEnter(taskDetails?.id)"
  (mouseleave)="onPanelMouseLeave()"
></app-set-priority>

<app-set-status-popup
  [isVisible]="showStatusPopup"
  [taskData]="taskDetails"
  [position]="popupPosition"
  [taskStatusTypeList]="taskStatusTypeList"
  (mouseenter)="onPanelMouseEnter(taskDetails?.id)"
  (mouseleave)="onPanelMouseLeave()"
  (statusSelected)="onStatusSelected($event, taskDetails)"
></app-set-status-popup>

<app-log-time-panel
  [taskData]="tempLogtimedata"
  [isVisible]="showTimelogPopup"
  [position]="popupPosition"
  [anchor]="hoverAnchor"
  [projectId]="projectId"
  (mouseenter)="onPanelMouseEnter(taskDetails?.id)"
  (mouseleave)="onPanelMouseLeave()"
  (triggerViewTimelog)="onEditClick(tempLogtimedata, false, drawerTab.Timelog)"
  (triggerTimelogAdded)="onNewTimelogAdded($event, tempLogtimedata)"
></app-log-time-panel>
