import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { TaskListTabEnum } from 'src/app/enum/tasklist-tab-enum';
import { CommonService, UrlShortenerService } from 'src/core/services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-project-dashboard',
  templateUrl: './project-dashbord.component.html',
  styleUrls: ['./project-dashbord.component.scss'],
})
export class ProjectDashboardComponent implements OnInit {
  projectId: any;

  leftTimeTasksList: any[];
  overTimeTasksList: any[];

  multiple = false;
  allowUnsort = true;
  openInNewTab = false;
  sorting = null;
  sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  constructor(
    private _Activatedroute: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private taskService: TaskService,
    public commonService: CommonService,
    private router: Router,
    private urlShortener: UrlShortenerService,
  ) {}

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe(params => {
      if (!!params) {
        this.projectId = params['projectId'] ? +atob(params['projectId']) : undefined;
        this.getLeftAndOverTimeTasksList();
      }
    });
  }

  getLeftAndOverTimeTasksList(): void {
    this.spinnerService.show();
    this.taskService.getLeftAndOverTimeTasksList(this.projectId).subscribe(res => {
      this.spinnerService.hide();
      this.overTimeTasksList = res.overTimeTasks;
      this.leftTimeTasksList = res.leftTimeTasks;
    });
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getLeftAndOverTimeTasksList();
  }

  onGoToTaskDetailsClick(data, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    event.preventDefault();

    const params: { [key: string]: string } = {
      taskId: btoa(data.taskId),
      tabId: btoa(TaskListTabEnum.Dashboard.toString()),
    };

    localStorage.removeItem('url_params');
    const shortKey = this.urlShortener.shortUrl(params);
    const fullUrl = `/task-Detail/${shortKey}`;

    if (this.openInNewTab) {
      window.open(fullUrl, '_blank');
    } else {
      this.router.navigate([fullUrl], { replaceUrl: true });
    }
  }
}
