<div class="q-content" *ngIf="this.authService.isAuthenticated">
  <div class="q-content-header">
    <div class="d-flex gap-15 align-items-center justify-content-between wp-100 mb-2 flex-wrap">
      <kendo-textbox
        placeholder="Search for users name"
        (keyup.enter)="onSearch($event)"
        [(ngModel)]="searchText"
        class="bg-white search"
      >
        <ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon"></i>
        </ng-template>
      </kendo-textbox>
      <abp-page-toolbar [toolbar]="data.items"></abp-page-toolbar>
    </div>
  </div>
  <div class="q-content-bg">
    <app-custom-extensible-table
      [data]="data.items"
      [recordsTotal]="data.totalCount"
      [list]="list"
    ></app-custom-extensible-table>
  </div>
</div>

<div class="authentication-settings">
  <kendo-dialog
    title="{{
      (selected?.id ? 'AbpIdentity::Edit User' : 'AbpIdentity::NewUser') | abpLocalization
    }}"
    *ngIf="isAddEditOpened"
    (close)="close('cancel')"
    [Width]="450"
    class="custom-user-popup"
  >
    <div>
      <form
        *ngIf="form; else loaderRef"
        [formGroup]="form"
        (ngSubmit)="save()"
        class="k-form k-form-md"
      >
        <kendo-tabstrip>
          <kendo-tabstrip-tab
            title="{{ 'AbpIdentity::UserInformations' | abpLocalization }}"
            [selected]="true"
          >
            <ng-template kendoTabContent>
              <abp-extensible-form [selectedRecord]="selected"></abp-extensible-form>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="{{ 'AbpIdentity::Roles' | abpLocalization }}">
            <ng-template kendoTabContent>
              <div
                *ngFor="let roleGroup of roleGroups; let i = index; trackBy: trackByFn"
                class="form-check mb-2 pl-0"
              >
                <input
                  type="checkbox"
                  [formControl]="roleGroup.controls[roles[i].name]"
                  [id]="'roles-' + i"
                  kendoCheckBox
                  class="mr-5"
                />
                <kendo-label
                  class="k-checkbox-label"
                  [for]="'roles-' + i"
                  text="{{ roles[i].name }}"
                ></kendo-label>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>

        <!-- <nb-tabset>
        <nb-tab tabTitle="{{ 'AbpIdentity::UserInformations' | abpLocalization }}">
          <abp-extensible-form [selectedRecord]="selected"></abp-extensible-form>
        </nb-tab>
        <nb-tab tabTitle="{{ 'AbpIdentity::Roles' | abpLocalization }}">
          <span>
            <div *ngFor="let roleGroup of roleGroups; let i = index; trackBy: trackByFn" class="form-check mb-2">
              <abp-checkbox *abpReplaceableTemplate="{
                  inputs: {
                    checkboxId: 'roles-' + i,
                    label: roles[i].name,
                    formControl: roleGroup.controls[roles[i].name]
                  },
                  componentKey: inputKey
                }" [checkboxId]="'roles-' + i" [formControl]="roleGroup.controls[roles[i].name]"
                [label]="roles[i].name">
              </abp-checkbox>
            </div>
          </span>
        </nb-tab>
      </nb-tabset> -->
      </form>
    </div>
    <kendo-dialog-actions>
      <button kendoButton (click)="close('no')" fillMode="outline">Cancel</button>
      <div [ngClass]="{ 'curser-not-allowd': this.form.invalid }" class="display-inline flex-0">
        <button kendoButton (click)="save()" [disabled]="this.form.invalid" themeColor="primary">
          {{ 'AbpIdentity::Save' | abpLocalization }}
        </button>
      </div>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog
    *ngIf="isAddEditPermissionOpened"
    (close)="closePermission('cancel')"
    [width]="850"
    class="permission-modal"
  >
    <app-custom-permission
      #abpPermissionManagement="abpPermissionManagement"
      *abpReplaceableTemplate="
        {
          inputs: {
            providerName: { value: 'U' },
            providerKey: { value: providerKey },
            visible: { value: visiblePermissions, twoWay: true },
          },
          outputs: { visibleChange: onVisiblePermissionChange },
          componentKey: permissionManagementKey,
        };
        let init = initTemplate
      "
      [entityDisplayName]="entityDisplayName"
      (abpInit)="init(abpPermissionManagement)"
    >
    </app-custom-permission>
  </kendo-dialog>
</div>
