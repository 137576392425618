import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AddProjectPeopleDto,
  GetProjectPeopleListInput,
  ProjectUserDto,
} from '../project-users/models';

@Injectable({
  providedIn: 'root',
})
export class ProjectUserService {
  apiName = 'ProjectService';

  addPeople = (projectId: number, input: AddProjectPeopleDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: `/api/project/${projectId}/user`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getPeople = (
    projectId: number,
    input: GetProjectPeopleListInput,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<ProjectUserDto>>(
      {
        method: 'GET',
        url: `/api/project/${projectId}/user`,
        params: {
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isShowAdmin: input.isShowAdmin,
        },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
