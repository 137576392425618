<div class="q-content">
  <div
    *ngIf="gridView.data.length === 0 && filterCount == 0; else gridTemplate"
    class="q-content-bg d-flex justify-content-center align-items-center flex-column timelog-container"
  >
    <img src="assets/images/timelog.png" alt="timelog" class="mb-10" />
    <p class="mt-10 font-size-16 fw-500">No Data to Display</p>
    <p class="text-secondary-text font-size-14 mt-10">
      It seems there is no filter apply to display timelog.
    </p>
    <a title="Apply Filter" class="add-on-btn font-size-13 fw-500" (click)="openFilter()"
      ><i class="far fa-filter mr-5 mt-5"></i> Apply Filter
    </a>
  </div>
</div>

<ng-template #gridTemplate>
  <div [ngClass]="!isFromTaskList ? 'q-content' : ''">
    <div class="q-content-header d-flex justify-content-end mb-20 flex-wrap gap-20">
      <div class="d-flex gap-15 justify-content-between flex-wrap gap-20">
        <div class="export-dropDown">
          <kendo-dropdownbutton
            [data]="exportActionList"
            #additionalSetting
            (itemClick)="onActionItemClicked($event)"
          >
            <i class="far fa-download k-icon font-size-14 mr-5"></i><span> Export </span>
          </kendo-dropdownbutton>
        </div>
        <div class="filter-icon-container" (click)="openFilter()">
          <span><i title="Filter" class="far fa-filter k-icon font-size-14"></i></span>
          <span>
            {{ filterCount }}
          </span>
        </div>
      </div>
    </div>

    <div [ngClass]="!isFromTaskList ? 'q-content-bg' : ''">
      <div class="border-bottom">
        <div class="d-flex gap-30 primary-card text-primary flex-wrap mb-24">
          <span class="fw-500"> Filtered Totals: </span>
          <div>
            <i class="fa-regular fa-clock"></i>
            <span class="ml-5 fw-500">logged</span> :
            {{ commonService.getHoursAndMinutesFromTotalMinutes(summary?.logged) }} ({{
              commonService.getOnlyHoursFromMinutes(summary?.logged) | number: '1.2-2'
            }})
          </div>
          <div>
            <i class="fa-solid fa-ban"></i>
            <span class="ml-5 fw-500">Non-billable</span> :
            {{ commonService.getHoursAndMinutesFromTotalMinutes(summary?.nonBillable) }} ({{
              commonService.getOnlyHoursFromMinutes(summary?.nonBillable) | number: '1.2-2'
            }})
          </div>
          <div *ngIf="!isBillableColumnVisible">
            <i class="fa-regular fa-circle-check"></i>
            <span class="ml-5 fw-500">Billable</span> :
            {{ commonService.getHoursAndMinutesFromTotalMinutes(summary?.billable) }} ({{
              commonService.getOnlyHoursFromMinutes(summary?.billable) | number: '1.2-2'
            }})
          </div>
          <div>
            <i class="fa-regular fa-clock"></i>
            <span class="ml-5 fw-500">Estimated</span> :
            {{ commonService.getHoursAndMinutesFromTotalMinutes(summary?.estimated) }} ({{
              commonService.getOnlyHoursFromMinutes(summary?.estimated) | number: '1.2-2'
            }})
          </div>
          <div *ngIf="!isBillableColumnVisible">
            <i class="fa-regular fa-clock"></i>
            <span class="ml-5 fw-500">Not Billed</span> :
            {{ commonService.getHoursAndMinutesFromTotalMinutes(summary?.notBilled) }} ({{
              commonService.getOnlyHoursFromMinutes(summary?.notBilled) | number: '1.2-2'
            }})
          </div>
        </div>
      </div>
      <div class="d-flex task-details-height">
        <div *ngIf="isFromTaskList">
          <div class="pt-2 left-side-details border-right">
            <div class="mb-2">
              <kendo-label
                text="Total Time Logged"
                class="fw-500 mb-5 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <span>{{
                  commonService.getHoursAndMinutesFromTotalMinutes(
                    projectSummary?.timeTotals?.totalMinsSum
                  )
                }}</span>
              </div>
            </div>
            <div class="pb-2">
              <kendo-label
                text="Total Non-billable Time"
                class="fw-500 mb-10 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeTotals?.nonBillableMinsSum
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="pb-2" *ngIf="!isBillableColumnVisible">
              <kendo-label
                text="Total Billable Time"
                class="fw-500 mb-10 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeTotals?.billableMinsSum
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="pb-2" *ngIf="!isBillableColumnVisible">
              <kendo-label
                text="Total Billed Time"
                class="fw-500 mb-10 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeTotals?.billedMinsSum
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="pb-2">
              <kendo-label
                text="Total Estimated Time"
                class="fw-500 mb-10 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeEstimates?.totalMinsEstimated
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="pb-2">
              <kendo-label
                text="Overall Project Estimated Time"
                class="fw-500 mb-10 display-inline"
                labelCssClass="k-form-label"
              ></kendo-label>
              <div>
                <p class="word-break">
                  {{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      projectSummary?.timeEstimates?.totalMinsEstimated
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="isFromTaskList ? 'right-side-details' : ''">
          <div class="grid-wrapper">
            <kendo-grid
              #grid
              [data]="gridView"
              [resizable]="true"
              [pageSize]="pageSize"
              [skip]="skip"
              [pageable]="
                gridView === undefined
                  ? false
                  : {
                      buttonCount: buttonCount,
                      info: info,
                      type: type,
                      pageSizes: pageSizes,
                      previousNext: previousNext,
                      position: pagerposition,
                    }
              "
              (pageChange)="pageChange($event)"
              [height]="gridHeight"
              [groupable]="false"
              class="custom-no-records-container time-dashborad-grid"
            >
              <ng-template kendoGridNoRecordsTemplate>
                <div *ngIf="isNoRecords" class="no-records-text-container">
                  No Records Available
                </div>
              </ng-template>

              <kendo-grid-column
                *ngIf="!isFromTaskList"
                [columnMenu]="false"
                [filterable]="true"
                field="projectName"
                title="Project"
                filter="string"
                [width]="50"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="d-flex gap-5">
                    {{ dataItem.projectName }}
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="timelogUserFirstName"
                title="Who"
                filter="string"
                [width]="50"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="d-flex gap-10">
                    <kendo-avatar
                      rounded="full"
                      size="large"
                      [border]="true"
                      [initials]="commonService.getInitials(dataItem.timelogUserName)"
                      [style.backgroundColor]="
                        commonService.generateAvtarColor(
                          dataItem.timelogUserName,
                          dataItem.timelogUserName
                        )
                      "
                      class="social-profile-text"
                      [attr.title]="timelogUserName"
                    ></kendo-avatar>
                    <div class="customer-name" [title]="timelogUserName">
                      {{ dataItem.timelogUserName }}
                    </div>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="taskName"
                title="Description"
                filter="string"
                [width]="150"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="d-flex gap-5">
                    <span class="task-name task-title" *ngIf="dataItem.taskName"> Task: </span>
                    <a
                      class="text-underline line-height-inherit word-break"
                      [routerLink]="['/task-Detail']"
                      [queryParams]="getQueryParams(dataItem)"
                      [target]="openInNewTab ? '_blank' : ''"
                      (click)="onGoToTaskDetailsClick(dataItem, $event)"
                      title="{{ dataItem.taskName }}"
                    >
                      {{ dataItem.taskName }}
                    </a>
                  </div>
                  <span *ngIf="dataItem.timelogDescription">{{ dataItem.timelogDescription }}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="tasklistName"
                title="Task List"
                filter="string"
                [width]="50"
                [sortable]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="d-flex gap-5">
                    <span class="clamp" title="{{ dataItem.tasklistName }}">
                      {{ dataItem.tasklistName }}
                    </span>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="start"
                title="Start"
                filter="string"
                [width]="50"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.timelogDatetime | date: 'HH:mm' }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="end"
                title="End"
                filter="string"
                [width]="50"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{
                    calculateEndTime(dataItem.timelogDatetime, dataItem.timelogMinutes)
                      | date: 'HH:mm'
                  }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="time"
                title="Actual Time"
                filter="string"
                [width]="50"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.timelogMinutes }}m
                </ng-template>
                <ng-template kendoGridGroupFooterTemplate let-aggregates> </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="hoursDecimal"
                title="Actual Hours"
                filter="number"
                [width]="50"
              >
                <ng-template kendoGridGroupFooterTemplate let-aggregates>
                  <span> {{ aggregates['hoursDecimal'].sum | number: '1.2-2' }}h</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="timelogBillableMinutes"
                title="Billable Hours"
                filter="number"
                [width]="50"
                [hidden]="isBillableColumnVisible"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span title="{{ dataItem.timelogBillableMinutes }}">{{
                    commonService.getHoursAndMinutesFromTotalMinutes(
                      dataItem.timelogBillableMinutes
                    )
                  }}</span>
                </ng-template>
                <ng-template kendoGridGroupFooterTemplate let-aggregates>
                  <span *ngIf="!isBillableColumnVisible">
                    {{ aggregates['timelogBillableMinutes'].sum / 60 | number: '1.2-2' }}h</span
                  >
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="billed"
                title="Billed"
                filter="boolean"
                [width]="40"
                [hidden]="isBillableColumnVisible"
              >
                <ng-template kendoGridCellTemplate let-dataItem
                  ><i class="fa-sharp fa-solid fa-circle-xmark font-size-18 billed-icon"></i>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [columnMenu]="false"
                [filterable]="true"
                field="status"
                title=""
                filter="boolean"
                [width]="30"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ul class="grid-action-list">
                    <div class="d-flex gap-10">
                      <li *ngIf="dataItem.canEditOrDelete">
                        <a title="Edit" (click)="onEdit(dataItem)">
                          <i class="far fa-pen"></i>
                        </a>
                      </li>
                    </div>
                  </ul>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
    <div kendoDialogContainer></div>
  </div>
</ng-template>

<kendo-drawer-container class="filter-container">
  <kendo-drawer
    [mode]="expandMode"
    [expanded]="isDrawerOpened"
    [position]="position"
    [autoCollapse]="false"
  >
    <ng-template kendoDrawerTemplate>
      <div class="d-flex justify-content-between drawer-header align-items-center">
        <h4 class="title">Filter</h4>
        <a class="close-icon" (click)="closeDrawer()"><i class="far fa-xmark"></i></a>
      </div>

      <div class="drawer-content">
        <fieldset class="mb-20">
          <kendo-label text="Date" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="dateFilter"
            textField="text"
            valueField="value"
            [value]="selectedFilterValue"
            [valuePrimitive]="true"
            (selectionChange)="selectionChange($event)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              {{ dataItem.text }}
            </ng-template>
          </kendo-dropdownlist>
        </fieldset>

        <div *ngIf="selectedFilterValue == 1">
          <fieldset class="mb-20">
            <kendo-label text="Start Date" labelCssClass="k-form-label"></kendo-label>
            <kendo-datepicker
              calendarType="classic"
              [format]="'EEE, d MMM yyyy'"
              (valueChange)="updateStartDate($event)"
              [(ngModel)]="startDate"
            ></kendo-datepicker>
          </fieldset>
          <fieldset class="mb-20">
            <kendo-label text="End Date" labelCssClass="k-form-label"></kendo-label>
            <kendo-datepicker
              calendarType="classic"
              [format]="'EEE, d MMM yyyy'"
              (valueChange)="updateEndDate($event)"
              [(ngModel)]="endDate"
            ></kendo-datepicker>
            <div *ngIf="dateError" class="text-danger">
              End Date cannot be earlier than Start Date.
            </div>
          </fieldset>
        </div>

        <fieldset class="k-form-fieldset mb-20" *ngIf="isAdmin">
          <kendo-label text="People" labelCssClass="k-form-label"></kendo-label>
          <kendo-multiselect
            [(ngModel)]="selectedUsers"
            [clearButton]="false"
            [checkboxes]="true"
            [data]="userList"
            valueField="id"
            textField="userName"
            (valueChange)="onUserSelectionChange($event)"
          ></kendo-multiselect>
        </fieldset>

        <fieldset class="k-form-fieldset mb-20" *ngIf="!isFromTaskList">
          <kendo-label text="Projects" labelCssClass="k-form-label"></kendo-label>
          <kendo-multiselect
            [(ngModel)]="selectedProject"
            [clearButton]="false"
            [checkboxes]="true"
            [data]="projectList"
            valueField="id"
            textField="name"
            (valueChange)="onProjectSelectionChange($event)"
          ></kendo-multiselect>
        </fieldset>

        <fieldset class="k-form-fieldset mb-20">
          <kendo-label text="Billable" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="billableFilterList"
            textField="text"
            valueField="value"
            [value]="selectedBillableFilterValue"
            [valuePrimitive]="true"
            (selectionChange)="billableFilterselectionChange($event)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              {{ dataItem.text }}
            </ng-template>
          </kendo-dropdownlist>
        </fieldset>
      </div>

      <div class="drawer-footer d-flex justify-content-end gap-15">
        <button kendoButton (click)="onSubmit()">Submit</button>

        <button kendoButton fillMode="outline" (click)="clearFilter(true)">Clear All</button>
      </div>
    </ng-template>
  </kendo-drawer>
</kendo-drawer-container>
