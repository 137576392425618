import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { WindowService } from '@progress/kendo-angular-dialog';
import { TimeLogPopupComponent } from '../time-log-popup/time-log-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, UrlShortenerService } from 'src/core/services';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { GetIdentityUsersInput, IdentityUserService } from '@abp/ng.identity/proxy';
import { ConfigStateService, ListService } from '@abp/ng.core';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionTypeEnum } from 'src/app/enum/status-type-enum';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { FormControl, FormGroup } from '@angular/forms';
import { TaskType } from 'src/app/enum/task-type';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { DrawerMode, DrawerPosition } from '@progress/kendo-angular-layout';
import { ProjectUserService } from '@proxy/project-service/project';
import { SubTasksComponent } from '../shared/sub-tasks/sub-tasks.component';
import { FilesComponent } from '../shared/files/files.component';
import { TaskDateDto, TimeComponent } from '../task-list';
import { Store } from '@ngxs/store';
import { AddTimelogList, SetTaskStatus } from 'src/app/core/store/task.action';
import { Observable } from 'rxjs';
import { TaskState } from 'src/app/core/store/task.state';
import { DrawerTab } from 'src/app/enum/drawer-tab-enum';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Priority } from 'tasks/task/config/src/enums/priority';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-add-task-detail',
  templateUrl: './add-task-detail.component.html',
  styleUrls: ['./add-task-detail.component.scss'],
})
export class AddTaskDetailComponent implements OnInit {
  //#region timmer
  isTimerRunning = false;

  startTime: Date;
  endTime: Date;

  timerInterval: any;
  startDate: any;
  dueDate: any;

  safeDescription: SafeHtml | undefined;
  //#endregion

  sorting = null;

  timerActionList = [
    { id: 1, iconClass: 'fa-solid fa-pen', text: 'Edit Task', actionTypeId: ActionTypeEnum.Edit },
    // {
    //   id: 2,
    //   iconClass: 'fa-solid fa-download',
    //   text: 'Export Task Timelog',
    //   actionTypeId: ActionTypeEnum.Export,
    // },
    {
      id: 3,
      iconClass: 'far fa-trash-alt',
      text: 'Delete Task',
      actionTypeId: ActionTypeEnum.Delete,
    },
  ];

  userList: any[] = [];
  fileList: any[] = [];
  subtaskList: any[] = [];
  assigneeList: any[] = [];
  assigneListItems: any[] = [];

  taskId: any;
  projectId: any;
  projectName: any;
  panelId: any;
  currentUserId: any;
  taskListId: any;
  taskDetails: any;
  tabId: any;
  lastModifiedField: any;

  actionTypeEnum = ActionTypeEnum;
  taskStatusType = TaskStatusType;
  taskForm: FormGroup;
  drawerSelectedTabId: number = DrawerTab.File;
  priorityEnum = Priority;

  totalLoggedNonBillableTime: string;
  totalLoggedBillableTime: string;

  currentUser = '';
  currentUserSurname = '';

  editedRowIndex: number;
  commentCount: number;
  fileCount: number = 0;
  taskStatus: number;

  @Input() sortingName = '';

  isAllSubTaskCompleted = false;
  isDrawerOpened = false;
  isProjectBillable = false;
  isShowAssigneeMultiSelect = false;
  isShowDueDateInput = false;
  isShowStartDateInput = false;
  openInNewTab = false;
  isFromEditAction: boolean = false;
  isFromDashboard: boolean = false;
  isValidTaskId = false;

  formGroup: FormGroup;

  expandMode: DrawerMode = 'overlay';
  position: DrawerPosition = 'end';

  taskTypeList: { id: TaskType; name: string; iconClass: string }[] = [];

  @ViewChild(TimeComponent) timelogComponent!: TimeComponent;
  @ViewChild(SubTasksComponent) subTasksComponent!: SubTasksComponent;
  @ViewChild(FilesComponent) filesComponent!: FilesComponent;

  totalLoggedTime$: Observable<any>;
  subTaskList$: Observable<any[]>;
  taskStatus$: Observable<any>;
  taskParams: any;
  scrollPosition = 0;

  constructor(
    private route: ActivatedRoute,
    private taskService: TaskService,
    public commonService: CommonService,
    public readonly list: ListService<GetIdentityUsersInput>,
    protected service: IdentityUserService,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private config: ConfigStateService,
    private readonly projectService: ProjectService,
    private projectUser: ProjectUserService,
    private store: Store,
    private urlShortener: UrlShortenerService,
    private windowService: WindowService,
    private cdr: ChangeDetectorRef,
  ) {
    this.currentUser = this.config.getOne('currentUser').name;
    this.currentUserSurname = this.config.getOne('currentUser').surName;
    this.currentUserId = this.config.getOne('currentUser').id;
    this.taskTypeList = this.commonService.getTaskTypeList();
  }

  ngOnInit(): void {
    this.setForm();
    this.route.paramMap.subscribe(params => {
      const taskId = params.get('taskId')?.split('-')[1] || '';
      if (taskId && /^\d+$/.test(taskId)) {
        this.taskId = taskId;
        let urlParams = this.urlShortener.getStoredUrlData();
        if (urlParams) {
          this.panelId = urlParams['panelId'] ? +atob(urlParams['panelId']) : undefined;
          this.tabId = urlParams['tabId'] ? +atob(urlParams['tabId']) : undefined;
          this.isFromDashboard = urlParams['isFromDashboard']
            ? atob(urlParams['isFromDashboard']) === 'true'
            : false;
        }
        this.getTaskListDetails();
      } else {
        this.router.navigateByUrl('/Error?statusCode=404');
      }
    });

    this.commonService.allTimeLogListFromTimer$.subscribe(() => {
      this.timelogComponent.getTimelogList();
    });

    this.totalLoggedTime$ = this.store.select(TaskState.getTotalLoggedTime);

    this.taskStatus$ = this.store.select(TaskState.getTaskStatus);
    this.taskStatus$.subscribe(status => {
      this.taskStatus = status;
    });
    setTimeout(() => {
      localStorage.removeItem('scrollPosition');
      window.scrollTo({ top: 0, behavior: 'auto' });
    }, 0);
  }

  setForm(): void {
    this.taskForm = new FormGroup({
      assignee: new FormControl(null),
      dueDate: new FormControl(''),
      startDate: new FormControl(''),
    });
  }

  getTaskListDetails(): void {
    this.spinnerService.show();
    this.taskService
      .get(this.taskId)
      .pipe(
        catchError(error => {
          this.spinnerService.hide();
          if (error.status === 404) {
            this.isValidTaskId = false;
            this.router.navigateByUrl('/Error?statusCode=404');
          } else {
            console.error('An error occurred:', error);
          }
          return of(null);
        }),
      )
      .subscribe(x => {
        if (!x) return;

        this.isValidTaskId = true;
        this.taskDetails = x;
        this.projectId = this.taskDetails.projectId;
        this.taskListId = this.taskDetails.taskListId;

        this.getProjectDetail();
        this.getAssigneeList();

        this.store.dispatch(new SetTaskStatus(x.taskStatus));
        this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(
          this.taskDetails.description.replace(/href="(?!https?:\/\/)/g, 'href="https://'),
        );
        this.taskForm.patchValue({
          assignee: x.users,
          dueDate: x.duedate ? new Date(x.duedate) : '',
          startDate: x.startdate ? new Date(x.startdate) : '',
        });

        this.store.select(TaskState.getSubTaskList).subscribe(subtasks => {
          const hasSubtasks = subtasks.length > 0;
          this.taskDetails.isAllSubTaskCompleted = hasSubtasks
            ? subtasks.every(task => task.taskStatus === TaskStatusType.Completed)
            : true;
        });
        this.spinnerService.hide();
      });
  }

  getIconClass(taskType: number): string {
    return this.taskTypeList.find(x => x.id === taskType)?.iconClass;
  }

  onDateChange(fieldName: string, value: Date): void {
    if (value === null || value === undefined || !(value instanceof Date)) {
      this.lastModifiedField = fieldName;
      this.taskForm.controls[fieldName].setValue(null);
      this.onSave();
      return;
    }

    this.lastModifiedField = fieldName;
    this.taskForm.controls[fieldName].setValue(value);

    const startDate = this.taskForm.controls.startDate.value;
    const dueDate = this.taskForm.controls.dueDate.value;

    if (startDate && dueDate && startDate > dueDate) {
      this.toasterService.error(
        NotificationMessage.startAndDueDateValidation,
        '',
        this.commonService.toasterMessageConfiguration,
      );

      const fieldInTaskDetails =
        this.lastModifiedField === 'dueDate'
          ? 'duedate'
          : this.lastModifiedField === 'startDate'
            ? 'startdate'
            : this.lastModifiedField;

      const originalValue = this.taskDetails[fieldInTaskDetails];

      const originalDate = originalValue ? new Date(originalValue) : null;

      this.taskForm.patchValue({
        [this.lastModifiedField]: originalDate,
      });

      return;
    }

    this.onSave();
  }

  onSave(): void {
    const startDate = this.taskForm.controls.startDate.value;
    const dueDate = this.taskForm.controls.dueDate.value;

    const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(
      this.taskDetails.estimateMinutes,
    );

    const estimateTime = `${typeof hours === 'string' && hours === '' ? 0 : hours}:${
      typeof minutes === 'string' && minutes === '' ? 0 : minutes
    }`;

    const estimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes(estimateTime);

    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: this.taskId,
      name: this.taskDetails.name,
      description: this.taskDetails.description,
      progress: 20,
      startdate: startDate
        ? new TaskDateDto(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate())
        : null,
      duedate: dueDate
        ? new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate())
        : null,
      priority: +this.taskDetails.priority,
      estimateMinutes,
      taskListId: this.taskListId,
      parentTaskId: this.taskDetails.parentTaskId,
      notify: true,
      assignedToUserIds: this.taskForm.controls.assignee.value.map(item => item.userId) || [],
      taskType: this.taskDetails.taskType,
      taskStatus: this.taskDetails.taskStatus,
      columnId: this.taskDetails.columnId,
      subTasks: [],
      lastModifierId: this.currentUserId,
    };

    this.taskService.update(this.taskId, param).subscribe(
      res => {
        this.spinnerService.hide();
        if (this.taskDetails) {
          this.taskDetails.users = this.taskForm.controls.assignee.value;
        }
        this.taskDetails = { ...this.taskDetails, ...param };
        this.taskDetails.lastModifierUserName = res?.lastModifierUserName;
        this.taskDetails.lastModificationTime = res?.lastModificationTime;
        this.taskDetails.startdate = res.startdate;
        this.taskDetails.duedate = res.duedate;
        this.taskForm.patchValue({
          dueDate: res.duedate ? new Date(res.duedate) : '',
          startDate: res.startdate ? new Date(res.startdate) : '',
        });

        this.toasterService.success(
          NotificationMessage.updateTaskSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );

        this.isShowAssigneeMultiSelect = false;
        this.isShowDueDateInput = false;
        this.isShowStartDateInput = false;

        document.body.style.overflow = 'auto';
      },
      err => this.spinnerService.hide(),
    );
  }

  back(): void {
    if (this.isFromDashboard) {
      this.router.navigate(['dashboard']);
      return;
    } else {
      this.router.navigate(['time']);
    }
  }

  getProjectDetail(): void {
    this.projectService.get(this.projectId).subscribe((res: any) => {
      this.isProjectBillable = res.isBillable;
    });
  }

  onTaskCompleteChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;

    if (this.subTasksComponent.allSubTaskCompleted()) {
      if (isChecked) {
        this.taskService.isMarkTaskAsComplete(this.taskId).subscribe(() => {
          this.store.dispatch(new SetTaskStatus(TaskStatusType.Completed));
        });
      } else {
        this.taskService.markTaskAsReopened(this.taskId).subscribe(() => {
          this.store.dispatch(new SetTaskStatus(TaskStatusType.ReOpened));
        });
      }
    } else {
      this.toasterService.info(
        NotificationTextMessage.taskCompletedErrorMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      checkbox.checked = false;
    }
  }

  onActionItemClicked(event: any): void {
    switch (event.actionTypeId) {
      case ActionTypeEnum.Edit:
        this.editTask();
        break;
      case ActionTypeEnum.Delete:
        this.removeTask();
        break;
      case ActionTypeEnum.Export:
        // Handle export action
        break;
      default:
        break;
    }
  }

  editTask(): void {
    this.drawerSelectedTabId = DrawerTab.Timelog;
    this.isFromEditAction = true;
    this.isDrawerOpened = !this.isDrawerOpened;
    this.taskId = this.taskId;
    this.projectId = this.projectId;
    this.taskDetails = this.taskDetails;
    this.assigneeList = this.assigneeList;
    this.fileCount = this.taskDetails.attachmentCount;

    document.body.style.overflow = 'hidden';
  }

  onTaskDrawerClose(event) {
    if (event) {
      this.isShowAssigneeMultiSelect = false;
      this.ngOnInit();
    }
    this.isDrawerOpened = !this.isDrawerOpened;
  }

  removeTask(): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + this.taskDetails.name + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();
        this.taskService.delete(this.taskId).subscribe(
          res => {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationMessage.deleteTaskSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.redirectToTaskList();
          },
          error => {
            this.spinnerService.hide();
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  redirectToTaskList(event?: MouseEvent, source?: 'taskList' | 'back'): void {
    this.openInNewTab = event?.ctrlKey || event?.metaKey;

    if (this.openInNewTab) {
      const params = new URLSearchParams({
        projectId: btoa(this.projectId),
        panelId: btoa(this.panelId),
        taskListId: btoa(this.taskListId),
      }).toString();

      const url = `/task-list?${params}`;
      window.open(url, '_blank');
      return;
    }

    event?.preventDefault();

    const params: { [key: string]: string } = {
      projectId: btoa(this.projectId),
      panelId: btoa(this.panelId),
      taskListId: btoa(this.taskListId),
    };

    if (source === 'back') {
      if (this.isFromDashboard) {
        this.router.navigate(['dashboard']);
        return;
      }

      if (this.tabId) {
        params.tabId = btoa(this.tabId);
      }
    }

    this.router.navigate(['task-list'], { queryParams: params });
  }

  openTimeLog(taskName) {
    if (this.commonService.isTimelogWindowOpen()) return;

    this.commonService.manageScrollPosition();

    document.body.style.overflow = 'hidden';
    const overlayDiv = document.createElement('div');
    overlayDiv.className = 'k-overlay';
    document.body.appendChild(overlayDiv);

    const windowRef = this.windowService.open({
      content: TimeLogPopupComponent,
      width: 500,
      title: 'Log Time',
      cssClass: 'timelog-popup add-time-log-quickly timelog-window',
      resizable: false,
    });

    windowRef.window.instance.leftChange.subscribe(position => {
      this.cdr.detectChanges();
      this.commonService.restrictTimelogMovement();
    });

    windowRef.window.instance.topChange.subscribe(position => {
      this.cdr.detectChanges();
      this.commonService.restrictTimelogMovement();
    });

    document.body.style.overflow = 'hidden';

    this.startTime = new Date();
    this.endTime = new Date();
    windowRef.content.instance.startTime = this.startTime;
    windowRef.content.instance.endTime = this.endTime;
    windowRef.content.instance.taskId = this.taskId;
    windowRef.content.instance.taskName = taskName;
    windowRef.content.instance.projectId = this.projectId;
    windowRef.content.instance.isProjectBillable = this.isProjectBillable;

    windowRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.store.dispatch(new AddTimelogList(res.data)).subscribe();
          this.store.dispatch(new SetTaskStatus(res.data.taskStatus));
          this.taskStatus$ = this.store.select(TaskState.getTaskStatus);
        }
        document.body.style.overflow = 'auto';
      }
      document.body.removeChild(overlayDiv);
      document.body.style.overflow = 'auto';
    });
  }

  onStartTimerClick() {
    const timerData = {
      showTimer: true,
      taskId: this.taskId,
      projectId: this.projectId,
    };

    this.commonService.showTimerPopup(timerData);
    this.commonService.manageScrollPosition();
  }

  getAssigneeList(): void {
    const params = {
      sorting: 'name asc',
      skipCount: 0,
      maxResultCount: 1000,
    };
    this.projectUser.getPeople(this.projectId, params).subscribe(res => {
      if (res.items.length > 0) {
        this.assigneeList = this.commonService.preprocessAssigneeList(res.items);
        this.assigneListItems = this.assigneeList.map(assignee => ({
          userName: assignee.userName,
          userId: assignee.userId,
        }));
      }
    });
  }

  onAssigneeSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { userName: string; userId: number }) =>
      item.userName.toLowerCase().includes(value.toLowerCase());

    this.assigneeList = this.assigneListItems.filter(contains(searchTerm));
  }

  onClickAssignee(): void {
    if (this.taskStatus !== this.taskStatusType.Completed) {
      this.isShowAssigneeMultiSelect = true;
    }
  }
}
