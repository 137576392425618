import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AddTaskDetailComponent,
  ProjectDashboardComponent,
  TaskListComponent,
  TimeComponent,
} from 'tasks/task/src/pages';
import {
  CategoryListComponent,
  CompanyListComponent,
  PortfolioComponent,
  ProductComponent,
  UserListComponent,
} from './modules';
import { ProjectListComponent } from 'projects/project/src/pages';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'account',
    loadChildren: () => import('@abp/ng.account').then(m => m.AccountModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@abp/ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'tenant-management',
    loadChildren: () =>
      import('@abp/ng.tenant-management').then(m => m.TenantManagementModule.forLazy()),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'projects',
    loadChildren: () => import('@project').then(m => m.ProjectModule),
  },
  {
    path: 'tasks',
    loadChildren: () => import('../../tasks/task/src/task.module').then(m => m.TaskModule),
  },
  {
    path: 'task-list',
    component: TaskListComponent,
  },
  {
    path: 'people',
    loadChildren: () => import('./people/people/people.module').then(m => m.PeopleModule),
  },
  {
    path: 'task-Detail',
    component: AddTaskDetailComponent,
  },
  {
    path: 'time',
    component: TimeComponent,
  },
  {
    path: 'project-dashboard',
    component: ProjectDashboardComponent,
  },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'product', component: ProductComponent },
  { path: 'company', component: CompanyListComponent },
  { path: 'category', component: CategoryListComponent },
  { path: 'user', component: UserListComponent },
  { path: 'project-list', component: ProjectListComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
