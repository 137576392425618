<kendo-dialog-titlebar (close)="onClose()">
  <span>{{ headerText }} project</span>
</kendo-dialog-titlebar>

<div>
  <div *ngIf="currentTab === 1">
    <div>
      <div class="border-bottom-dashed">
        <kendo-card [width]="auto">
          <kendo-card-header
            class="k-hstack gap-15 pl-0 pr-0 align-items-center justify-content-between pb-10 flex-wrap"
          >
            <div class="d-flex gap-15">
              <kendo-avatar rounded="medium" class="k-avatar-square">
                <i class="fas fa-file-alt font-size-24"></i>
              </kendo-avatar>
              <div>
                <h1 kendoCardTitle class="fw-normal">Project Details</h1>
                <p kendoCardSubtitle class="fw-normal">Add basic details for your project</p>
              </div>
            </div>
            <div>
              <kendo-circularprogressbar
                [progressColor]="randomColorList"
                [value]="progessLevelIndicatorValue"
                style="width: 70px; height: 70px"
              >
                <ng-template
                  kendoCircularProgressbarCenterTemplate
                  let-color="color"
                  let-value="progressValue"
                >
                  <div>
                    <span>{{ currentTab }}</span>
                    /
                    <span>{{ progressValue }}</span>
                  </div>
                </ng-template>
              </kendo-circularprogressbar>
            </div>
          </kendo-card-header>
        </kendo-card>
      </div>
      <form [formGroup]="projectForm" validateOnSubmit class="mt-2 k-form k-form-md">
        <kendo-formfield>
          <kendo-label
            [for]="projectDescription"
            text="Project Description"
            labelCssClass="k-form-label"
          ></kendo-label>
          <kendo-editor
            style="height: 150px"
            #projectDescription
            class="mb-20 kendo-editor"
            placeholder="| What's this project about"
            formControlName="projectDescription"
            [iframeCss]="{ content: customStyleContent }"
          ></kendo-editor>
        </kendo-formfield>
        <div class="mb-5">
          <kendo-label labelCssClass="k-form-label fw-500">Expected results / goals</kendo-label>
        </div>
        <div class="grid-wrapper">
          <kendo-grid
            #grid
            [data]="productsGoalList"
            (edit)="onEditProjectGoal($event)"
            (cancel)="onCancelProjectGoal($event)"
            (save)="onSaveProjectGoal($event)"
            (add)="onAddProjectGoal($event)"
            (remove)="onRemoveProjectGoal($event)"
            class="add-goal-grid"
          >
            <ng-template kendoGridToolbarTemplate [position]="'bottom'">
              <a
                kendoGridAddCommand
                class="add-goal mt-10 pl-10"
                [ngClass]="{ 'mt-10 pl-10': productsGoalList.length > 0 }"
                ><i class="far fa-plus pr-5"></i>Add new goal</a
              >
            </ng-template>
            <ng-template kendoGridNoRecordsTemplate>
              <div class="display-none"></div>
            </ng-template>
            <kendo-grid-column field="description">
              <ng-template kendoGridHeaderTemplate></ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-flex gap-10 align-items-center">
                  <i class="fa-regular fa-bullseye-arrow text-icon-red-color font-size-20"></i>
                  {{ dataItem.description }}
                </div>
              </ng-template>
              <ng-template
                kendoGridEditTemplate
                let-dataItem="dataItem"
                let-formGroup="formGroup"
                let-rowIndex="rowIndex"
              >
                <form class="k-form k-form-md" [formGroup]="formGroup">
                  <fieldset class="k-form-fieldset d-flex gap-5">
                    <kendo-textbox
                      [formControl]="formGroup.get('description')"
                      (keydown.enter)="
                        onSaveProjectGoal({
                          sender: grid,
                          rowIndex: rowIndex,
                          formGroup: formGroup,
                          isNew: isNew,
                        })
                      "
                      class="k-input-inner-text-box"
                      placeholder="Enter your project goal!"
                    ></kendo-textbox>
                  </fieldset>
                </form>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-command-column [width]="95">
              <ng-template kendoGridCellTemplate let-isNew="isNew">
                <ul class="grid-action-list grid-command-action">
                  <div class="d-flex gap-5 justify-content-end">
                    <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
                      <a
                        title="Save"
                        class="grid-command-action-links"
                        kendoGridSaveCommand
                        [disabled]="formGroup?.invalid"
                      >
                        <i class="far fa-floppy-disk"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        title="Discard change"
                        class="grid-command-action-links"
                        kendoGridCancelCommand
                      >
                        <i class="far fa-ban"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        title="Edit"
                        class="grid-command-action-links"
                        kendoGridEditCommand
                        *ngIf="!isNew"
                      >
                        <i class="far fa-pen"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        title="Delete"
                        class="grid-command-action-links"
                        kendoGridRemoveCommand
                        *ngIf="!isNew"
                      >
                        <i class="far fa-trash-alt"></i>
                      </a>
                    </li>
                  </div>
                </ul>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
        </div>

        <div class="d-flex gap-20 flex-wrap mb-15">
          <kendo-formfield class="flex-1">
            <kendo-label [for]="projectTitle" text="Project Name" labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-textbox
              formControlName="projectTitle"
              aria-placeholder="Give your project a name"
              #projectTitle
            ></kendo-textbox>
            <kendo-formerror *ngIf="projectForm.controls.projectTitle.errors?.maxlength"
              >Error: Project Name cannot have more than 255 character!</kendo-formerror
            >
            <kendo-formerror *ngIf="projectForm.controls.projectTitle.errors?.required"
              >Error: Project Name is required!</kendo-formerror
            >
          </kendo-formfield>
          <kendo-formfield class="flex-1">
            <kendo-label text="Company" labelCssClass="k-form-label"
              ><span class="required">*</span></kendo-label
            >
            <kendo-dropdownlist
              [data]="companyList?.items"
              [textField]="'name'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              formControlName="company"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span class="template">{{ dataItem.name }}</span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!projectForm.controls.company.required"
              >Error: Company is required</kendo-formerror
            >
          </kendo-formfield>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="false">
    <div>
      <div class="border-bottom-dashed">
        <kendo-card [width]="auto">
          <kendo-card-header
            class="k-hstack gap-15 pl-0 pr-0 align-items-center justify-content-between pb-10"
          >
            <div class="d-flex gap-15">
              <kendo-avatar rounded="medium" class="k-avatar-square">
                <i class="fas fa-wallet font-size-24"></i>
              </kendo-avatar>
              <div>
                <h1 kendoCardTitle class="fw-normal">Budget Details</h1>
                <p kendoCardSubtitle class="fw-normal">Select a budget type for your project</p>
              </div>
            </div>
            <div>
              <kendo-circularprogressbar
                [progressColor]="randomColorList"
                [value]=""
                style="width: 70px; height: 70px"
              >
                <ng-template
                  kendoCircularProgressbarCenterTemplate
                  let-color="color"
                  let-value="value"
                >
                  <div>
                    <span>{{ currentTab }}</span>
                    /
                    <span>{{ value }}</span>
                  </div>
                </ng-template>
              </kendo-circularprogressbar>
            </div>
          </kendo-card-header>
        </kendo-card>
      </div>
      <div class="mt-2">
        <p>Choose a budget type</p>
        <div class="d-flex gap-15 type-list-wrapper">
          <div class="type-list-items">
            <div class="q-type text-align-right">
              <input type="radio" name="budgetType" value="Male" #budget kendoRadioButton />
              <p class="mb-5 font-size-14 text-align-left">No budget</p>
              <p class="font-size-12 text-secondary-text text-align-left">
                Internal or non-billable <br />
                project
              </p>
            </div>
          </div>
          <div class="type-list-items">
            <div class="q-type text-align-right">
              <input
                type="radio"
                name="budgetType"
                value="Male"
                #standard
                kendoRadioButton
                checked
              />
              <p class="mb-5 font-size-14 text-align-left">Standard</p>
              <p class="font-size-12 text-secondary-text text-align-left">
                Bill clients for standalone <br />
                projects
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <form [formGroup]="budgetForm" class="mt-2">
            <div class="d-flex gap-20">
              <kendo-formfield class="flex-1">
                <kendo-label
                  [for]="budgetAmount"
                  text="Budget Amount"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <kendo-textbox formControlName="budgetAmount" #budgetAmount>
                  <ng-template kendoTextBoxPrefixTemplate>
                    <i class="fa-solid fa-indian-rupee-sign k-icon"></i>
                  </ng-template>
                </kendo-textbox>
              </kendo-formfield>
              <div class="flex-1">
                <kendo-label text="Start and end date" labelCssClass="k-form-label"></kendo-label>
                <div class="d-flex gap-10">
                  <kendo-formfield>
                    <kendo-datepicker
                      #startDate
                      formControlName="startDate"
                      [min]="min"
                      [max]="max"
                    >
                    </kendo-datepicker>
                  </kendo-formfield>
                  <kendo-formfield>
                    <kendo-datepicker #endDate formControlName="endDate" [min]="min" [max]="max">
                    </kendo-datepicker>
                  </kendo-formfield>
                </div>
              </div>
            </div>
            <div class="d-flex gap-20 mt-2">
              <kendo-formfield class="flex-1">
                <kendo-label
                  [for]="budgetRepeats"
                  text="Budget Repeats"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <kendo-dropdownlist
                  #budgetRepeats
                  formControlName="budgetRepeats"
                  [data]="BudgetRepeatsData"
                >
                </kendo-dropdownlist>
              </kendo-formfield>
              <kendo-formfield class="flex-1">
                <kendo-label
                  [for]="budgetBasedOn"
                  text="Budget Based on"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <kendo-dropdownlist
                  #budgetBasedOn
                  formControlName="budgetBasedOn"
                  [data]="BudgetBasedOnData"
                >
                </kendo-dropdownlist>
              </kendo-formfield>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentTab === 2">
    <div>
      <div class="border-bottom-dashed mb-20">
        <kendo-card [width]="auto">
          <kendo-card-header
            class="k-hstack gap-15 pl-0 pr-0 align-items-center justify-content-between flex-wrap"
          >
            <div class="d-flex gap-15">
              <kendo-avatar rounded="medium" class="k-avatar-square">
                <i class="far fa-user-group font-size-24"></i>
              </kendo-avatar>
              <div>
                <h1 kendoCardTitle class="fw-normal">Assign Workforce</h1>
                <p kendoCardSubtitle class="fw-normal">Assign peoples</p>
              </div>
            </div>
            <div>
              <kendo-circularprogressbar
                [progressColor]="randomColorList"
                [value]="progessLevelIndicatorValue"
                style="width: 70px; height: 70px"
              >
                <ng-template
                  kendoCircularProgressbarCenterTemplate
                  let-color="color"
                  let-value="progressValue"
                >
                  <div>
                    <span>{{ currentTab }}</span>
                    /
                    <span>{{ progressValue }}</span>
                  </div>
                </ng-template>
              </kendo-circularprogressbar>
            </div>
          </kendo-card-header>
        </kendo-card>
      </div>
      <kendo-textbox
        placeholder="Search for people"
        (keyup.enter)="getPeopleList()"
        (ngModelChange)="onSearchTextChange($event)"
        [(ngModel)]="searchText"
        class="search wp-100 mb-20"
      >
        <ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon font-size-14"></i>
        </ng-template>
      </kendo-textbox>
      <kendo-tabstrip class="assignee-tree">
        <kendo-tabstrip-tab title="People" [selected]="true">
          <ng-template kendoTabContent>
            <kendo-treelist
              [kendoTreeListHierarchyBinding]="peopleList"
              idField="id"
              parentIdField="companyId"
              kendoTreeListExpandable
              [initiallyExpanded]="true"
              kendoTreeListSelectable
              [selectable]="treeListSettings"
              [(selectedItems)]="selectedAssigneeList"
              itemKey="name"
              (selectionChange)="onPeopleSelectionChange($event)"
              class="create-project-treeview"
            >
              <kendo-treelist-checkbox-column
                [columnMenu]="false"
                [checkChildren]="true"
                [showSelectAll]="true"
                class="checkbox-column text-align-right"
                [width]="40"
                [expandable]="true"
              >
              </kendo-treelist-checkbox-column>
              <kendo-treelist-column field="name" title="{{ peopleSelect }}" [width]="350">
                <ng-template kendoTreeListCellTemplate let-dataItem>
                  <div class="d-flex gap-10">
                    <kendo-avatar
                      rounded="full"
                      size="large"
                      [border]="true"
                      [initials]="commonService.getInitials(dataItem.name)"
                      [style.backgroundColor]="
                        commonService.generateAvtarColor(dataItem.id, dataItem.name)
                      "
                      class="social-profile-text"
                    ></kendo-avatar>
                    <div class="customer-name">{{ dataItem.name }}</div>
                  </div>
                </ng-template>
              </kendo-treelist-column>
              <ng-template kendoTreeListNoRecordsTemplate let-dataItem>
                <div class="no-records-available">No records available</div>
              </ng-template>
            </kendo-treelist>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Teams" *ngIf="false">
          <ng-template kendoTabContent>
            <p>Tab 2 Content</p>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
      <div class="mb-20">
        <form class="k-form d-flex-row">
          <kendo-formfield class="d-flex-col-md-6 d-flex-col-xs-12 d-flex-col">
            <kendo-label
              text="Select responsible person"
              [for]="assignee"
              labelCssClass="k-form-label"
            ></kendo-label>
            <kendo-dropdownlist
              [data]="assigneeList"
              [textField]="'name'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="selectedResponsiblePerson"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span class="template">{{ dataItem.name }}</span>
              </ng-template>
              <ng-template kendoDropDownListNoDataTemplate>
                <span>Select a user from the 'People' tab to populate this list.</span>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
          <div
            class="assignee-profile d-flex flex-column gap-10 d-flex-col-md-6 d-flex-col-xs-12 d-flex-col"
          >
            <kend-label>Assignee</kend-label>
            <div>
              <div *ngFor="let item of assigneeList; let i = index" class="assignee-container">
                <kendo-avatar
                  [initials]="commonService.getInitials(item.name)"
                  [style.backgroundColor]="commonService.generateAvtarColor(item.id, item.name)"
                  class="social-profile-text"
                ></kendo-avatar>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="currentTab === 3">
    <div>
      <div class="border-bottom-dashed mb-20">
        <kendo-card [width]="auto">
          <kendo-card-header
            class="k-hstack gap-15 pl-0 pr-0 align-items-center justify-content-between flex-wrap"
          >
            <div class="d-flex gap-15">
              <kendo-avatar rounded="medium" class="k-avatar-square">
                <i class="far fa-square-dashed-circle-plus font-size-24"></i>
              </kendo-avatar>
              <div>
                <h1 kendoCardTitle class="fw-normal">Advance Options</h1>
                <p kendoCardSubtitle class="fw-normal">Apply additional options to your project</p>
              </div>
            </div>
            <div>
              <kendo-circularprogressbar
                [progressColor]="randomColorList"
                [value]="progessLevelIndicatorValue"
                style="width: 70px; height: 70px"
              >
                <ng-template
                  kendoCircularProgressbarCenterTemplate
                  let-color="color"
                  let-value="progressValue"
                >
                  <div>
                    <span>{{ currentTab }}</span>
                    /
                    <span>{{ progressValue }}</span>
                  </div>
                </ng-template>
              </kendo-circularprogressbar>
            </div>
          </kendo-card-header>
        </kendo-card>
      </div>
      <form class="k-form k-form-md" [formGroup]="advanceOptionsForm">
        <kendo-formfield class="mb-24">
          <kendo-label text="Product" [for]="product" labelCssClass="k-form-label"></kendo-label>
          <kendo-multiselect
            #product
            [data]="productList"
            formControlName="product"
            textField="name"
            valueField="id"
            [clearButton]="false"
            [filterable]="true"
            [checkboxes]="true"
            [ngModelOptions]="{ standalone: true }"
          ></kendo-multiselect>
        </kendo-formfield>
        <kendo-formfield class="mb-24">
          <kendo-label text="Project category" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="categoryList.items"
            [textField]="'categoryName'"
            [valueField]="'id'"
            [value]="selectedValue"
            [valuePrimitive]="true"
            formControlName="projectCategory"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span class="template">{{ dataItem.categoryName }}</span>
            </ng-template>
          </kendo-dropdownlist>
        </kendo-formfield>
        <kendo-formfield class="mb-24">
          <kendo-label
            text="Connected portfolios"
            [for]="portfolio"
            labelCssClass="k-form-label"
          ></kendo-label>
          <kendo-multiselect
            #portfolio
            [data]="portfolioList"
            formControlName="portfolio"
            textField="name"
            valueField="id"
            [clearButton]="false"
            [filterable]="true"
            [checkboxes]="true"
            [ngModelOptions]="{ standalone: true }"
          ></kendo-multiselect>
        </kendo-formfield>
        <div class="mb-24">
          <div class="mb-5">
            <kendo-label labelCssClass="k-form-label">Tags</kendo-label>
          </div>
          <div class="d-flex flex-wrap gap-5" [ngClass]="{ 'mb-15': tags.length > 0 }">
            <kendo-chip
              [removable]="true"
              *ngFor="let item of tags"
              (click)="removeTag(item.name)"
              themeColor="info"
            >
              <span>{{ item.name }}</span>
            </kendo-chip>
          </div>
          <div class="grid-wrapper tag-grid">
            <div *ngIf="isAddingTag" class="mb-15">
              <span class="line"></span>
              <div class="d-flex gap-5 align-items-center add-tag-container">
                <kendo-combobox
                  [allowCustom]="true"
                  [data]="filterTagListData()"
                  [textField]="'text'"
                  [valueField]="'value'"
                  [filterable]="true"
                  (filterChange)="tagFilter($event)"
                  [placeholder]="'Enter new tag name'"
                  [valueNormalizer]="valueNormalizer"
                  [(value)]="tagValue"
                  (valueChange)="valueChange($event)"
                  (selectionChange)="selectionChange($event)"
                  (keydown.enter)="addNewTag(tagValue)"
                >
                </kendo-combobox>
                <div class="add-tag-container-actions d-flex gap-5">
                  <div [ngClass]="{ 'curser-not-allowd': !isTagSaveDisable }">
                    <button
                      kendoButton
                      (click)="addNewTag(tagValue)"
                      class="actions-button"
                      [disabled]="!isTagSaveDisable"
                    >
                      <i class="far fa-floppy-disk"></i>
                    </button>
                  </div>
                  <button kendoButton (click)="onCancelTag()" class="actions-button">
                    <i class="far fa-ban"></i>
                  </button>
                </div>
              </div>
              <span class="line"></span>
            </div>

            <kendo-grid (add)="addTagHandler($event)">
              <ng-template kendoGridToolbarTemplate [position]="'bottom'">
                <a kendoGridAddCommand class="add-tag"><i class="fal fa-plus pr-5"></i>Add Tag</a>
              </ng-template>
              <ng-template kendoGridNoRecordsTemplate>
                <div class="display-none"></div>
              </ng-template>
            </kendo-grid>
          </div>
        </div>
        <kendo-label>Time</kendo-label>
        <kendo-formfield
          class="mb-20 mt-0 d-flex gap-10"
          style="flex-direction: row-reverse; justify-content: start"
        >
          <kendo-label
            class="k-checkbox-label"
            [for]="time"
            text="Billable"
            labelCssClass="k-form-label"
          ></kendo-label>
          <input type="checkbox" #time formControlName="time" kendoCheckBox />
        </kendo-formfield>
      </form>
    </div>
  </div>
</div>

<kendo-dialog-actions>
  <button *ngIf="currentTab !== 1" kendoButton (click)="previousStepClick()" fillMode="outline">
    Previous Step
  </button>
  <div [ngClass]="{ 'curser-not-allowd': projectForm.invalid }" class="display-inline flex-0">
    <ng-container *ngIf="(currentTab === 1 && !isEdit) || currentTab === 3">
      <button
        kendoButton
        (click)="createProject()"
        [disabled]="projectForm.invalid"
        [fillMode]="currentTab === 1 && !isEdit ? 'outline' : ''"
      >
        {{ headerText }} Project
      </button>
    </ng-container>
  </div>
  <div [ngClass]="{ 'curser-not-allowd': this.projectForm.invalid }" class="flex-0">
    <button
      *ngIf="currentTab !== 3"
      [disabled]="this.projectForm.invalid"
      kendoButton
      (click)="nextStepClick()"
    >
      Next Step
    </button>
  </div>
</kendo-dialog-actions>
