<div *ngIf="isRender">
  <div class="d-flex justify-content-between drawer-header align-items-center">
    <h4 class="title">Task Details</h4>
    <a class="close-icon" (click)="closeDrawer()"><i class="far fa-xmark"></i></a>
  </div>
  <div class="drawer-content">
    <form [formGroup]="taskForm" validateOnSubmit class="k-form k-form-md">
      <div class="d-flex align-items-center mb-20">
        <div class="display-inline" [ngClass]="{ 'curser-not-allowd': isAllSubTaskCompleted }">
          <input
            [disabled]="isAllSubTaskCompleted"
            [checked]="(taskStatus$ | async) === taskStatusType.Completed"
            type="checkbox"
            kendoCheckBox
            class="billable-checkbox mr-5"
            size="large"
            (change)="onTaskCompleteChange()"
          />
        </div>
        <kendo-dropdownbutton
          [iconClass]="getIconClass(this.taskForm.controls.taskType.value)"
          [data]="taskTypeList"
          textField="name"
          valueField="id"
          (itemClick)="setTaskTypeValue($event)"
          [popupSettings]="{ popupClass: popupClass }"
        >
          <ng-template kendoDropDownButtonItemTemplate let-dataItemDropDownButton>
            <div
              class="dropdown-menu-links"
              [ngClass]="{
                taskTypeSelected:
                  this.taskForm.controls.taskType.value === dataItemDropDownButton.id,
              }"
            >
              <i class="{{ dataItemDropDownButton.iconClass }} dropdown-item-color mr-5"></i>
              {{ dataItemDropDownButton.name }}
            </div>
          </ng-template>
        </kendo-dropdownbutton>
        <kendo-textarea
          class="k-input-inner-text-box"
          placeholder="Give your task a name"
          formControlName="taskName"
          [rows]="1"
        ></kendo-textarea>
      </div>
      <div class="mb-20">
        <kendo-editor
          style="height: 150px"
          class="mb-20 kendo-editor"
          placeholder="| Add a description"
          [iframeCss]="{ content: customStyleContent }"
          formControlName="description"
          [resizable]="true"
        ></kendo-editor>
      </div>
      <div>
        <div class="d-flex-row mb-20">
          <div class="d-flex-col-lg-5 d-flex-col">
            <kendo-label class="label-text"
              ><i class="far fa-user mr-10 font-size-16 label-text-icon"></i
              ><span class="font-size-14">Assignee</span></kendo-label
            >
          </div>
          <div class="d-flex-col-lg-7 d-flex-col d-flex align-items-center">
            <div
              *ngIf="!isShowAssigneeMultiSelect"
              (click)="
                isShowAssigneeMultiSelect = true;
                isShowDueDateInput = false;
                isShowPriorityInput = false
              "
            >
              <div
                *ngIf="this.taskForm.controls.assignee?.value.length === 0; else assigneeTemplate"
                class="d-flex align-items-center"
              >
                <i class="fal fa-circle-user mr-10 font-size-22"></i>
              </div>
              <ng-template #assigneeTemplate>
                <ng-container *ngFor="let user of this.taskForm.controls.assignee.value">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitialsAndAvtarColor(user).avatar"
                    [style.backgroundColor]="
                      commonService.getInitialsAndAvtarColor(user).generateAvtarColor
                    "
                    class="social-profile-text assignee-container"
                    [attr.title]="commonService.getInitialsAndAvtarColor(user).userName"
                    class="social-profile-text assignee-profile"
                  ></kendo-avatar>
                </ng-container>
              </ng-template>
            </div>
            <kendo-multiselect
              *ngIf="isShowAssigneeMultiSelect"
              [clearButton]="false"
              [checkboxes]="true"
              [data]="assigneeList"
              valueField="userId"
              textField="userName"
              [ngModelOptions]="{ standalone: true }"
              formControlName="assignee"
              (close)="isShowAssigneeMultiSelect = false"
              [filterable]="true"
              (filterChange)="onAssigneeSearch($event)"
            ></kendo-multiselect>
          </div>
        </div>

        <div class="d-flex-row mb-20">
          <div class="d-flex-col-lg-5 d-flex-col">
            <kendo-label class="label-text"
              ><i class="far fa-calendar mr-10 font-size-16 label-text-icon"></i
              ><span class="font-size-14">Due Date</span></kendo-label
            >
          </div>
          <div class="d-flex-col-lg-7 d-flex-col">
            <div
              *ngIf="!isShowDueDateInput"
              (click)="
                isShowAssigneeMultiSelect = false;
                isShowDueDateInput = true;
                isShowPriorityInput = false
              "
            >
              <div
                *ngIf="
                  this.taskForm.controls.dueDate?.value === '' ||
                    this.taskForm.controls.dueDate?.dueDate === null;
                  else dueDate
                "
              >
                <i class="fal fa-calendar-circle-plus font-size-18"></i>
              </div>
              <ng-template #dueDate>
                <div>
                  {{ this.taskForm.controls.dueDate?.value | date: 'dd-MMM-yyyy' }}
                </div>
              </ng-template>
            </div>

            <kendo-datepicker
              *ngIf="isShowDueDateInput"
              format="dd/MM/yyyy"
              formatPlaceholder="formatPattern"
              class="w-260"
              formControlName="dueDate"
              (close)="isShowDueDateInput = false"
              calendarType="classic"
              [placeholder]="!dateValue ? 'Choose a date ...' : ''"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="d-flex-row mb-20">
          <div class="d-flex-col-lg-5 d-flex-col">
            <kendo-label class="label-text"
              ><i class="far fa-flag mr-10 font-size-16 label-text-icon"></i
              ><span class="font-size-14">Priority</span></kendo-label
            >
          </div>
          <div class="d-flex-col-lg-7 d-flex-col">
            <div
              *ngIf="!isShowPriorityInput"
              (click)="
                isShowAssigneeMultiSelect = false;
                isShowDueDateInput = false;
                isShowPriorityInput = true
              "
            >
              <div
                class="status-container priority-{{
                  priorityEnum[this.taskForm.controls.priority.value]
                }}"
              >
                <span class="mr-5">
                  <i class="fa-solid fa-flag"></i>
                </span>
                {{ priorityEnum[this.taskForm.controls.priority?.value] }}
              </div>
            </div>
            <kendo-dropdownlist
              *ngIf="isShowPriorityInput"
              [data]="priorityList"
              textField="text"
              valueField="id"
              [valuePrimitive]="true"
              class="w-200"
              formControlName="priority"
              (close)="isShowPriorityInput = false"
            >
            </kendo-dropdownlist>
          </div>
        </div>
      </div>

      <div class="d-flex-row mb-20">
        <div class="d-flex-col-lg-5 d-flex-col">
          <kendo-label class="label-text">
            <i class="far fa-hourglass mr-10 font-size-16 label-text-icon"></i
            ><span class="font-size-14">Estimate Hours</span></kendo-label
          >
        </div>
        <div class="d-flex-col-lg-7 d-flex-col">
          <div class="d-flex gap-10 flex-wrap">
            <div class="d-flex">
              <kendo-textbox
                formControlName="estimateHour"
                [clearButton]="false"
                size="small"
                maxlength="5"
                [style.width.px]="80"
                class="k-input-inner-text-box"
                aria-placeholder="hour"
                #listName
                data-field="estimateHour"
                (keydown)="commonService.onKeyDownForEstHour($event)"
                (paste)="onPaste($event)"
              ></kendo-textbox>
              <span class="m-auto ml-5">/h</span>
            </div>
            <div class="d-flex">
              <kendo-textbox
                formControlName="estimateMinute"
                [clearButton]="false"
                size="small"
                [style.width.px]="80"
                class="k-input-inner-text-box"
                aria-placeholder="minuts"
                #listName
                data-field="estimateMinute"
                (keydown)="commonService.onKeyDownForEstMinutes($event)"
                (paste)="onPaste($event)"
              ></kendo-textbox>
              <span class="m-auto ml-5">/m</span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex-row mb-20">
        <div class="d-flex-col-lg-5 d-flex-col">
          <kendo-label class="label-text"
            ><i class="fa-thin fa-battery-full mr-10 font-size-16 label-text-icon"></i>
            <span class="font-size-14">Status</span></kendo-label
          >
        </div>
        <div class="d-flex-col-lg-7 d-flex-col d-flex align-items-center">
          <kendo-dropdownlist
            [data]="taskStatusTypeList"
            textField="value"
            valueField="key"
            placeholder="Select Status"
            formControlName="taskStatus"
            [ngModelOptions]="{ standalone: true }"
            [valuePrimitive]="true"
            class="w-200"
          ></kendo-dropdownlist>
        </div>
      </div>
      <hr />

      <div>
        <kendo-label labelCssClass="k-form-label font-size-16 mb-5" text="Sub Tasks"></kendo-label>
        <app-sub-tasks
          [taskId]="taskId"
          [sortingName]="sortingName"
          [taskListId]="taskListId"
          (reloadPage)="onReloadPage($event)"
          [loadStateData]="loadStateData"
        ></app-sub-tasks>
      </div>
      <hr />

      <div>
        <kendo-label labelCssClass="k-form-label font-size-16 mb-5" text="Files"></kendo-label>
        <app-files
          [taskId]="taskId"
          [taskName]="taskForm.controls.taskName.value"
          [loadStateData]="loadStateData"
        ></app-files>
      </div>

      <hr />
      <div>
        <kendo-tabstrip class="assignee-tree">
          <kendo-tabstrip-tab title="Time logs" [selected]="true">
            <ng-template kendoTabContent>
              <app-time-log-grid
                [taskId]="taskId"
                [projectId]="projectId"
                [isProjectBillable]="isProjectBillable"
                [taskName]="taskForm.controls.taskName.value"
                [loadStateData]="loadStateData"
              ></app-time-log-grid>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Files" *ngIf="false">
            <ng-template kendoTabContent>
              <div class="d-flex justify-content-center align-items-center flex-column mt-4">
                <img src="assets/images/no-files.png" alt="timelog" class="mb-10" />
                <p class="mt-5">No files are attached.</p>
                <p class="text-secondary-text font-size-12">
                  It seems there are no files attached on this task.
                </p>
                <a title="AP Timelog" class="add-on-btn" (click)="openAttachedFile()"
                  ><i class="far fa-plus"></i> Attach files
                </a>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </form>
  </div>
  <div class="drawer-footer d-flex justify-content-end gap-15">
    <button kendoButton fillMode="outline" (click)="closeDrawer()">Cancel</button>
    <button kendoButton (click)="onSave()">Save</button>
  </div>
</div>
