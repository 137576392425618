import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-set-an-estimate',
  templateUrl: './set-an-estimate.component.html',
  styleUrls: ['./set-an-estimate.component.scss'],
})
export class SetAnEstimateComponent implements OnInit {
  @Input() set estimateTime(value: any) {
    if (value !== undefined && value !== 0) {
      this.estimateTimeOnEdit = value;
      const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(value);
      this.estimateForm?.patchValue({
        estimateHour: hours,
        estimateMinute: minutes,
      });
      this.buttonText = 'Update estimate';
    } else {
      this.resetForm();
    }
  }

  @Input() showEstimatePopup: boolean = false;
  @Input() position: { top: number; left: number } | null = null;
  @Output() triggerEstimate = new EventEmitter<any>();
  estimateForm: FormGroup;
  buttonText: string = 'Add estimate';
  estimateTimeOnEdit: number = 0;

  constructor(
    private commonService: CommonService,
    private toasterService: ToasterService,
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  onAddEstimate(): void {
    const estimateHour = this.estimateForm.value.estimateHour?.toString().trim();
    const estimateMinute = this.estimateForm.value.estimateMinute?.toString().trim();

    const normalizedHour = estimateHour === '' ? '' : Number(estimateHour).toString();
    const normalizedMinute = estimateMinute === '' ? '' : Number(estimateMinute).toString();

    if (
      (normalizedHour === '0' && normalizedMinute === '0') ||
      (normalizedHour === '0' && normalizedMinute === '') ||
      (normalizedHour === '' && normalizedMinute === '0')
    ) {
      this.toasterService.error(
        NotificationMessage.estimateCanNotZero,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(
        this.estimateTimeOnEdit,
      );
      this.estimateForm?.patchValue({
        estimateHour: hours,
        estimateMinute: minutes,
      });
      return;
    }

    const estimateTime = `${this.estimateForm.value.estimateHour || 0}:${
      this.estimateForm.value.estimateMinute || 0
    }`;

    this.triggerEstimate.next(estimateTime);
    if (
      this.estimateForm.value.estimateHour !== '' ||
      this.estimateForm.value.estimateMinute !== ''
    )
      this.buttonText = 'Update estimate';
  }

  private setForm(): void {
    this.estimateForm = new FormGroup({
      estimateHour: new FormControl(''),
      estimateMinute: new FormControl(''),
    });
  }

  public resetForm(): void {
    this.estimateForm?.reset({
      estimateHour: '',
      estimateMinute: '',
    });
    this.estimateTimeOnEdit = 0;
    this.buttonText = 'Add estimate';
  }
}
