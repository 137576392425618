import { ToasterService } from '@abp/ng.theme.shared';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateUpdateTimelogDto } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import { ConfigStateService } from '@abp/ng.core';
import { ProjectUserService } from '@proxy/project-service/project';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-time-log-popup',
  templateUrl: './time-log-popup.component.html',
  styleUrls: ['./time-log-popup.component.scss'],
})
export class TimeLogPopupComponent implements OnInit {
  currentDate = new Date();
  @Input() startTime: any;
  @Input() endTime: any;
  @Input() taskId: any;
  @Input() projectId: any;
  @Input() timeLogId: any;
  @Input() taskName: any;
  @Input() isProjectBillable: boolean = false;
  @Input() userId: any;

  currentUser: any;

  popupClass = 'timer-picker-popup';

  timeSpent: string;
  totalSpentMinutes: number;

  logTimeForm: FormGroup;
  logTimeTitle = 'Log Time';

  userList: any[] = [];
  selectedUserValue: any[] = [];

  isAdmin = false;
  isEdit = false;

  constructor(
    public dialogRef: DialogRef,
    private timelogService: TimelogService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private datePipe: DatePipe,
    private spinnerService: NgxSpinnerService,
    private config: ConfigStateService,
    public projectUser: ProjectUserService,
  ) {
    this.currentUser = this.config.getOne('currentUser');
    this.preprocessCurrentUser();

    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    if (currentUserRole.includes('admin')) {
      this.isAdmin = true;
    }
  }

  preprocessCurrentUser() {
    if (this.currentUser) {
      const { name, surName, userName } = this.currentUser;
      const fullName = name?.trim() && surName?.trim() ? `${name} ${surName}` : userName;
      this.currentUser = {
        ...this.currentUser,
        userName: fullName,
      };
    }
  }

  ngOnInit(): void {
    if (this.timeLogId) {
      this.isEdit = true;
      this.logTimeTitle = 'Update Log Time';
      this.editTimeLogForm(this.timeLogId);
    }
    this.setForm();
  }

  getUserList(): void {
    const params = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    };
    this.projectUser.getPeople(this.projectId, params).subscribe(
      res => {
        if (res.items.length > 0) {
          this.userList = res.items.map(user => {
            const fullName =
              user.name?.trim() && user.surname?.trim()
                ? `${user.name} ${user.surname}`
                : user.userName;

            return {
              ...user,
              userName: fullName,
            };
          });
        } else {
          this.userList = [];
        }
      },
      err => {
        console.error(err);
      },
    );
  }

  quickAddTime(unit: 'hours' | 'minutes', value: number): void {
    const control = unit === 'hours' ? 'hours' : 'minutes';
    this.logTimeForm.get(control)?.setValue(value);

    if (unit === 'hours') {
      this.logTimeForm.get('minutes')?.setValue(0);
    } else {
      this.logTimeForm.get('hours')?.setValue(0);
    }

    if (unit === 'minutes') {
      this.onMinutesBlur();
    }
  }

  onMinutesBlur(): void {
    const minutes = this.logTimeForm.get('minutes')?.value;
    this.updateTime(minutes);
  }

  private updateTime(minutes: number): void {
    if (minutes >= 60) {
      const extraHours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      this.logTimeForm.patchValue({
        hours: (this.logTimeForm.get('hours')?.value || 0) + extraHours,
        minutes: remainingMinutes,
      });
    } else {
      this.logTimeForm.patchValue({
        hours: this.logTimeForm.get('hours')?.value,
        minutes,
      });
    }
  }

  updateDate(date: Date): void {
    this.currentDate = date;
    this.logTimeForm.get('date').setValue(date);
  }

  setForm(): void {
    this.logTimeForm = new FormGroup({
      date: new FormControl(this.currentDate),
      startTime: new FormControl(),
      endTime: new FormControl(),
      timeSpent: new FormControl(''),
      hours: new FormControl(0),
      minutes: new FormControl(0),
      billable: new FormControl(''),
      description: new FormControl('', Validators.maxLength(255)),
      taskComplete: new FormControl(false),
      userId: new FormControl(''),
    });

    this.logTimeForm.setValidators(this.timeRangeValidator);
    if (this.isAdmin) {
      this.logTimeForm.get('userId')?.setValidators(Validators.required);
    } else {
      this.logTimeForm.get('userId')?.clearValidators();
    }

    this.logTimeForm.get('userId')?.updateValueAndValidity();
    this.getUserList();
  }

  timeRangeValidator(group: FormGroup) {
    const startTime = group.get('startTime').value;
    const endTime = group.get('endTime').value;

    if (startTime && endTime && startTime >= endTime) {
      return { invalidTimeRange: true };
    }

    return null;
  }

  editTimeLogForm(timeLogId): void {
    this.timelogService.get(timeLogId).subscribe(res => {
      const convertedUTCDateToIST = this.datePipe.transform(
        new Date(res.timelogDatetime),
        'yyyy-MM-dd HH:mm:ss',
        '+0530',
      );

      var getStartTime = convertedUTCDateToIST.slice(11, 23);
      const startTimeParts = getStartTime.split(':');
      const hrs = parseInt(startTimeParts[0], 10);
      const mins = parseInt(startTimeParts[1], 10);

      const startTimeDate = new Date(this.datePipe.transform(convertedUTCDateToIST, 'yyyy-MM-dd'));
      startTimeDate.setHours(hrs);
      startTimeDate.setMinutes(mins);

      var getEndTime = this.commonService.getEndDate(convertedUTCDateToIST, res.timelogMinutes);

      const minutes = res.timelogBillableMinutes;
      this.updateTime(minutes);

      if (minutes >= 60) {
        const extraHours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        this.logTimeForm.patchValue({
          hours: this.logTimeForm.get('hours')?.value + extraHours,
          minutes: remainingMinutes,
        });
      } else {
        this.logTimeForm.patchValue({
          hours: this.logTimeForm.get('hours')?.value,
          minutes: minutes,
        });
      }

      this.logTimeForm.patchValue({
        date: new Date(this.datePipe.transform(convertedUTCDateToIST, 'yyyy-MM-dd')),
        timeSpent: res.timelogMinutes,
        description: res.timelogDescription,
        billable: res.timelogIsBillable,
        startTime: startTimeDate,
        endTime: getEndTime,
        userId: this.userId,
      });
    });
  }

  getEndDate(data): any {
    let timelogDatetime = new Date(data.timelogDatetime);
    timelogDatetime.setMinutes(timelogDatetime.getMinutes() + data.timelogMinutes);
    return timelogDatetime;
  }

  getStartDate(data) {
    const timelogDatetime = new Date(data.timelogDatetime);
    const timelogStartTime = new Date(timelogDatetime.getTime() - data.timelogHasStartTime * 60000); // Convert minutes to milliseconds
    const startTimeWithSubtraction = new Date(
      timelogStartTime.getTime() + data.timelogMinutes * 60000,
    );
    return startTimeWithSubtraction;
  }

  getTimelogDatetime(data): any {
    var getDate = new Date(this.datePipe.transform(data.date, 'yyyy-MM-dd'));
    var datePart = getDate.toLocalISOString().slice(0, 10);
    var timePart = data.startTime.toLocalISOString().slice(11, 23);
    var newDate = datePart + 'T' + timePart + 'Z';

    return newDate;
  }

  saveLogTime(data): void {
    this.spinnerService.show();

    const hours = this.logTimeForm.get('hours')?.value || 0;
    const minutes = this.logTimeForm.get('minutes')?.value || 0;
    var timelogBillableMinutes = this.isProjectBillable
      ? Number(hours) * 60 + Number(minutes)
      : null;

    const userId = this.logTimeForm.get('userId')?.value;
    if (this.isAdmin && !userId) {
      this.logTimeForm.get('userId')?.markAsTouched();
      this.spinnerService.hide();
      this.toasterService.error(
        NotificationTextMessage.userSelection,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return;
    }

    if (!this.logTimeForm.hasError('invalidTimeRange')) {
      const param: CreateUpdateTimelogDto = {
        taskId: this.taskId,
        projectId: this.projectId,
        timelogDatetime: this.getTimelogDatetime(data),
        timelogMinutes: this.totalSpentMinutes,
        timelogDescription: data.description,
        timelogIsBillable: data.billable === true ? 1 : 0,
        timelogHasStartTime: this.totalSpentMinutes,
        timelogBillableMinutes: timelogBillableMinutes,
        userId: this.isAdmin ? userId : this.currentUser.id,
        markTaskAsComplete: this.logTimeForm.get('taskComplete')?.value,
      };

      if (!this.isEdit) {
        this.timelogService.create(param).subscribe(
          res => {
            if (res) {
              this.spinnerService.hide();

              const dialogResult = { confirmed: true, data: res };
              this.dialogRef.close(dialogResult);

              this.toasterService.success(
                NotificationMessage.timelogAddedMessage,
                '',
                this.commonService.toasterMessageConfiguration,
              );
            }
          },
          err => {
            this.spinnerService.hide();
          },
        );
      } else {
        this.timelogService.update(this.timeLogId, param).subscribe(
          res => {
            if (res) {
              this.spinnerService.hide();
              const dialogResult = { confirmed: true, data: res };
              this.dialogRef.close(dialogResult);
              this.toasterService.success(
                NotificationMessage.timelogUpdatedMessage,
                '',
                this.commonService.toasterMessageConfiguration,
              );
            }
          },
          err => {
            this.spinnerService.hide();
          },
        );
      }
    } else {
      this.spinnerService.hide();
      this.toasterService.error(
        NotificationTextMessage.addValidateDateErrorMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  calculateTimeSpent() {
    if (this.startTime && this.endTime) {
      const start = new Date(this.startTime);
      const end = new Date(this.endTime);
      const diff = end.getTime() - start.getTime();

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      this.totalSpentMinutes = Math.floor(diff / (1000 * 60));
      this.timeSpent = `${hours}:${minutes}`;
    }
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }
}
