<div class="q-content p-20">
  <div class="q-content-bg">
    <div class="q-content-header">
      <div class="d-flex justify-content-between mb-20 flex-wrap gap-20">
        <kendo-textbox
          placeholder="Search for portfolio name"
          class="search"
          (keyup.enter)="getPortfolioList()"
          [(ngModel)]="searchText"
          (ngModelChange)="onSearchTextChange($event)"
          ><ng-template kendoTextBoxPrefixTemplate>
            <i class="fas fa-search k-icon font-size-14"></i> </ng-template
        ></kendo-textbox>
        <div class="d-flex gap-20 flex-wrap">
          <button kendoButton id="create" type="button" (click)="onPortfolioAdd()">
            <span
              ><i class="far fa-plus mr-10"></i>{{ '::Add New Portfolio' | abpLocalization }}</span
            >
          </button>
          <div class="display-none">
            <!-- currently we are not displaying this area -->
            <kendo-dropdownlist
              class="w-150"
              [data]="statusFilter"
              textField="text"
              valueField="value"
              [value]="selectedFilterValue"
              [valuePrimitive]="true"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                {{ dataItem.text }}
              </ng-template>
            </kendo-dropdownlist>
            <a class="flat-icon-container"><i class="fas fa-ellipsis-h"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-wrapper">
      <kendo-grid
        [kendoGridBinding]="portfolioData.items"
        [pageSize]="pageSize"
        [height]="gridHeight"
        [skip]="skip"
        [pageable]="
          portfolioData.items === undefined
            ? false
            : {
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: pagerposition,
              }
        "
        (pageChange)="pageChange($event)"
        [sortable]="{
          allowUnsort: true,
          mode: multiple ? 'multiple' : 'single',
        }"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        class="custom-no-records-container"
      >
        <ng-template kendoGridNoRecordsTemplate>
          <div
            *ngIf="isNoRecords && this.portfolioData.items.length === 0"
            class="no-records-text-container"
          >
            No Records Available
          </div>
        </ng-template>

        <kendo-grid-column field="name" title="Portfolio Name" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex gap-15">
              <i class="fas fa-folder"></i>
              <div class="d-flex flex-column potfolio-details">
                <a
                  class="wmx-100 clamp text-underline"
                  [routerLink]="['/project-list']"
                  [queryParams]="getQueryParams(dataItem)"
                  [target]="openInNewTab ? '_blank' : ''"
                  (click)="onPortfolioClick(dataItem, $event)"
                  title="{{ dataItem.name }}"
                >
                  {{ dataItem.name }}
                </a>
                <span *ngIf="dataItem.projectIds">{{ dataItem.projectIds?.length }} Project</span>
              </div>
            </div>
          </ng-template> </kendo-grid-column
        ><kendo-grid-column field="lastVisit" title="Last Visited" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ transformLastVisitTime(dataItem.lastVisit) }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="creatorId" title="Created By" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-avatar
              rounded="full"
              size="large"
              [border]="true"
              [initials]="commonService.getInitials(getCreatedByName(dataItem.creatorId))"
              [style.backgroundColor]="
                commonService.generateAvtarColor(
                  dataItem.creatorId,
                  getCreatedByName(dataItem.creatorId)
                )
              "
              class="social-profile-text"
            ></kendo-avatar>
            <span class="ml-10">{{ getCreatedByName(dataItem.creatorId) }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="status" title="Status" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex align-items-center justify-content-between">
              <div *ngIf="dataItem.status === statusType.Active">Active</div>
              <ul class="grid-action-list">
                <div class="d-flex gap-10">
                  <li>
                    <a title="Edit" (click)="onPortfolioAdd(dataItem.id)">
                      <i class="far fa-pen"></i>
                    </a>
                  </li>
                  <li *ngIf="!dataItem.isDefault">
                    <a title="Delete" (click)="onDelete(dataItem)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
