<div class="q-content">
  <div class="q-content-header">
    <div class="d-flex justify-content-between mb-20 flex-wrap gap-20">
      <kendo-textbox
        placeholder="Search for company name"
        class="search"
        (keyup.enter)="onSearch($event)"
        (ngModelChange)="onSearchTextChange($event)"
        [(ngModel)]="searchText"
        ><ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon font-size-14"></i> </ng-template
      ></kendo-textbox>
      <div class="d-flex gap-20 flex-wrap">
        <button kendoButton id="create" type="button" (click)="onAddCompany()">
          <span><i class="far fa-plus mr-10"></i>{{ '::Add New Company' | abpLocalization }}</span>
        </button>
        <kendo-dropdownlist
          class="w-150"
          [data]="statusFilter"
          textField="text"
          valueField="value"
          [value]="selectedFilterValue"
          [valuePrimitive]="true"
          (selectionChange)="selectionChange($event)"
          [hidden]="true"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            {{ dataItem.text }}
          </ng-template>
        </kendo-dropdownlist>
        <a class="flat-icon-container" [hidden]="true"><i class="fas fa-ellipsis-h"></i></a>
      </div>
    </div>
  </div>
  <div class="grid-wrapper q-content-bg">
    <kendo-grid
      [data]="companyData"
      [pageSize]="pageSize"
      [height]="gridHeight"
      [skip]="skip"
      [pageable]="
        companyData === undefined
          ? false
          : {
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext,
              position: pagerposition,
            }
      "
      (pageChange)="pageChange($event)"
      [sortable]="{
        allowUnsort: true,
        mode: multiple ? 'multiple' : 'single',
      }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      class="custom-no-records-container"
    >
      <ng-template kendoGridNoRecordsTemplate>
        <div
          *ngIf="isNoRecords && this.companyData.data.length === 0"
          class="no-records-text-container"
        >
          No Records Available
        </div>
      </ng-template>

      <kendo-grid-column field="name" title="Company Name" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="customer-name">{{ dataItem.name }}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="website" title="Website" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="customer-name">{{ dataItem.website }}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="email1" title="Email" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="customer-name">{{ dataItem.email1 }}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="phone" title="Phone" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div class="customer-name">{{ dataItem.phone }}</div>

            <ul class="grid-action-list">
              <div class="d-flex gap-10">
                <li>
                  <a title="Edit" (click)="onAddCompany(dataItem.id)">
                    <i class="far fa-pen"></i>
                  </a>
                </li>
                <li *ngIf="!dataItem.isDefault">
                  <a title="Delete" (click)="onDelete(dataItem)">
                    <i class="far fa-trash-alt"></i>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
<div kendoDialogContainer></div>
