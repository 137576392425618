import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  DrawerMode,
  DrawerPosition,
  ExpansionPanelComponent,
  SelectEvent,
} from '@progress/kendo-angular-layout';
import { TaskListService } from 'projects/task-service/src/lib/proxy/task-service/task-list';
import { TaskType } from 'src/app/enum/task-type';
import { AddTaskListComponent } from './add-task-list/add-task-list.component';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskListActionEnumEnum } from 'src/app/enum/tasklist-action-filter';
import { Observable, Subject, combineLatest, map, take } from 'rxjs';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { DatePipe } from '@angular/common';
import { CommonService, UrlShortenerService } from 'src/core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { ToasterService } from '@abp/ng.theme.shared';
import { IdentityUserService } from '@abp/ng.identity/proxy';
import { AddTaskComponent } from './add-task/add-task.component';
import { ConfigStateService } from '@abp/ng.core';
import { TreeViewComponent } from '@progress/kendo-angular-treeview';
import { UpdateTaskParentTaskIdDto } from 'projects/task-service/src/lib/proxy/service/project/task/dtos';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskListTabEnum } from 'src/app/enum/tasklist-tab-enum';
import { Store } from '@ngxs/store';
import { TaskState } from 'src/app/core/store/task.state';
import { takeUntil, tap } from 'rxjs/operators';
import { DrawerTab } from 'src/app/enum/drawer-tab-enum';
import { AttachTaskFilePopupComponent } from '../attach-task-file-popup/attach-task-file-popup.component';
import { ProjectUserService } from '@proxy/project-service/project';
import { ProjectBoardColumnService } from 'projects/project-service/src/lib/proxy/project-service';
import { SetAnEstimateComponent } from '../shared/set-an-estimate/set-an-estimate.component';
import { SetSubTaskList, SetTaskStatus } from 'src/app/core/store/task.action';
import { DashboardEnum } from 'src/app/enum/dashboard-enum';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { DropDownButton } from '@progress/kendo-angular-buttons';

export class TaskDateDto {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  @Input() isFromDashboard: boolean = false;
  @Input() triggerGetOpenData!: Subject<number>;
  @Input() triggerGetDueData!: Subject<number>;
  @Input() triggerGetCompleteData!: Subject<number>;

  private unsubscribe$ = new Subject<void>();

  @Output() updateDashboardCount = new EventEmitter<void>();

  @ViewChild(SetAnEstimateComponent)
  setAnEstimateComponent: SetAnEstimateComponent;

  taskListsList: any[] = [];
  completedTaskList: any = [];
  taskList = [];
  assigneeList: any[] = [];
  assigneListItems: any[] = [];
  selectedAssignee: any[] = [];

  statusList: Array<string> = ['All status', 'abc'];
  priorityEnum = Priority;
  taskStatusType = TaskStatusType;
  tasklistTab = TaskListTabEnum;
  selectedTabId = TaskListTabEnum.List;
  drawerSeletecTabId: number = DrawerTab.File;
  drawerTab = DrawerTab;

  editedItem: any;
  projectId: any;
  taskId: any;
  taskDetails: any;
  tempTaskDetailsEstimateMinutes: any;
  currentExpandedPanelId: any;
  timeLogpopupCloseTimeout: any;
  estimatePopupCloseTimeout: any;
  priorityDropdownCloseTimeout: any;
  statusDropdownCloseTimeout: any;
  filterRequestParam: any;
  assigneePopupCloseTimeout: any;
  taskStatusTypeList: any;
  tempLogtimedata: any;

  projectName = '';
  searchText: string = '';

  pageSize = 30;
  maxResultCountForTaskListList: number = 30;

  skipcount = 0;
  skipCountForTaskListList: number = 0;
  skipCountForCompletedTask = 0;
  skipCountForSubTaskList = 0;
  totalTaskCount: number;
  totalCompletedTaskCount: number;
  fileCount: number = 0;
  drawerSelectedTabId: number = DrawerTab.File;
  taskEstimateMinutes: number = 0;
  taskPriority: number = 0;
  taskFilterCount: number = 0;
  dashboardPanelId: DashboardEnum;
  dashboardEnum = DashboardEnum;

  popupClass = 'task-type-dropdown';
  expandMode: DrawerMode = 'overlay';
  position: DrawerPosition = 'end';
  addNewTaskformGroup: FormGroup;

  isDrawerOpened = false;
  isNoRecordFound = false;
  isAdmin = false;
  isShowCompleteTask = false;
  isAddNew = false;
  openInNewTab = false;
  addNewTaskTop = false;
  isLoadingForTaskListList = true;
  isLoadingForTaskList = false;
  isSetDatePopupVisible = false;
  showStatusPopup = false;
  isLoadingForCompletedTaskList = false;
  isFilterApply = false;
  public loadDialog = false;
  isEstimateSave = true;
  isCurrentUserResponsiblePerson: boolean = false;
  isNewTaskSaving = false;

  subTaskErrors: { [key: string]: boolean } = {};
  showAddNewTaskInput: { [index: number]: boolean } = {};
  disableAddTaskListIcon: { [index: number]: boolean } = {};
  subTaskMaxLengthErrors: { [key: string]: boolean } = {};
  isShowSubTaskDescriptionEditor: { [key: string]: boolean } = {};
  isFromEditAction: boolean = false;
  showTimelogPopup: boolean = false;
  isMouseInsidePanel = false;
  showPriorityDropdown: boolean = false;
  showEstimatePopup: boolean = false;
  isShowDescriptionEditor: boolean = false;
  showTaskFilterPopup: boolean = false;
  showAssigneePopup: boolean = false;
  isPanalExpanded: boolean = false;
  isNewTaskEstimate: boolean = false;

  popupPosition: { top: number; left: number } | null = null;
  sorting = null;
  hoverAnchor: HTMLElement | null = null;
  priorityDropdownPosition: { top: number; left: number } | null = null;

  isAnyPopupOpen = false;
  hoveredTaskId: number | null = null;
  popupTaskId: number | null = null;

  sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  @ViewChildren(ExpansionPanelComponent) panels: QueryList<ExpansionPanelComponent>;
  @ViewChildren('taskmain') taskmains: QueryList<ElementRef>;
  @ViewChildren('taskTitle') taskTitles: QueryList<ElementRef>;
  @ViewChildren('treeviewContainer') treeviewContainers!: QueryList<ElementRef>;
  @ViewChildren('taskTypeDropdown') taskTypeDropdowns!: QueryList<DropDownButton>;
  @ViewChildren('taksListAction') taksListActions!: QueryList<DropDownButton>;
  @ViewChild('treeview', { static: false })
  taskStatus$: Observable<any>;

  public treeview: TreeViewComponent;

  priorityList: any[] = [
    {
      id: Priority.High,
      text: Priority[Priority.High],
    },
    {
      id: Priority.Low,
      text: Priority[Priority.Low],
    },
    {
      id: Priority.Medium,
      text: Priority[Priority.Medium],
    },
    {
      id: Priority.None,
      text: Priority[Priority.None],
    },
  ];

  taskTypeList: { id: TaskType; name: string; iconClass: string }[] = [];
  tempDataItem: any;
  taksListActionItem = [
    {
      text: TaskListActionEnumEnum[TaskListActionEnumEnum.Edit],
      iconClass: 'far fa-pen',
    },
    {
      text: TaskListActionEnumEnum[TaskListActionEnumEnum.Delete],
      iconClass: 'far fa-trash-alt',
    },
  ];

  customStyleContent = `
    .k-content * {
      font-family: 'Poppins', sans-serif;
      color: #404a5f;
    }

    .k-content .k-placeholder {
      font-size: 13px;
      color: #909EB2;
    }

    body {
    padding-top: 0px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f6f6f6;
  }

  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }
  `;

  constructor(
    private _Activatedroute: ActivatedRoute,
    private taskListService: TaskListService,
    private taskService: TaskService,
    private dialogService: DialogService,
    private router: Router,
    public datepipe: DatePipe,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private toasterService: ToasterService,
    protected service: IdentityUserService,
    private config: ConfigStateService,
    private fb: FormBuilder,
    private store: Store,
    private projectUser: ProjectUserService,
    private projectBoardColumnService: ProjectBoardColumnService,
    private cdr: ChangeDetectorRef,
    private projectService: ProjectService,
    private urlShortener: UrlShortenerService,
  ) {
    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    if (currentUserRole.includes('admin')) {
      this.isAdmin = true;
    }
    this.taskTypeList = this.commonService.getTaskTypeList();
  }

  ngOnInit(): void {
    this.setForm();
    this._Activatedroute.queryParams.subscribe(params => {
      if (params) {
        this.projectId = params['projectId'] ? +atob(params['projectId']) : null;
        this.currentExpandedPanelId = params['panelId'] ? atob(params['panelId']) : undefined;
        this.selectedTabId = params['tabId'] ? +atob(params['tabId']) : TaskListTabEnum.List;

        if (!this.isFromDashboard) this.getProjectTaskStatusList();
        if (!this.isFromDashboard) this.getProjectDetail();
      }
    });

    this.handleDashboardTrigger(this.triggerGetOpenData);
    this.handleDashboardTrigger(this.triggerGetDueData);
    this.handleDashboardTrigger(this.triggerGetCompleteData);

    this.commonService.timelogAdd$.subscribe(res => {
      if (res) {
        this.onNewTimelogAdded(res, this.tempLogtimedata);
      }
    });

    this.taskStatus$ = this.store.select(TaskState.getTaskStatus);
  }

  getProjectDetail(): void {
    this.projectService.get(this.projectId).subscribe((res: any) => {
      if (res) {
        this.projectName = res.name;
        this.isCurrentUserResponsiblePerson = res.isCurrentUserResponsiblePerson;
      }
    });
  }

  handleDashboardTrigger(trigger$: Observable<any>) {
    if (trigger$) {
      trigger$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.dashboardPanelId = res;
        this.isLoadingForTaskListList = true;
        this.currentExpandedPanelId = undefined;
        this.skipCountForTaskListList = 0;
        this.taskListsList = [];
        this.getTaskListList();
      });
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.k-popup') && this.showTaskFilterPopup) {
      this.showTaskFilterPopup = false;
    }
    if (!target.closest('.k-popup') && this.showAssigneePopup) {
      this.showAssigneePopup = false;
    }
  }

  getAppliedFilter(filterReqParam: any): void {
    this.isFilterApply = filterReqParam.isFilterApply;
    this.taskFilterCount = filterReqParam.filterCount;
    this.filterRequestParam = filterReqParam.param;
    this.showTaskFilterPopup = false;
    this.isLoadingForTaskListList = true;
    this.skipCountForTaskListList = 0;
    this.taskListsList = [];
    this.getTaskListList();
  }

  openAttachedFile(dataItem): void {
    const dialogRef = this.dialogService.open({
      content: AttachTaskFilePopupComponent,
      width: 500,
      cssClass: 'attach-files-popup',
    });

    dialogRef.content.instance.taskId = dataItem.id;
    dialogRef.content.instance.taskName = dataItem.taskName;

    dialogRef.result.subscribe(res => {
      if (res['confirmed']) {
        dataItem.attachmentCount = res['resData']?.attachmentCount;

        this.taskList = [...this.taskList];
      }
    });
  }

  updateTaskList(
    dataItem,
    isEstimateSave: boolean = false,
    isAssigneeUpdated: boolean = false,
  ): void {
    const dueDate = this.taskDetails.dueDate ? new Date(this.taskDetails.dueDate) : null;
    const startDate = this.taskDetails.startDate ? new Date(this.taskDetails.startDate) : null;

    const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(
      this.taskDetails.estimateMinutes,
    );
    this.taskDetails.estimateHour = hours;
    this.taskDetails.estimateMinute = minutes;

    var estimateTime = `${
      this.taskDetails.estimateHour === '' ? 0 : this.taskDetails.estimateHour
    }:${this.taskDetails.estimateMinute === '' ? 0 : this.taskDetails.estimateMinute}`;
    if (!estimateTime) {
    }
    const estimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes(estimateTime);

    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: this.taskDetails.id,
      name: this.taskDetails.taskName,
      description: this.taskDetails.description,
      progress: 20,
      startdate:
        this.taskDetails.startDate === null
          ? null
          : new TaskDateDto(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()),
      duedate:
        this.taskDetails.dueDate === null
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: +this.taskDetails.priority,
      estimateMinutes: estimateMinutes,
      taskListId: this.taskDetails.taskListId,
      parentTaskId: this.taskDetails.parentTaskId,
      notify: true,
      assignedToUserIds:
        this.taskDetails.assignee?.length > 0
          ? this.taskDetails.assignee.map(item => item.userId)
          : [],
      taskType: this.taskDetails.taskType,
      taskStatus:
        this.taskDetails.taskStatus === TaskStatusType.Completed
          ? TaskStatusType.Completed
          : this.taskDetails.taskStatus,
      columnId: this.taskDetails.projectTaskStatus,
      subTasks: [],
      projectId: this.projectId ?? this.taskDetails.projectId,
    };

    this.taskService.update(this.taskDetails.id, param).subscribe(
      res => {
        this.handleInlineSaveSuccess(res, dataItem, isEstimateSave, isAssigneeUpdated);
        document.body.style.overflow = 'auto';
      },
      error => {
        this.taskDetails.assignee = [];
        this.setAnEstimateComponent.resetForm();
        this.taskDetails.priority = null;
      },
    );
  }

  private handleInlineSaveSuccess(
    res: any,
    dataItem,
    isEstimateSave: boolean = false,
    isAssigneeUpdated: boolean = false,
  ): void {
    if (!isEstimateSave) {
      if (isAssigneeUpdated) {
        if (dataItem.assignee.length > 0) {
          this.toasterService.success(
            NotificationMessage.updateTaskSuccessMsg,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        }
      } else {
        this.toasterService.success(
          NotificationMessage.updateTaskSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      }
    } else {
      const message = this.isEstimateSave
        ? NotificationMessage.saveEstimationSuccessMsg
        : NotificationMessage.updateEstimationSuccessMsg;

      this.toasterService.success(message, '', this.commonService.toasterMessageConfiguration);
      this.setAnEstimateComponent.resetForm();
    }
    this.mapDataOnUpdate(dataItem, res);
  }

  mapDataOnUpdate(task, res) {
    // Map properties from `res` to `task`
    task.id = res.id;
    task.taskName = res.name;
    task.assignee = res.users;
    task.startDate = res.startdate;
    task.dueDate = res.duedate;
    task.priority = res.priority;
    task.taskId = null;
    task.hasChildren = task.hasChildren;
    task.taskType = res.taskType;
    task.taskStatus = res.taskStatus;
    task.estimateMinutes = res.estimateMinutes;
    task.isAllSubTaskCompleted = res.isAllSubTaskCompleted;
    task.attachmentCount = res.attachmentCount;
    task.timelogMinutes = res.timelogMinutes;
    task.isAddSubtask = false;
    task.description = res.description;
    task.taskListId = res.taskListId;
    task.parentTaskId = res.parentTaskId;
    task.totalCount = res.subTaskIds?.length || 0;
    task.children = task.children || [];
    task.projectTaskStatus = res.columnId;
    task.projectTaskStatusName = res.projectColumnName;
    task.projectId = res.projectId;
    task.projectName = res.projectName;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustMaxWidth();
  }

  showDescriptionEditor(dataItem: any): void {
    this.isShowDescriptionEditor = !this.isShowDescriptionEditor;
    setTimeout(() => this.cdr.detectChanges(), 0);
  }

  showSubTaskDescriptionEditor(dataItem: any): void {
    dataItem.description = '';
    this.isShowSubTaskDescriptionEditor[dataItem.id] =
      !this.isShowSubTaskDescriptionEditor[dataItem.id];

    setTimeout(() => this.cdr.detectChanges(), 0);
  }

  adjustMaxWidth() {
    if (this.taskmains) {
      this.taskmains.toArray().forEach(main => {
        const container = main.nativeElement;
        const childrenArray = Array.from(container.children) as HTMLElement[];
        let nonAnchorWidth = 0;
        let totalwidth = 0;

        childrenArray.forEach((child: HTMLElement) => {
          totalwidth += child.offsetWidth;
          if (child.tagName.toLowerCase() !== 'a') {
            nonAnchorWidth += child.offsetWidth;
          }
        });

        const parentWidth = container.parentElement.offsetWidth;
        const availableWidth = parentWidth - nonAnchorWidth;

        if (totalwidth > parentWidth) {
          const anchorTag = childrenArray.find((child: HTMLElement) => {
            if (child.tagName.toLowerCase() === 'a') {
              return true;
            }
            const childs = Array.from(child.children) as HTMLElement[];
            return childs.some((x: HTMLElement) => x.tagName.toLowerCase() === 'a');
          });

          if (anchorTag) {
            const spanTag = anchorTag.querySelector('span') as HTMLElement;

            if (spanTag) {
              spanTag.style.maxWidth = `${availableWidth - 10}px`;
            }
          }
        }
      });
    }
  }

  setForm(): void {
    this.addNewTaskformGroup = this.fb.group({
      taskName: ['', [Validators.required, Validators.maxLength(255)]],
      description: [''],
    });
  }

  onSearchTextChange(): void {
    this.isLoadingForTaskListList = true;
    this.skipCountForTaskListList = 0;
    this.taskListsList = [];
    this.getTaskListList();
  }

  getParamForGetListList(): any {
    let param = {
      projectId: this.projectId,
      showMilestones: true,
      getCompleteCount: true,
      getNewDefaultTask: true,
      sorting: null,
      skipCount: this.skipCountForTaskListList,
      maxResultCount: this.maxResultCountForTaskListList,
      searchTerm: this.searchText,
      completedOnly: this.filterRequestParam?.completedOnly,
      userIds: this.isFromDashboard
        ? this.config.getOne('currentUser').id
        : this.filterRequestParam?.assignee,
      startDate: this.filterRequestParam?.startDate,
      dueDate: this.filterRequestParam?.dueDate,
      taskStatusId: this.filterRequestParam?.taskStatusId,
      taskPriority: this.filterRequestParam?.priority,
      isLateTask: this.filterRequestParam?.isLateTask,
      isDueWeek: this.filterRequestParam?.isDueWeek,
      isFilterApply: this.isFromDashboard ? true : this.isFilterApply,
      dashboardEnum: this.isFromDashboard ? this.dashboardPanelId : null,
      isFromDashboard: this.isFromDashboard ? true : false,
      isNotCompleted: this.filterRequestParam?.isNotCompleted,
      isDueNextWeek: this.filterRequestParam?.isDueNextWeek,
      createdByMe: this.filterRequestParam?.createdByMe,
    };

    return param;
  }

  updateValueOfGetTaskListList(): void {
    var param = this.getParamForGetListList();
    this.taskListService.getList(param).subscribe(res => {
      if (res.items.length === 0) return;
      const data = res.items.find(x => x.id === this.currentExpandedPanelId);
      if (!data) return;

      const taskList = this.taskListsList.find(x => x.id === this.currentExpandedPanelId);
      if (!taskList) return;

      Object.assign(taskList, {
        totalTimelogged: data.totalTimelogged,
        totalEstimatedTime: data.totalEstimatedTime,
        totalNumberOfTask: data.totalNumberOfTask,
        completeCount: data.completeCount,
      });
    });
  }

  getTaskListList(isDefaultExpanded = false): void {
    if (!this.isLoadingForTaskListList) return;

    var param = this.getParamForGetListList();
    this.spinnerService.show();

    this.taskListService.getList(param).subscribe(res => {
      if (res.items.length > 0) {
        let tempArray = this.taskListsList;
        res.items.forEach(element => {
          const data = {
            id: element.id,
            name: element.name,
            totalNumberOfTask: element.totalNumberOfTask,
            completeCount: element.completeCount,
            totalTimelogged: element.totalTimelogged,
            totalEstimatedTime: element.totalEstimatedTime,
            expanded: this.currentExpandedPanelId === element.id ? isDefaultExpanded : false,
            projectId: element.projectId,
            projectName: element.projectName,
          };
          tempArray.push(data);
        });
        this.taskListsList = tempArray;

        setTimeout(() => {
          this.taskListsList.forEach((_, index) => {
            if (this.currentExpandedPanelId === undefined)
              this.disableAddTaskListIcon[index] = true;
          });
        }, 0);

        this.skipCountForTaskListList += this.maxResultCountForTaskListList;
        this.isLoadingForTaskListList = this.taskListsList.length < res.totalCount;

        var isCurrentExpandedFound = this.taskListsList.find(
          x => x.id === +this.currentExpandedPanelId,
        );

        if (!!isCurrentExpandedFound && !this.isFilterApply) {
          this.onTaskListsListExpandedPanelClick(
            this.currentExpandedPanelId,
            isCurrentExpandedFound,
          );
        } else {
          this.currentExpandedPanelId = undefined;
        }

        if (isCurrentExpandedFound) {
          this.taskListsList.forEach((item, index) => {
            this.disableAddTaskListIcon[index] = item.id !== this.currentExpandedPanelId;
          });
        }
      } else {
        this.isLoadingForTaskListList = false;
        this.spinnerService.hide();
      }

      this.isNoRecordFound = res.items.length > 0 ? false : true;
      this.spinnerService.hide();
    });
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getTaskList(this.currentExpandedPanelId);
  }

  showCompletedTask(): void {
    this.skipCountForCompletedTask = 0;
    this.isShowCompleteTask = !this.isShowCompleteTask;

    if (this.isShowCompleteTask) {
      this.getCompletedTaskList(this.currentExpandedPanelId);
    }
  }

  onAddTaskClick(isNew: boolean): void {
    this.isAddNew = isNew;
    this.isShowDescriptionEditor = false;
    this.taskEstimateMinutes = 0;
    this.taskDetails = undefined;

    if (isNew) {
      this.addNewTaskTop = !this.isAddNew;
    }
    this.addNewTaskformGroup.reset();
  }

  onAddTaskClickOnTop(addNewTaskTop: boolean, index: number): void {
    this.addNewTaskTop = addNewTaskTop;
    this.showAddNewTaskInput[index] = addNewTaskTop;

    this.isShowDescriptionEditor = false;
    this.taskEstimateMinutes = 0;
    this.taskDetails = undefined;
    if (addNewTaskTop) {
      this.isAddNew = !this.addNewTaskTop;
    }
    this.addNewTaskformGroup.reset();
    setTimeout(() => {
      const container = this.treeviewContainers.toArray()[index];
      if (container) {
        const elementToScroll = container.nativeElement.querySelector('input');
        if (elementToScroll) {
          elementToScroll.focus();
          elementToScroll.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }, 0);
  }

  mapSubTask(element: any, parentTaskId: number, dataitem: any): any {
    const mappedSubTask = {
      id: element.id,
      taskName: element.taskName,
      assignee: element.assignee,
      dueDate: element.dueDate,
      startDate: element.startDate,
      priority: element.priority,
      taskId: parentTaskId,
      hasChildren: Array.isArray(element.subTaskIds) && element.subTaskIds.length > 0,
      taskType: element.taskType,
      taskStatus: element.taskStatus,
      estimateMinutes: element.estimateMinutes,
      isAllSubTaskCompleted: element.isAllSubTaskCompleted,
      isAddSubtask: false,
      description: element.description,
      taskListId: element.taskListId,
      parentTaskId: element.parentTaskId,
      children: this.getInitialChildren(element),
      projectTaskStatus: element.projectTaskStatus,
      projectTaskStatusName: element.projectTaskStatusName,
      projectId: element.projectId,
      projectName: element.projectName,
      attachmentCount: element.attachmentCount,
      timelogMinutes: element.timelogMinutes,
      totalCount: element.subTaskIds?.length || 0,
    };

    // Ensure dataitem.children exists
    if (!Array.isArray(dataitem.children)) {
      dataitem.children = [];
    }

    // Check if there's any child with id === 0
    const hasInvalidChild = dataitem.children.some(child => child.id === 0);

    if (hasInvalidChild) {
      // Remove all children with id === 0
      dataitem.children = dataitem.children.filter(child => child.id !== 0);
    }

    const isDuplicate = dataitem.children.some(child => child.id === mappedSubTask.id);

    if (!isDuplicate) {
      // Push the mappedSubTask after removal
      dataitem.children.push(mappedSubTask);
    }

    return mappedSubTask;
  }

  /**
   * Returns the initial children array for a subtask.
   */
  private getInitialChildren(element: any): any[] {
    return element.subTaskIds?.length > 0
      ? [this.createPlaceholderChild(element.id, element.subTaskIds.length)]
      : [];
  }

  createPlaceholderChild(parentTaskId: number, subTaskCount: number): any {
    return {
      id: 0,
      taskName: '',
      assignee: '',
      startDate: '',
      dueDate: '',
      priority: '',
      taskId: parentTaskId,
      hasChildren: '',
      taskType: 0,
      taskStatus: '',
      estimateMinutes: '',
      isAllSubTaskCompleted: '',
      attachmentCount: '',
      timelogMinutes: '',
      isAddSubtask: false,
      description: '',
      taskListId: '',
      parentTaskId,
      children: [],
      totalCount: subTaskCount,
      projectTaskStatus: '',
      projectTaskStatusName: '',
      projectId: null,
      projectName: '',
    };
  }

  onExpand(event: any): void {
    event.dataItem.children = [];
    this.getSubtask(event.dataItem.id).subscribe((elements: any[]) => {
      event.dataItem.children = elements.map(element =>
        this.mapSubTask(element, event.dataItem.id, event.dataItem),
      );
    });

    setTimeout(() => this.adjustMaxWidth(), 0);
  }

  hasSubtask(item: any): boolean {
    return item.hasChildren;
  }

  fetchSubtask = (dataitem: any): Observable<any[]> => {
    this.skipCountForSubTaskList = 0;
    return this.getSubtask(dataitem.id).pipe(
      map((elements: any[]) =>
        elements.map(element => this.mapSubTask(element, dataitem.id, dataitem)),
      ),
    );
  };

  getCompletedTaskList(taskListId: number): void {
    const param = this.buildTaskListParams(taskListId, this.skipCountForCompletedTask, true);

    this.taskService.getList(param).subscribe(({ items, totalCount }) => {
      this.totalCompletedTaskCount = totalCount;

      this.isShowCompleteTask = this.totalCompletedTaskCount > 0;
      this.completedTaskList = this.mapTaskItems(items);

      setTimeout(() => this.adjustMaxWidth(), 0);
    });
  }

  getTaskList(taskListId: number, isFromMarkCompleted: boolean = false): void {
    const param = this.buildTaskListParams(taskListId, this.skipcount, false);
    this.spinnerService.show();
    this.taskService.getList(param).subscribe(({ items, totalCount }) => {
      this.totalTaskCount = totalCount;

      this.taskList = this.mapTaskItems(items);
      // Add a placeholder if no tasks are found but more can be loaded
      if (this.taskList.length === 0 && this.pageSize < totalCount) {
        this.taskList = [
          { id: 0, taskName: 'No tasks available', hasChildren: false, children: [] },
        ];
      }
      this.spinnerService.hide();

      setTimeout(() => this.adjustMaxWidth(), 0);
    });

    if (isFromMarkCompleted) {
      this.getCompletedTaskList(taskListId);
    }
  }

  buildTaskListParams(taskListId: number, skipCount: number, completedOnly: boolean): any {
    return {
      taskListId,
      sorting: this.sorting,
      skipCount,
      maxResultCount: this.pageSize,
      getSubTasks: true,
      includeLoggedTime: true,
      completedOnly: this.isFromDashboard ? null : completedOnly,
      userIds: this.isFromDashboard
        ? this.config.getOne('currentUser').id
        : this.filterRequestParam?.assignee,
      startDate: this.filterRequestParam?.startDate,
      dueDate: this.filterRequestParam?.dueDate,
      taskStatusId: this.filterRequestParam?.taskStatusId,
      taskPriority: this.filterRequestParam?.priority,
      isLateTask: this.filterRequestParam?.isLateTask,
      isDueWeek: this.filterRequestParam?.isDueWeek,
      dashboardEnum: this.isFromDashboard ? this.dashboardPanelId : null,
      isNotCompleted: this.filterRequestParam?.isNotCompleted,
      isDueNextWeek: this.filterRequestParam?.isDueNextWeek,
      isFromDashboard: this.isFromDashboard,
    };
  }

  mapTaskItems(items: any[]): any[] {
    return items.map(element => ({
      id: element.id,
      taskName: element.name,
      assignee: element.users,
      startDate: element.startdate,
      dueDate: element.duedate,
      priority: element.priority,
      taskId: null,
      hasChildren: !!element.subTaskIds,
      taskType: element.taskType,
      taskStatus: element.taskStatus,
      estimateMinutes: element.estimateMinutes,
      isAllSubTaskCompleted: element.isAllSubTaskCompleted,
      attachmentCount: element.attachmentCount,
      timelogMinutes: element.timelogMinutes,
      isAddSubtask: false,
      description: element.description,
      taskListId: element.taskListId,
      parentTaskId: element.parentTaskId,
      totalCount: element.subTaskIds?.length || 0,
      children: this.getInitialChildren(element),
      projectTaskStatus: element.columnId,
      projectTaskStatusName: element.projectColumnName,
      projectId: element.projectId,
      projectName: element.projectName,
    }));
  }

  //#region find Task
  findTaskById(tasks: any[], targetId: number): any | null {
    for (const task of tasks) {
      if (task.id === targetId) {
        return task;
      }

      // If the task has children, recursively search within them
      if (task.children && task.children.length > 0) {
        const foundTask = this.findTaskById(task.children, targetId);
        if (foundTask) {
          return foundTask;
        }
      }
    }

    return null;
  }

  isAllSubTasksCompleted(task) {
    if (!task.children || task.children.length === 0) {
      return true; // If no children, return true
    }

    return task.children.every(child => {
      if (child.children.length > 0) {
        return child.isAllSubTaskCompleted && this.isAllSubTasksCompleted(child);
      }
      return true;
    });
  }

  markParentSubTaskCompleted(tasks: any[], dataItem: any): void {
    for (const task of tasks) {
      const parentTaskList = this.findTaskById([task], dataItem.parentTaskId);
      if (parentTaskList) {
        const allChildrenCompleted = this.isAllSubTasksCompleted(dataItem);
        if (allChildrenCompleted) {
          parentTaskList.isAllSubTaskCompleted = true;
        } else {
          parentTaskList.isAllSubTaskCompleted = false;
        }

        return;
      }
    }
  }
  //#endregion

  onTaskCompleteChange(dataItem: any, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isTaskCompleted = checkbox.checked;

    const taskAction = isTaskCompleted
      ? this.taskService.isMarkTaskAsComplete(dataItem.id)
      : this.taskService.markTaskAsReopened(dataItem.id);

    dataItem.taskStatus = isTaskCompleted ? TaskStatusType.Completed : TaskStatusType.ReOpened;

    taskAction.subscribe(res => {
      if (res['success'] === true || res === true) {
        const taskStatus = isTaskCompleted ? TaskStatusType.Completed : TaskStatusType.ReOpened;
        this.store.dispatch(new SetTaskStatus(taskStatus));
        this.taskStatus$ = this.store.select(TaskState.getTaskStatus);
        this.toasterService.success(
          NotificationTextMessage.taskUpdatedMessage,
          '',
          this.commonService.toasterMessageConfiguration,
        );

        const taskListsList = this.taskListsList.find(x => x.id === this.currentExpandedPanelId);

        if (taskListsList) {
          if (this.isFromDashboard) {
            taskListsList.totalNumberOfTask -= 1;
            this.updateDashboardCount.next();
          } else {
            this.isLoadingForTaskListList = true;
            this.skipCountForTaskListList = 0;
            this.updateValueOfGetTaskListList();
          }
        }

        if (dataItem.parentTaskId && !isTaskCompleted) {
          this.updateParentStatus(dataItem.parentTaskId);
        }

        if (!dataItem.parentTaskId) {
          this.skipcount = 0;
          this.getTaskList(this.currentExpandedPanelId, true);
        }

        if (this.isShowCompleteTask && !isTaskCompleted) {
          this.getTaskList(this.currentExpandedPanelId, true);
        }

        dataItem.isCompleted = isTaskCompleted;
        this.markParentSubTaskCompleted(this.taskList, dataItem);
      } else {
        checkbox.checked = false;
        this.toasterService.error(
          res['error'].message,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      }
    });
  }

  updateParentStatus(parentTaskId: number): void {
    let parentTask = this.findTaskById(this.taskList, parentTaskId);

    while (parentTask) {
      parentTask.taskStatus = TaskStatusType.ReOpened;
      if (parentTask.parentTaskId) {
        parentTask = this.findTaskById(this.taskList, parentTask.parentTaskId);
      } else {
        break;
      }
    }
  }

  getSubtask(id: any): Observable<any[]> {
    this.spinnerService.show();

    let param: any = {
      sorting: this.sorting,
      maxResultCount: this.pageSize,
      skipCount: this.skipCountForSubTaskList,
    };
    if (this.skipCountForSubTaskList === 0) {
      this.spinnerService.hide();
    }

    return this.taskService.getSubTasksByParentTaskIdAndPagedAndSortedResultRequest(id, param).pipe(
      map(x => {
        const subTaskData = x.items.map(element => ({
          id: element.id,
          taskName: element.name,
          assignee: element.users,
          dueDate: element.duedate,
          startDate: element.startdate,
          priority: element.priority,
          taskId: id,
          hasChildren: !!element.subTaskIds,
          taskType: element.taskType,
          taskStatus: element.taskStatus,
          estimateMinutes: element.estimateMinutes,
          isAllSubTaskCompleted: element.isAllSubTaskCompleted,
          subTaskIds: element.subTaskIds,
          taskListId: element.taskListId,
          parentTaskId: element.parentTaskId,
          totalCount: x.totalCount,
          description: element.description,
          projectTaskStatus: element.columnId,
          projectTaskStatusName: element.projectColumnName,
          projectId: element.projectId,
          projectName: element.projectName,
          attachmentCount: element.attachmentCount,
          timelogMinutes: element.timelogMinutes,
        }));
        this.spinnerService.hide();
        return subTaskData;
      }),
      tap(subTaskData => {
        this.spinnerService.hide();
        for (let data of subTaskData) {
          this.isShowSubTaskDescriptionEditor[data.id] = false;
        }
        setTimeout(() => this.adjustMaxWidth(), 0);
      }),
    );
  }

  checkIsDisable(dataItem: any): boolean {
    if (dataItem.hasChildren) {
      return !dataItem.isAllSubTaskCompleted;
    }
    return false;
  }

  onCancelSubtask(dataItem) {
    this.subTaskErrors[dataItem.id] = false;
    dataItem.isAddSubtask = false;
  }

  onInputChange(value: string, dataItem: any): void {
    this.subTaskMaxLengthErrors[dataItem.id] = false;

    if (value?.trim()) {
      this.subTaskErrors[dataItem.id] = false;
    }
    if (value.length > 255) {
      this.subTaskMaxLengthErrors[dataItem.id] = true;
    }
  }

  getUpdatedTaskAssignee(task: any, dataItem) {
    this.showAssigneePopup = false;
    this.taskDetails.assignee = task;
    this.updateTaskList(dataItem, false, true);
  }

  getTaskEstimate(taskEstimate: any, dataItem) {
    this.isEstimateSave = this.setAnEstimateComponent.buttonText === 'Add estimate' ? true : false;

    const newEstimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes(taskEstimate);

    if (dataItem) {
      const taskList = this.taskListsList.find(x => x.id === this.currentExpandedPanelId);
      if (!taskList) return;

      const previousEstimate = this.taskDetails.estimateMinutes ?? 0;

      if (previousEstimate === newEstimateMinutes) return;

      taskList.totalEstimatedTime =
        taskList.totalEstimatedTime - previousEstimate + newEstimateMinutes;

      this.taskDetails.estimateMinutes = newEstimateMinutes;
      this.tempTaskDetailsEstimateMinutes = null;
      this.showEstimatePopup = false;
      this.popupTaskId = null;
      this.hoveredTaskId = null;

      this.updateTaskList(dataItem, true);
    }

    if (this.isNewTaskEstimate) {
      this.taskEstimateMinutes = newEstimateMinutes;
    }
  }

  onSaveNewTask(taskListId: any, isFromTopAdd: any, index: number) {
    if (this.isNewTaskSaving) return;
    this.isNewTaskSaving = true;

    if (this.addNewTaskformGroup.invalid) {
      this.addNewTaskformGroup.markAllAsTouched();
      this.isNewTaskSaving = false;
      return;
    }

    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: 0,
      name: this.addNewTaskformGroup.value.taskName,
      description: this.addNewTaskformGroup.value.description ?? '',
      progress: 20,
      taskListId: taskListId,
      startdate: null,
      duedate: null,
      priority: 0,
      estimateMinutes: this.taskEstimateMinutes,
      parentTaskId: null,
      notify: true,
      assignedToUserIds: [],
      taskType: 0,
      taskStatus: TaskStatusType.New,
      subTasks: [],
      positionAfterTask: isFromTopAdd ? -1 : 0,
    };

    this.taskService.create(param).subscribe(
      res => {
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationTextMessage.taskAddedMessage,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.skipcount = 0;
        this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalNumberOfTask += 1;
        this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalEstimatedTime +=
          this.taskEstimateMinutes;

        let items: any[] = Array.isArray(res) ? res : [res];
        if (isFromTopAdd) {
          this.taskList.unshift(...this.mapTaskItems(items));
        } else {
          this.taskList.push(...this.mapTaskItems(items));
        }
        this.taskList = [...this.taskList];
        this.totalTaskCount = this.taskList.length;

        this.isAddNew = false;
        this.addNewTaskTop = false;
        this.showAddNewTaskInput[index] = false;
        this.taskEstimateMinutes = 0;

        setTimeout(() => this.adjustMaxWidth(), 0);
        this.isNewTaskSaving = false;
      },

      err => {
        this.spinnerService.hide();
        this.isNewTaskSaving = false;
      },
    );
  }

  onSaveSubTask(subTaskEditor: any, dataItem: any, taskListId: any): void {
    if (
      !this.isSubTaskNameValid(subTaskEditor.value, dataItem.id) ||
      !this.isSubTaskNameLengthValid(subTaskEditor.value, dataItem.id)
    ) {
      return;
    }

    this.subTaskErrors[dataItem.id] = false;
    this.subTaskMaxLengthErrors[dataItem.id] = false;
    this.isShowSubTaskDescriptionEditor[dataItem.id] = false;
    this.isShowDescriptionEditor = false;

    const param = this.prepareSubTaskParam(subTaskEditor.value, dataItem, taskListId);

    dataItem.isAddSubtask = false;

    this.taskService.create(param).subscribe(
      res => {
        this.handleSubTaskSaveSuccess(res, dataItem);
        this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalNumberOfTask += 1;
        this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalEstimatedTime +=
          this.taskEstimateMinutes;
        this.isShowSubTaskDescriptionEditor[dataItem.id] = false;

        this.taskEstimateMinutes = 0;
        if (this.isNewTaskEstimate) this.setAnEstimateComponent.resetForm();
      },
      err => this.handleSubTaskSaveError(),
    );
  }

  /**
   * Validates the subtask name and updates the error state if invalid.
   */
  private isSubTaskNameValid(subTaskName: string, dataItemId: number): boolean {
    const isValid = subTaskName && subTaskName.trim() !== '';
    this.subTaskErrors[dataItemId] = !isValid;
    return isValid;
  }

  private isSubTaskNameLengthValid(subTaskName: string, dataItemId: number): boolean {
    const isValid = subTaskName.length > 255 ? false : true;
    this.subTaskMaxLengthErrors[dataItemId] = !isValid;
    return isValid;
  }
  /**
   * Prepares the parameter object for creating a new subtask.
   */
  private prepareSubTaskParam(
    subTaskName: string,
    dataItem: any,
    taskListId: any,
  ): CreateUpdateTaskDto {
    return {
      isQuickEdit: true,
      id: 0,
      name: subTaskName.trim(),
      description: dataItem.description,
      progress: 20,
      taskListId,
      startdate: null,
      duedate: null,
      priority: Priority.None,
      estimateMinutes: dataItem.isAddSubtask ? this.taskEstimateMinutes : dataItem.estimateMinutes,
      parentTaskId: dataItem.id,
      notify: true,
      assignedToUserIds: [],
      taskType: TaskType.Task,
      taskStatus: TaskStatusType.New,
      subTasks: [],
    };
  }

  /**
   * Handles the success response for subtask creation.
   */
  private handleSubTaskSaveSuccess(res: any, dataItem: any): void {
    this.toasterService.success(
      NotificationTextMessage.taskAddedMessage,
      '',
      this.commonService.toasterMessageConfiguration,
    );

    this.skipCountForSubTaskList = 0;

    let items: any[] = Array.isArray(res) ? res : [res];

    dataItem.hasChildren = true;
    dataItem.children.push(...this.mapTaskItems(items));

    this.taskList = [...this.taskList];
  }

  /**
   * Handles the error response for subtask creation.
   */
  private handleSubTaskSaveError(): void {
    this.spinnerService.hide();
  }

  reloadOnTaskListDeleted(taskIds) {
    taskIds.forEach(taskId => {
      let tasklistData = this.findTaskById(this.taskList, taskId);

      let timelogMinutes = tasklistData?.timelogMinutes;
      let totalEstimatedTime = tasklistData?.estimateMinutes;

      this.taskList = this.taskList.filter(x => x.id != taskId);
      this.taskList = [...this.taskList];

      this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalTimelogged -=
        timelogMinutes;

      this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalEstimatedTime -=
        totalEstimatedTime;

      if (this.taskList.length == 0 && this.isFromDashboard) {
        const taskListListId = this.taskList[0]?.taskListId;
        this.taskListsList = this.taskListsList.filter(x => x.id !== taskListListId);
      }
    });
  }

  reloadOnCompletedTaskListDeleted(taskIds) {
    taskIds.forEach(taskId => {
      const taskListListId = this.completedTaskList[0]?.taskListId;

      this.completedTaskList = this.completedTaskList.filter(x => x.id != taskId);

      this.completedTaskList = [...this.completedTaskList];

      this.totalCompletedTaskCount -= 1;

      this.taskListsList.find(x => x.id === this.currentExpandedPanelId).completeCount =
        this.totalCompletedTaskCount;

      if (this.completedTaskList.length == 0 && this.isFromDashboard) {
        this.taskListsList = this.taskListsList.filter(x => x.id !== taskListListId);
      }
    });
  }

  onDeleteClick(dataItem: any, isCompleted: boolean): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + dataItem.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.taskService.delete(dataItem.id).subscribe(res => {
          this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalNumberOfTask -= 1;
          this.toasterService.success(
            NotificationTextMessage.taskRemoveMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          if (!isCompleted) {
            this.reloadOnTaskListDeleted([dataItem.id]);
          } else {
            this.reloadOnCompletedTaskListDeleted([dataItem.id]);
          }
        });
      }
    });
  }

  onAddTask(data?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddTaskComponent,
      width: 450,
    });

    const addTaskListInfo = dialogRef.content.instance as AddTaskListComponent;

    addTaskListInfo.taskListId = data.id;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.isLoadingForTaskListList = true;
        this.skipCountForTaskListList = 0;
        this.taskListsList = [];
        this.getTaskListList();
        this.getTaskList(this.currentExpandedPanelId, false);
        setTimeout(() => this.adjustMaxWidth(), 0);
      }
    });
  }

  onAddTaskList(item?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddTaskListComponent,
      width: 450,
    });

    dialogRef.content.instance.projectId = this.projectId ?? item?.projectId;
    const addTaskListInfo = dialogRef.content.instance as AddTaskListComponent;
    addTaskListInfo.taskListId = item?.id;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.taskListsList = [];
        this.skipCountForTaskListList = 0;
        this.maxResultCountForTaskListList = 30;
        this.isLoadingForTaskListList = true;
        this.getTaskListList();
        this.adjustMaxWidth();
      }
    });
  }
  onHoverTaskListLastItem(node: any, index: number, parentNode: any): void {
    const isRootNode = !parentNode;
    this.hoveredTaskId = node.id;

    if (isRootNode) {
      const isLastRootNode = +index === this.taskList.length - 1;

      if (isLastRootNode) {
        this.onScrollTaskList();
      }
    } else if (parentNode?.children) {
    }
  }

  onLeaveTaskListPanel() {
    this.hoveredTaskId = null;
  }

  onHoverCompletedTaskListLastItem(node: any, index: number, parentNode: any): void {
    const isRootNode = !parentNode;

    if (isRootNode) {
      const isLastRootNode = +index === this.completedTaskList.length - 1;
      if (isLastRootNode) {
        this.onScrollCompleteTaskList();
      }
    } else if (parentNode?.children) {
    }
  }

  onTaskNameClick(data: any, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    event.preventDefault(); // Prevent default navigation behavior

    var params: any = {
      taskId: btoa(data.id),
      panelId: btoa(this.currentExpandedPanelId),
      tabId: btoa(TaskListTabEnum.List.toString()),
    };

    if (this.isFromDashboard) {
      params.isFromDashboard = btoa(this.isFromDashboard.toString());
    }

    localStorage.removeItem('url_params');
    const shortKey = this.urlShortener.shortUrl(params);
    const fullUrl = `/task-Detail/${shortKey}`;

    if (this.openInNewTab) {
      window.open(fullUrl, '_blank');
    } else {
      this.router.navigate([fullUrl], { replaceUrl: true });
    }
  }

  getQueryParams(data: any, taskListId: string) {
    return {
      taskId: btoa(data.id),
      projectId: btoa(this.isFromDashboard ? data.projectId : this.projectId),
      taskListId: btoa(taskListId),
      panelId: btoa(this.currentExpandedPanelId),
    };
  }

  onTaskListsListExpandedPanelClick(index: number, item?: any): void {
    this.taskListsList.forEach((_, i) => {
      this.disableAddTaskListIcon[i] = index === i ? false : true;
    });

    this.taskList = [];

    // Handle collapse scenario
    if (item?.expanded) {
      item.expanded = false;
      this.isPanalExpanded = false;
      return; // Stop execution here to prevent updating `currentExpandedPanelId`
    }

    // Handle expand scenario
    if (this.currentExpandedPanelId !== item?.id) {
      const lastSelectedItem = this.taskListsList.find(x => x.id === this.currentExpandedPanelId);
      if (lastSelectedItem) {
        lastSelectedItem.expanded = false;
      }
    }

    item.expanded = true;
    this.currentExpandedPanelId = item.id; // Only update if expanded
    this.isPanalExpanded = true;

    this.panels.forEach((panel, idx) => {
      if (idx !== index && panel.expanded) {
        panel.toggle();
      }
    });

    this.totalCompletedTaskCount = item.completeCount;

    if (this.isFromDashboard) {
      this.getProjectTaskStatusList(item.projectId);
      this.getAssigneeList(item);
    }

    if (
      (!!this.filterRequestParam && this.filterRequestParam.completedOnly) ||
      this.dashboardPanelId === DashboardEnum.Complete
    ) {
      this.taskList = [];
      this.getCompletedTaskList(item.id);
    } else {
      this.isShowCompleteTask = false;
      this.skipcount = 0;
      this.getTaskList(item.id);
    }

    this.isAddNew = false;
  }

  onDropdownOpen() {
    this.closeTaksListActions();
    this.showAssigneePopup = false;
  }

  onTaskListActionClick(data: any, item: any): void {
    const action = data.text;
    switch (action) {
      case TaskListActionEnumEnum[TaskListActionEnumEnum.Edit]:
        this.onAddTaskList(item);
        break;
      case TaskListActionEnumEnum[TaskListActionEnumEnum.Delete]:
        this.onDeleteTaskList(item.id, item.name);
        break;
    }
  }

  onDeleteTaskList(taskListId, taskListName) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + taskListName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.taskListService.delete(taskListId).subscribe(
          res => {
            this.taskListsList = this.taskListsList.filter(x => x.id != taskListId);
            this.adjustMaxWidth();
            this.toasterService.success(
              NotificationMessage.deleteTaskListSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.isNoRecordFound = this.taskListsList.length > 0 ? false : true;
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  onTaskTypeClick(event: any, dataItem: any, taskListId: any) {
    const dueDate = new Date(dataItem.dueDate);
    const startDate = new Date(dataItem.startDate);

    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: dataItem.id,
      name: dataItem.taskName,
      description: dataItem.description,
      progress: 20,
      taskListId: taskListId,
      startdate:
        dataItem.startDate === null
          ? null
          : new TaskDateDto(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()),
      duedate:
        dataItem.dueDate === null
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: dataItem.priority,
      estimateMinutes: dataItem.estimateMinutes,
      parentTaskId: dataItem.taskId,
      notify: true,
      assignedToUserIds:
        dataItem.assignee === null ? [] : dataItem.assignee.map(item => item.userId),
      taskType: event.id,
      taskStatus: dataItem.taskStatus,
      columnId: dataItem.projectTaskStatus,
      subTasks: [],
    };

    this.taskService.update(dataItem.id, param).subscribe(res => {
      dataItem.taskType = event.id;
      if (this.isFromDashboard) this.updateDashboardCount.next();
    });
  }

  getIconClass(taskType: number): string {
    return this.taskTypeList.find(x => x.id === taskType).iconClass;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  onNewTimelogAdded(data, dataItem): void {
    dataItem.timelogMinutes += data?.timelogMinutes;
    dataItem.taskStatus = data.taskStatus;
    if (data.taskStatus === TaskStatusType.Completed) {
      this.getTaskList(
        this.currentExpandedPanelId,
        data.taskStatus === TaskStatusType.Completed || data.taskStatus === TaskStatusType.ReOpened,
      );
    }
    this.taskListsList.find(x => x.id === this.currentExpandedPanelId).totalTimelogged +=
      data.timelogMinutes;
  }

  onEditClick(data: any, isFromEditAction: boolean, drawerTab: DrawerTab): void {
    this.store.dispatch(new SetSubTaskList());
    this.drawerSelectedTabId = drawerTab;
    this.isFromEditAction = isFromEditAction;
    this.isDrawerOpened = !this.isDrawerOpened;
    this.taskId = data.id;
    this.taskDetails = data;
    this.projectId = this.isFromDashboard ? data.projectId : this.projectId;
    this.fileCount = data.attachmentCount;
  }

  updateTaskInHierarchy(tasks: any[], parentId: number, subTaskList: any[]) {
    for (let task of tasks) {
      if (task.id === parentId) {
        if (!task.children) {
          task.children = [];
        }

        const newSubTasks = subTaskList.map(subTask => this.mapTaskItems([subTask])[0]);

        task.children = [...newSubTasks];
        task.totalCount = subTaskList.length;
        task.hasChildren = subTaskList.length ? true : false;

        this.taskList = [...this.taskList];
        return;
      }

      if (task.children && task.children.length > 0) {
        this.updateTaskInHierarchy(task.children, parentId, subTaskList);
      }
    }
  }

  onUpdateSubTaskOnDrawerClose(subTaskList) {
    if (subTaskList.length > this.taskDetails.totalCount) {
      //Update if new sub task is added from drawer
      if (!this.taskDetails) return;

      this.updateTaskInHierarchy(this.taskList, this.taskDetails.id, subTaskList);

      this.taskList = [...this.taskList];
    } else if (subTaskList.length <= this.taskDetails.totalCount) {
      //Update if new sub task is deleted from drawer

      let completedMissingSubtaskIds = this.taskDetails.children
        .filter(
          task =>
            !subTaskList.some(subTask => subTask.id === task.id) &&
            task.taskStatus === TaskStatusType.Completed,
        )
        .map(task => task.id);

      let notCompletedMissingSubtaskIds = this.taskDetails.children
        .filter(
          task =>
            !subTaskList.some(subTask => subTask.id === task.id) &&
            task.taskStatus !== TaskStatusType.Completed,
        )
        .map(task => task.id);

      if (notCompletedMissingSubtaskIds.length > 0) {
        this.reloadOnTaskListDeleted(notCompletedMissingSubtaskIds);
      }

      if (completedMissingSubtaskIds.length > 0) {
        this.reloadOnCompletedTaskListDeleted(completedMissingSubtaskIds);
      }
    }

    var checkSubTaskUpdated = this.taskDetails.children.filter(x => x.id != 0);

    //Update subtask name if updated from drawer
    if (!!checkSubTaskUpdated) {
      checkSubTaskUpdated.forEach(updatedTask => {
        const matchingSubTask = subTaskList.find(subTask => subTask.id === updatedTask.id);
        if (!!matchingSubTask) {
          if (matchingSubTask.name !== updatedTask.taskName) {
            updatedTask.taskName = matchingSubTask.name;
          }
        }
      });
    }
  }

  onTaskDrawerClose(event) {
    combineLatest([
      this.store.select(TaskState.getFileList),
      this.store.select(TaskState.getTotalLoggedTime),
      this.store.select(TaskState.getSubTaskList),
      this.store.select(TaskState.getTaskStatus),
    ])
      .pipe(take(1))
      .subscribe(([fileList, totalLoggedTime, subTaskList, taskStatus]) => {
        this.taskDetails.attachmentCount = fileList.length;
        this.taskDetails.timelogMinutes = totalLoggedTime;
        const taskListItem = this.taskListsList.find(x => x.id === this.currentExpandedPanelId);

        if (taskListItem) {
          taskListItem.totalTimelogged =
            totalLoggedTime !== '' ? totalLoggedTime : taskListItem.totalTimelogged;
          this.taskDetails.timelogMinutes =
            totalLoggedTime !== '' ? totalLoggedTime : taskListItem.totalTimelogged;
        }
        this.skipCountForTaskListList = 0;
        this.updateValueOfGetTaskListList();
        this.onUpdateSubTaskOnDrawerClose(subTaskList);

        if (event.isSuccess) {
          this.taskDetails.taskName = event.data.name;
          this.taskDetails.assignee = event.data.assignedToUserIds;
          this.taskDetails.priority = event.data.priority;
          this.taskDetails.taskType = event.data.taskType;

          if (event.data.startdate !== null) {
            this.taskDetails.startDate = `${event.data.startdate.year}-${String(
              event.data.startdate.month,
            ).padStart(2, '0')}-${String(event.data.startdate.day).padStart(2, '0')}`;
          } else {
            this.taskDetails.startDate = null;
          }

          if (event.data.duedate !== null) {
            this.taskDetails.dueDate = `${event.data.duedate.year}-${String(
              event.data.duedate.month,
            ).padStart(2, '0')}-${String(event.data.duedate.day).padStart(2, '0')}`;
          } else {
            this.taskDetails.dueDate = null;
          }

          this.taskDetails.estimateMinutes = event.data.estimateMinutes;
          this.taskDetails.projectTaskStatus = event.data.columnId;
          this.taskDetails.projectTaskStatusName = event.data.projectColumnName;
          this.taskDetails.description = event.data.description;
        }

        if (this.taskDetails.taskStatus !== taskStatus) {
          this.taskDetails.taskStatus = taskStatus;
          this.getTaskList(
            this.currentExpandedPanelId,
            taskStatus === TaskStatusType.Completed || taskStatus === TaskStatusType.ReOpened,
          );
        }
        this.isDrawerOpened = !this.isDrawerOpened;
      });
  }

  onAddSubtaskClick(dataItem: any): void {
    dataItem.isAddSubtask = true;
    this.taskEstimateMinutes = 0;
    this.taskDetails = undefined;
  }

  onNodeDrop(event: any): void {
    const { sourceItem, destinationItem, dropPosition } = event;

    if (dropPosition === 'over') {
      destinationItem.children = destinationItem.children || [];
      destinationItem.children.push(sourceItem);

      const hasUnderscore = sourceItem.item.index.includes('_');
      const underscoreCount = hasUnderscore ? (sourceItem.item.index.match(/_/g) || []).length : 0;

      const params: UpdateTaskParentTaskIdDto = {
        parentTaskId: underscoreCount == 1 ? null : destinationItem.item.dataItem.id,
        taskId: sourceItem.item.dataItem.id,
      };

      this.taskService.updateTaskParentTaskId(params).subscribe();
    } else if (dropPosition === 'before' || dropPosition === 'after') {
      const parent = this.findParent(this.taskList, destinationItem);
      if (parent) {
        const siblings = parent.children;
        const destIndex = siblings.indexOf(destinationItem);
        siblings.splice(dropPosition === 'before' ? destIndex : destIndex + 1, 0, sourceItem);

        const hasUnderscore = sourceItem.item.index.includes('_');
        const underscoreCount = hasUnderscore
          ? (sourceItem.item.index.match(/_/g) || []).length
          : 0;

        const params: UpdateTaskParentTaskIdDto = {
          parentTaskId: underscoreCount == 1 ? null : destinationItem.item.dataItem.id,
          taskId: sourceItem.item.dataItem.id,
        };

        this.taskService.updateTaskParentTaskId(params).subscribe();
      }
    }

    this.removeNode(this.taskList, sourceItem);
  }

  onNodeDragStart(event: any): void {
    console.log('Drag started:', event.sourceItem);
  }

  findParent(items: any[], child: any): any {
    for (let item of items) {
      if (item.children?.includes(child)) {
        return item;
      }
      if (item.children) {
        const parent = this.findParent(item.children, child);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }

  removeNode(items: any[], node: any): void {
    for (let i = 0; i < items.length; i++) {
      if (items[i] === node) {
        items.splice(i, 1);
        return;
      }
      if (items[i].children) {
        this.removeNode(items[i].children, node);
      }
    }
  }

  onScrollTaskListList(event: any): void {
    const target = event.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.getTaskListList();
      setTimeout(() => this.adjustMaxWidth(), 0);
    }
  }

  onTimelogIconClick(event: MouseEvent, data: any) {
    this.showAssigneePopup = false;
    this.loadDialog = true;
    this.togglePopup('showTimelogPopup', event, data);
  }

  onPanelMouseEnter(taskId: number): void {
    this.isMouseInsidePanel = true;
    this.popupTaskId = taskId;
    if (this.timeLogpopupCloseTimeout) {
      clearTimeout(this.timeLogpopupCloseTimeout);
    }
    if (this.estimatePopupCloseTimeout) {
      clearTimeout(this.estimatePopupCloseTimeout);
    }
    if (this.priorityDropdownCloseTimeout) {
      clearTimeout(this.priorityDropdownCloseTimeout);
    }
    if (this.statusDropdownCloseTimeout) {
      clearTimeout(this.statusDropdownCloseTimeout);
    }

    if (this.assigneePopupCloseTimeout) {
      clearTimeout(this.assigneePopupCloseTimeout);
    }
  }

  onPanelMouseLeave(): void {
    this.isMouseInsidePanel = false;
    this.isAnyPopupOpen = false;
    this.schedulePanelClose();
  }

  schedulePanelClose(): void {
    if (!this.isMouseInsidePanel) {
      this.popupTaskId = null;
      this.hoveredTaskId = null;
      this.timeLogpopupCloseTimeout = setTimeout(() => {
        this.showTimelogPopup = false;
      }, 200);
      this.estimatePopupCloseTimeout = setTimeout(() => {
        this.showEstimatePopup = false;
      }, 200);
      this.priorityDropdownCloseTimeout = setTimeout(() => {
        this.showPriorityDropdown = false;
      }, 200);
      this.statusDropdownCloseTimeout = setTimeout(() => {
        this.showStatusPopup = false;
      }, 200);
      this.assigneePopupCloseTimeout = setTimeout(() => {
        this.showAssigneePopup = false;
      }, 200);
    }
  }

  private setPopupTimeout(flagName: string, timeoutName: string, delay: number): void {
    this[timeoutName] = setTimeout(() => {
      this[flagName] = false;
    }, delay);
  }

  onEstimateHoverOut(): void {
    this.setPopupTimeout('showEstimatePopup', 'estimatePopupCloseTimeout', 200);
  }

  onAssigneeHoverOut(): void {
    this.setPopupTimeout('showAssigneePopup', 'assigneePopupCloseTimeout', 200);
  }

  onPriorityHoverOut(): void {
    this.setPopupTimeout('showPriorityDropdown', 'priorityDropdownCloseTimeout', 200);
  }

  onStatusHoverOut(): void {
    this.setPopupTimeout('showStatusPopup', 'statusDropdownCloseTimeout', 200);
  }

  onTimelogIconHoverOut(): void {
    this.setPopupTimeout('showTimelogPopup', 'timeLogpopupCloseTimeout', 500);
  }

  closeTaskTypeDropdown(): void {
    this.taskTypeDropdowns?.forEach(dropdown => {
      if (dropdown.isOpen) {
        dropdown.toggle(false);
      }
    });
  }

  closeTaksListActions(): void {
    this.taksListActions?.forEach(dropdown => {
      if (dropdown.isOpen) {
        dropdown.toggle(false);
      }
    });

    this.closeTaskTypeDropdown();
  }

  openSetAssignee(event: MouseEvent, data: any) {
    this.tempDataItem = data;
    this.togglePopup('showAssigneePopup', event, data);

    if (this.showAssigneePopup) {
      if (!this.isFromDashboard) {
        if (!this.assigneeList || this.assigneeList.length === 0) {
          this.getAssigneeList(data);
        }
      }
      this.selectedAssignee = data.assignee ?? [];
    }
  }

  openSetEstimate(event: MouseEvent, data: any, isNewTaskEstimate: boolean = false) {
    this.showAssigneePopup = false;
    this.isNewTaskEstimate = isNewTaskEstimate;

    this.tempTaskDetailsEstimateMinutes = data?.estimateMinutes ?? 0;
    this.togglePopup('showEstimatePopup', event, data);
  }

  togglePriorityDropdown(event: MouseEvent, data: any): void {
    this.showAssigneePopup = false;
    this.togglePopup('showPriorityDropdown', event, data);
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    this.priorityDropdownPosition = {
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    };
  }

  toggleSetDatePopup(event: Event): void {
    this.togglePopup('isSetDatePopupVisible', event);
    event.preventDefault();
  }

  onShowTaskFilter(event: MouseEvent): void {
    this.togglePopup('showTaskFilterPopup', event);
  }

  toggleStatusPopup(event: MouseEvent, data: any): void {
    this.showAssigneePopup = false;
    this.togglePopup('showStatusPopup', event, data);
    if (this.taskStatusTypeList.length === 0) {
      this.showStatusPopup = false;
      this.toasterService.warn(
        NotificationTextMessage.noTaskStatusListAvailableMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  private togglePopup(flagName: string, event: MouseEvent | Event, data: any = null): void {
    this[flagName] = !this[flagName];
    if (data) this.taskDetails = data;

    this.closeTaksListActions();
    this.tempTaskDetailsEstimateMinutes = data?.estimateMinutes;
    this.tempLogtimedata = data;
    const targetElement = event.currentTarget as HTMLElement;
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      this.popupPosition = {
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      };
    }
    event.stopPropagation();
  }

  onPrioritySelected(priority: number, dataItem): void {
    this.taskDetails.priority = priority;
    this.updateTaskList(dataItem);
    this.showPriorityDropdown = false;
    this.popupTaskId = null;
    this.hoveredTaskId = null;
  }

  closePriorityDropdown(): void {
    this.showPriorityDropdown = false;
  }

  onScrollTaskList(): void {
    // Exit if data is currently being fetched or if all tasks have been loaded
    if (this.isLoadingForTaskList || this.taskList.length >= this.totalTaskCount) {
      return;
    }

    // Set loading flag to true to prevent further calls until data is loaded
    this.isLoadingForTaskList = true;

    // Increment the skip count for pagination
    this.skipcount += this.pageSize;

    // Build parameters for the task list request
    const param = this.buildTaskListParams(this.currentExpandedPanelId, this.skipcount, false);

    // Fetch the data
    this.taskService.getList(param).subscribe(
      ({ items, totalCount }) => {
        this.totalTaskCount = totalCount;
        const newData = this.mapTaskItems(items);
        this.taskList = [...this.taskList, ...newData];
        this.isLoadingForTaskList = false;
      },
      error => {
        this.isLoadingForTaskList = false;
      },
    );
  }

  onScrollCompleteTaskList(): void {
    if (
      this.isLoadingForCompletedTaskList ||
      this.completedTaskList.length >= this.totalCompletedTaskCount
    ) {
      return;
    }

    this.isLoadingForCompletedTaskList = true;

    this.skipCountForCompletedTask += this.pageSize;

    const param = this.buildTaskListParams(
      this.currentExpandedPanelId,
      this.skipCountForCompletedTask,
      true,
    );

    // Fetch the data
    this.taskService.getList(param).subscribe(
      ({ items, totalCount }) => {
        // Update total count and task list
        this.totalCompletedTaskCount = totalCount;

        // Map new data to the task list
        const newData = this.mapTaskItems(items);
        this.completedTaskList = [...this.completedTaskList, ...newData];

        // Hide the spinner and reset loading flag
        this.isLoadingForCompletedTaskList = false;
      },
      error => {
        console.error('Error loading tasks:', error);

        // Handle error, hide spinner, and reset loading flag
        this.isLoadingForCompletedTaskList = false;
      },
    );
  }

  closeSetDatePopup(): void {
    this.isSetDatePopupVisible = false;
  }

  getProjectTaskStatusList(projectId?: number): void {
    const data: any = {
      projectId: this.isFromDashboard ? projectId : this.projectId,
    };

    this.projectBoardColumnService.getList(data).subscribe(
      response => {
        this.taskStatusTypeList = response.items.map((item: any) => ({
          key: item.id,
          value: item.columnname,
        }));
        this.taskStatusTypeList = orderBy(this.taskStatusTypeList, [{ field: 'key', dir: 'asc' }]);
      },
      err => {},
    );
  }

  onStatusSelected(projectTaskStatus: any, dataItem): void {
    this.taskDetails.projectTaskStatus = projectTaskStatus.key;
    this.updateTaskList(dataItem);
    this.showStatusPopup = false;
    this.popupTaskId = null;
    this.hoveredTaskId = null;
  }

  getAssigneeList(data: any): void {
    const params = {
      sorting: 'name asc',
      skipCount: 0,
      maxResultCount: 1000,
    };

    this.projectUser
      .getPeople(this.isFromDashboard ? data.projectId : this.projectId, params)
      .subscribe(res => {
        if (res.items.length > 0) {
          this.assigneeList = this.commonService.preprocessAssigneeList(res.items);
          this.assigneListItems = this.assigneeList.map(assignee => ({
            userName: assignee.userName,
            userId: assignee.userId,
          }));
        }
      });
  }

  onTabChange(e: SelectEvent): void {
    this.loadDialog = false;
    this.currentExpandedPanelId = this.isPanalExpanded ? this.currentExpandedPanelId : null;

    if (e.title === 'List') {
      this.getProjectTaskStatusList();
      setTimeout(() => (this.loadDialog = true), 100);
    }
  }

  onCollapse(index: number, item?: any): void {
    this.showAddNewTaskInput[index] = false;
    this.disableAddTaskListIcon[index] = true;
    this.currentExpandedPanelId = this.isPanalExpanded ? this.currentExpandedPanelId : null;
  }

  TaskListNameWidth() {
    setTimeout(() => this.adjustMaxWidth(), 0);
  }
}
