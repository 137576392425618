import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TimeLogPopupComponent } from '../../time-log-popup/time-log-popup.component';
import { WindowService } from '@progress/kendo-angular-dialog';
import { CommonService } from 'src/core/services';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';

@Component({
  selector: 'app-log-time-panel',
  templateUrl: './log-time-panel.component.html',
  styleUrls: ['./log-time-panel.component.scss'],
})
export class LogTimePanelComponent {
  isProjectBillable = false;
  startTime: Date;
  endTime: Date;
  @Output() triggerViewTimelog = new EventEmitter();
  @Output() triggerTimelogAdded = new EventEmitter();

  @Input() isVisible: boolean = false;
  @Input() anchor!: HTMLElement | null;
  @Input() projectId: any;
  @Input() taskData: any = null;
  @Input() position: { top: number; left: number } | null = null;

  constructor(
    private commonService: CommonService,
    private projectService: ProjectService,
    private windowService: WindowService,
    private cdr: ChangeDetectorRef,
  ) {}

  getProjectDetail(): void {
    this.projectService.get(this.projectId ?? this.taskData.projectId).subscribe((res: any) => {
      this.isProjectBillable = res.isBillable;
      this.openTimeLog();
    });
  }

  onViewTimelogClick(): void {
    this.triggerViewTimelog.emit();
  }

  onStartTimerClick() {
    const timerData = {
      showTimer: true,
      taskId: this.taskData.id,
      projectId: this.projectId ?? this.taskData.projectId,
    };

    this.commonService.showTimerPopup(timerData);
  }

  onLogMoreTimeClick(): void {
    this.getProjectDetail();
  }

  openTimeLog() {
    if (this.commonService.isTimelogWindowOpen()) return;

    this.commonService.manageScrollPosition();

    document.body.style.overflow = 'hidden';

    const overlayDiv = document.createElement('div');
    overlayDiv.className = 'k-overlay';
    document.body.appendChild(overlayDiv);

    const windowRef = this.windowService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly timelog-popup timelog-window',
      title: 'Log Time',
      resizable: false,
    });

    windowRef.window.instance.leftChange.subscribe(position => {
      this.cdr.detectChanges();
      this.commonService.restrictTimelogMovement();
    });

    windowRef.window.instance.topChange.subscribe(position => {
      this.cdr.detectChanges();
      this.commonService.restrictTimelogMovement();
    });

    this.startTime = new Date();
    this.endTime = new Date();
    windowRef.content.instance.startTime = this.startTime;
    windowRef.content.instance.endTime = this.endTime;
    windowRef.content.instance.taskId = this.taskData.id;
    windowRef.content.instance.taskName = this.taskData.taskName;
    windowRef.content.instance.projectId = this.projectId ?? this.taskData.projectId;
    windowRef.content.instance.isProjectBillable = this.isProjectBillable;
    windowRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.triggerTimelogAdded.emit(res.data);
        }
      }
      document.body.style.overflow = 'auto';
      document.body.removeChild(overlayDiv);
    });
  }
}
