import { Component, OnInit } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { AddProductComponent } from './add-product/add-product.component';
import { StatusTypeEnum } from 'src/app/enum/status-type-enum';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid';
import { CommonService } from 'src/core/services';
import { ProductService } from '@proxy/project-service/product';
import { GetProductListDto } from '@proxy/project-service/products/dtos';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterService } from '@abp/ng.theme.shared';
import { ListService } from '@abp/ng.core';
import { GetIdentityUsersInput, IdentityUserService } from '@abp/ng.identity/proxy';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  searchText = '';
  public selectedFilterValue = 1;
  public statusFilter: Array<{ text: string; value: number }> = [{ text: 'All Status', value: 1 }];
  statusType = StatusTypeEnum;
  gridHeight: number;
  openInNewTab = false;

  //#region  Pagination
  public gridView: GridDataResult;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 10;
  public skip = 0;
  public sorting = null;
  public multiple = false;
  public allowUnsort = true;
  isNoRecords = false;
  //#endregion

  public sort: SortDescriptor[] = [
    {
      field: 'name',
    },
    {
      field: 'lastVisit',
    },
    {
      field: 'status',
    },
  ];

  userList: any[];
  constructor(
    private dialogService: DialogService,
    public commonService: CommonService,
    private productService: ProductService,
    public readonly list: ListService<GetIdentityUsersInput>,
    protected service: IdentityUserService,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getUserList();
    this.getList();
    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.calculateGridHeight();
    });
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;
    const gridHeaderHeight = 50;
    let headerHeight: number;
    if (screenWidth < 769) {
      headerHeight = 96;
    } else {
      headerHeight = 48;
    }
    const wrapperPadding = 40;
    let calculatedHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperPadding);

    if (screenWidth < 1024) {
      const TitleHeight = 54;
      calculatedHeight -= TitleHeight;
    }

    this.gridHeight = calculatedHeight;
  }

  onProductAdd(id: any): void {
    const dialogRef = this.dialogService.open({
      content: AddProductComponent,
      width: 450,
    });
    const attachFileInfo = dialogRef.content.instance as AddProductComponent;
    attachFileInfo.productId = id;
    attachFileInfo.setOnLoadData();
    dialogRef.result.subscribe((res: any) => {
      if (res?.confirmed) {
        this.getList();
      }
    });
  }

  onDelete(data: any): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + data.name + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();

        this.productService.delete(data.id).subscribe(
          res => {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationMessage.deleteProductSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.searchText = '';
            this.getList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  onSearchTextChange(searchText: string): void {
    if (searchText.length > 2 || searchText.length === 0) {
      this.getList();
    }
  }

  getList(): void {
    this.spinnerService.show();

    const param: GetProductListDto = {
      sorting: this.sorting,
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      searchTerm: this.searchText,
    };

    this.productService.getList(param).subscribe((res: any) => {
      this.spinnerService.hide();
      this.gridView = {
        data: orderBy(res.items, this.sort),
        total: res.totalCount,
      };
      this.isNoRecords = res.items.length === 0;
    });
  }

  getUserList() {
    this.list
      .hookToQuery(query => this.service.getList(query))
      .subscribe(res => {
        this.userList = res.items;
      });
  }

  onProductClick(data, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    if (this.openInNewTab) return;

    event.preventDefault();

    if (!this.openInNewTab) {
      const params = {
        productId: btoa(data.id),
        productName: btoa(data.name),
        isFromProduct: btoa(JSON.stringify(true)),
      };

      this.router.navigate(['project-list'], { queryParams: params });
    }
  }

  getQueryParams(data: any) {
    return {
      productId: btoa(data.id),
      productName: btoa(data.name),
      isFromProduct: btoa(JSON.stringify(true)),
    };
  }

  getCreatedByName(createdById) {
    if (!this.userList) {
      return null;
    }
    const foundItem = this.userList.find(item => createdById === item.id);
    if (foundItem) {
      return foundItem.name;
    } else {
      return null;
    }
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.getList();
  }

  sortChange(sort): void {
    this.sort = sort;
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getList();
  }

  onSearch(event: any): void {
    this.getList();
  }
}
