<div class="q-content">
  <div class="q-content-header d-flex justify-content-end mb-20 flex-wrap gap-20">
    <div class="d-flex gap-15 justify-content-between flex-wrap gap-20">
      <div class="filter-icon-container" (click)="exportTimesheet()">
        <span title="Export Excel"><i class="fa-solid fa-download k-icon font-size-14"></i></span>
      </div>
      <div class="filter-icon-container" (click)="openFilter()">
        <span><i title="Filter" class="fas fa-filter k-icon font-size-14"></i></span>
        <span>
          {{ filterCount }}
        </span>
      </div>
    </div>
  </div>
  <div class="q-content-bg">
    <div class="grid-wrapper">
      <kendo-grid
        #grid
        [data]="gridView"
        [resizable]="true"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="
          gridView === undefined
            ? false
            : {
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: pagerposition,
              }
        "
        (pageChange)="pageChange($event)"
        [height]="gridHeight"
        [groupable]="false"
        class="custom-no-records-container time-dashborad-grid"
      >
        <ng-template kendoGridNoRecordsTemplate>
          <div *ngIf="isNoRecords" class="no-records-text-container">No Records Available</div>
        </ng-template>
        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="timelogUserFirstName"
          title="Who"
          filter="string"
          [width]="40"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex gap-10">
              <kendo-avatar
                rounded="full"
                size="large"
                [border]="true"
                [initials]="commonService.getInitials(dataItem.timelogUserFirstName)"
                [style.backgroundColor]="
                  commonService.generateAvtarColor(
                    dataItem.timelogUserFirstName,
                    dataItem.timelogUserFirstName
                  )
                "
                class="social-profile-text"
              ></kendo-avatar>
              <div class="customer-name">
                {{ dataItem.timelogUserFirstName }} {{ dataItem.timelogUserLastName }}
              </div>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="taskName"
          title="Description"
          filter="string"
          [width]="150"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex gap-5">
              <span class="task-name task-title" *ngIf="dataItem.taskName"> Task: </span>
              <a
                class="text-underline line-height-inherit word-break"
                (click)="onGoToTaskDetailsClick(dataItem)"
                title="{{ dataItem.taskName }}"
              >
                {{ dataItem.taskName }}
              </a>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="tasklistName"
          title="Task List"
          filter="string"
          [width]="40"
          [sortable]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex gap-5">
              <span class="clamp" title="{{ dataItem.tasklistName }}">
                {{ dataItem.tasklistName }}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="start"
          title="Start"
          filter="string"
          [width]="20"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.timelogDatetime | date: 'HH:mm' }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="end"
          title="End"
          filter="string"
          [width]="20"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{
              calculateEndTime(dataItem.timelogDatetime, dataItem.timelogMinutes) | date: 'HH:mm'
            }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="time"
          title="Actual Time"
          filter="string"
          [width]="30"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.timelogMinutes }}m
          </ng-template>
          <ng-template kendoGridGroupFooterTemplate let-aggregates> </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="hoursDecimal"
          title="Actual Hours"
          filter="number"
          [width]="30"
        >
          <ng-template kendoGridGroupFooterTemplate let-aggregates>
            <span *ngIf="!isBillableColumnVisible">
              {{ aggregates['hoursDecimal'].sum | number: '1.2-2' }}h</span
            >
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="timelogBillableMinutes"
          title="Billable Hours"
          filter="number"
          [width]="30"
          [hidden]="isBillableColumnVisible"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span title="{{ dataItem.timelogBillableMinutes }}">{{
              commonService.getHoursAndMinutesFromTotalMinutes(dataItem.timelogBillableMinutes)
            }}</span>
          </ng-template>
          <ng-template kendoGridGroupFooterTemplate let-aggregates>
            <span *ngIf="!isBillableColumnVisible">
              {{ aggregates['timelogBillableMinutes'].sum / 60 | number: '1.2-2' }}h</span
            >
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="billed"
          title="Billed"
          filter="boolean"
          [width]="20"
          [hidden]="isBillableColumnVisible"
        >
          <ng-template kendoGridCellTemplate let-dataItem
            ><i class="fa-sharp fa-solid fa-circle-xmark font-size-18 billed-icon"></i>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [columnMenu]="false"
          [filterable]="true"
          field="status"
          title=""
          filter="boolean"
          [width]="20"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <ul class="grid-action-list">
              <div class="d-flex gap-10">
                <li>
                  <a title="Edit" (click)="onEdit(dataItem)">
                    <i class="far fa-pen"></i>
                  </a>
                </li>
              </div>
            </ul>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>
<kendo-drawer-container class="add-more-details">
  <kendo-drawer
    [mode]="expandMode"
    [expanded]="isDrawerOpened"
    [position]="position"
    [autoCollapse]="false"
  >
    <ng-template kendoDrawerTemplate>
      <div class="d-flex justify-content-between drawer-header align-items-center">
        <h4 class="title">Filter</h4>
        <a class="close-icon" (click)="closeDrawer()"><i class="far fa-xmark"></i></a>
      </div>
      <div class="drawer-content">
        <kendo-dropdownlist
          class="w-200"
          [data]="dateFilter"
          textField="text"
          valueField="value"
          [value]="selectedFilterValue"
          [valuePrimitive]="true"
          (selectionChange)="selectionChange($event)"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            {{ dataItem.text }}
          </ng-template>
        </kendo-dropdownlist>
        <div>
          <div class="mb-1" *ngIf="selectedFilterValue == 1">
            <kendo-label text="Start Date" labelCssClass="k-form-label"></kendo-label>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <kendo-datepicker
                  calendarType="classic"
                  [format]="'EEE, d MMM yyyy'"
                  (valueChange)="updateStartDate($event)"
                  [(ngModel)]="startDate"
                ></kendo-datepicker>
              </div>
            </div>
            <kendo-label text="End Date" labelCssClass="k-form-label"></kendo-label>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <kendo-datepicker
                  calendarType="classic"
                  [format]="'EEE, d MMM yyyy'"
                  (valueChange)="updateEndDate($event)"
                  [(ngModel)]="endDate"
                ></kendo-datepicker>
              </div>
            </div>
            <div *ngIf="dateError" class="text-danger">
              End Date cannot be earlier than Start Date.
            </div>
          </div>
        </div>
      </div>
      <div class="drawer-footer d-flex justify-content-center gap-15">
        <button kendoButton fillMode="outline" (click)="clearFilter()">Clear</button>
      </div>
    </ng-template>
  </kendo-drawer>
</kendo-drawer-container>
