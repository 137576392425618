import { ListService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ProductService } from '@proxy/project-service/product';
import { CreateUpdateProductDto } from '@proxy/project-service/products/dtos';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { NotificationMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent {
  productForm: FormGroup;
  productId = 0;
  headerText: string;
  listItemData: any;
  items: any[] = [];
  listItems: any[] = [];
  value: any;

  constructor(
    public readonly list: ListService,
    public dialogRef: DialogRef,
    private toasterService: ToasterService,
    private projectService: ProjectService,
    public commonService: CommonService,
    private productService: ProductService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.setForm();
  }

  setOnLoadData(): void {
    this.headerText = this.productId === 0 ? 'Create a new Product' : 'Update Product';
    this.getProjectList();
  }

  getProductDataById(): void {
    this.productService.get(this.productId).subscribe(res => {
      let selectedProjectList: any[] = [];
      if (res.projectIds.length > 0) {
        res.projectIds.forEach(element => {
          const data = this.listItems.find(x => x.value === element);
          if (data) {
            selectedProjectList.push(data);
          }
        });
      }
      this.productForm.patchValue({
        name: res.name,
        projectIds: selectedProjectList,
      });
      this.spinnerService.hide();
    });
  }

  getProjectList(): void {
    const data: any = {
      maxResultCount: 1000,
    };
    const ProjectStreamCreator = () => this.projectService.getList(data);
    this.list.hookToQuery(ProjectStreamCreator).subscribe(response => {
      this.items = response.items;
      this.listItems = this.items.map(project => ({ text: project.name, value: project.id }));
      this.listItemData = this.listItems.slice();
      if (this.productId > 0) {
        this.spinnerService.show();
        this.getProductDataById();
      }
    });
  }

  setForm(): void {
    this.productForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(5000)]),
      projectIds: new FormControl([]),
    });
  }

  onSubmit(): void {
    if (this.productForm.invalid) {
      this.productForm.controls.name.markAsTouched();
      return;
    }

    this.spinnerService.show();

    let productIds: any[] = [];
    const productData = this.productForm.controls.projectIds.value;
    if (productData.length > 0) {
      productData.forEach(element => {
        productIds.push(element.value);
      });
    }
    const param: CreateUpdateProductDto = {
      name: this.productForm.controls.name.value,
      status: 0,
      projectIds: productIds,
    };

    if (this.productId > 0) {
      this.productService.update(this.productId, param).subscribe((res: any) => {
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.updateProductSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.commonService.onDialogClose(this.dialogRef, true);
      });
    } else {
      this.productService.create(param).subscribe((res: any) => {
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.saveProductSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.commonService.onDialogClose(this.dialogRef, true);
      });
    }
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }

  public onProjectSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { text: string; value: number }) =>
      item.text.toLowerCase().includes(value.toLowerCase());

    this.listItemData = this.listItems.filter(contains(searchTerm));
  }
}
