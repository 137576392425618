import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashbord.component.html',
  styleUrls: ['./project-dashbord.component.scss'],
})
export class ProjectDashboardComponent implements OnInit {
  projectId: any;
  leftTimeTasksList: any[];
  overTimeTasksList: any[];
  gridHeight: number;
  projectName: any;
  public multiple = false;
  public allowUnsort = true;
  public sorting = null;
  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];
  constructor(
    private _Activatedroute: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private taskService: TaskService,
    public commonService: CommonService,
    private router: Router,
  ) {
    this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.projectId = +atob(params.get('projectId'));
        this.projectName = atob(params.get('projectName'));
        this.getLeftAndOverTimeTasksList();
      }
    });
  }

  ngOnInit(): void {}

  getLeftAndOverTimeTasksList(): void {
    this.spinnerService.show();
    this.taskService.getLeftAndOverTimeTasksList(this.projectId).subscribe(res => {
      this.spinnerService.hide();
      this.overTimeTasksList = res.overTimeTasks;
      this.leftTimeTasksList = res.leftTimeTasks;
    });
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getLeftAndOverTimeTasksList();
  }

  onGoToTaskDetailsClick(data, taskListId): void {
    var params = {
      taskId: btoa(data.taskId),
      projectId: btoa(this.projectId),
      projectName: btoa(this.projectName),
      taskListId: btoa(taskListId),
    };

    this.router.navigate(['task-Detail', params]);
  }
}
